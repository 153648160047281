/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Location } from '../model/location';
import { PageLocation } from '../model/pageLocation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { LocationsServiceInterface, IlocationsCheckLocationIsUsedParams,IlocationsCreateParams,IlocationsDeleteParams,IlocationsDeleteByListParams,IlocationsGetListParams,IlocationsGetListWithAreasParams,IlocationsGetListWithBinsParams,IlocationsGetListWithLocationAccessesParams,IlocationsGetLocationByIDParams,IlocationsGetSearchListParams,IlocationsUpdateParams,IlocationsUpdateByListParams,IlocationsUploadLocationsParams, }                            from './locations.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class LocationsService implements LocationsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param locationIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsCheckLocationIsUsed({ deviceId, message, ignoreLoadingBar, formData, locationIds } : IlocationsCheckLocationIsUsedParams, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public locationsCheckLocationIsUsed({ deviceId, message, ignoreLoadingBar, formData, locationIds } : IlocationsCheckLocationIsUsedParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public locationsCheckLocationIsUsed({ deviceId, message, ignoreLoadingBar, formData, locationIds } : IlocationsCheckLocationIsUsedParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    @datastore
    public locationsCheckLocationIsUsed({ deviceId, message, ignoreLoadingBar, formData, locationIds } : IlocationsCheckLocationIsUsedParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationIds === null || locationIds === undefined) {
            throw new Error('Required parameter locationIds was null or undefined when calling locationsCheckLocationIsUsed.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationIds) {
            locationIds.forEach((element) => {
                queryParameters = queryParameters.append('locationIds', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<string>>(`${this.basePath}/Locations/CheckLocationIsUsed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<Location>;
    public locationsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
    public locationsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
    @datastore
    public locationsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling locationsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Location>(`${this.basePath}/Locations`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsDelete({ deviceId, message, ignoreLoadingBar, formData, locationID } : IlocationsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationsDelete({ deviceId, message, ignoreLoadingBar, formData, locationID } : IlocationsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationsDelete({ deviceId, message, ignoreLoadingBar, formData, locationID } : IlocationsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public locationsDelete({ deviceId, message, ignoreLoadingBar, formData, locationID } : IlocationsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling locationsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Locations/${encodeURIComponent(String(locationID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, locationIDs } : IlocationsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, locationIDs } : IlocationsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, locationIDs } : IlocationsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public locationsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, locationIDs } : IlocationsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationIDs === null || locationIDs === undefined) {
            throw new Error('Required parameter locationIDs was null or undefined when calling locationsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationIDs) {
            locationIDs.forEach((element) => {
                queryParameters = queryParameters.append('locationIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Locations/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsGetList({ deviceId, message, ignoreLoadingBar, formData, locationID, page, views } : IlocationsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Location>>;
    public locationsGetList({ deviceId, message, ignoreLoadingBar, formData, locationID, page, views } : IlocationsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Location>>>;
    public locationsGetList({ deviceId, message, ignoreLoadingBar, formData, locationID, page, views } : IlocationsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Location>>>;
    @datastore
    public locationsGetList({ deviceId, message, ignoreLoadingBar, formData, locationID, page, views } : IlocationsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Location>>(`${this.basePath}/Locations`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param areaID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsGetListWithAreas({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, page, views } : IlocationsGetListWithAreasParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Location>>;
    public locationsGetListWithAreas({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, page, views } : IlocationsGetListWithAreasParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Location>>>;
    public locationsGetListWithAreas({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, page, views } : IlocationsGetListWithAreasParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Location>>>;
    @datastore
    public locationsGetListWithAreas({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, page, views } : IlocationsGetListWithAreasParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling locationsGetListWithAreas.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Location>>(`${this.basePath}/Locations/${encodeURIComponent(String(locationID))}/Areas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param areaID 
     * @param binID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsGetListWithBins({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, binID, page, views } : IlocationsGetListWithBinsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Location>>;
    public locationsGetListWithBins({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, binID, page, views } : IlocationsGetListWithBinsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Location>>>;
    public locationsGetListWithBins({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, binID, page, views } : IlocationsGetListWithBinsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Location>>>;
    @datastore
    public locationsGetListWithBins({ deviceId, message, ignoreLoadingBar, formData, locationID, areaID, binID, page, views } : IlocationsGetListWithBinsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling locationsGetListWithBins.');
        }
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling locationsGetListWithBins.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (binID !== undefined && binID !== null) {
            queryParameters = queryParameters.set('binID', <any>binID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Location>>(`${this.basePath}/Locations/${encodeURIComponent(String(locationID))}/Areas/${encodeURIComponent(String(areaID))}/Bins`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param locationAccesID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsGetListWithLocationAccesses({ deviceId, message, ignoreLoadingBar, formData, locationID, locationAccesID, page, views } : IlocationsGetListWithLocationAccessesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Location>>;
    public locationsGetListWithLocationAccesses({ deviceId, message, ignoreLoadingBar, formData, locationID, locationAccesID, page, views } : IlocationsGetListWithLocationAccessesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Location>>>;
    public locationsGetListWithLocationAccesses({ deviceId, message, ignoreLoadingBar, formData, locationID, locationAccesID, page, views } : IlocationsGetListWithLocationAccessesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Location>>>;
    @datastore
    public locationsGetListWithLocationAccesses({ deviceId, message, ignoreLoadingBar, formData, locationID, locationAccesID, page, views } : IlocationsGetListWithLocationAccessesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling locationsGetListWithLocationAccesses.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationAccesID !== undefined && locationAccesID !== null) {
            queryParameters = queryParameters.set('locationAccesID', <any>locationAccesID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Location>>(`${this.basePath}/Locations/${encodeURIComponent(String(locationID))}/LocationAccesses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsGetLocationByID({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IlocationsGetLocationByIDParams, observe?: 'body', reportProgress?: boolean): Observable<Location>;
    public locationsGetLocationByID({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IlocationsGetLocationByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Location>>;
    public locationsGetLocationByID({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IlocationsGetLocationByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Location>>;
    @datastore
    public locationsGetLocationByID({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IlocationsGetLocationByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling locationsGetLocationByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Location>(`${this.basePath}/Locations/GetLocationByID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param division 
     * @param locationDescription 
     * @param locationChineseDescription 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, division, locationDescription, locationChineseDescription, page, take, views } : IlocationsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageLocation>;
    public locationsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, division, locationDescription, locationChineseDescription, page, take, views } : IlocationsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageLocation>>;
    public locationsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, division, locationDescription, locationChineseDescription, page, take, views } : IlocationsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageLocation>>;
    @datastore
    public locationsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, division, locationDescription, locationChineseDescription, page, take, views } : IlocationsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (division !== undefined && division !== null) {
            queryParameters = queryParameters.set('division', <any>division);
        }
        if (locationDescription !== undefined && locationDescription !== null) {
            queryParameters = queryParameters.set('locationDescription', <any>locationDescription);
        }
        if (locationChineseDescription !== undefined && locationChineseDescription !== null) {
            queryParameters = queryParameters.set('locationChineseDescription', <any>locationChineseDescription);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageLocation>(`${this.basePath}/Locations/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsUpdate({ deviceId, message, ignoreLoadingBar, formData, locationID, model } : IlocationsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationsUpdate({ deviceId, message, ignoreLoadingBar, formData, locationID, model } : IlocationsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationsUpdate({ deviceId, message, ignoreLoadingBar, formData, locationID, model } : IlocationsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public locationsUpdate({ deviceId, message, ignoreLoadingBar, formData, locationID, model } : IlocationsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling locationsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling locationsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/Locations/${encodeURIComponent(String(locationID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IlocationsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IlocationsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IlocationsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public locationsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IlocationsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling locationsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/Locations/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, projectID } : IlocationsUploadLocationsParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, projectID } : IlocationsUploadLocationsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, projectID } : IlocationsUploadLocationsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public locationsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, projectID } : IlocationsUploadLocationsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling locationsUploadLocations.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Locations/UploadLocation/${encodeURIComponent(String(projectID))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
