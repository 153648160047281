/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { LocationAccess } from '../model/locationAccess';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { LocationAccessesServiceInterface, IlocationAccessesCreateParams,IlocationAccessesDeleteParams,IlocationAccessesGetListParams,IlocationAccessesUpdateParams, }                            from './locationAccesses.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class LocationAccessesService implements LocationAccessesServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationAccessesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationAccessesCreateParams, observe?: 'body', reportProgress?: boolean): Observable<LocationAccess>;
    public locationAccessesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationAccessesCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<LocationAccess>>;
    public locationAccessesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationAccessesCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<LocationAccess>>;
    @datastore
    public locationAccessesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IlocationAccessesCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling locationAccessesCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<LocationAccess>(`${this.basePath}/LocationAccesses`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationAccessID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationAccessesDelete({ deviceId, message, ignoreLoadingBar, formData, locationAccessID } : IlocationAccessesDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationAccessesDelete({ deviceId, message, ignoreLoadingBar, formData, locationAccessID } : IlocationAccessesDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationAccessesDelete({ deviceId, message, ignoreLoadingBar, formData, locationAccessID } : IlocationAccessesDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public locationAccessesDelete({ deviceId, message, ignoreLoadingBar, formData, locationAccessID } : IlocationAccessesDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationAccessID === null || locationAccessID === undefined) {
            throw new Error('Required parameter locationAccessID was null or undefined when calling locationAccessesDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/LocationAccesses/${encodeURIComponent(String(locationAccessID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationAccessID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationAccessesGetList({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, page, views } : IlocationAccessesGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<LocationAccess>>;
    public locationAccessesGetList({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, page, views } : IlocationAccessesGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<LocationAccess>>>;
    public locationAccessesGetList({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, page, views } : IlocationAccessesGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<LocationAccess>>>;
    @datastore
    public locationAccessesGetList({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, page, views } : IlocationAccessesGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationAccessID !== undefined && locationAccessID !== null) {
            queryParameters = queryParameters.set('locationAccessID', <any>locationAccessID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<LocationAccess>>(`${this.basePath}/LocationAccesses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationAccessID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public locationAccessesUpdate({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, model } : IlocationAccessesUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public locationAccessesUpdate({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, model } : IlocationAccessesUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public locationAccessesUpdate({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, model } : IlocationAccessesUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public locationAccessesUpdate({ deviceId, message, ignoreLoadingBar, formData, locationAccessID, model } : IlocationAccessesUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationAccessID === null || locationAccessID === undefined) {
            throw new Error('Required parameter locationAccessID was null or undefined when calling locationAccessesUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling locationAccessesUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/LocationAccesses/${encodeURIComponent(String(locationAccessID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
