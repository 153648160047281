import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector, Optional } from '@angular/core';
import * as momentImported from 'moment';
import { BehaviorSubject, Subject } from 'rxjs';

const moment = momentImported;

// import { appInjector } from '~/app/app-injector';

export const DS_STATUS = 'DataStatus';
export const DS_ONLINE_RESPONSE_LOG_FORMAT = 'color:black;background-color:yellow';
export const DS_RESEND_LOG_FORMAT = 'color:white;-backgroundcolor:orange';
export const DS_OFFLINE_LOG_FORMAT = 'color:black;background-color: lightcyan';

export namespace DataStore {
  export type DataStatusEnum = 'BACKEND' | 'CACHE' | 'CREATE_OK' | 'CREATE_FAIL' | 'CREATE_PENDING' | 'CREATE_QUEUE' | 'DELETE_OK' | 'DELETE_FAIL' | 'DELETE_PENDING' | 'DELETE_QUEUE' | 'UPDATE_OK' | 'UPDATE_FAIL' | 'UPDATE_PENDING' | 'UPDATE_QUEUE';
  export const Status = {
    BACKEND: 'BACKEND' as DataStatusEnum,
    CACHE: 'CACHE' as DataStatusEnum,
    CREATE_OK: 'CREATE_OK' as DataStatusEnum,
    CREATE_FAIL: 'CREATE_FAIL' as DataStatusEnum,
    CREATE_PENDING: 'CREATE_PENDING' as DataStatusEnum,
    CREATE_QUEUE: 'CREATE_QUEUE' as DataStatusEnum,
    DELETE_OK: 'DELETE_OK' as DataStatusEnum,
    DELETE_FAIL: 'DELETE_FAIL' as DataStatusEnum,
    DELETE_PENDING: 'DELETE_PENDING' as DataStatusEnum,
    DELETE_QUEUE: 'DELETE_QUEUE' as DataStatusEnum,
    UPDATE_OK: 'UPDATE_OK' as DataStatusEnum,
    UPDATE_FAIL: 'UPDATE_FAIL' as DataStatusEnum,
    UPDATE_PENDING: 'UPDATE_PENDING' as DataStatusEnum,
    UPDATE_QUEUE: 'UPDATE_QUEUE' as DataStatusEnum
  };
}
export interface DataStoreVariables {
  [key: string]: DataStoreReturn;
}
export interface DataStoreReturn {
  value: any;
  state: ReturnState;
}

enum ReturnState {
  LOCAL,
  REMOTE
}

export const ONLINE_CHECK_URL = new InjectionToken('ONLINE_CHECK_URL');
export const ENABLE_DATA_STORE = new InjectionToken('ENABLE_DATA_STORE');

@Injectable(
  { providedIn: 'root' }
)
export class DataStoreService {
  private variableVault = {};
  private v = {};
  private isScheduleRunning = false;
  private PREFIX_CACHE = '[CACHE]';
  private PREFIX_PENDING = '[PENDIG]';
  public valueTotalSize = 0;
  RESEND_PENDING_INTERVAL = 60 * 1000;
  RESEND_TIMER;
  private lastIsOnline = false;
  private OnlineStateSubject = new BehaviorSubject<boolean>(false);
  OnlineState$ = this.OnlineStateSubject.asObservable();
  constructor(
    @Optional() @Inject(ONLINE_CHECK_URL) public onlineCheckUrl: string,
    @Optional() @Inject(ENABLE_DATA_STORE) public isEnable: boolean,
    private http: HttpClient) {
    console.log(this.onlineCheckUrl);
  }

  GetVault<T>(type: new () => T) {
    if (this.variableVault[type.name] == null) { this.variableVault[type.name] = new type(); }
    return this.variableVault[type.name];
  }

  SetVault<T>(name: string, vault: object) {
    this.variableVault[name] = vault;
    return this.variableVault[name];
  }

  isOnline(): Promise<boolean> {
    if (this.onlineCheckUrl != null) {
      const img = new Image();
      img.src = this.onlineCheckUrl + '?' + Math.random();
      return new Promise<boolean>(resolve => {
        img.onload = () => {
          this.lastIsOnline = true;
          this.OnlineStateSubject.next(this.lastIsOnline);
          resolve(true);
        };
        img.onerror = () => {
          this.lastIsOnline = false;
          this.OnlineStateSubject.next(this.lastIsOnline);
          resolve(false);
        };
      });
    } else {
      return new Promise<boolean>(resolve => true);
    }
  }

  ResentPendingRequest() {
    this.isOnline().then((online) => {
      if (this.isScheduleRunning != true && online) {
        this.isScheduleRunning = true;
        for (const key in localStorage) {
          if (key.startsWith(this.PREFIX_PENDING) && localStorage.hasOwnProperty(key)) {
            const params = /(\[.*\])\@(.*)\.(.*)\((.*)\)/.exec(key);
            console.log(`full:${params[0]}`);
            console.log(`prefix:${params[1]}`);
            console.log(`service:${params[2]}`);
            console.log(`method:${params[3]}`);
            console.log(`param:${decodeURIComponent(params[4])}`);
            console.log(`....`);

            this.dsRemoveCache(`${params[0]}`);
            const sev = DataStoreInjector.getService(params[2]);
            try {
              sev[params[3]](JSON.parse(decodeURIComponent(params[4])))
                .subscribe();
            } catch (e) {
              console.log(`retry ${params[3]} fail: ${e}`);
            }
          }
        }
        this.isScheduleRunning = false;
        console.log(`%c ResentPendingRequest:finished ${new Date()}`, DS_RESEND_LOG_FORMAT);
      } else {
        console.log(`${moment().format('LLLL')} isScheduleRunning:${this.isScheduleRunning} online:${online}`);
      }
    });
  }

  getV(name: string) {
    console.log('getV:' + decodeURIComponent(name));
    if (this.v[name] == null || this.v[name].isStopped) { this.v[name] = new Subject<any>(); }
    return this.v[name];
  }

  dsOfflineResponse(requestType: string, key: string, model: object): Promise<void> {
    return new Promise((resolve, reject) => {
      console.log(`%c ds-Offline-Response ${requestType}: ${decodeURIComponent(key)}`, DS_OFFLINE_LOG_FORMAT, Error());
      let nextData = null;
      this.isOnline().then((online) => {
        switch (requestType) {
          case 'Create':
            (model || {})['DataStatus'] = online ? DataStore.Status.CREATE_PENDING : DataStore.Status.CREATE_QUEUE;
            nextData = model;
            break;
          case 'Update':
            nextData = online ? DataStore.Status.UPDATE_PENDING : DataStore.Status.UPDATE_QUEUE;
            break;
          case 'Delete':
            nextData = online ? DataStore.Status.DELETE_PENDING : DataStore.Status.DELETE_QUEUE;
            break;
          default:
            // case 'GetList':
            const fromCache = this.dsGetCache(`${this.PREFIX_CACHE}@${key}`);
            nextData = this.dsSetStatus(fromCache, DataStore.Status.CACHE);
            break;
        }
        if (['Create', 'Update'].indexOf(requestType) > -1) {
          const error = model ? false : true;
          if (error) { throw new Error(`${decodeURIComponent(key)} ${requestType} failed`); }
        }
        if (!online) {
          this.dsSaveCache(`${this.PREFIX_PENDING}@${key}`, model);
          clearInterval(this.RESEND_TIMER);
          this.RESEND_TIMER = setInterval(() => this.ResentPendingRequest(), this.RESEND_PENDING_INTERVAL);
        }
        if (nextData) { this.getV(key).next(nextData); }
        resolve();
      });
    });
  }

  dsSetStatus(result: any, status: DataStore.DataStatusEnum) {
    if (result instanceof Object && !this.isPrimitive(result)) {
      ((Array.isArray(result) ? result : result.Items) || []).forEach(item => {
        if (item instanceof Object) { item[DS_STATUS] = status; }
      });
      result[DS_STATUS] = status;
    }
    return result;
  }

  dsOnlineResponse(requestType: string, key: string, data: object, success: boolean = true) {
    console.log(`%c ds-Online-Response ${requestType}: ${decodeURIComponent(key)}`, DS_ONLINE_RESPONSE_LOG_FORMAT);
    let nextData = null;
    switch (requestType) {
      case 'Create':
        data['DataStatus'] = success ? DataStore.Status.CREATE_OK : DataStore.Status.CREATE_FAIL;
        nextData = data;
        break;
      case 'Update':
        nextData = success ? DataStore.Status.UPDATE_OK : DataStore.Status.UPDATE_FAIL;
        break;
      case 'Delete':
        nextData = success ? DataStore.Status.DELETE_OK : DataStore.Status.DELETE_FAIL;
        break;
      case 'PENDING':
        nextData = data;
        break;
      default:
        // case 'GetList':
        if (success) {
          this.dsSaveCache(`${this.PREFIX_CACHE}@${key}`, data);
          nextData = this.dsSetStatus(data, DataStore.Status.BACKEND);
        }
        break;
    }
    this.dsRemoveCache(`${this.PREFIX_PENDING}@${key}`);
    // if (nextData) {
    this.getV(key).next(nextData);
    this.getV(key).complete();
    // }
  }

  dsFlatten(data) {
    const result = {};
    function recurse(cur, prop) {
      if (Object(cur) !== cur) {
        result[prop] = cur;
      } else if (Array.isArray(cur)) {
        for (let i = 0; i < cur.length; i++) {
          recurse(cur[i], prop + '[' + i + ']');
        }
        if (cur.length == 0) {
          result[prop] = [];
        }
      } else {
        let isEmpty = true;
        for (const p in cur) {
          isEmpty = false;
          recurse(cur[p], prop ? prop + '.' + p : p);
        }
        if (isEmpty && prop) {
          result[prop] = {};
        }
      }
    }
    recurse(data, '');
    return result;
  }

  dsUnFlatten(data) {
    'use strict';
    if (Object(data) !== data || Array.isArray(data)) {
      return data;
    }
    const regex = /\.?([^.\[\]]+)|\[(\d+)\]/g,
      resultholder = {};
    for (const p in data) {
      let cur = resultholder,
        prop = '',
        m;
      while (m = regex.exec(p)) {
        cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
        prop = m[2] || m[1];
      }
      cur[prop] = data[p];
    }
    return resultholder[''] || resultholder;
  }

  dsRequestType(propertyName: string) {
    return (/Create|Get|Update|Delete/.exec(propertyName) || [])[0];
  }

  dsGetCache(key: string) {
    return JSON.parse(localStorage.getItem(key));
  }

  dsSaveCache(key: string, results: any) {
    const toCache = JSON.stringify(results);
    if (toCache) {
      if (toCache.length <= 500 * 1024) {
        localStorage.setItem(key, toCache);
        this.valueTotalSize = this.valueTotalSize + toCache.length;
      } else {
        console.warn(`Datastore size exceeded: ${toCache.length} ${decodeURIComponent(key)}`);
      }
    }
  }

  dsClearCache() {
    for (const key in localStorage) {
      if (key.startsWith(this.PREFIX_CACHE) && localStorage.hasOwnProperty(key)) {
        this.dsRemoveCache(key);
      }
    }
  }
  dsRemoveCache(key: string) {
    localStorage.removeItem(key);
  }

  dsSubjectName(target: object, propertyName: string, ...args: any[]) {
    const params = args.map(a => JSON.stringify(a)).join();
    const subjectName = encodeURIComponent(`${target.constructor.name}.${propertyName}(${params})`);
    return subjectName;
  }

  isPrimitive(val) {
    if (val === null) { return false; }
    return !((typeof val === 'function') || (typeof val === 'object'));
  }

}

@Injectable(
  { providedIn: 'root' }
)
export class DataStoreInjector {
  private static Injector: Injector | undefined = undefined;
  public constructor(injector: Injector) {
    DataStoreInjector.Injector = injector;
  }
  public static getService(name: string) {
    if (DataStoreInjector.Injector == null) { throw new Error(`injector not init`); }
    let service;
    try {
      service = DataStoreInjector.Injector.get(name);
    } catch (e) {
      throw new Error(`${name} not init`);
    }
    return service;
  }
}
