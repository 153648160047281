/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AreaStatus } from '../model/areaStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { WarehouseDashboardServiceInterface, IwarehouseDashboardConsumedTrendParams,IwarehouseDashboardGetAreaStatusParams, }                            from './warehouseDashboard.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class WarehouseDashboardService implements WarehouseDashboardServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param projectID 
     * @param previousNumberOfDays 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseDashboardConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IwarehouseDashboardConsumedTrendParams, observe?: 'body', reportProgress?: boolean): Observable<Array<AreaStatus>>;
    public warehouseDashboardConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IwarehouseDashboardConsumedTrendParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AreaStatus>>>;
    public warehouseDashboardConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IwarehouseDashboardConsumedTrendParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AreaStatus>>>;
    @datastore
    public warehouseDashboardConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IwarehouseDashboardConsumedTrendParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling warehouseDashboardConsumedTrend.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (previousNumberOfDays !== undefined && previousNumberOfDays !== null) {
            queryParameters = queryParameters.set('previousNumberOfDays', <any>previousNumberOfDays);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<AreaStatus>>(`${this.basePath}/WarehouseDashboard/ConsumedTrend/${encodeURIComponent(String(projectID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public warehouseDashboardGetAreaStatus({ deviceId, message, ignoreLoadingBar, formData, locationID } : IwarehouseDashboardGetAreaStatusParams, observe?: 'body', reportProgress?: boolean): Observable<Array<AreaStatus>>;
    public warehouseDashboardGetAreaStatus({ deviceId, message, ignoreLoadingBar, formData, locationID } : IwarehouseDashboardGetAreaStatusParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AreaStatus>>>;
    public warehouseDashboardGetAreaStatus({ deviceId, message, ignoreLoadingBar, formData, locationID } : IwarehouseDashboardGetAreaStatusParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AreaStatus>>>;
    @datastore
    public warehouseDashboardGetAreaStatus({ deviceId, message, ignoreLoadingBar, formData, locationID } : IwarehouseDashboardGetAreaStatusParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling warehouseDashboardGetAreaStatus.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<AreaStatus>>(`${this.basePath}/WarehouseDashboard/AreaStatus/${encodeURIComponent(String(locationID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
