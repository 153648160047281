/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { InventoryPlan } from '../model/inventoryPlan';
import { InventoryPlanSummary } from '../model/inventoryPlanSummary';
import { OutstandingInventory } from '../model/outstandingInventory';
import { ProjectTransferSuccessRateDOT } from '../model/projectTransferSuccessRateDOT';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { InventoryPlansServiceInterface, IinventoryPlansClearRemarksParams,IinventoryPlansCreateParams,IinventoryPlansDeleteParams,IinventoryPlansDownloadProjectTransferSummaryPDFParams,IinventoryPlansEncryptRequestIDParams,IinventoryPlansGetInventoryPlanParams,IinventoryPlansGetInventoryPlanByApprovalHeaderIDParams,IinventoryPlansGetInventoryPlanByEncryptedIDParams,IinventoryPlansGetInventoryPlanByIDParams,IinventoryPlansGetInventoryPlanJsonByIDParams,IinventoryPlansGetOutstandingInventorysParams,IinventoryPlansGetStatusCountParams,IinventoryPlansGetSummaryParams,IinventoryPlansProjectTransferSuccessRateParams,IinventoryPlansSaveRemarksParams,IinventoryPlansSearchInventoryPlanParams,IinventoryPlansSiteEntranceAvailableParams,IinventoryPlansStatReceiptAndIssueParams,IinventoryPlansUpdateParams,IinventoryPlansUpdateInventoryPlanAndReceiptPlanStatusParams,IinventoryPlansUpdateInventoryPlanWhenSubmitedParams, }                            from './inventoryPlans.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class InventoryPlansService implements InventoryPlansServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param remarksType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansClearRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType } : IinventoryPlansClearRemarksParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public inventoryPlansClearRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType } : IinventoryPlansClearRemarksParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public inventoryPlansClearRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType } : IinventoryPlansClearRemarksParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public inventoryPlansClearRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType } : IinventoryPlansClearRemarksParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansClearRemarks.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (remarksType !== undefined && remarksType !== null) {
            queryParameters = queryParameters.set('remarksType', <any>remarksType);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<boolean>(`${this.basePath}/InventoryPlans/ClearRemarks/${encodeURIComponent(String(inventoryPlanID))}`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryPlansCreateParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryPlan>;
    public inventoryPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryPlansCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryPlan>>;
    public inventoryPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryPlansCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryPlan>>;
    @datastore
    public inventoryPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryPlansCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling inventoryPlansCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<InventoryPlan>(`${this.basePath}/InventoryPlans`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryPlansDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryPlansDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryPlansDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/InventoryPlans/${encodeURIComponent(String(inventoryPlanID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param encryptedID 
     * @param currentLanguage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansDownloadProjectTransferSummaryPDF({ deviceId, message, ignoreLoadingBar, formData, encryptedID, currentLanguage } : IinventoryPlansDownloadProjectTransferSummaryPDFParams, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public inventoryPlansDownloadProjectTransferSummaryPDF({ deviceId, message, ignoreLoadingBar, formData, encryptedID, currentLanguage } : IinventoryPlansDownloadProjectTransferSummaryPDFParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public inventoryPlansDownloadProjectTransferSummaryPDF({ deviceId, message, ignoreLoadingBar, formData, encryptedID, currentLanguage } : IinventoryPlansDownloadProjectTransferSummaryPDFParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    @datastore
    public inventoryPlansDownloadProjectTransferSummaryPDF({ deviceId, message, ignoreLoadingBar, formData, encryptedID, currentLanguage } : IinventoryPlansDownloadProjectTransferSummaryPDFParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (encryptedID === null || encryptedID === undefined) {
            throw new Error('Required parameter encryptedID was null or undefined when calling inventoryPlansDownloadProjectTransferSummaryPDF.');
        }
        if (currentLanguage === null || currentLanguage === undefined) {
            throw new Error('Required parameter currentLanguage was null or undefined when calling inventoryPlansDownloadProjectTransferSummaryPDF.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (encryptedID !== undefined && encryptedID !== null) {
            queryParameters = queryParameters.set('encryptedID', <any>encryptedID);
        }
        if (currentLanguage !== undefined && currentLanguage !== null) {
            queryParameters = queryParameters.set('currentLanguage', <any>currentLanguage);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get(`${this.basePath}/InventoryPlans/DownloadProjectTransferSummaryPDF`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansEncryptRequestID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansEncryptRequestIDParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryPlansEncryptRequestID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansEncryptRequestIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryPlansEncryptRequestID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansEncryptRequestIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryPlansEncryptRequestID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansEncryptRequestIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansEncryptRequestID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryPlanID !== undefined && inventoryPlanID !== null) {
            queryParameters = queryParameters.set('inventoryPlanID', <any>inventoryPlanID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<string>(`${this.basePath}/InventoryPlans/EncryptRequestID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param projectID 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, projectID, status } : IinventoryPlansGetInventoryPlanParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryPlan>;
    public inventoryPlansGetInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, projectID, status } : IinventoryPlansGetInventoryPlanParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryPlan>>;
    public inventoryPlansGetInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, projectID, status } : IinventoryPlansGetInventoryPlanParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryPlan>>;
    @datastore
    public inventoryPlansGetInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, projectID, status } : IinventoryPlansGetInventoryPlanParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansGetInventoryPlan.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryPlanID !== undefined && inventoryPlanID !== null) {
            queryParameters = queryParameters.set('inventoryPlanID', <any>inventoryPlanID);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<InventoryPlan>(`${this.basePath}/InventoryPlans/GetInventoryPlan`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approvalHeadrId 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetInventoryPlanByApprovalHeaderID({ deviceId, message, ignoreLoadingBar, formData, approvalHeadrId, views } : IinventoryPlansGetInventoryPlanByApprovalHeaderIDParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryPlan>;
    public inventoryPlansGetInventoryPlanByApprovalHeaderID({ deviceId, message, ignoreLoadingBar, formData, approvalHeadrId, views } : IinventoryPlansGetInventoryPlanByApprovalHeaderIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryPlan>>;
    public inventoryPlansGetInventoryPlanByApprovalHeaderID({ deviceId, message, ignoreLoadingBar, formData, approvalHeadrId, views } : IinventoryPlansGetInventoryPlanByApprovalHeaderIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryPlan>>;
    @datastore
    public inventoryPlansGetInventoryPlanByApprovalHeaderID({ deviceId, message, ignoreLoadingBar, formData, approvalHeadrId, views } : IinventoryPlansGetInventoryPlanByApprovalHeaderIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (approvalHeadrId === null || approvalHeadrId === undefined) {
            throw new Error('Required parameter approvalHeadrId was null or undefined when calling inventoryPlansGetInventoryPlanByApprovalHeaderID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalHeadrId !== undefined && approvalHeadrId !== null) {
            queryParameters = queryParameters.set('approvalHeadrId', <any>approvalHeadrId);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<InventoryPlan>(`${this.basePath}/InventoryPlans/GetInventoryPlanByApprovalHeaderID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param encryptedID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetInventoryPlanByEncryptedID({ deviceId, message, ignoreLoadingBar, formData, encryptedID, views } : IinventoryPlansGetInventoryPlanByEncryptedIDParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryPlan>;
    public inventoryPlansGetInventoryPlanByEncryptedID({ deviceId, message, ignoreLoadingBar, formData, encryptedID, views } : IinventoryPlansGetInventoryPlanByEncryptedIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryPlan>>;
    public inventoryPlansGetInventoryPlanByEncryptedID({ deviceId, message, ignoreLoadingBar, formData, encryptedID, views } : IinventoryPlansGetInventoryPlanByEncryptedIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryPlan>>;
    @datastore
    public inventoryPlansGetInventoryPlanByEncryptedID({ deviceId, message, ignoreLoadingBar, formData, encryptedID, views } : IinventoryPlansGetInventoryPlanByEncryptedIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (encryptedID === null || encryptedID === undefined) {
            throw new Error('Required parameter encryptedID was null or undefined when calling inventoryPlansGetInventoryPlanByEncryptedID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (encryptedID !== undefined && encryptedID !== null) {
            queryParameters = queryParameters.set('encryptedID', <any>encryptedID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<InventoryPlan>(`${this.basePath}/InventoryPlans/GetInventoryPlanByEncryptedID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetInventoryPlanByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, views } : IinventoryPlansGetInventoryPlanByIDParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryPlan>;
    public inventoryPlansGetInventoryPlanByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, views } : IinventoryPlansGetInventoryPlanByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryPlan>>;
    public inventoryPlansGetInventoryPlanByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, views } : IinventoryPlansGetInventoryPlanByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryPlan>>;
    @datastore
    public inventoryPlansGetInventoryPlanByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, views } : IinventoryPlansGetInventoryPlanByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansGetInventoryPlanByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryPlanID !== undefined && inventoryPlanID !== null) {
            queryParameters = queryParameters.set('inventoryPlanID', <any>inventoryPlanID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<InventoryPlan>(`${this.basePath}/InventoryPlans/GetInventoryPlanByID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetInventoryPlanJsonByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansGetInventoryPlanJsonByIDParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryPlansGetInventoryPlanJsonByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansGetInventoryPlanJsonByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryPlansGetInventoryPlanJsonByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansGetInventoryPlanJsonByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryPlansGetInventoryPlanJsonByID({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID } : IinventoryPlansGetInventoryPlanJsonByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansGetInventoryPlanJsonByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryPlanID !== undefined && inventoryPlanID !== null) {
            queryParameters = queryParameters.set('inventoryPlanID', <any>inventoryPlanID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<string>(`${this.basePath}/InventoryPlans/GetInventoryPlanJsonByID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNo 
     * @param divisionCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetOutstandingInventorys({ deviceId, message, ignoreLoadingBar, formData, projectNo, divisionCode } : IinventoryPlansGetOutstandingInventorysParams, observe?: 'body', reportProgress?: boolean): Observable<Array<OutstandingInventory>>;
    public inventoryPlansGetOutstandingInventorys({ deviceId, message, ignoreLoadingBar, formData, projectNo, divisionCode } : IinventoryPlansGetOutstandingInventorysParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OutstandingInventory>>>;
    public inventoryPlansGetOutstandingInventorys({ deviceId, message, ignoreLoadingBar, formData, projectNo, divisionCode } : IinventoryPlansGetOutstandingInventorysParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OutstandingInventory>>>;
    @datastore
    public inventoryPlansGetOutstandingInventorys({ deviceId, message, ignoreLoadingBar, formData, projectNo, divisionCode } : IinventoryPlansGetOutstandingInventorysParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectNo !== undefined && projectNo !== null) {
            queryParameters = queryParameters.set('projectNo', <any>projectNo);
        }
        if (divisionCode !== undefined && divisionCode !== null) {
            queryParameters = queryParameters.set('divisionCode', <any>divisionCode);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<OutstandingInventory>>(`${this.basePath}/InventoryPlans/OutstandingInventorys/List`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param isReceiptRequest 
     * @param projectID 
     * @param showAll 
     * @param checkJobAll 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetStatusCount({ deviceId, message, ignoreLoadingBar, formData, isReceiptRequest, projectID, showAll, checkJobAll } : IinventoryPlansGetStatusCountParams, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public inventoryPlansGetStatusCount({ deviceId, message, ignoreLoadingBar, formData, isReceiptRequest, projectID, showAll, checkJobAll } : IinventoryPlansGetStatusCountParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public inventoryPlansGetStatusCount({ deviceId, message, ignoreLoadingBar, formData, isReceiptRequest, projectID, showAll, checkJobAll } : IinventoryPlansGetStatusCountParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    @datastore
    public inventoryPlansGetStatusCount({ deviceId, message, ignoreLoadingBar, formData, isReceiptRequest, projectID, showAll, checkJobAll } : IinventoryPlansGetStatusCountParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (isReceiptRequest === null || isReceiptRequest === undefined) {
            throw new Error('Required parameter isReceiptRequest was null or undefined when calling inventoryPlansGetStatusCount.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isReceiptRequest !== undefined && isReceiptRequest !== null) {
            queryParameters = queryParameters.set('isReceiptRequest', <any>isReceiptRequest);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (showAll !== undefined && showAll !== null) {
            queryParameters = queryParameters.set('showAll', <any>showAll);
        }
        if (checkJobAll !== undefined && checkJobAll !== null) {
            queryParameters = queryParameters.set('checkJobAll', <any>checkJobAll);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<number>>(`${this.basePath}/InventoryPlans/GetStatusCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansGetSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryPlansGetSummaryParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryPlanSummary>;
    public inventoryPlansGetSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryPlansGetSummaryParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryPlanSummary>>;
    public inventoryPlansGetSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryPlansGetSummaryParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryPlanSummary>>;
    @datastore
    public inventoryPlansGetSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryPlansGetSummaryParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryPlansGetSummary.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<InventoryPlanSummary>(`${this.basePath}/InventoryPlans/GetSummary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param source 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansProjectTransferSuccessRate({ deviceId, message, ignoreLoadingBar, formData, source } : IinventoryPlansProjectTransferSuccessRateParams, observe?: 'body', reportProgress?: boolean): Observable<ProjectTransferSuccessRateDOT>;
    public inventoryPlansProjectTransferSuccessRate({ deviceId, message, ignoreLoadingBar, formData, source } : IinventoryPlansProjectTransferSuccessRateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectTransferSuccessRateDOT>>;
    public inventoryPlansProjectTransferSuccessRate({ deviceId, message, ignoreLoadingBar, formData, source } : IinventoryPlansProjectTransferSuccessRateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectTransferSuccessRateDOT>>;
    @datastore
    public inventoryPlansProjectTransferSuccessRate({ deviceId, message, ignoreLoadingBar, formData, source } : IinventoryPlansProjectTransferSuccessRateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (source === null || source === undefined) {
            throw new Error('Required parameter source was null or undefined when calling inventoryPlansProjectTransferSuccessRate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (source !== undefined && source !== null) {
            queryParameters = queryParameters.set('source', <any>source);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<ProjectTransferSuccessRateDOT>(`${this.basePath}/InventoryPlans/ProjectTransferSuccessRate`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param remarksType 
     * @param remarks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansSaveRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType, remarks } : IinventoryPlansSaveRemarksParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public inventoryPlansSaveRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType, remarks } : IinventoryPlansSaveRemarksParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public inventoryPlansSaveRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType, remarks } : IinventoryPlansSaveRemarksParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public inventoryPlansSaveRemarks({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, remarksType, remarks } : IinventoryPlansSaveRemarksParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansSaveRemarks.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (remarksType !== undefined && remarksType !== null) {
            queryParameters = queryParameters.set('remarksType', <any>remarksType);
        }
        if (remarks !== undefined && remarks !== null) {
            queryParameters = queryParameters.set('remarks', <any>remarks);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<boolean>(`${this.basePath}/InventoryPlans/SaveRemarks/${encodeURIComponent(String(inventoryPlanID))}`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param searchMode 
     * @param projectID 
     * @param locationID 
     * @param source 
     * @param supplierNo 
     * @param requestor 
     * @param fromDeliveryDate 
     * @param toDeliveryDate 
     * @param datePeriod 
     * @param teamID 
     * @param siteEntranceID 
     * @param licensePlateNumber 
     * @param showAll 
     * @param page 
     * @param take 
     * @param status 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansSearchInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, searchMode, projectID, locationID, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, siteEntranceID, licensePlateNumber, showAll, page, take, status, views } : IinventoryPlansSearchInventoryPlanParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryPlanSummary>;
    public inventoryPlansSearchInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, searchMode, projectID, locationID, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, siteEntranceID, licensePlateNumber, showAll, page, take, status, views } : IinventoryPlansSearchInventoryPlanParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryPlanSummary>>;
    public inventoryPlansSearchInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, searchMode, projectID, locationID, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, siteEntranceID, licensePlateNumber, showAll, page, take, status, views } : IinventoryPlansSearchInventoryPlanParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryPlanSummary>>;
    @datastore
    public inventoryPlansSearchInventoryPlan({ deviceId, message, ignoreLoadingBar, formData, searchMode, projectID, locationID, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, siteEntranceID, licensePlateNumber, showAll, page, take, status, views } : IinventoryPlansSearchInventoryPlanParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (searchMode === null || searchMode === undefined) {
            throw new Error('Required parameter searchMode was null or undefined when calling inventoryPlansSearchInventoryPlan.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchMode !== undefined && searchMode !== null) {
            queryParameters = queryParameters.set('searchMode', <any>searchMode);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (source !== undefined && source !== null) {
            queryParameters = queryParameters.set('source', <any>source);
        }
        if (supplierNo !== undefined && supplierNo !== null) {
            queryParameters = queryParameters.set('supplierNo', <any>supplierNo);
        }
        if (requestor !== undefined && requestor !== null) {
            queryParameters = queryParameters.set('requestor', <any>requestor);
        }
        if (fromDeliveryDate !== undefined && fromDeliveryDate !== null) {
            queryParameters = queryParameters.set('fromDeliveryDate', <any>fromDeliveryDate.toISOString());
        }
        if (toDeliveryDate !== undefined && toDeliveryDate !== null) {
            queryParameters = queryParameters.set('toDeliveryDate', <any>toDeliveryDate.toISOString());
        }
        if (datePeriod !== undefined && datePeriod !== null) {
            queryParameters = queryParameters.set('datePeriod', <any>datePeriod);
        }
        if (teamID !== undefined && teamID !== null) {
            queryParameters = queryParameters.set('teamID', <any>teamID);
        }
        if (siteEntranceID !== undefined && siteEntranceID !== null) {
            queryParameters = queryParameters.set('siteEntranceID', <any>siteEntranceID);
        }
        if (licensePlateNumber !== undefined && licensePlateNumber !== null) {
            queryParameters = queryParameters.set('licensePlateNumber', <any>licensePlateNumber);
        }
        if (showAll !== undefined && showAll !== null) {
            queryParameters = queryParameters.set('showAll', <any>showAll);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<InventoryPlanSummary>(`${this.basePath}/InventoryPlans/SearchInventoryPlan`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param siteEntranceID 
     * @param targetedDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansSiteEntranceAvailable({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, targetedDate } : IinventoryPlansSiteEntranceAvailableParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public inventoryPlansSiteEntranceAvailable({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, targetedDate } : IinventoryPlansSiteEntranceAvailableParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public inventoryPlansSiteEntranceAvailable({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, targetedDate } : IinventoryPlansSiteEntranceAvailableParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public inventoryPlansSiteEntranceAvailable({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, targetedDate } : IinventoryPlansSiteEntranceAvailableParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryPlansSiteEntranceAvailable.');
        }
        if (siteEntranceID === null || siteEntranceID === undefined) {
            throw new Error('Required parameter siteEntranceID was null or undefined when calling inventoryPlansSiteEntranceAvailable.');
        }
        if (targetedDate === null || targetedDate === undefined) {
            throw new Error('Required parameter targetedDate was null or undefined when calling inventoryPlansSiteEntranceAvailable.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (siteEntranceID !== undefined && siteEntranceID !== null) {
            queryParameters = queryParameters.set('siteEntranceID', <any>siteEntranceID);
        }
        if (targetedDate !== undefined && targetedDate !== null) {
            queryParameters = queryParameters.set('targetedDate', <any>targetedDate.toISOString());
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/InventoryPlans/SiteEntranceAvailable`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param fromDate 
     * @param toDate 
     * @param inventoryPlanStatus 
     * @param count 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansStatReceiptAndIssue({ deviceId, message, ignoreLoadingBar, formData, projectID, fromDate, toDate, inventoryPlanStatus, count } : IinventoryPlansStatReceiptAndIssueParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryPlan>>;
    public inventoryPlansStatReceiptAndIssue({ deviceId, message, ignoreLoadingBar, formData, projectID, fromDate, toDate, inventoryPlanStatus, count } : IinventoryPlansStatReceiptAndIssueParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryPlan>>>;
    public inventoryPlansStatReceiptAndIssue({ deviceId, message, ignoreLoadingBar, formData, projectID, fromDate, toDate, inventoryPlanStatus, count } : IinventoryPlansStatReceiptAndIssueParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryPlan>>>;
    @datastore
    public inventoryPlansStatReceiptAndIssue({ deviceId, message, ignoreLoadingBar, formData, projectID, fromDate, toDate, inventoryPlanStatus, count } : IinventoryPlansStatReceiptAndIssueParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryPlansStatReceiptAndIssue.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (inventoryPlanStatus !== undefined && inventoryPlanStatus !== null) {
            queryParameters = queryParameters.set('inventoryPlanStatus', <any>inventoryPlanStatus);
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<InventoryPlan>>(`${this.basePath}/InventoryPlans/StatReceiptAndIssue`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling inventoryPlansUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/InventoryPlans/${encodeURIComponent(String(inventoryPlanID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansUpdateInventoryPlanAndReceiptPlanStatus({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, status } : IinventoryPlansUpdateInventoryPlanAndReceiptPlanStatusParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryPlansUpdateInventoryPlanAndReceiptPlanStatus({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, status } : IinventoryPlansUpdateInventoryPlanAndReceiptPlanStatusParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryPlansUpdateInventoryPlanAndReceiptPlanStatus({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, status } : IinventoryPlansUpdateInventoryPlanAndReceiptPlanStatusParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryPlansUpdateInventoryPlanAndReceiptPlanStatus({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, status } : IinventoryPlansUpdateInventoryPlanAndReceiptPlanStatusParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansUpdateInventoryPlanAndReceiptPlanStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling inventoryPlansUpdateInventoryPlanAndReceiptPlanStatus.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryPlanID !== undefined && inventoryPlanID !== null) {
            queryParameters = queryParameters.set('inventoryPlanID', <any>inventoryPlanID);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.put<string>(`${this.basePath}/InventoryPlans/UpdateInventoryPlanAndReceiptPlanStatus`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryPlanID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryPlansUpdateInventoryPlanWhenSubmited({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateInventoryPlanWhenSubmitedParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryPlansUpdateInventoryPlanWhenSubmited({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateInventoryPlanWhenSubmitedParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryPlansUpdateInventoryPlanWhenSubmited({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateInventoryPlanWhenSubmitedParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryPlansUpdateInventoryPlanWhenSubmited({ deviceId, message, ignoreLoadingBar, formData, inventoryPlanID, model } : IinventoryPlansUpdateInventoryPlanWhenSubmitedParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryPlanID === null || inventoryPlanID === undefined) {
            throw new Error('Required parameter inventoryPlanID was null or undefined when calling inventoryPlansUpdateInventoryPlanWhenSubmited.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling inventoryPlansUpdateInventoryPlanWhenSubmited.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/InventoryPlans/${encodeURIComponent(String(inventoryPlanID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
