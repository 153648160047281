/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PageReceiptPlan } from '../model/pageReceiptPlan';
import { ReceiptPlan } from '../model/receiptPlan';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ReceiptPlansServiceInterface, IreceiptPlansCreateParams,IreceiptPlansDeleteParams,IreceiptPlansGetListParams,IreceiptPlansGetSearchListParams,IreceiptPlansUpdateParams,IreceiptPlansUpdateByListParams, }                            from './receiptPlans.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ReceiptPlansService implements ReceiptPlansServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IreceiptPlansCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ReceiptPlan>;
    public receiptPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IreceiptPlansCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReceiptPlan>>;
    public receiptPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IreceiptPlansCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReceiptPlan>>;
    @datastore
    public receiptPlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IreceiptPlansCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling receiptPlansCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ReceiptPlan>(`${this.basePath}/ReceiptPlans`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param receiptPlanID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptPlansDelete({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID } : IreceiptPlansDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public receiptPlansDelete({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID } : IreceiptPlansDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public receiptPlansDelete({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID } : IreceiptPlansDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public receiptPlansDelete({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID } : IreceiptPlansDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (receiptPlanID === null || receiptPlanID === undefined) {
            throw new Error('Required parameter receiptPlanID was null or undefined when calling receiptPlansDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ReceiptPlans/${encodeURIComponent(String(receiptPlanID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param receiptPlanID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptPlansGetList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, page, views } : IreceiptPlansGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ReceiptPlan>>;
    public receiptPlansGetList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, page, views } : IreceiptPlansGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ReceiptPlan>>>;
    public receiptPlansGetList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, page, views } : IreceiptPlansGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ReceiptPlan>>>;
    @datastore
    public receiptPlansGetList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, page, views } : IreceiptPlansGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (receiptPlanID !== undefined && receiptPlanID !== null) {
            queryParameters = queryParameters.set('receiptPlanID', <any>receiptPlanID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ReceiptPlan>>(`${this.basePath}/ReceiptPlans`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param receiptPlanID 
     * @param itemVariantID 
     * @param locationID 
     * @param areaID 
     * @param binID 
     * @param inventoryPlanID 
     * @param receiptPlanStatus 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptPlansGetSearchList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, itemVariantID, locationID, areaID, binID, inventoryPlanID, receiptPlanStatus, page, take, views } : IreceiptPlansGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageReceiptPlan>;
    public receiptPlansGetSearchList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, itemVariantID, locationID, areaID, binID, inventoryPlanID, receiptPlanStatus, page, take, views } : IreceiptPlansGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageReceiptPlan>>;
    public receiptPlansGetSearchList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, itemVariantID, locationID, areaID, binID, inventoryPlanID, receiptPlanStatus, page, take, views } : IreceiptPlansGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageReceiptPlan>>;
    @datastore
    public receiptPlansGetSearchList({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, itemVariantID, locationID, areaID, binID, inventoryPlanID, receiptPlanStatus, page, take, views } : IreceiptPlansGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (receiptPlanID !== undefined && receiptPlanID !== null) {
            queryParameters = queryParameters.set('receiptPlanID', <any>receiptPlanID);
        }
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (binID !== undefined && binID !== null) {
            queryParameters = queryParameters.set('binID', <any>binID);
        }
        if (inventoryPlanID !== undefined && inventoryPlanID !== null) {
            queryParameters = queryParameters.set('inventoryPlanID', <any>inventoryPlanID);
        }
        if (receiptPlanStatus !== undefined && receiptPlanStatus !== null) {
            queryParameters = queryParameters.set('receiptPlanStatus', <any>receiptPlanStatus);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageReceiptPlan>(`${this.basePath}/ReceiptPlans/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param receiptPlanID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, model } : IreceiptPlansUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public receiptPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, model } : IreceiptPlansUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public receiptPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, model } : IreceiptPlansUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public receiptPlansUpdate({ deviceId, message, ignoreLoadingBar, formData, receiptPlanID, model } : IreceiptPlansUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (receiptPlanID === null || receiptPlanID === undefined) {
            throw new Error('Required parameter receiptPlanID was null or undefined when calling receiptPlansUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling receiptPlansUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ReceiptPlans/${encodeURIComponent(String(receiptPlanID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public receiptPlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IreceiptPlansUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public receiptPlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IreceiptPlansUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public receiptPlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IreceiptPlansUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public receiptPlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IreceiptPlansUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling receiptPlansUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/ReceiptPlans/UpdateByList`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
