<div class="modal-header">
    <h4 class="modal-title pull-left">{{ title | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">

    <pg-tabset tabAnimation="slide-left" Type="linetriangle" ShowPagination="true">
        <pg-tab (pgClick)="searchIssuedHistory()" *ngIf="showIssuedHistory">
            <ng-template #TabHeading>
                {{ 'ISSUED_HISTORY' | translate }}
            </ng-template>

            <div class="d-flex flex-column w-100 my-2">
                <p-table [value]="issuedHistoryMovementList" *ngIf="issuedHistoryMovementList && issuedHistoryMovementList.length > 0; else noIssueHistoryFound">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'ITEM_LOT'|translate}}</th>
                            <th>{{'BIN'|translate}}</th>
                            <th>{{'QUANTITY'|translate}}</th>
                            <th>{{'TRANSACTION_DATE'|translate}}</th>
                            <th></th>
                        </tr>
                    </ng-template>
    
                    <ng-template pTemplate="body" let-item let-ri="rowIndex">
                        <tr>
                            <td>{{item.ItemLot.ID}}</td>
                            <td>{{item.Bin | desc: 'BIN_DESC':'BinEnglish'}}</td>
                            <td>{{item.Quantity}}</td>
                            <td>{{item.TransactionDate|date: 'shortDate'}}</td>
                            <td>
                                <button class="btn btn-primary" (click)="confirmIssuedItem(item)" *ngIf="!item.Confirmed || item.Confirmed === 'N'; else alreadyConfirmed">
                                    <i class="fa fa-truck mx-2"></i>{{'CONFIRM'|translate}}
                                </button>
                                <ng-template #alreadyConfirmed>
                                    {{'CONFIRM_ISSUED'|translate}}: {{item.ConfirmedUser}}
                                </ng-template>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
    
                <ng-template #noIssueHistoryFound>
                    {{'NO_ISSUED_HISTORY_FOUND'|translate}}
                </ng-template>
    
            </div>
        </pg-tab>

        <pg-tab (pgClick)="searchConfirmedHistory()" *ngIf="showConfirmedHistory">
            <ng-template #TabHeading>
                {{ 'CONFIRMED_HISTORY' | translate }}
            </ng-template>

            <div class="d-flex flex-column w-100 my-2">
                <p-table [value]="issuedConfirmedHistoryMovementList" *ngIf="issuedConfirmedHistoryMovementList && issuedConfirmedHistoryMovementList.length > 0; else noConfirmedHistoryFound">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>{{'ITEM_LOT'|translate}}</th>
                            <th>{{'BIN'|translate}}</th>
                            <th>{{'QUANTITY'|translate}}</th>
                            <th>{{'TRANSACTION_DATE'|translate}}</th>
                        </tr>
                    </ng-template>
    
                    <ng-template pTemplate="body" let-item let-ri="rowIndex">
                        <tr>
                            <td>{{item.ItemLot.ID}}</td>
                            <td>{{item.Bin | desc: 'BIN_DESC':'BinEnglish'}}</td>
                            <td>{{item.Quantity}}</td>
                            <td>{{item.TransactionDate|date: 'shortDate'}}</td>
                        </tr>
                    </ng-template>
                </p-table>
    
                <ng-template #noConfirmedHistoryFound>
                    <span>{{'NO_CONFIRMED_HISTORY_FOUND'|translate}}</span>
                </ng-template>
    
            </div>

        </pg-tab>
    </pg-tabset>

    
</div>
