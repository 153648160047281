import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApprovalHeader, ApprovalHeadersService, ApprovalService, Project } from '@gammon/inventory-api';
import { ApprovalType, ApprovalTypeEnum } from '@material/common';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { GeneralService, ModalService, ModalStatus } from '../../../services';

@Component({
    selector: 'approval-list-tab',
    templateUrl: './approval-list-tab.component.html',
    styleUrls: ['./approval-list-tab.component.scss']
})
export class ApprovalListTabComponent implements OnInit, OnDestroy {

    bsModalRef: BsModalRef;
    //@ViewChild('decisionMapModal', { static: false }) decisionMapModal;
    @ViewChild('decisionMapModal') decisionMapModal: TemplateRef<any>;
    @Input() actionType: ApprovalType = ApprovalTypeEnum.WRITE_OFF;
    @Input() currentProject: Project;

    subscriptionList: Subscription[] = [];

    approvalHeaderList: ApprovalHeader[] = [];
    totalRecords = 0;
    tableRowOffset: number;
    tableRowCount: number;
    tablePage: number;

    selectedApprovalHeader: ApprovalHeader;
    decisionMapHtml: string;

    constructor(
        private approvalHeadersService: ApprovalHeadersService,
        private generalService: GeneralService,
        private modalService: ModalService,
        private approvalService: ApprovalService,
        private bsModalService: BsModalService,
        private router: Router
    ) { }

    ngOnInit() { }

    loadApprovalHeaderLazy(event: LazyLoadEvent) {
        this.tableRowOffset = event.first;
        this.tableRowCount = event.rows;
        this.tablePage = event.first / event.rows + 1;

        this.searchApprovalHeader();
    }

    searchApprovalHeader() {
        this.subscriptionList.push(this.approvalHeadersService.approvalHeadersGetSearchList({approvalType: this.generalService.actionTypeToApprovalType(this.actionType), page: this.tablePage, size: this.tableRowCount, fromID: this.currentProject.ID, views: ['PROJECT']}).subscribe(pageData => {
            if (pageData && pageData.Items) {
                this.totalRecords = pageData.TotalItems;
                this.approvalHeaderList = pageData.Items;
                console.log('approvalHeaderList: ', this.approvalHeaderList);
            }
        }));
    }

    closeModal() {
        if (this.bsModalRef) { this.bsModalRef.hide(); }
    }

    showSummaryModal(header: ApprovalHeader) {
      if (!header) { return; }

      const templateData = {
          header
      };
      this.approvalHeadersService.approvalHeadersGetApprovalHeaderTid({approvalHeaderID: header.ID})
      .pipe(take(1)).subscribe(tid => {
        this.router.navigate([`inventory/main/approval/summary/${this.actionType}/${tid}`]);
      });
    }

    showDecisionMapModal(header: ApprovalHeader) {
        this.decisionMapHtml = '';
        this.selectedApprovalHeader = header;
        this.approvalService.approvalReturnDecisionMapTemplate({refNo: header.ReferenceNumber.toString()}).subscribe(data => {
            this.decisionMapHtml = data;
            const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
            this.bsModalRef = this.bsModalService.show(this.decisionMapModal, config);
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.decisionMapHtml, 'text/xml');

            console.log(doc);
        });
    }

    abortApproval(header: ApprovalHeader) {
        this.subscriptionList.push(this.approvalService.approvalAbortApproval({refNo: header.ReferenceNumber.toString()}).subscribe(data => {
            if (data == true) {
                this.modalService.showNotification(ModalStatus.success, ['ABORTED_REFERENCE_NUMBER', header.ReferenceNumber.toString()]);
            } else {
                this.modalService.showNotification(ModalStatus.danger, ['CANNOT_ABORT_REFERENCE_NUMBER', header.ReferenceNumber.toString()]);
            }

            this.searchApprovalHeader();
        }, error => {
          console.error(error);
          this.modalService.showNotification(ModalStatus.danger, ['CANNOT_ABORT_REFERENCE_NUMBER', header.ReferenceNumber.toString()]);
        }));
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }

}
