<div class="row px-2">
  <div class="input-group">
    <ng-select [style.border]="required && !selected ?'1px solid red' : ''"
      style="width: 100%;"
      [items]="stringArray"
      [(ngModel)]="selected"
      (ngModelChange)="selectedChange.emit(selected)"
      [clearable]="true"
      appendTo="body"
    >
      <ng-template ng-label-tmp let-item="item">
        {{ item | translate }}
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{ item | translate }}
      </ng-template>
    </ng-select>
  </div>
</div>
