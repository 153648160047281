<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'CREATE_STOCK_LEVEL' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="height: 80% !important;">

    <div class="row">
        <div class="col">
            <div class="btn-group">
                <button class="btn" [ngClass]="[_stockLevelType=='PROJECT'?'btn-primary':'']" (click)="_stockLevelType='PROJECT'; clearSelection()">{{'PROJECT'|translate}}</button>
                <button class="btn" [ngClass]="[_stockLevelType=='LOCATION'?'btn-warning':'']" (click)="_stockLevelType='LOCATION'; clearSelection()">{{'LOCATION'|translate}}</button>
            </div>

            <div class="btn-group mx-1">
                <button class="btn" [ngClass]="[alertType=='LOW_INVENTORY_LEVEL'?'btn-complete':'']" (click)="alertType='LOW_INVENTORY_LEVEL'">{{'LOW_INVENTORY_LEVEL'|translate}}</button>
                <button class="btn" [ngClass]="[alertType=='MILESTONE'?'btn-success':'']" (click)="alertType='MILESTONE'">{{'MILESTONE'|translate}}</button>
            </div>

        </div>
    </div>

    <div class="row mb-1">
        <div class="col-6">
            <ng-container>
                <search-category [orientation]="'row'" [project]="currentProject" [(category)]="selectedCategory">
                </search-category>
            </ng-container>
            <ng-container>
                <search-subcategory [orientation]="'row'" [project]="currentProject"  [category]="selectedCategory" [(subCategory)]="selectedSubCategory"
                    (subCategoryChange)="onSubCategoryChange($event)">
                </search-subcategory>
            </ng-container>
            <ng-container>
                <search-item [orientation]="'row'" [project]="currentProject" [category]="selectedCategory" [subCategory]="selectedSubCategory"
                    [(item)]="selectedItem" (onItemChange)="onItemChange($event)">
                </search-item>
            </ng-container>

            <div class="row py-1" *ngIf="_stockLevelType=='LOCATION'">
                <div class="col-3 my-auto">
                    <span class="fs-12">{{ 'LOCATION' | translate }}</span>
                </div>
                <div class="col-8 my-auto">
                    <ng-select
                        style="width: 100%"
                        (open)="searchLocations();"
                        [items]="searchLocationList"
                        (ngModelChange)="selectionLocationChange($event)"
                        [(ngModel)]="selectedLocation"
                        [disabled]="_stockLevelType!='LOCATION'">

                        <ng-template ng-label-tmp let-item="item">
                            <span>{{ item['LOCATION_DESC'|translate] }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span>{{ item['LOCATION_DESC'|translate] }}</span>
                        </ng-template>

                    </ng-select>

                </div>
            </div>
        </div>

        <div class="col-6">
            <!-- QR Scanner -->
        </div>
    </div>

    <div class="row mb-4 p-3 d-flex flex-row-reverse">
        <button class="btn btn-primary" (click)="search()">{{'SEARCH'|translate}}</button>
    </div>


    <div class="table-responsive" style="border-radius: 5px;">
        <p-table
            [value]="itemResultList"
            [responsive]="true"
            [scrollable]="true"
            [paginator]="true"
            [rows]="10"
            [totalRecords]="totalRecords"
            [lazy]="true"
            (onLazyLoad)="loadItemVariantLazy($event)"
            scrollHeight="380px"
            selectionMode="multiple"
            [(selection)]="selectionResultList">
            <ng-template pTemplate="header">
                <tr>
                    <th style="width : 80px"></th>
                    <th>{{ 'ITEM_CODE' | translate }}</th>
                    <th>{{ 'DESCRIPTION' | translate }}</th>
                    <th>{{ 'CHINESE_DESCRIPTION' | translate }}</th>
                    <th>{{ 'BRAND' | translate }}</th>
                    <th>{{ 'MODEL' | translate}}</th>
                    <th>{{ 'SIZE' | translate }}</th>
                    <th>{{ 'REMARK' | translate }}</th>
                    <th>{{ 'UNIT' | translate }}</th>
                    <th>{{ 'ALERT_LEVEL' | translate }}</th>
                    <th>{{ 'GAMMON_PRODUCT_CODE' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-ri="rowIndex">
                <tr [pSelectableRow]="item">
                    <td style="width: 80px" [pSelectableRow]="item">
                        <p-tableCheckbox [value]="item"></p-tableCheckbox>
                    </td>
                    <td class="fs-12">{{ item.Item.ItemCode }}</td>
                    <td class="fs-12">{{ item.Item.ItemEnglish }}</td>
                    <td class="fs-12">{{ item.Item.ItemChinese }}</td>
                    <td class="fs-12">{{ item.Brand }}</td>
                    <td class="fs-12">{{ item.ModelNo }}</td>
                    <td class="fs-12">{{ item.Size }}</td>
                    <td class="fs-12">{{ item.Remark }}</td>
                    <td class="fs-12">{{ item.UOM }}</td>
                    <td class="fs-12">
                        <div class="row">
                            <input class="form-control" [(ngModel)]="item['AlertLevel']" type="number" style="width: 60%" min="1" />
                        </div>
                    </td>
                    <td class="fs-12">{{ item.GammonProductCode }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>


    <div class="row d-flex flex-row-reverse">
        <button class="btn btn-primary mx-1" [disabled]="disableSelectIv()" (click)="addStockLevels()">{{ 'SELECT' | translate }}</button>
        <button class="btn btn-warning mx-1" (click)="closeModal()">{{ 'CANCEL' | translate }}</button>
    </div>

</div>
