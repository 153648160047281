/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Item } from '../model/item';
import { PageItem } from '../model/pageItem';
import { PageItemDailyBalanceSearchListResult } from '../model/pageItemDailyBalanceSearchListResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ItemsServiceInterface, IitemsCheckIsExistCodeParams,IitemsCheckIsExistItemsParams,IitemsCreateParams,IitemsDeleteParams,IitemsDeleteByListParams,IitemsDownloadItemDailyBalancesExcelParams,IitemsGetItemByIDParams,IitemsGetListParams,IitemsGetListWithIbQtyGreaterThanZeroParams,IitemsGetListWithItemAccessesParams,IitemsGetListWithItemLotsParams,IitemsGetListWithItemVariantsParams,IitemsGetListWithJdeMappingsParams,IitemsGetListWithStockLevelsParams,IitemsGetSearchItemDailyBalanceListParams,IitemsGetSearchListParams,IitemsUpdateParams,IitemsUpdateByListParams,IitemsUploadItemsParams, }                            from './items.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ItemsService implements ItemsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param subCategoryID 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, code } : IitemsCheckIsExistCodeParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public itemsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, code } : IitemsCheckIsExistCodeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public itemsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, code } : IitemsCheckIsExistCodeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public itemsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, code } : IitemsCheckIsExistCodeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemsCheckIsExistCode.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling itemsCheckIsExistCode.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Items/CheckIsExistCode/${encodeURIComponent(String(subCategoryID))}/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsCheckIsExistItems({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsCheckIsExistItemsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsCheckIsExistItems({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsCheckIsExistItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsCheckIsExistItems({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsCheckIsExistItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsCheckIsExistItems({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsCheckIsExistItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemsCheckIsExistItems.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<Item>>(`${this.basePath}/Items/CheckIsExistItems`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<Item>;
    public itemsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Item>>;
    public itemsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Item>>;
    @datastore
    public itemsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Item>(`${this.basePath}/Items`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsDelete({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemsDelete({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemsDelete({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemsDelete({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemIDs } : IitemsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemIDs } : IitemsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemIDs } : IitemsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemIDs } : IitemsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemIDs === null || itemIDs === undefined) {
            throw new Error('Required parameter itemIDs was null or undefined when calling itemsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemIDs) {
            itemIDs.forEach((element) => {
                queryParameters = queryParameters.append('itemIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Items/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param param_projectID 
     * @param param_id 
     * @param param_itemCategoryId 
     * @param param_subCategoryID 
     * @param param_itemId 
     * @param param_itemDesc 
     * @param param_inCount 
     * @param param_outCount 
     * @param param_startBalanceDate 
     * @param param_endBalanceDate 
     * @param param_startCreateDate 
     * @param param_endCreateDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsDownloadItemDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_itemDesc, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemsDownloadItemDailyBalancesExcelParams, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public itemsDownloadItemDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_itemDesc, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemsDownloadItemDailyBalancesExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public itemsDownloadItemDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_itemDesc, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemsDownloadItemDailyBalancesExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    @datastore
    public itemsDownloadItemDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_itemDesc, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemsDownloadItemDailyBalancesExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_id !== undefined && param_id !== null) {
            queryParameters = queryParameters.set('param.id', <any>param_id);
        }
        if (param_itemCategoryId !== undefined && param_itemCategoryId !== null) {
            queryParameters = queryParameters.set('param.itemCategoryId', <any>param_itemCategoryId);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemId !== undefined && param_itemId !== null) {
            queryParameters = queryParameters.set('param.itemId', <any>param_itemId);
        }
        if (param_itemDesc !== undefined && param_itemDesc !== null) {
            queryParameters = queryParameters.set('param.itemDesc', <any>param_itemDesc);
        }
        if (param_inCount !== undefined && param_inCount !== null) {
            queryParameters = queryParameters.set('param.inCount', <any>param_inCount);
        }
        if (param_outCount !== undefined && param_outCount !== null) {
            queryParameters = queryParameters.set('param.outCount', <any>param_outCount);
        }
        if (param_startBalanceDate !== undefined && param_startBalanceDate !== null) {
            queryParameters = queryParameters.set('param.startBalanceDate', <any>param_startBalanceDate.toISOString());
        }
        if (param_endBalanceDate !== undefined && param_endBalanceDate !== null) {
            queryParameters = queryParameters.set('param.endBalanceDate', <any>param_endBalanceDate.toISOString());
        }
        if (param_startCreateDate !== undefined && param_startCreateDate !== null) {
            queryParameters = queryParameters.set('param.startCreateDate', <any>param_startCreateDate.toISOString());
        }
        if (param_endCreateDate !== undefined && param_endCreateDate !== null) {
            queryParameters = queryParameters.set('param.endCreateDate', <any>param_endCreateDate.toISOString());
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get(`${this.basePath}/Items/DownloadItemDailyBalancesExcel`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param restrictActiveParents 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetItemByID({ deviceId, message, ignoreLoadingBar, formData, itemID, restrictActiveParents, views } : IitemsGetItemByIDParams, observe?: 'body', reportProgress?: boolean): Observable<Item>;
    public itemsGetItemByID({ deviceId, message, ignoreLoadingBar, formData, itemID, restrictActiveParents, views } : IitemsGetItemByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Item>>;
    public itemsGetItemByID({ deviceId, message, ignoreLoadingBar, formData, itemID, restrictActiveParents, views } : IitemsGetItemByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Item>>;
    @datastore
    public itemsGetItemByID({ deviceId, message, ignoreLoadingBar, formData, itemID, restrictActiveParents, views } : IitemsGetItemByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsGetItemByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restrictActiveParents !== undefined && restrictActiveParents !== null) {
            queryParameters = queryParameters.set('restrictActiveParents', <any>restrictActiveParents);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Item>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetList({ deviceId, message, ignoreLoadingBar, formData, itemID, views } : IitemsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsGetList({ deviceId, message, ignoreLoadingBar, formData, itemID, views } : IitemsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsGetList({ deviceId, message, ignoreLoadingBar, formData, itemID, views } : IitemsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsGetList({ deviceId, message, ignoreLoadingBar, formData, itemID, views } : IitemsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Item>>(`${this.basePath}/Items`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param subCategoryID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, subCategoryID, views } : IitemsGetListWithIbQtyGreaterThanZeroParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, subCategoryID, views } : IitemsGetListWithIbQtyGreaterThanZeroParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, subCategoryID, views } : IitemsGetListWithIbQtyGreaterThanZeroParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, subCategoryID, views } : IitemsGetListWithIbQtyGreaterThanZeroParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemsGetListWithIbQtyGreaterThanZero.');
        }
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemsGetListWithIbQtyGreaterThanZero.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Item>>(`${this.basePath}/Items/GetListWithIbQtyGreaterThanZero`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param itemAccessID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, itemID, itemAccessID, page, views } : IitemsGetListWithItemAccessesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, itemID, itemAccessID, page, views } : IitemsGetListWithItemAccessesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, itemID, itemAccessID, page, views } : IitemsGetListWithItemAccessesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, itemID, itemAccessID, page, views } : IitemsGetListWithItemAccessesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsGetListWithItemAccesses.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemAccessID !== undefined && itemAccessID !== null) {
            queryParameters = queryParameters.set('itemAccessID', <any>itemAccessID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Item>>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}/ItemAccesses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param itemVariantID 
     * @param itemLotID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, itemLotID, page, views } : IitemsGetListWithItemLotsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, itemLotID, page, views } : IitemsGetListWithItemLotsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, itemLotID, page, views } : IitemsGetListWithItemLotsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, itemLotID, page, views } : IitemsGetListWithItemLotsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsGetListWithItemLots.');
        }
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemsGetListWithItemLots.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemLotID !== undefined && itemLotID !== null) {
            queryParameters = queryParameters.set('itemLotID', <any>itemLotID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Item>>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}/ItemVariants/${encodeURIComponent(String(itemVariantID))}/ItemLots`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param itemVariantID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetListWithItemVariants({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, page, views } : IitemsGetListWithItemVariantsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsGetListWithItemVariants({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, page, views } : IitemsGetListWithItemVariantsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsGetListWithItemVariants({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, page, views } : IitemsGetListWithItemVariantsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsGetListWithItemVariants({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, page, views } : IitemsGetListWithItemVariantsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsGetListWithItemVariants.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Item>>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}/ItemVariants`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param itemVariantID 
     * @param jdeMappingID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetListWithJdeMappings({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, jdeMappingID, page, views } : IitemsGetListWithJdeMappingsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsGetListWithJdeMappings({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, jdeMappingID, page, views } : IitemsGetListWithJdeMappingsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsGetListWithJdeMappings({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, jdeMappingID, page, views } : IitemsGetListWithJdeMappingsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsGetListWithJdeMappings({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, jdeMappingID, page, views } : IitemsGetListWithJdeMappingsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsGetListWithJdeMappings.');
        }
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemsGetListWithJdeMappings.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (jdeMappingID !== undefined && jdeMappingID !== null) {
            queryParameters = queryParameters.set('jdeMappingID', <any>jdeMappingID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Item>>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}/ItemVariants/${encodeURIComponent(String(itemVariantID))}/JdeMappings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param itemVariantID 
     * @param stockLevelID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, stockLevelID, page, views } : IitemsGetListWithStockLevelsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Item>>;
    public itemsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, stockLevelID, page, views } : IitemsGetListWithStockLevelsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Item>>>;
    public itemsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, stockLevelID, page, views } : IitemsGetListWithStockLevelsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Item>>>;
    @datastore
    public itemsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemID, itemVariantID, stockLevelID, page, views } : IitemsGetListWithStockLevelsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsGetListWithStockLevels.');
        }
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemsGetListWithStockLevels.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stockLevelID !== undefined && stockLevelID !== null) {
            queryParameters = queryParameters.set('stockLevelID', <any>stockLevelID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Item>>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}/ItemVariants/${encodeURIComponent(String(itemVariantID))}/StockLevels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param id 
     * @param itemCategoryId 
     * @param subCategoryID 
     * @param itemId 
     * @param itemDesc 
     * @param inCount 
     * @param outCount 
     * @param startBalanceDate 
     * @param endBalanceDate 
     * @param startCreateDate 
     * @param endCreateDate 
     * @param page 
     * @param size 
     * @param orderByDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetSearchItemDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, itemDesc, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemsGetSearchItemDailyBalanceListParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemDailyBalanceSearchListResult>;
    public itemsGetSearchItemDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, itemDesc, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemsGetSearchItemDailyBalanceListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemDailyBalanceSearchListResult>>;
    public itemsGetSearchItemDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, itemDesc, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemsGetSearchItemDailyBalanceListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemDailyBalanceSearchListResult>>;
    @datastore
    public itemsGetSearchItemDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, itemDesc, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemsGetSearchItemDailyBalanceListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (itemCategoryId !== undefined && itemCategoryId !== null) {
            queryParameters = queryParameters.set('itemCategoryId', <any>itemCategoryId);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemId !== undefined && itemId !== null) {
            queryParameters = queryParameters.set('itemId', <any>itemId);
        }
        if (itemDesc !== undefined && itemDesc !== null) {
            queryParameters = queryParameters.set('itemDesc', <any>itemDesc);
        }
        if (inCount !== undefined && inCount !== null) {
            queryParameters = queryParameters.set('inCount', <any>inCount);
        }
        if (outCount !== undefined && outCount !== null) {
            queryParameters = queryParameters.set('outCount', <any>outCount);
        }
        if (startBalanceDate !== undefined && startBalanceDate !== null) {
            queryParameters = queryParameters.set('startBalanceDate', <any>startBalanceDate.toISOString());
        }
        if (endBalanceDate !== undefined && endBalanceDate !== null) {
            queryParameters = queryParameters.set('endBalanceDate', <any>endBalanceDate.toISOString());
        }
        if (startCreateDate !== undefined && startCreateDate !== null) {
            queryParameters = queryParameters.set('startCreateDate', <any>startCreateDate.toISOString());
        }
        if (endCreateDate !== undefined && endCreateDate !== null) {
            queryParameters = queryParameters.set('endCreateDate', <any>endCreateDate.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (orderByDesc !== undefined && orderByDesc !== null) {
            queryParameters = queryParameters.set('orderByDesc', <any>orderByDesc);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemDailyBalanceSearchListResult>(`${this.basePath}/Items/GetSearchItemDailyBalanceList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param subCategoryID 
     * @param itemCode 
     * @param description 
     * @param chineseDescription 
     * @param unitOfMeasure 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemCode, description, chineseDescription, unitOfMeasure, page, take, views } : IitemsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageItem>;
    public itemsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemCode, description, chineseDescription, unitOfMeasure, page, take, views } : IitemsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItem>>;
    public itemsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemCode, description, chineseDescription, unitOfMeasure, page, take, views } : IitemsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItem>>;
    @datastore
    public itemsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemCode, description, chineseDescription, unitOfMeasure, page, take, views } : IitemsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemCode !== undefined && itemCode !== null) {
            queryParameters = queryParameters.set('itemCode', <any>itemCode);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (chineseDescription !== undefined && chineseDescription !== null) {
            queryParameters = queryParameters.set('chineseDescription', <any>chineseDescription);
        }
        if (unitOfMeasure !== undefined && unitOfMeasure !== null) {
            queryParameters = queryParameters.set('unitOfMeasure', <any>unitOfMeasure);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItem>(`${this.basePath}/Items/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemID, model } : IitemsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemID, model } : IitemsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemID, model } : IitemsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemID, model } : IitemsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/Items/${encodeURIComponent(String(itemID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/Items/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemsUploadItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemsUploadItemsParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemsUploadItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemsUploadItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemsUploadItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemsUploadItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemsUploadItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemsUploadItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemsUploadItems.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Items/UploadItem/${encodeURIComponent(String(subCategoryID))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
