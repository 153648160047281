import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ADUser, Area, AreasService, Bin, BinsService, InventoryBalance, InventoryBalancesService, Item, ItemLot, ItemCategoriesService, ItemCategory, ItemsService, ItemSubCategoriesService, ItemSubCategory, Location, LocationsService, Project, Team } from '@gammon/inventory-api';
import { ActionType, ActionTypeEnum, CommonIssuePlan } from '@material/common';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'search-inventory-balance',
    templateUrl: './search-inventory-balance.component.html',
    styleUrls: ['./search-inventory-balance.component.scss']
})
export class SearchInventoryBalanceComponent implements OnInit, OnDestroy {

    @Input() actionType: ActionType;

    @Output() close = new EventEmitter<any>();
    @Output() add = new EventEmitter<any>();
    @Input() tableSelectionMode: 'single' | 'multiple' = 'multiple';
    @Input() tableTakeRows: number = 10;
    @Input() withApproval = false;

    @Input() currentProject: Project;
    _keeper: ADUser;
    _keeperName: string;
    @Input() set keeperName(value: string) {
        this._keeperName = value;
        if(value){
            this._team = null;
        }
        this.tablePage = 1;
        if (this.actionType == ActionTypeEnum.RETURN) {
            this.isLazyLoad = true;
            this.searchInventoryBalanceByEmployeeID();
        }
    }
    @Input() set keeper(value: ADUser) {
        this._keeper = value;
        if(value){
            this._team = null;
        }
        this.tablePage = 1;
        if (this.actionType == ActionTypeEnum.RETURN) {
            this.isLazyLoad = true;
            this.searchInventoryBalanceByEmployeeID();
        }
    }

    _team: Team;
    @Input() set team(value: Team) {
        if (value){
            this._keeper = null;
        }
        this._team = value;
        this.tablePage = 1;

        if (this.actionType == ActionTypeEnum.RETURN) {
            this.isLazyLoad = true;
            this.searchInventoryBalanceByTeamID();
        }
    }

    //Search criteria
    @Input() disableAllSearchCriteria = false;
    @Input() disableItemSearch = false;
    @Input() disableLocationSearch = false;

    subscriptionList: Subscription[] = [];

    searchCategoryList: ItemCategory[] = [];
    selectedCategory: ItemCategory = null;

    searchSubCategoryList: ItemSubCategory[] = [];
    selectedSubCategory: ItemSubCategory = null;

    searchItemList: Item[] = [];
    selectedItem: Item = null;
    specificQuantity = 0;

    searchLocationList: Location[] = [];
    selectedLocation: Location = null;
    @Output() locationChange = new EventEmitter<Location>(); //for change-location
    @Input() set location(value: Location) {
      this.searchAreaList = [];
      this.selectedArea = null;
      this.searchBinList = [];
      this.selectedBin = null;

      this.selectedLocation = value;
      // if (this.selectedLocation) {
      //     if (this.selectedLocation.Areas && this.selectedLocation.Areas.length == 1) {
      //         this.selectedArea = this.selectedLocation.Areas[0];
      //         if (this.selectedArea.Bins.length == 1) {
      //             this.selectedBin = this.selectedArea.Bins[0];
      //         }
      //     }
      // }
    }

    @Input() set area(value: Area) {
      this.selectedArea = value;
    }


    @Input() set item(value: Item) {

        if (!value) { return; }

        this.selectedCategory = value.ItemSubCategory.ItemCategory;
        this.selectedSubCategory = value.ItemSubCategory;
        this.selectedItem = value;

        setTimeout(() => {this.search(); }, 500);

    }


    searchAreaList: Area[] = [];
    selectedArea: Area = null;

    searchBinList: Bin[] = [];
    selectedBin: Bin = null;
    plantNumber: string = '';
    itemDescription: string = '';
    itemVariantModel: string = '';
    movementRemarks: string = '';

    @Input() set issuePlanItem(value: CommonIssuePlan) {
        if (value && value.ItemVariant && value.ItemVariant.Item && value.ItemVariant.Item.ItemSubCategory && value.ItemVariant.Item.ItemSubCategory.ItemCategory) {
          this.selectedCategory = value.ItemVariant.Item.ItemSubCategory.ItemCategory;
          this.selectedSubCategory = value.ItemVariant.Item.ItemSubCategory;
          this.selectedItem = value.ItemVariant.Item;

          if (value.QuantityToBeIssued) {
            this.specificQuantity = value.QuantityToBeIssued;
          }

          this.search();
        }
    }

    searchInventoryBalanceList: InventoryBalance[] = [];

    universalProductCode: string;
    gammonProductCode: string;

    selectionResultList: InventoryBalance | InventoryBalance[];

    //table pagination
    isLazyLoad: boolean = false;
    totalRecords = 0;
    tableRowOffset: number;
    tableRowCount = 10;
    tablePage: number;
    ActionType = ActionTypeEnum;
    constructor(
        private itemCategorieservice: ItemCategoriesService,
        private itemSubCategoriesService: ItemSubCategoriesService,
        private itemsService: ItemsService,
        private locationsService: LocationsService,
        private areasService: AreasService,
        private binsService: BinsService,
        public translate: TranslateService,
        private inventoryBalancesService: InventoryBalancesService
    ) { }

    ngOnInit() {
        this.tableRowCount = this.tableTakeRows;
     }

    searchCategory() {
        this.subscriptionList.push(
          // this.itemCategorieservice.itemCategoriesGetSearchList({
          //   projectNumber: this.currentProject.ProjectNumber,
          //   by: 'PROJECT',
          //   withGlobal: true
          // })
          // .subscribe(pageData => {
          //     if (pageData.Items) {
          //         this.searchCategoryList = pageData.Items;
          //     }
          // })
          this.itemCategorieservice.itemCategoriesGetListWithIbQtyGreaterThanZero({
            projectNo: this.currentProject.ProjectNumber
          }).subscribe(itemCategories => {
            if (itemCategories) {
              this.searchCategoryList = itemCategories;
            }
          })
        );
    }

    searchSubCategory() {
        this.subscriptionList.push(this.itemSubCategoriesService.itemSubCategoriesGetListWithIbQtyGreaterThanZero({
          projectID: this.currentProject.ID,
          categoryID: this.selectedCategory.ID
        }).subscribe(itemSubCategories => {
            if (itemSubCategories) {
                this.searchSubCategoryList = itemSubCategories;
            }
        }));
    }

    searchItem() {
        this.subscriptionList.push(this.itemsService.itemsGetListWithIbQtyGreaterThanZero({
          projectID: this.currentProject.ID,
          subCategoryID: this.selectedSubCategory.ID
        }).subscribe(items => {
            if (items) {
                this.searchItemList = items;
            }
        }));
    }

    searchLocation() {
        this.subscriptionList.push(this.locationsService.locationsGetSearchList({ projectID: this.currentProject.ID, views: ['AREA', 'BIN'] }).subscribe(pageData => {
            if (pageData.Items) {
                this.searchLocationList = pageData.Items;
            }
        }));
    }

    searchArea() {
        this.subscriptionList.push(this.areasService.areasGetSearchList({ locationID: this.selectedLocation.ID }).subscribe(pageData => {
            if (pageData.Items) {
                this.searchAreaList = pageData.Items;
            }
        }));
    }

    searchBin() {
        this.subscriptionList.push(this.binsService.binsGetSearchList({ areaID: this.selectedArea.ID }).subscribe(pageData => {
            if (pageData.Items) {
                this.searchBinList = pageData.Items;
            }
        }));
    }

    loadInventoryBalanceLazy(event: LazyLoadEvent) {
        this.tableRowOffset = event.first;
        this.tableRowCount = event.rows;
        this.tablePage = event.first / event.rows + 1;
        this.search();
    }

    search() {
        this.isLazyLoad = true;
        console.log(this.actionType);
        switch (this.actionType) {
            case ActionTypeEnum.ISSUE:
            case ActionTypeEnum.WRITE_OFF:
            case ActionTypeEnum.CHANGE_LOCATION:
            case ActionTypeEnum.ISSUE_REQUEST:
            case ActionTypeEnum.TRANSFER:
                if (this.actionType == ActionTypeEnum.CHANGE_LOCATION) {
                    // must select location to do search
                    if (this.selectedLocation == null && this.withApproval) { return; }
                }
                this.searchInventoryBalance();
                break;
            case ActionTypeEnum.RETURN:
                this.searchInventoryBalanceByEmployeeID();
                break;
        }
    }

    searchInventoryBalance() {
        // var currentViews : Array<'NONE' | 'SKIP_CHILD' | 'AREA' | 'BIN' | 'INVENTORY_BALANCE' | 'INVENTORY_PLAN' | 'ISSUE_PLAN' | 'ITEM' | 'ITEM_ACCESS' | 'ITEM_CATEGORY' | 'ITEM_LOT' | 'ITEM_SUB_CATEGORY' | 'ITEM_VARIANT' | 'JDE_MAPPING' | 'LOCATION' | 'LOCATION_ACCESS' | 'MOVEMENT' | 'PROJECT' | 'PROJECT_APPROVER' | 'RECEIPT_PLAN' | 'STOCK_LEVEL' | 'APPROVAL_DETAIL' | 'APPROVAL_HEADER' | 'KEEPER' | 'BUDGET' | 'TEAM' | 'SKIP_PARENT'>;
        // if(this.actionType == ActionTypeEnum.ISSUE){
        //     currentViews = ['ITEM_LOT', 'ITEM_VARIANT', 'ITEM', 'BIN', 'AREA', 'LOCATION', 'KEEPER'];
        // }else{
        //     currentViews = ['ITEM_LOT', 'ITEM_VARIANT', 'ITEM', 'ITEM_SUB_CATEGORY', 'ITEM_CATEGORY', 'BIN', 'AREA', 'LOCATION', 'KEEPER'];
        // }
        this.inventoryBalancesService.inventoryBalancesGetInventoryBalanceSearchList({
            param_projectID: this.currentProject.ID,
            param_categoryID: this.selectedCategory ? this.selectedCategory.ID : null,
            param_subCategoryID: this.selectedSubCategory ? this.selectedSubCategory.ID : null,
            param_itemID: this.selectedItem ? this.selectedItem.ID : null,
            param_locationID: this.selectedLocation ? this.selectedLocation.ID : null,
            param_areaID: this.selectedArea ? this.selectedArea.ID : null,
            param_binID: this.selectedBin ? this.selectedBin.ID : null,
            param_itemDescription: this.itemDescription,
            param_plantNumber: (this.plantNumber || '').trim(),
            param_model: this.itemVariantModel,
            param_movementRemarks: this.movementRemarks,
            param_minQuantity: this.specificQuantity ? this.specificQuantity : null,
            page: this.tablePage, size: this.tableRowCount//,
            //views: currentViews
        }).subscribe(pageData => {
            this.searchInventoryBalanceList = [];

            if ([ActionTypeEnum.ISSUE, ActionTypeEnum.WRITE_OFF, ActionTypeEnum.CHANGE_LOCATION].indexOf(this.actionType)) {
                this.selectionResultList = [];
            } else if (this.actionType == ActionTypeEnum.ISSUE_REQUEST) {
                this.selectionResultList = null;
            }

            //console.log('PageData: ', pageData);

            if (pageData && pageData.Items.length > 0) {
                this.totalRecords = pageData.TotalItems;
                //console.log('Inventory Balances', pageData.Items);
                this.searchInventoryBalanceList = pageData.Items as InventoryBalance[];
            }
        });
    }

    searchInventoryBalanceByEmployeeID() {
        if (this._keeper || this._keeperName) {
            this.subscriptionList.push(this.inventoryBalancesService.inventoryBalancesGetInventoryBalanceSearchList({
                param_employeeID: (this._keeper||{}).EmployeeID || 'includeResignedGammonStaff', //search for ib resigned gammon staff keeper
                param_keeperName: this._keeperName,
                param_categoryID: this.selectedCategory ? this.selectedCategory.ID : null,
                param_subCategoryID: this.selectedSubCategory ? this.selectedSubCategory.ID : null,
                param_itemID: this.selectedItem ? this.selectedItem.ID : null,
                param_itemDescription: this.itemDescription,
                param_plantNumber: (this.plantNumber || '').trim(),
                page: this.tablePage,
                size: this.tableRowCount//,
                //views: ['ITEM', 'ITEM_LOT', 'ITEM_VARIANT', 'BIN', 'AREA', 'LOCATION', 'KEEPER']
            }).subscribe(pageData => {
                this.searchInventoryBalanceList = [];
                this.totalRecords = pageData.TotalItems;

                if (pageData && pageData.Items.length > 0) {
                    this.searchInventoryBalanceList = pageData.Items as InventoryBalance[];
                }
            }, error => {
                console.error(error);
                this.totalRecords = 0;
                this.searchInventoryBalanceList = [];
            }));
        } else {
            this.searchInventoryBalanceList = [];
        }
    }

    searchInventoryBalanceByTeamID() {
        if (this._team) {
            this.subscriptionList.push(this.inventoryBalancesService.inventoryBalancesGetInventoryBalanceSearchList({
                param_categoryID: this.selectedCategory ? this.selectedCategory.ID : null,
                param_subCategoryID: this.selectedSubCategory ? this.selectedSubCategory.ID : null,
                param_itemID: this.selectedItem ? this.selectedItem.ID : null,
                page: this.tablePage,
                size: this.tableRowCount//,
                //views: ['ITEM', 'ITEM_LOT', 'ITEM_VARIANT', 'BIN', 'AREA', 'LOCATION', 'KEEPER']
            }).subscribe(pageData => {
                console.log('page data: ', pageData);
                this.searchInventoryBalanceList = [];
                if (pageData && pageData.Items.length > 0) {
                    this.totalRecords = pageData.TotalItems;
                    this.searchInventoryBalanceList = pageData.Items as InventoryBalance[];
                } else {
                    this.totalRecords = 0;
                    this.searchInventoryBalanceList = [];
                }
            }, error => {
                console.error(error);
                this.totalRecords = 0;
                this.searchInventoryBalanceList = [];
            }));
        } else {
            console.log('no Team as input');
            this.searchInventoryBalanceList = [];
        }
    }

    onCategoryChange(event) {
        this.selectedCategory = event;
    }

    onSubCategoryChange(event) {
        this.selectedSubCategory = event;
    }

    onItemChange(event) {
        this.selectedItem = event;
    }
    // selectionCategoryChange() {
    //     //Clear child
    //     this.searchSubCategoryList = [];
    //     this.selectedSubCategory = null;

    //     this.searchItemList = [];
    //     this.selectedItem = null;
    // }

    // selectionSubCategoryChange() {
    //     //Clear child
    //     this.searchItemList = [];
    //     this.selectedItem = null;
    // }

    selectionLocationChange() {
        //Clear child
        this.searchAreaList = [];
        this.selectedArea = null;

        this.searchBinList = [];
        this.selectedBin = null;

        this.locationChange.emit(this.selectedLocation);
    }

    selectionAreaChange() {
        //Clear child
        this.searchBinList = [];
        this.selectedBin = null;
    }

    addInventoryBalance() {
        this.add.emit(this.selectionResultList);

        if ([
          ActionTypeEnum.ISSUE,
          ActionTypeEnum.WRITE_OFF,
          ActionTypeEnum.CHANGE_LOCATION,
          ActionTypeEnum.RETURN
        ].indexOf(this.actionType) > -1) {
            this.selectionResultList = [];
        } else if (this.actionType == ActionTypeEnum.ISSUE_REQUEST) {
            this.selectionResultList = null;
        }
    }

    closeModal() {
        this.close.emit(true);
        this.selectionResultList = [];
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }
}
