import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Project } from '@gammon/inventory-api';
import { ApprovalTypeEnum, ToggleValue } from '@material/common';
import { Subscription } from 'rxjs';

@Component({
  selector: 'approval-route-tab',
  templateUrl: './approval-route-tab.component.html',
  styleUrls: ['./approval-route-tab.component.scss']
})
export class ApprovalRouteTabComponent implements OnInit, OnDestroy {
  @Input() currentProject: Project;

  ToggleValue = ToggleValue;

  subscriptionList: Subscription[] = [];
  ApprovalType = ApprovalTypeEnum;
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}
