import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ItemCategory, ItemSubCategoriesService, ItemSubCategory, Project } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';
import { ShareStateService } from '../../services/share-state.service';

@Component({
  selector: 'search-subcategory',
  templateUrl: './search-subcategory.component.html',
  styleUrls: ['./search-subcategory.component.scss']
})
export class SearchSubcategoryComponent implements OnInit, OnDestroy {

  @Input() project: Project;
  @Input() category: ItemCategory;
  @Input() subCategory: ItemSubCategory;
  @Input() orientation: 'row'|'column';
  @Output() subCategoryChange = new EventEmitter<ItemSubCategory>();
  @Input() componentType: 'item-variant' | 'inventory-balance' = 'inventory-balance';
  
  subscriptionList: Subscription[] = [];
  subscriptionSubCategory: Subscription;
  subscriptionSelectedSubCategory: Subscription;
  searchSubCategoryList: ItemSubCategory[] = [];

  constructor(
    private itemSubCategoriesService: ItemSubCategoriesService,
    private shareStateService: ShareStateService
  ) {
    this.subscriptionSubCategory = this.shareStateService.subCategoryListObservable.subscribe(subCategory => {
      this.searchSubCategoryList = subCategory as ItemSubCategory[];
    });
    this.subscriptionSelectedSubCategory = this.shareStateService.selectedSubCategoryObservable.subscribe(selectionSubCategory => {
      this.subCategory = selectionSubCategory;
    });
  }

  ngOnInit() {
  }

  searchSubCategories() {
    // this.subscriptionList.push(this.itemSubCategoriesService.itemSubCategoriesGetSearchList({ categoryID: this.category.ID, views:['ITEM_SUB_CATEGORY','ITEM'] }).subscribe(pageData => {
    //   if (pageData.Items) {
    //     this.searchSubCategoryList = pageData.Items;
    //   }
    // }));
    switch (this.componentType) {
      case 'item-variant': // search from item variant (e.g. receipt)
        this.itemSubCategoriesService.itemSubCategoriesGetSearchList({ categoryID: this.category.ID, views:['ITEM'] })
        .subscribe(pageData => {
            if (pageData.Items) { this.searchSubCategoryList = pageData.Items; }
        });
          break;
      default: // search from inventory balance (e.g. issue, change-location, etc...)
          this.subscriptionList.push(
              this.itemSubCategoriesService.itemSubCategoriesGetListWithIbQtyGreaterThanZero({
                  projectID: this.project.ID,
                  categoryID: this.category.ID,
                  views: ['ITEM']
              }).subscribe(itemSubCategories => {
                  if (itemSubCategories) {
                    this.searchSubCategoryList = itemSubCategories;
                  }
              })
          );
          break;
      }
  }

  onSubCategoryChange(event) {
    this.subCategoryChange.emit(event);
    this.shareStateService.emitItemList([]);
    this.shareStateService.emitSelectedItem(null);
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(subscription => {
      if (subscription) { subscription.unsubscribe(); }
    });
  }
}
