/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApprovalHeader } from '../model/approvalHeader';
import { PageApprovalHeader } from '../model/pageApprovalHeader';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ApprovalHeadersServiceInterface, IapprovalHeadersCreateParams,IapprovalHeadersDeleteParams,IapprovalHeadersGetApprovalHeaderByTidParams,IapprovalHeadersGetApprovalHeaderTidParams,IapprovalHeadersGetSearchListParams,IapprovalHeadersUpdateParams, }                            from './approvalHeaders.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ApprovalHeadersService implements ApprovalHeadersServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalHeadersCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalHeadersCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ApprovalHeader>;
    public approvalHeadersCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalHeadersCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalHeader>>;
    public approvalHeadersCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalHeadersCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalHeader>>;
    @datastore
    public approvalHeadersCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalHeadersCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling approvalHeadersCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ApprovalHeader>(`${this.basePath}/ApprovalHeaders`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stockTransferHeaderID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalHeadersDelete({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID } : IapprovalHeadersDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalHeadersDelete({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID } : IapprovalHeadersDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalHeadersDelete({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID } : IapprovalHeadersDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalHeadersDelete({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID } : IapprovalHeadersDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stockTransferHeaderID === null || stockTransferHeaderID === undefined) {
            throw new Error('Required parameter stockTransferHeaderID was null or undefined when calling approvalHeadersDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ApprovalHeaders/${encodeURIComponent(String(stockTransferHeaderID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param tid 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalHeadersGetApprovalHeaderByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalHeadersGetApprovalHeaderByTidParams, observe?: 'body', reportProgress?: boolean): Observable<ApprovalHeader>;
    public approvalHeadersGetApprovalHeaderByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalHeadersGetApprovalHeaderByTidParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalHeader>>;
    public approvalHeadersGetApprovalHeaderByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalHeadersGetApprovalHeaderByTidParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalHeader>>;
    @datastore
    public approvalHeadersGetApprovalHeaderByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalHeadersGetApprovalHeaderByTidParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling approvalHeadersGetApprovalHeaderByTid.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tid !== undefined && tid !== null) {
            queryParameters = queryParameters.set('tid', <any>tid);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ApprovalHeader>(`${this.basePath}/ApprovalHeaders/GetApprovalHeaderByTid`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approvalHeaderID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalHeadersGetApprovalHeaderTid({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID } : IapprovalHeadersGetApprovalHeaderTidParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalHeadersGetApprovalHeaderTid({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID } : IapprovalHeadersGetApprovalHeaderTidParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalHeadersGetApprovalHeaderTid({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID } : IapprovalHeadersGetApprovalHeaderTidParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalHeadersGetApprovalHeaderTid({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID } : IapprovalHeadersGetApprovalHeaderTidParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (approvalHeaderID === null || approvalHeaderID === undefined) {
            throw new Error('Required parameter approvalHeaderID was null or undefined when calling approvalHeadersGetApprovalHeaderTid.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalHeaderID !== undefined && approvalHeaderID !== null) {
            queryParameters = queryParameters.set('approvalHeaderID', <any>approvalHeaderID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<string>(`${this.basePath}/ApprovalHeaders/GetApprovalHeaderTid`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approvalHeaderID 
     * @param referenceNumber 
     * @param fromID 
     * @param toID 
     * @param approvalStatus 
     * @param transferDate 
     * @param onBeforeAfter 
     * @param requestor 
     * @param remarks 
     * @param approvalType 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalHeadersGetSearchList({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID, referenceNumber, fromID, toID, approvalStatus, transferDate, onBeforeAfter, requestor, remarks, approvalType, page, size, views } : IapprovalHeadersGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageApprovalHeader>;
    public approvalHeadersGetSearchList({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID, referenceNumber, fromID, toID, approvalStatus, transferDate, onBeforeAfter, requestor, remarks, approvalType, page, size, views } : IapprovalHeadersGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageApprovalHeader>>;
    public approvalHeadersGetSearchList({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID, referenceNumber, fromID, toID, approvalStatus, transferDate, onBeforeAfter, requestor, remarks, approvalType, page, size, views } : IapprovalHeadersGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageApprovalHeader>>;
    @datastore
    public approvalHeadersGetSearchList({ deviceId, message, ignoreLoadingBar, formData, approvalHeaderID, referenceNumber, fromID, toID, approvalStatus, transferDate, onBeforeAfter, requestor, remarks, approvalType, page, size, views } : IapprovalHeadersGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (approvalHeaderID !== undefined && approvalHeaderID !== null) {
            queryParameters = queryParameters.set('approvalHeaderID', <any>approvalHeaderID);
        }
        if (referenceNumber !== undefined && referenceNumber !== null) {
            queryParameters = queryParameters.set('referenceNumber', <any>referenceNumber);
        }
        if (fromID !== undefined && fromID !== null) {
            queryParameters = queryParameters.set('fromID', <any>fromID);
        }
        if (toID !== undefined && toID !== null) {
            queryParameters = queryParameters.set('toID', <any>toID);
        }
        if (approvalStatus !== undefined && approvalStatus !== null) {
            queryParameters = queryParameters.set('approvalStatus', <any>approvalStatus);
        }
        if (transferDate !== undefined && transferDate !== null) {
            queryParameters = queryParameters.set('transferDate', <any>transferDate.toISOString());
        }
        if (onBeforeAfter !== undefined && onBeforeAfter !== null) {
            queryParameters = queryParameters.set('onBeforeAfter', <any>onBeforeAfter);
        }
        if (requestor !== undefined && requestor !== null) {
            queryParameters = queryParameters.set('requestor', <any>requestor);
        }
        if (remarks !== undefined && remarks !== null) {
            queryParameters = queryParameters.set('remarks', <any>remarks);
        }
        if (approvalType !== undefined && approvalType !== null) {
            queryParameters = queryParameters.set('approvalType', <any>approvalType);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageApprovalHeader>(`${this.basePath}/ApprovalHeaders/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stockTransferHeaderID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalHeadersUpdate({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID, model } : IapprovalHeadersUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalHeadersUpdate({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID, model } : IapprovalHeadersUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalHeadersUpdate({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID, model } : IapprovalHeadersUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalHeadersUpdate({ deviceId, message, ignoreLoadingBar, formData, stockTransferHeaderID, model } : IapprovalHeadersUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stockTransferHeaderID === null || stockTransferHeaderID === undefined) {
            throw new Error('Required parameter stockTransferHeaderID was null or undefined when calling approvalHeadersUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling approvalHeadersUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ApprovalHeaders/${encodeURIComponent(String(stockTransferHeaderID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
