/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApprovalDetail } from '../model/approvalDetail';
import { PageApprovalDetail } from '../model/pageApprovalDetail';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ApprovalDetailsServiceInterface, IapprovalDetailsCreateParams,IapprovalDetailsDeleteParams,IapprovalDetailsGetApprovalDetailsByTidParams,IapprovalDetailsGetSearchListParams,IapprovalDetailsUpdateParams, }                            from './approvalDetails.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ApprovalDetailsService implements ApprovalDetailsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalDetailsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalDetailsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ApprovalDetail>;
    public approvalDetailsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalDetailsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApprovalDetail>>;
    public approvalDetailsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalDetailsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApprovalDetail>>;
    @datastore
    public approvalDetailsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IapprovalDetailsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling approvalDetailsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ApprovalDetail>(`${this.basePath}/ApprovalDetails`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approvalDetailID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalDetailsDelete({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID } : IapprovalDetailsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalDetailsDelete({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID } : IapprovalDetailsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalDetailsDelete({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID } : IapprovalDetailsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalDetailsDelete({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID } : IapprovalDetailsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (approvalDetailID === null || approvalDetailID === undefined) {
            throw new Error('Required parameter approvalDetailID was null or undefined when calling approvalDetailsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ApprovalDetails/${encodeURIComponent(String(approvalDetailID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param tid 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalDetailsGetApprovalDetailsByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalDetailsGetApprovalDetailsByTidParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ApprovalDetail>>;
    public approvalDetailsGetApprovalDetailsByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalDetailsGetApprovalDetailsByTidParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ApprovalDetail>>>;
    public approvalDetailsGetApprovalDetailsByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalDetailsGetApprovalDetailsByTidParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ApprovalDetail>>>;
    @datastore
    public approvalDetailsGetApprovalDetailsByTid({ deviceId, message, ignoreLoadingBar, formData, tid, views } : IapprovalDetailsGetApprovalDetailsByTidParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (tid === null || tid === undefined) {
            throw new Error('Required parameter tid was null or undefined when calling approvalDetailsGetApprovalDetailsByTid.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tid !== undefined && tid !== null) {
            queryParameters = queryParameters.set('tid', <any>tid);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ApprovalDetail>>(`${this.basePath}/ApprovalDetails/GetApprovalDetailsByTid`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ID 
     * @param approvalHeaderID 
     * @param tid 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalDetailsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, ID, approvalHeaderID, tid, page, size, views } : IapprovalDetailsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageApprovalDetail>;
    public approvalDetailsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, ID, approvalHeaderID, tid, page, size, views } : IapprovalDetailsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageApprovalDetail>>;
    public approvalDetailsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, ID, approvalHeaderID, tid, page, size, views } : IapprovalDetailsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageApprovalDetail>>;
    @datastore
    public approvalDetailsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, ID, approvalHeaderID, tid, page, size, views } : IapprovalDetailsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ID !== undefined && ID !== null) {
            queryParameters = queryParameters.set('ID', <any>ID);
        }
        if (approvalHeaderID !== undefined && approvalHeaderID !== null) {
            queryParameters = queryParameters.set('approvalHeaderID', <any>approvalHeaderID);
        }
        if (tid !== undefined && tid !== null) {
            queryParameters = queryParameters.set('tid', <any>tid);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageApprovalDetail>(`${this.basePath}/ApprovalDetails/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approvalDetailID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalDetailsUpdate({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID, model } : IapprovalDetailsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalDetailsUpdate({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID, model } : IapprovalDetailsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalDetailsUpdate({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID, model } : IapprovalDetailsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalDetailsUpdate({ deviceId, message, ignoreLoadingBar, formData, approvalDetailID, model } : IapprovalDetailsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (approvalDetailID === null || approvalDetailID === undefined) {
            throw new Error('Required parameter approvalDetailID was null or undefined when calling approvalDetailsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling approvalDetailsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ApprovalDetails/${encodeURIComponent(String(approvalDetailID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
