<div
    class="modal-header p-3"
    [ngClass]="modalStatus ? 'bg-' + modalStatus : 'bg-primary'"
    *ngIf="title; else noTitle"
>
    <h4 class="modal-title text-white bold pull-left">{{ title | translate: messageParam }}</h4>
    <button type="button" class="close text-white pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<ng-template #noTitle>
    <div class="modal-header p-3 bg-white">
        <h4 class="modal-title text-white bold pull-left"></h4>
        <button
            type="button"
            class="close text-master pull-right"
            aria-label="Close"
            (click)="bsModalRef.hide()"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
</ng-template>

<div class="modal-body p-3">
    <ng-container *ngIf="isArray(messages); else nonArray">
        <span *ngFor="let message of messages">{{ message | translate: messageParam }} </span>
    </ng-container>
    <ng-template #nonArray>
        <ng-container *ngIf="isObject(messages); else nonObject">
            <span>{{ messages['error'].Message || messages }} </span>
        </ng-container>
        <ng-template #nonObject>
            <span>{{ (messages || '').toString() | translate: messageParam }} </span>
        </ng-template>
    </ng-template>
    <div #componentTemplate></div>
</div>

<div class="modal-footer p-3">
    <ng-container *ngIf="modalType == 'yesNo'">
        <button type="button" class="btn btn-info" (click)="confirm()">
            {{ confirmBtnName | translate }}
        </button>
        <button type="button" class="btn btn-danger" (click)="cancel()">
            {{ cancelBtnName | translate }}
        </button>
    </ng-container>
    <!-- TODO: variable not defined -->
    <!-- <ng-container *ngIf="modalType=='close'">
        <button type="button" class="btn btn-info" (click)="close()">{{ closeBtnName | translate }}</button>
    </ng-container> -->
</div>
