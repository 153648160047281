import { Injectable } from '@angular/core';
import { Project } from '@gammon/inventory-api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShareStateService {

  public project: Project = {};

  private projectSource = new Subject();
  projectSourceObservable = this.projectSource.asObservable();

  private hasMove = new Subject();
  haseMoveObservable = this.hasMove.asObservable();

  private approvalRoutes = new Subject();
  approvalRouteObservable = this.approvalRoutes.asObservable();

  private categoryType = new Subject();
  categoryTypeObservable = this.categoryType.asObservable();
  constructor() { }

  private changeLanguage = new Subject();
  changeLanguageObservable = this.changeLanguage.asObservable();

  private hiddenBtn = new Subject();
  hiddenBtnObservable = this.hiddenBtn.asObservable();

  private subCategoryList = new Subject();
  subCategoryListObservable = this.subCategoryList.asObservable();

  private selectedSubCategory = new Subject();
  selectedSubCategoryObservable = this.selectedSubCategory.asObservable();

  private itemList = new Subject();
  itemListObservable = this.itemList.asObservable();

  private selectedItem = new Subject();
  selectedItemObservable = this.selectedItem.asObservable();

  private selectedCategory = new Subject();
  selectedCategoryObservable = this.selectedCategory.asObservable();

  emitProjectSource(project: any) {
    this.projectSource.next(project);
  }

  emitBoolean(isMove: boolean) {
    this.hasMove.next(isMove);
  }

  emitApprovalRoute(approvalRoute: any) {
    this.approvalRoutes.next(approvalRoute);
  }

  emitCategoryType(categoryType: string) {
    this.categoryType.next(categoryType);
  }

  emintChangeLanguage(language: string) {
    this.changeLanguage.next(language);
  }

  emitHiddenBtn(isShow: boolean) {
    this.hiddenBtn.next(isShow);
  }

  emitSubCategoryList(subCategory: any) {
    this.subCategoryList.next(subCategory);
  }

  emitSelectedSubCategory(selectionSubCategory: any) {
    this.selectedSubCategory.next(selectionSubCategory);
  }

  emitItemList(item: any) {
    this.itemList.next(item);
  }

  emitSelectedItem(selectionItem: any) {
    this.selectedItem.next(selectionItem);
  }

  emitSelectedCategory(selectionCategory: any) {
    this.selectedCategory.next(selectionCategory);
  }
}
