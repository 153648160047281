<div class="input-group">
  <ng-select
  style="width: 250px"
  (open)="filterAreaList()"
  [items]="areaList"
  [(ngModel)]="area"
  [disabled]="!location"
  (ngModelChange)="areaChange.emit(area)"
  appendTo="body"
>
  <ng-template ng-label-tmp let-item="item">
    <span>{{ item | desc: "AREA_DESC":"AreaEnglish" }}</span>
  </ng-template>
  <ng-template
    ng-option-tmp
    let-item="item"
    let-search="searchTerm"
    let-index="index"
  >
    <span>{{ item | desc: "AREA_DESC":"AreaEnglish" }}</span>
  </ng-template>
</ng-select>
</div>
