import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Area, AreasService, Bin, BinsService, InventoryBalance, ItemVariant, Location, LocationsService, Project } from '@gammon/inventory-api';
import { CommonBin, CommonInventoryBalance, CommonItemVariant } from '@material/common';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { GeneralService, ModalService, ModalStatus } from '../../services';
@Component({
    selector: 'search-bin',
    templateUrl: './search-bin.component.html',
    styleUrls: ['./search-bin.component.scss']
})
export class SearchBinComponent implements OnInit, OnDestroy {

    @Input() currentProject: Project = {};
    @Input() actionType = '';
    @Input() itemList: InventoryBalance[]|ItemVariant[] = [];
    @Input() popup = true;
    @Input() excludeBin: Bin;
    @Input() item?: CommonInventoryBalance | CommonItemVariant;
    subscriptionList: Subscription[] = [];

    //Locations (one...many)
    @Output() binChange = new EventEmitter<Bin>();
    @Output() areaChange = new EventEmitter<Area>();
    @Output() locationChange = new EventEmitter<Location>();
    @Output() close = new EventEmitter<any>();
    @Output() itemListChange = new EventEmitter<InventoryBalance[]|ItemVariant[]>();

    locationList: Location[] = [];
    @Input() location: Location;
    areaList: Area[] = [];
    @Input() area: Area = {};
    binList: Bin[] = [];
    @Input() bin: Bin;

    @Input() disableLocation = false;
    @Input() disableArea = false;

    //Quick Search Bin
    binTerm$ = new Subject<string>();
    quickSearchBinInput: string = null; //Input string for Bin ID in Set Location Modal

    searchSubscription: Subscription;

    constructor(
        private generalService: GeneralService,
        private locationsService: LocationsService,
        private areasService: AreasService,
        private binsService: BinsService,
        private modalService: ModalService
    ) { }

    ngOnInit() {
        this.searchSubscription = this.binTerm$.pipe(debounceTime(500), switchMap(term => {
            if (term.length > 0) {
                this.quickSearchBin();
            }

            return EMPTY;
        })).subscribe();
    }

    quickSearchBin() {
        //Reset selections
        this.resetSelections();
        if (!this.quickSearchBinInput || this.quickSearchBinInput.length == 0) { return; }

        if (this.generalService.parseQrCodeApplicationCode(this.quickSearchBinInput) == 'MM') {
            if (this.generalService.parseQrCodeType(this.quickSearchBinInput) == 'LOC') {
                //start parsing
                const binID = this.generalService.parseQrCodeID(this.quickSearchBinInput);

                this.subscriptionList.push(this.binsService.binsGetBinByID({binID: +binID, views: ['AREA', 'LOCATION']}).subscribe(data => {
                    if (data && data.Area.Location.ProjectID == this.currentProject.ID) {
                        this.bin = data;
                        this.area = data.Area;
                        if (this.hasInputLocation() && data.Area.LocationID != this.location.ID) {
                          this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'BIN_LOCATION_DOES_NOT_MATCH_WITH_REQUESTED_LOCATION');
                        } else {
                          this.location = data.Area.Location;
                        }
                    } else {
                        this.bin = null;
                        this.area = null;
                        this.location = this.hasInputLocation() ? this.location : null;

                        this.quickSearchBinInput = null;
                        this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'BIN_DOES_NOT_EXIST');
                    }

                    this.binChange.emit(this.bin);
                    this.areaChange.emit(this.area);
                    this.locationChange.emit(this.location);
                }));
            } else {
                this.modalService.showNotification(ModalStatus.danger, 'INVALID_QR_CODE', 2000);
            }
        } else {
            this.modalService.showNotification(ModalStatus.danger, 'INVALID_QR_CODE', 2000);
        }

    }

    searchLocationList() {
        this.subscriptionList.push(this.locationsService.locationsGetSearchList({projectID: this.currentProject.ID, views: ['AREA', 'BIN']}).subscribe(pageData => {
            if (pageData.Items) {
                this.locationList = pageData.Items;
            }
        }));
    }

    searchAreaList() {
        this.subscriptionList.push(this.areasService.areasGetSearchList({locationID: this.location.ID}).subscribe(pageData => {
            if (pageData.Items) {
                this.areaList = pageData.Items;
            }
        }));
    }

    searchBinList() {
      this.subscriptionList.push(this.binsService.binsGetSearchList({areaID: this.area.ID, views: ['AREA', 'LOCATION']}).subscribe(pageData => {
        this.binList = pageData.Items as CommonBin[];
        this.binList.forEach(bin => {
          if (this.shouldDisableBin(bin)) {
            (bin as CommonBin).disabled = true;
          }
        });
      }));
    }

    shouldDisableBin(bin: Bin): boolean {
      const isSameBinID = (this.excludeBin != null && this.excludeBin.ID == bin.ID);
      const isSameIbKeeper = (this.item == null ||
        (
          (this.item.OriginalKeeper || {}).EmployeeID == (this.item.Keeper || {}).EmployeeID
        )
      );
      return isSameBinID && isSameIbKeeper;
    }

    changeLocation() {
      if (this.location.Areas.length == 1) {
        this.areaList = this.location.Areas;
        this.area = this.areaList[0];
      } else {
        this.areaList = [];
        this.area = null;
      }

      this.changeArea();
    }

    changeArea() {
      this.areaChange.emit(this.area);
      if (((this.area || {}).Bins || []).length == 1 && !this.shouldDisableBin(this.area.Bins[0])) {
          this.binList = this.area.Bins;
          this.bin = this.binList[0];
          this.bin.Area = this.area;
          this.bin.Area.Location = this.location;
      } else {
        this.binList = [];
        this.bin = null;
      }
      this.changeBin();
    }

    changeBin() {
        this.binChange.emit(this.bin);
    }

    setBin() {
      if (this.itemList) {
        this.itemList.forEach(item => {
            item['TargetLocation'] = this.location;
            item['TargetArea'] = this.area;
            item['TargetBin'] = this.bin;
        });
      }

      this.locationChange.emit(this.location);
      this.areaChange.emit(this.area);
      this.binChange.emit(this.bin);

      this.itemListChange.emit(this.itemList);
    }

    hasInputLocation(): boolean {
      return (this.location && this.disableLocation);
    }

    resetSelections() {
      this.location = this.hasInputLocation() ? this.location : null;
      this.area = null;
      this.bin = null;
      this.locationList = [];
      this.areaList = [];
      this.binList = [];
    }

    closeModal() {
        this.close.emit();

        //Clear the inputs
        this.resetSelections();
        this.quickSearchBinInput = null;
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }
}
