import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Project, SiteEntrance, SiteEntrancesService } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'search-site-entrance',
  templateUrl: './search-site-entrance.component.html',
  styleUrls: ['./search-site-entrance.component.scss']
})
export class SearchSiteEntranceComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Output() siteEntranceChange = new EventEmitter<SiteEntrance>();
  siteEntranceList: SiteEntrance[] = [];

  @Input() siteEntrance: SiteEntrance;

  subscriptionList: Subscription[] = [];

  constructor(private siteEntranceService: SiteEntrancesService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  searchSiteEntrances() {
    this.subscriptionList.push(
      this.siteEntranceService.siteEntrancesSearchSiteEntrances({ projectID: this.project.ID }).subscribe((pageData) => {
        this.siteEntranceList = pageData?.Items;
      })
    );
  }
}
