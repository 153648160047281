<ng-container *ngIf="!popup">
  <ng-select
  style="width: 250px"
  (open)="searchBinList()"
  [items]="binList"
  [(ngModel)]="bin"
  (ngModelChange)="changeBin()"
  [disabled]="!area"
  appendTo="body"
>
  <ng-template ng-label-tmp let-item="item" >
    <span>{{ item | desc: "BIN_DESC":"BinEnglish" }}</span>
  </ng-template>
  <ng-template
    ng-option-tmp
    let-item="item"
    let-search="searchTerm"
    let-index="index"
  >
    <span>{{ item | desc: "BIN_DESC":"BinEnglish" }}</span>
  </ng-template>
</ng-select>
</ng-container>

<ng-container *ngIf="popup">
<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'SEARCH_BIN' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row d-flex flex-row">
        <div class="col my-auto">
            <div class="row">
                <div class="col">
                    <div class="p-2 mb-1">
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0"><i class="icofont-bar-code"></i> {{ 'BIN' | translate }}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <input type="text" class="form-control" placeholder="{{ 'SCAN_LOCATION' | translate }}" [(ngModel)]="quickSearchBinInput" (keyup)="binTerm$.next($event.target.value)" />
                            </div>

                        </div>
                    </div>

                    <div class="p-2 mb-1">
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0"><i class="icofont-location-pin"></i> {{ 'LOCATION' | translate }}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <ng-select
                                    (open)="searchLocationList()"
                                    [items]="locationList"
                                    [(ngModel)]="location"
                                    (ngModelChange)="changeLocation()"
                                    [disabled]="disableLocation"
                                    appendTo="body">
                                    <ng-template ng-label-tmp let-item="item">
                                        <ng-container *ngIf="item">
                                            <span>{{ item | desc: 'LOCATION_DESC': 'LocationEnglish' }}</span>
                                        </ng-container>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        <span>{{ item | desc: 'LOCATION_DESC' : 'LocationEnglish' }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="p-2 mb-1">
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0"><i class="icofont-bricks"></i> {{ 'AREA' | translate }}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <ng-select
                                    (open)="searchAreaList()"
                                    [items]="areaList"
                                    [(ngModel)]="area"
                                    (ngModelChange)="changeArea()"
                                    [disabled]="!location || disableArea"
                                    appendTo="body">
                                    <ng-template ng-label-tmp let-item="item">
                                        <ng-container *ngIf="item">
                                            <span>{{ item | desc: 'AREA_DESC' : 'AreaEnglish' }}</span>
                                        </ng-container>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        <span>{{ item | desc: 'AREA_DESC' : 'AreaEnglish' }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="p-2 mb-1">
                        <div class="row">
                            <div class="col">
                                <h5 class="m-0"><i class="icofont-box"></i> {{ 'BIN' | translate }}</h5>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <ng-select
                                    (open)="searchBinList()"
                                    [items]="binList"
                                    [(ngModel)]="bin"
                                    (ngModelChange)="changeBin()"
                                    [disabled]="!area || !location"
                                    appendTo="body">
                                    <ng-template ng-label-tmp let-item="item">
                                        <ng-container *ngIf="item">
                                            <span>{{ item | desc: 'BIN_DESC' : 'BinEnglish' }}</span>
                                        </ng-container>
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item">
                                        <span>{{ item | desc : 'BIN_DESC' : 'BinEnglish' }}</span>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>

                    <div class="p-2 mb-1">
                        <div class="d-flex flex-row justify-content-end">
                            <button class="btn btn-info mx-1" (click)="closeModal()">{{ 'CANCEL' | translate }}</button>
                            <button class="btn btn-complete" (click)="setBin(); closeModal()">{{ 'CONFIRM' | translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>
