/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ADUser } from './aDUser';
import { ApprovalHeader } from './approvalHeader';
import { Area } from './area';
import { IssuePlan } from './issuePlan';
import { Location } from './location';
import { Movement } from './movement';
import { Project } from './project';
import { ReceiptPlan } from './receiptPlan';
import { SiteEntrance } from './siteEntrance';
import { SiteEntranceHistory } from './siteEntranceHistory';
import { Team } from './team';
import { TransportType } from './transportType';


export interface InventoryPlan { 
    Source?: string;
    PlanStatus?: string;
    Requestor?: string;
    Remarks?: string;
    TargetDeliveryDate?: Date;
    SupplierNo?: string;
    PlanID?: number;
    ProjectID?: number;
    LocationID?: number;
    AreaID?: number;
    DatePeriod?: string;
    SupplierName?: string;
    TargetLocationID?: number;
    TargetAreaID?: number;
    TeamID?: number;
    RemarksReview?: string;
    RemarksComplete?: string;
    LastApprover?: string;
    ActualReceivedDate?: Date;
    LiftingQuantity?: number;
    InstallationDate?: Date;
    SiteEntranceID?: number;
    LicensePlateNumber?: string;
    SubmissionDate?: Date;
    TransportTypeID?: number;
    IssuePlans?: Array<IssuePlan>;
    ReceiptPlans?: Array<ReceiptPlan>;
    Project?: Project;
    Location?: Location;
    Area?: Area;
    Movements?: Array<Movement>;
    Team?: Team;
    ApprovalHeader?: ApprovalHeader;
    SiteEntrance?: SiteEntrance;
    SiteEntranceHistory?: Array<SiteEntranceHistory>;
    TransportType?: TransportType;
    ReferenceNo?: number;
    Tid?: string;
    RequestorDetails?: ADUser;
    ID?: number;
    CreatedDate?: Date;
    Status?: string;
    DataStatus?: InventoryPlan.DataStatusEnum;
    DataDate?: Date;
}
export namespace InventoryPlan {
    export type DataStatusEnum = 'BACKEND' | 'CACHE' | 'CREATE_OK' | 'CREATE_FAIL' | 'CREATE_PENDING' | 'CREATE_QUEUE' | 'DELETE_OK' | 'DELETE_FAIL' | 'DELETE_PENDING' | 'DELETE_QUEUE' | 'UPDATE_OK' | 'UPDATE_FAIL' | 'UPDATE_PENDING' | 'UPDATE_QUEUE' | 'VALIDATION_OK' | 'VALIDATION_FAIL' | 'WAITING_FOR_APPROVAL';
    export const DataStatusEnum = {
        BACKEND: 'BACKEND' as DataStatusEnum,
        CACHE: 'CACHE' as DataStatusEnum,
        CREATEOK: 'CREATE_OK' as DataStatusEnum,
        CREATEFAIL: 'CREATE_FAIL' as DataStatusEnum,
        CREATEPENDING: 'CREATE_PENDING' as DataStatusEnum,
        CREATEQUEUE: 'CREATE_QUEUE' as DataStatusEnum,
        DELETEOK: 'DELETE_OK' as DataStatusEnum,
        DELETEFAIL: 'DELETE_FAIL' as DataStatusEnum,
        DELETEPENDING: 'DELETE_PENDING' as DataStatusEnum,
        DELETEQUEUE: 'DELETE_QUEUE' as DataStatusEnum,
        UPDATEOK: 'UPDATE_OK' as DataStatusEnum,
        UPDATEFAIL: 'UPDATE_FAIL' as DataStatusEnum,
        UPDATEPENDING: 'UPDATE_PENDING' as DataStatusEnum,
        UPDATEQUEUE: 'UPDATE_QUEUE' as DataStatusEnum,
        VALIDATIONOK: 'VALIDATION_OK' as DataStatusEnum,
        VALIDATIONFAIL: 'VALIDATION_FAIL' as DataStatusEnum,
        WAITINGFORAPPROVAL: 'WAITING_FOR_APPROVAL' as DataStatusEnum
    };
}
