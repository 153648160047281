import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { CommonDateService } from '@gammon/common/lib/services/common-date.service';
import {
  RowErrorInformation,
  ADUser,
  Area,
  Bin,
  Configuration,
  InventoryBalance,
  InventoryBalancesService,
  Item,
  ItemCategory,
  ItemLotsService,
  ItemSubCategory,
  ItemVariantsService,
  KeeperService,
  Location,
  LocationsService,
  Project,
  SupplierInformation,
  SupplierInformationService,
  Team,
  UploadToStockReceiptResult,
  UsersService,
  UploadPlantNosToGetBalancesResult,
  InventoryPlansService
} from '@gammon/inventory-api';
import { DownloadItemTaggingFileParam } from '@gammon/inventory-api/model/downloadItemTaggingFileParam';
import { ItemLot, ItemVariant, PageItemVariant, SiteEntrance, SiteEntranceTransport, TransportType } from '@gammon/inventory-api/model/models';
import { UpdateExpireDateParam } from '@gammon/inventory-api/model/updateExpireDateParam';
import { ActionType, ActionTypeEnum, CommonInventoryBalance, CommonItemVariant } from '@material/common';
import { NgSelectComponent } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { config } from 'projects/AppConfig';
import { EMPTY, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { UploadFile } from '../../../../@pages/components/upload/interface';
import { SearchInventoryBalanceComponent } from '../../../components/search-inventory-balance/search-inventory-balance.component';
import { GeneralService } from '../../../services/general.service';
import { ModalService, ModalStatus } from '../../../services/modal.service';
import { faCalendar, faDoorOpen, faFile, faQrcode, faStickyNote, faTimes, faTrash, faTruck } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'items-tab',
  templateUrl: './items-tab.component.html',
  styleUrls: ['./items-tab.component.scss']
})
export class ItemsTabComponent implements OnInit, OnDestroy {
  @Input() actionType: ActionType;
  @Input() currentProject: Project;
  @Input() tableTakeRows: number = 10;
  @ViewChild(SearchInventoryBalanceComponent) searchInventoryBalanceComponent: SearchInventoryBalanceComponent;

  bsModalRef: BsModalRef;
  @ViewChild('exportItemVariantFilterModal') exportItemVariantFilterModal: TemplateRef<any>;
  @ViewChild('searchItemVariantModal') searchItemVariantModal: TemplateRef<any>;
  @ViewChild('searchInventoryBalanceModal') searchInventoryBalanceModal: TemplateRef<any>;
  @ViewChild('setLocationModal') setLocationModal: TemplateRef<any>;
  @ViewChild('setLocationByLineModal') setLocationByLineModal: TemplateRef<any>;
  @ViewChild('sourceTargetLocationAreaModal') sourceTargetLocationAreaModal: TemplateRef<any>;

  @ViewChild('keeperInput') keeperInput;

  @Input() withApproval = false; //for change-location

  @Output() itemListChange = new EventEmitter<(CommonInventoryBalance | CommonItemVariant)[]>();
  itemList: Array<CommonInventoryBalance | CommonItemVariant> = [];
  currentLineItem: CommonInventoryBalance | CommonItemVariant;
  currentLineIndex: number;
  itemListSelection: any[] = [];
  AllBin: Bin;
  AllKeeper: ADUser;
  subscriptionList: Subscription[] = [];

  faDoorOpen = faDoorOpen;
  faTruck = faTruck;
  faStickyNote = faStickyNote;
  faTrash = faTrash;
  faFile = faFile;
  faCalendar = faCalendar;
  faTimes = faTimes;
  faQrcode = faQrcode;

  //tagging file
  @ViewChild('importWordPdfModal', { static: true }) importWordPdfModal: TemplateRef<any>;
  FileList: UploadFile[] = [];
  @Input() authHeader: { Authorization };
  currentItemLotId: number;
  API_BASE_PATH: string;

  //Edit Modal
  @ViewChild('editLot', { static: true }) editLot: TemplateRef<any>;
  //update item lot expiry date
  selectedInventoryBalance: InventoryBalance;
  editInventoryBalanceList: InventoryBalance[] = [];
  //Quick Search Item
  term$ = new Subject<string>();
  searchSubscription: Subscription;
  quickSearchItemInput: string = null; //Input string for Search Item bar

  @Output() supplierChange = new EventEmitter<SupplierInformation>();
  supplier: SupplierInformation;
  @Output() supplierNameChange = new EventEmitter<string>();
  supplierName: string;

  //Keeper
  @Output() keeperChange = new EventEmitter<ADUser>();
  @Output() keeperNameChange = new EventEmitter<string>();
  keeper: ADUser;
  keeperNameSearchParam: string;
  tableRowOffset: number;
  tableRowCount = 10;
  totalRecords = 0;
  tablePage: number;

  //Target Date
  @Output() targetDateChange = new EventEmitter<Date>();
  targetDate: Date;
  @Output() targetTimeChange = new EventEmitter<Date>();
  targetTime: Date;
  minDate: Date = new Date();
  @Output() periodChange = new EventEmitter<string>();
  period: string = 'time';

  //Target Project
  @Output() targetProjectChange = new EventEmitter<Project>();
  targetProject: Project;

  //Source Project
  @Output() sourceProjectChange = new EventEmitter<Project>();
  sourceProject: Project;

  //Remarks
  @Output() remarksChange = new EventEmitter<string>();
  remarks: string;

  //Plan Remarks
  @Output() planRemarksChange = new EventEmitter<string>();
  planRemarks: string;

  //Location
  @Output() sourceLocationChange = new EventEmitter<Location>();
  sourceLocationList: Location[] = [];
  sourceLocation: Location;

  @Output() requestLocationChange = new EventEmitter<Location>();
  requestLocation: Location;

  @Output() requestAreaChange = new EventEmitter<Area>();
  requestArea: Area;

  @Output() requestLiftingQuantityChange = new EventEmitter<Number>();
  requestLiftingQuantity: Number;

  @Output() requestInstallationDateChange = new EventEmitter<Date>();
  requestInstallationDate: Date;

  //Return Location/Area/Bin (only for temp loading)
  returnLocationList: Location[] = [];
  returnAreaList: Area[] = [];
  returnBinList: Bin[] = [];

  //QR input (for issue/return input to search-inventory-balance)
  searchItem: Item;

  // Team
  team: Team;
  @Output() teamChange = new EventEmitter<Team>();
  teamIsResponsible: boolean = false;
  @Output() toggleResponsibleTeam = new EventEmitter<boolean>();

  siteEntrance: SiteEntrance;
  @Output() siteEntranceChange = new EventEmitter<SiteEntrance>();

  siteEntranceTransport: SiteEntranceTransport;
  @Output() transportTypeChange = new EventEmitter<TransportType>();

  licensePlateNumberInput: string;
  licensePlateNumberList: string[] = [];
  @Output() licensePlateNumberListChange = new EventEmitter<string[]>();

  // Issue Request Source/Target Location/Area
  requestSourceLocation: Location;
  @Output() requestSourceLocationChange = new EventEmitter<Location>();
  requestSourceArea: Area;
  @Output() requestSourceAreaChange = new EventEmitter<Area>();
  requestTargetLocation: Location;
  @Output() requestTargetLocationChange = new EventEmitter<Location>();
  requestTargetArea: Area;
  @Output() requestTargetAreaChange = new EventEmitter<Area>();

  requestLiftingQuantityList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20];
  inventoryBalanceExcelData: Array<Array<any>> = new Array<Array<any>>();
  inputInventoryBalanceExcelData: Array<Array<any>> = new Array<Array<any>>();

  itemVariantExcelData: Array<Array<any>> = new Array<Array<any>>();
  inputItemVariantExcelData: Array<Array<any>> = new Array<Array<any>>();
  maxTransactionDate: Date = new Date();
  @Input() quantityRequired: boolean;
  @Input() quantityIsOk: boolean = true;
  @Input() toBinRequired: boolean;
  @Input() targetDateRequired: boolean;
  @Input() toProjectRequired: boolean;
  @Input() keeperRequired: boolean;
  @Input() siteEntranceRequired: boolean;
  @Input() transportTypeRequired: boolean;
  @Input() licensePlateRequired: boolean;

  ActionType = ActionTypeEnum;

  hour: string = '00';
  minute: string = '00';
  hourArray: string[] = [];

  @Input() timeSlotUnavailable: boolean = false;

  constructor(
    private generalService: GeneralService,
    private modalService: ModalService,
    private itemVariantsService: ItemVariantsService,
    private itemLotsService: ItemLotsService,
    private locationsService: LocationsService,
    private bsModalService: BsModalService,
    private commonDateService: CommonDateService,
    private httpClient: HttpClient,
    private inventoryBalanceService: InventoryBalancesService,
    private itemLotService: ItemLotsService,
    private suppliersService: SupplierInformationService,
    private translate: TranslateService,
    private usersService: UsersService,
    private keeperService: KeeperService,
    private inventoryPlanService: InventoryPlansService
  ) {
    this.searchSubscription = this.term$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => {
          if (term.length > 0) {
            this.quickSearchItemVariant();
          }
          return EMPTY;
        })
      )
      .subscribe();
    let n = new Array(24).fill('', 0, 24);
    n.forEach((e, i) => {
      this.hourArray.push(i < 10 ? '0' + i : '' + i);
    });
  }

  ngOnInit() {
    //Initiallize transferDate
    this.targetDate = new Date(Date.now());
    this.onTargetDateChange();
    if (this.currentProject) {
      this.sourceProject = this.currentProject;
    }
  }

  handleExportInventoryBalanceForIssue() {
    //set data
    this.setInventoryBalanceExcelDataForIssue();
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.inventoryBalanceExcelData);
    ws['!cols'] = [
      { width: 20 },
      { width: 14 },
      { width: 20 },
      { width: 16 },
      { width: 16 },
      { width: 16 },
      { width: 30 },
      { width: 25 },
      { width: 25 },
      { width: 10 },
      { width: 12 },
      { width: 12 },
      { width: 30 },
      { width: 30 }
    ];
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.utils.book_append_sheet(wb, ws2, 'Sheet2');
    /* save to file */
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const projectNo = (this.currentProject || {}).ProjectNumber || '';
    const fileName = 'MM' + '_' + 'Project' + '_' + projectNo + '_' + 'Stock Issue' + '_' + 'export' + '_' + year + month + day + '.xlsx';

    XLSX.writeFile(wb, fileName);
  }

  setInventoryBalanceExcelDataForIssue() {
    this.inventoryBalanceExcelData.length = 0;
    this.inventoryBalanceExcelData.push([
      'Inventory Balance Id/庫存餘額編號',
      'Quantity/數量',
      'MaxQuantity/最大數量',
      'Keeper ID/管理人工号',
      'Keeper Name/管理人姓名',
      'Non Gammon Keeper/非金門管理人',
      'Transaction Date/交易日期(YYYY/MM/DD)',
      'English Description/英文描述',
      'Chinese Description/中文描述',
      'Brand/牌子',
      'Model/型號',
      'Size/尺寸',
      'Item Remark/物品備註',
      'Issue Remark/提取備注'
    ]);
    const inventoryBalanceList = this.itemList as Array<CommonInventoryBalance>;
    inventoryBalanceList.forEach((ib) => {
      const row = [];
      row.push(ib.ID);
      row.push(ib.Quantity || 0);
      row.push(ib.MaxQuantity || 0);
      row.push((ib.Keeper || {}).EmployeeID || null);
      row.push((ib.OriginalKeeper || {}).Name || null);
      row.push(ib.KeeperName || null);
      var transactionDateStr = this.getYYYYMMDDString(ib.TransactionDate);
      row.push(transactionDateStr);
      row.push((((ib.ItemLot || {}).ItemVariant || {}).Item || {}).ItemEnglish);
      row.push((((ib.ItemLot || {}).ItemVariant || {}).Item || {}).ItemChinese);
      row.push(((ib.ItemLot || {}).ItemVariant || {}).Brand);
      row.push(((ib.ItemLot || {}).ItemVariant || {}).ModelNo);
      row.push(((ib.ItemLot || {}).ItemVariant || {}).Size);
      row.push((ib.ItemLot || {}).Remarks || ((ib.ItemLot || {}).ItemVariant || {}).Remark);
      row.push(ib.MovementRemarks);
      this.inventoryBalanceExcelData.push(row);
    });
  }

  handleImportInventoryBalanceForIssue(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    if (target.files[0].name.toLowerCase().indexOf('xlsx') < 0) {
      throw new Error('Only support file type is xlsx');
    }
    if (target.files[0].size > 1024 * 1024 * 1024 * 10) {
      throw new Error('File size not more than 10M');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.inputInventoryBalanceExcelData = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.setInventoryBalanceListForIssue(); //update item list date
      //console.log(this.inputdata)
      evt.target.value = ''; // 清空
    };
    reader.readAsBinaryString(target.files[0]);
  }

  //update item list date
  setInventoryBalanceListForIssue() {
    let inputExcelData = this.inputInventoryBalanceExcelData;
    inputExcelData.splice(0, 1);
    inputExcelData.forEach((item) => {
      //item[0] is Item Variant Id
      if (item.length > 0 && item[0]) {
        this.itemList.forEach((element) => {
          if (element.ID === item[0]) {
            element = element as CommonInventoryBalance;
            element.Quantity = item[1];
            element.TransactionDate = this.convertToDate(item[6]);
            element.KeeperName = item[5];
            element.MovementRemarks = item[13];
            if (item[3]) {
              this.subscriptionList.push(
                this.usersService.usersGetListByQuery({ query: item[3] }).subscribe((data) => {
                  if (data && data.length > 0) {
                    this.subscriptionList.push(
                      this.keeperService.keeperCreateKeeper({ model: data[0] }).subscribe((keeper) => {
                        element = element as CommonInventoryBalance;
                        element.Keeper = data[0];
                        element.OriginalKeeper = element.Keeper;
                      })
                    );
                  }
                })
              );
            } else {
              element.Keeper = null;
              element.OriginalKeeper = null;
            }
          }
        });
      }
    });
    this.inputItemVariantExcelData.length = 0;
  }

  // to set item variant quantity
  handleExportItemVariant() {
    //set data
    this.setItemVariantExcelData();
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.itemVariantExcelData);
    ws['!cols'] = [{ width: 20 }, { width: 14 }, { width: 16 }, { width: 30 }, { width: 25 }, { width: 25 }, { width: 10 }, { width: 12 }, { width: 12 }, { width: 30 }];
    //  const ws2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.itemVariantExcelData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.utils.book_append_sheet(wb, ws2, 'Sheet2');
    console.log(wb);
    /* save to file */
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    let projectNo = (this.currentProject || {}).ProjectNumber || '';
    let fileName = 'MM' + '_' + 'Project' + '_' + projectNo + '_' + 'Stock Receipt' + '_' + 'export' + '_' + year + month + day + '.xlsx';

    XLSX.writeFile(wb, fileName);
  }

  setItemVariantExcelData() {
    this.itemVariantExcelData.length = 0;
    this.itemVariantExcelData.push([
      'Item Variant Id/子物品編號',
      'Quantity/數量',
      'Plant Number/機械編號',
      'Expiry Date/過期日(YYYY/MM/DD)',
      'English Description/英文描述',
      'Chinese Description/中文描述',
      'Brand/牌子',
      'Model/型號',
      'Size/尺寸',
      'Receipt Remark/提取備注'
    ]);
    let itemVariantList = this.itemList as Array<CommonItemVariant>;
    itemVariantList.forEach((itemVariant) => {
      let row = [];
      row.push(itemVariant.ID);
      row.push(itemVariant.Quantity || 0);
      row.push(itemVariant.PlantNo || '');
      var expirtyDateStr = this.getYYYYMMDDString(itemVariant.ExpiryDate);
      row.push(expirtyDateStr);
      row.push((itemVariant.Item || {}).ItemEnglish);
      row.push((itemVariant.Item || {}).ItemChinese);
      row.push(itemVariant.Brand || '');
      row.push(itemVariant.ModelNo || '');
      row.push(itemVariant.Size || 0);
      row.push(itemVariant.MovementRemarks || '');
      this.itemVariantExcelData.push(row);
    });
  }

  handleImportItemVariant(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>evt.target;
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    if (target.files[0].name.toLowerCase().indexOf('xlsx') < 0) {
      throw new Error('Only support file type is xlsx');
    }

    if (target.files[0].size > 1024 * 1024 * 1024 * 10) {
      throw new Error('File size not more than 10M');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', cellDates: true });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.inputItemVariantExcelData = XLSX.utils.sheet_to_json(ws, { header: 1 });
      this.setItemVariantList(); //update item list date
      //console.log(this.inputdata)
      evt.target.value = ''; // 清空
    };
    reader.readAsBinaryString(target.files[0]);
  }

  //update item list date
  setItemVariantList() {
    let inputExcelData = this.inputItemVariantExcelData;
    inputExcelData.splice(0, 1);
    inputExcelData.forEach((item) => {
      //item[0] is Item Variant Id
      if (item.length > 0 && item[0]) {
        this.itemList.forEach((element) => {
          if (element.ID === item[0]) {
            element.Quantity = item[1];
            element.PlantNo = item[2];
            element.ExpiryDate = this.convertToDate(item[3]); //this.isDate(item[3]) ? item[3] : null;
            element.MovementRemarks = item[9];
          }
        });
      }
    });
    this.inputItemVariantExcelData.length = 0;
  }

  getYYYYMMDDString(currentDate: Date) {
    if (!currentDate) {
      return null;
    }
    const mm = currentDate.getMonth() + 1;
    return currentDate.getFullYear() + '/' + mm + '/' + currentDate.getDate();
  }

  convertToDate(dateString) {
    if (!dateString) return null;
    let date = new Date(dateString);
    if (date && date.toString() === 'Invalid Date') {
      return null;
    }
    if (typeof dateString === typeof new Date()) {
      date.setDate(date.getDate() + 1); // Because XLSX will subtract one from the excel data with time type (2019/1/10), However, the string type will not be reduced by one
    }
    return date;
  }

  onHandleExpireDateChange(data: { value: Date; oldValue: Date }, itemLotID: number) {
    let newExpireDate = data.value;
    let oldExpireDate = data.oldValue;
    if (!newExpireDate) {
      return;
    }

    this.modalService.showConfirmModal(ModalStatus.danger, 'EDIT_ITEM_LOT_EXPIRY_DATE', ['ARE_YOU_SURE_YOU_WANT_TO_EDIT']).subscribe((event) => {
      if (event === 'confirm') {
        //request api to update expireDate.
        const updateExpireDateParam: UpdateExpireDateParam = {
          ItemLotID: itemLotID,
          NewExpireDate: newExpireDate
        };
        this.subscriptionList.push(
          this.itemLotService.itemLotsUpdateItemLotExpireDate({ model: updateExpireDateParam }).subscribe((res) => {
            if (res.toUpperCase() != ItemLot.DataStatusEnum.UPDATEOK) {
              this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
              return;
            }
            this.setExpireDateValue(itemLotID, newExpireDate);
            this.bsModalRef.hide();
            this.modalService.showMessageModal(ModalStatus.success, 'OPERATION_SUCCESS', 'SUCCESS');
          })
        );
      } else {
        this.setExpireDateValue(itemLotID, oldExpireDate);
      }
    });
  }

  setExpireDateValue(itemLotID: number, date: Date): void {
    let inventoryBalanceList: InventoryBalance[] = this.itemList;
    inventoryBalanceList.forEach((item) => {
      let inventoryBalanc: InventoryBalance = item as InventoryBalance;
      if (inventoryBalanc.ItemLot.ID === itemLotID) {
        inventoryBalanc.ItemLot.ExpiryDate = date;
      }
    });
  }

  /**
   *  +++++++++++++++++
   *  |   Edit Modal  |
   *  +++++++++++++++++
   */
  showEditLot(item: InventoryBalance) {
    const config: ModalOptions = { class: 'modal-x' };
    if (!item.ItemLot) {
      item.ItemLot = {};
    }
    if (!item.ItemLot.ExpiryDate) {
      item.ItemLot.ExpiryDate = null;
    }
    this.selectedInventoryBalance = item;
    this.editInventoryBalanceList = [JSON.parse(JSON.stringify(item))];
    this.bsModalRef = this.bsModalService.show(this.editLot, config);
  }

  OnSubmitSplit() {
    console.log('Edit IB list: ', this.editInventoryBalanceList);
    if (
      !this.editInventoryBalanceList ||
      this.editInventoryBalanceList.length < 0 ||
      !this.editInventoryBalanceList[0].ItemLot ||
      !this.editInventoryBalanceList[0].ItemLot.ID ||
      !this.editInventoryBalanceList[0].ItemLot.ExpiryDate
    ) {
      //fail
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    let itemLotID = this.editInventoryBalanceList[0].ItemLot.ID;
    let newExpireDate = this.editInventoryBalanceList[0].ItemLot.ExpiryDate;
    this.subscriptionList.push(
      this.itemLotService.itemLotsUpdateExpireDate({ itemLotID, newExpireDate }).subscribe((res) => {
        //this.subscriptionList.push(this.itemLotService.itemLotsUpdateItemLotExpireDate({model: updateExpireDateDTO}).subscribe((res) => {
        if (res.toUpperCase() != ItemLot.DataStatusEnum.UPDATEOK) {
          //fail
          this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
          return;
        }
        this.editInventoryBalanceList = [];
        this.bsModalRef.hide();
      })
    );
  }

  IgnoreItemLotInIbList(itemLots: ItemLot[], ibWithSerialList: InventoryBalance[]): ItemLot[] {
    const duplicateList = [];
    itemLots.forEach((itemLot) => {
      const editIbItemLotList = ibWithSerialList.filter((ib) => ib.ItemLot.ID === itemLot.ID && ib.ItemLot.SerialNo === itemLot.SerialNo);
      if (editIbItemLotList.length === 0) {
        duplicateList.push(itemLot);
      }
    });
    return duplicateList;
  }

  /**
   * +++++++++++++++++++++++++++++++
   * |     Import word and pdf     |
   * +++++++++++++++++++++++++++++++
   */
  showImportModal(itemLotId) {
    this.currentItemLotId = itemLotId;
    this.inventoryBalanceService
      .inventoryBalancesGetTaggingFiles({
        itemLotId: itemLotId
      })
      .subscribe((p) => {
        const data = p.Data;
        this.FileList = [];
        data.forEach((d) => {
          this.FileList.push({
            name: d.Name,
            size: 10,
            type: '',
            uid: '',
            lastModifiedDate: d.LastModifiedDate,
            status: 'complete',
            response: { itemLotId: itemLotId }
          });
        });
      });
    // tslint:disable-next-line: no-shadowed-variable
    const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xl' };
    this.bsModalRef = this.bsModalService.show(this.importWordPdfModal, config);
  }

  getUploadLink() {
    return this.currentItemLotId ? config.API_BASE_PATH + '/InventoryBalances/ImportItemTaggingFile/' + this.currentItemLotId : null;
  }

  onHandleRemove(file) {
    if (!file || !file.response || !file.response.itemLotId) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    this.modalService.showConfirmModal(ModalStatus.danger, 'DELETE', ['ARE_YOU_SURE_YOU_WANT_TO_DELETE', file.name]).subscribe((event) => {
      if (event === 'confirm') {
        //request api to delete file by itemLotId and file name.
        this.inventoryBalanceService
          .inventoryBalancesDeleteTaggingFiles({
            itemLotId: file.response.itemLotId,
            fileName: file.name
          })
          .subscribe((p) => {
            if (p) {
              const matchKey = file.uid ? 'uid' : 'name';
              const removed = this.FileList.filter((item) => item[matchKey] !== file[matchKey]);
              this.FileList = removed;
            }
          });
      }
    });
  }

  handleDownloadAll(files: UploadFile[]): void {
    let that = this;
    files.forEach((file) => {
      that.onHandleDownloadFile(file);
    });
  }

  onHandleDownloadFile(file) {
    if (!file || !file.name || !file.response || !file.response.itemLotId) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    let fileInfos = file.name.split('.');
    //back API rule: fileName forbid to include '.' when to upload file.
    if (fileInfos.length < 2) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    let fileExtension = fileInfos[fileInfos.length - 1];
    fileExtension = fileExtension.trim().toLowerCase();
    let contentType = '';
    if (fileExtension === 'doc') {
      contentType = 'application/msword';
    } else if (fileExtension === 'docx') {
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (fileExtension === 'pdf') {
      contentType = 'application/pdf';
    } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
      contentType = 'image/jpeg';
    } else if (fileExtension === 'png') {
      contentType = 'image/png';
    } else if (fileExtension === 'bmp') {
      contentType = 'image/bmp';
    } else if (fileExtension === 'csv') {
      contentType = 'text/csv';
    } else if (fileExtension === 'xls') {
      contentType = 'application/vnd.ms-excel';
    } else if (fileExtension === 'xlsx') {
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    if (!contentType) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    const downloadTaggingFIleParam: DownloadItemTaggingFileParam = {
      ItemLotId: file.response.itemLotId,
      FileName: file.name
    };
    this.inventoryBalanceService.inventoryBalancesDownloadItemTaggingFile({ itemLotId: file.response.itemLotId, fileName: file.name }).subscribe((data) => {
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = file.name;
      link.click();

      link.addEventListener('click', function () {
        URL.revokeObjectURL(downloadURL);
        document.getElementById('download').remove();
      });
    });
  }

  export(contentType, itemLotId, fileName): Observable<any> {
    let headers = new HttpHeaders();
    const configuration = new Configuration();
    const httpHeaderAccepts: string[] = [contentType, 'application/json', 'text/json', 'application/octet-stream', 'application/xml', 'text/xml'];
    headers = headers.set('Accept', configuration.selectHeaderAccept(httpHeaderAccepts));
    return this.httpClient.get<any>(`${config.API_BASE_PATH}/InventoryBalances/ExportItemTaggingFile`, {
      responseType: 'blob' as 'json',
      withCredentials: configuration.withCredentials,
      headers,
      params: {
        itemLotId: itemLotId,
        fileName: fileName
      }
    });
  }

  quickSearchItemVariant() {
    //only search when there are inputs
    if (this.quickSearchItemInput) {
      if (this.generalService.qrCodeValid(this.quickSearchItemInput)) {
        if (this.generalService.parseQrCodeType(this.quickSearchItemInput) === 'ITM') {
          //start parsing
          const itemVariantID = this.generalService.parseQrCodeID(this.quickSearchItemInput);

          //Call api to search item by item variant ID
          this.subscriptionList.push(
            this.itemVariantsService.itemVariantsGetItemVariantByID({ itemVariantID: +itemVariantID, restrictActiveParents: true, views: ['ITEM', 'ITEM_SUB_CATEGORY', 'ITEM_CATEGORY'] }).subscribe(
              (data) => {
                if (data) {
                  const result = data as CommonItemVariant;

                  if (this.itemList.find((item) => item.ID === result.ID)) {
                    this.modalService.showMessageModal(ModalStatus.warning, 'WARNING', 'ITEM_ALREADY_EXIST');
                  } else {
                    switch (this.actionType) {
                      case ActionTypeEnum.RECEIPT:
                      case ActionTypeEnum.RECEIPT_REQUEST:
                      case ActionTypeEnum.ISSUE_REQUEST:
                        this.addItemVariant(result);
                        break;
                      case ActionTypeEnum.ISSUE:
                      case ActionTypeEnum.WRITE_OFF:
                      case ActionTypeEnum.CHANGE_LOCATION:
                      case ActionTypeEnum.TRANSFER:
                        this.searchItem = result.Item;

                        //auto pop up after qr code input
                        this.showSearchStockModal();
                        if (this.searchInventoryBalanceComponent) {
                          this.searchInventoryBalanceComponent.search();
                        }
                        break;
                      case ActionTypeEnum.RETURN:
                        this.searchItem = result.Item;

                        if (!this.keeper) {
                          (this.keeperInput as NgSelectComponent).focus();
                        } else {
                          //auto pop up after qr code input
                          this.showSearchStockModal();
                          if (this.searchInventoryBalanceComponent) {
                            this.searchInventoryBalanceComponent.search();
                          }
                        }
                        break;
                    }
                  }
                } else {
                  this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'ITEM_DOES_NOT_EXIST');
                }
              },
              (error) => {
                this.modalService.showNotification(ModalStatus.danger, 'ITEM_NOT_FOUND', 2000);
              }
            )
          );
        } else {
          this.modalService.showNotification(ModalStatus.danger, 'INVALID_QR_CODE', 2000);
        }
      } else {
        const searchItemLotBySerial: Observable<ItemLot> = this.itemLotsService.itemLotsGetItemLotBySerial({
          projectID: this.currentProject.ID,
          serialNo: this.quickSearchItemInput,
          views: ['ITEM_VARIANT', 'ITEM', 'ITEM_SUB_CATEGORY', 'ITEM_CATEGORY', 'PROJECT']
        });

        const searchItemVariantByGPC: Observable<PageItemVariant> = this.itemVariantsService.itemVariantsGetSearchList({
          projectID: this.currentProject.ID,
          gammonProductCode: this.quickSearchItemInput,
          views: ['ITEM', 'ITEM_SUB_CATEGORY', 'ITEM_CATEGORY', 'PROJECT']
        });

        const searchItemLotAndItemVariant = forkJoin([searchItemLotBySerial, searchItemVariantByGPC]);

        this.subscriptionList.push(
          searchItemLotAndItemVariant.subscribe((searchResult) => {
            const itemVariantFromItemLotBySerial: ItemVariant = searchResult[0]?.ItemVariant;
            const itemVariantByGPC: ItemVariant = searchResult[1]?.Items[0];

            console.log('item variant from item lot by serial: ', itemVariantFromItemLotBySerial);
            console.log('item variant by gpc: ', itemVariantByGPC);

            const result: ItemVariant = itemVariantFromItemLotBySerial ?? itemVariantByGPC;

            console.log('final result:' + result?.ID);

            if (result) {
              switch (this.actionType) {
                case ActionTypeEnum.RECEIPT:
                case ActionTypeEnum.RECEIPT_REQUEST:
                case ActionTypeEnum.ISSUE_REQUEST:
                  if (this.itemList.find((item) => item.ID === result.ID)) {
                    this.modalService.showMessageModal(ModalStatus.warning, 'WARNING', 'ITEM_ALREADY_EXIST');
                  } else {
                    this.addItemVariant(result as CommonItemVariant);
                  }
                  break;
                case ActionTypeEnum.ISSUE:
                case ActionTypeEnum.WRITE_OFF:
                case ActionTypeEnum.CHANGE_LOCATION:
                case ActionTypeEnum.TRANSFER:
                  this.searchItem = result.Item;

                  //auto pop up after qr code input
                  this.showSearchStockModal();
                  if (this.searchInventoryBalanceComponent) {
                    this.searchInventoryBalanceComponent.search();
                  }

                  break;
                case ActionTypeEnum.RETURN:
                  this.searchItem = result.Item;
                  if (!this.keeper) {
                    (this.keeperInput as NgSelectComponent).focus();
                  } else {
                    //auto pop up after qr code input
                    this.showSearchStockModal();
                    if (this.searchInventoryBalanceComponent) {
                      this.searchInventoryBalanceComponent.search();
                    }
                  }
                  break;
              }
            } else {
              this.modalService.showNotification(ModalStatus.danger, 'ITEM_NOT_FOUND', 2000);
            }
          })
        );
      }
    }
  }

  addItemVariant(itemVariant: CommonItemVariant) {
    itemVariant.TransactionDate = this.commonDateService.GetDateOnly();
    itemVariant.PlantNo = '';
    itemVariant.ExpiryDate = null;
    itemVariant.PoNo = null;
    this.itemList = [...this.itemList, itemVariant];
    console.log(this.itemList);
    this.itemListChange.emit(this.itemList);
  }

  removeItem(index: number) {
    this.itemList = this.itemList.filter((item) => item.ID != this.itemList[index].ID);
    this.itemList = [...this.itemList];
    this.itemListSelection = []; //clear selection for safety
    this.itemListChange.emit(this.itemList);
  }

  addItemVariants(selectionResultList: CommonItemVariant[]) {
    selectionResultList.forEach((itemVariant) => {
      if (!this.itemList.find((item) => item.ID === itemVariant.ID)) {
        itemVariant.TransactionDate = this.commonDateService.GetDateOnly();
        itemVariant.PlantNo = '';
        itemVariant.ExpiryDate = null;
        itemVariant.PoNo = null;
        this.itemList = [...this.itemList, itemVariant];
      }
    });
    console.log('addItemVariants: ', this.itemList);
    this.itemListChange.emit(this.itemList);
  }

  checkSameBin(item: CommonInventoryBalance | CommonItemVariant) {
    if ('ItemLotID' in item) {
      // IB
      if (item.Bin.ID === (item.TargetBin || {}).ID && (item.Keeper || {}).EmployeeID === (item.OriginalKeeper || {}).EmployeeID) {
        item.TargetBin = null;
      }
    }
  }

  onKeeperChange() {
    this.keeperChange.emit(this.keeper);
    //Pop up for Return
    if (this.actionType == ActionTypeEnum.RETURN) {
      //this.itemList = [];
      if (this.keeper) {
        this.keeperNameSearchParam = null;
        this.showSearchStockModal();
      }
    }
  }

  onKeeperNameChange() {
    this.keeperNameChange.emit(this.keeperNameSearchParam);
    //Pop up for Return
    if (this.actionType === ActionTypeEnum.RETURN) {
      //this.itemList = [];
      if (this.keeperNameSearchParam) {
        this.keeper = null;
        this.showSearchStockModal();
      }
    }
  }

  onTableKeeperChange(item = null) {
    if (this.actionType === ActionTypeEnum.ISSUE && item) {
      item.OriginalKeeper = item.Keeper;
    }
  }

  /**
   * ++++++++++++++++++++++++++
   * |       TargetDate       |
   * ++++++++++++++++++++++++++
   */

  onTargetDateChange() {
    this.targetDateChange.emit(this.targetDate);
  }

  onInstallationDateChange() {
    this.requestInstallationDateChange.emit(this.requestInstallationDate);
  }

  onTargetTimeChange(type: 'hour' | 'minute', value: string) {
    type == 'hour' ? (this.hour = value) : (this.minute = value);
    this.targetTime = new Date(new Date(new Date().setHours(+this.hour)).setMinutes(+this.minute));

    this.targetTimeChange.emit(this.targetTime);
  }

  onPeriodChange(value: string) {
    this.period = value?.toLowerCase();
    this.periodChange.emit(this.period);
  }

  /**
   * +++++++++++++++++++++++
   * |       Remarks       |
   * +++++++++++++++++++++++
   */

  onRemarksChange() {
    this.remarksChange.emit(this.remarks);
  }

  /**
   * ++++++++++++++++++++++++++
   * |     Plan Remarks       |
   * ++++++++++++++++++++++++++
   */

  onPlanRemarksChange() {
    this.planRemarksChange.emit(this.planRemarks);
  }

  onSourceLocationChange(event) {
    let tempLocation = null;
    if (this.sourceLocation) {
      tempLocation = JSON.parse(JSON.stringify(this.sourceLocation));
    }

    if (this.itemList && this.itemList.length > 0) {
      //show alert
      this.modalService.showConfirmModal(ModalStatus.warning, 'WARNING', 'This will clear your current items, are you sure?').subscribe((modalAction) => {
        if (modalAction === 'confirm') {
          this.sourceLocation = event;
          this.sourceLocationChange.emit(this.sourceLocation);

          this.itemList = [];
          this.itemListChange.emit(this.itemList);
        } else {
          this.sourceLocation = tempLocation;
          this.sourceLocationChange.emit(this.sourceLocation);
        }
      });
    } else {
      this.sourceLocation = event;
      this.sourceLocationChange.emit(this.sourceLocation);
    }
  }

  onRequestSourceLocationChange(event) {
    this.requestSourceLocation = event;
    this.requestSourceLocationChange.emit(this.requestSourceLocation);
    this.onRequestSourceAreaChange(null);
  }

  onRequestSourceAreaChange(event) {
    this.requestSourceArea = event;
    this.requestSourceAreaChange.emit(this.requestSourceArea);
  }

  onRequestTargetLocationChange(event) {
    this.requestTargetLocation = event;
    this.requestTargetLocationChange.emit(this.requestTargetLocation);
    this.onRequestTargetAreaChange(null);
  }

  onRequestTargetAreaChange(event) {
    this.requestTargetArea = event;
    this.requestTargetAreaChange.emit(this.requestTargetArea);
  }

  /**
   * ++++++++++++++++++++++++++++++++++++
   * |       Location (For Return)      |
   * ++++++++++++++++++++++++++++++++++++
   */

  searchReturnLocationList() {
    this.subscriptionList.push(
      this.locationsService.locationsGetSearchList({ projectID: this.currentProject.ID, views: ['AREA', 'BIN'] }).subscribe((pageData) => {
        if (pageData.Items) {
          this.returnLocationList = pageData.Items;
        }
      })
    );
  }

  changeReturnLocation(item: InventoryBalance) {
    //set all the selection to the same return location
    this.itemListSelection.forEach((inventoryBalance) => {
      //Reset child
      if (inventoryBalance['TargetArea']) {
        inventoryBalance['TargetArea'] = null;
      }
      if (inventoryBalance['TargetBin']) {
        inventoryBalance['TargetBin'] = null;
      }

      inventoryBalance['TargetLocation'] = item['TargetLocation'];
    });
  }

  onTargetProjectChange(event) {
    this.targetProject = event;
    this.targetProjectChange.emit(this.targetProject);
  }

  /**
   * ++++++++++++++++++++++++
   * |       Supplier       |
   * ++++++++++++++++++++++++
   */

  onSupplierChange(event) {
    if (!event) {
      this.requestLiftingQuantity = null;
      this.requestLiftingQuantityChange.emit(this.requestLiftingQuantity);
      this.requestInstallationDate = null;
      this.requestInstallationDateChange.emit(this.requestInstallationDate);
    }
    this.supplier = event;
    this.supplierChange.emit(this.supplier);

    this.supplierName = this.supplier ? this.supplier.AddressBookName.trim() : null;
  }

  onSupplierNameChange(event) {
    this.supplierName = event;
    this.supplierNameChange.emit(this.supplierName);
  }

  addInventoryBalance(inventoryBalance: CommonInventoryBalance) {
    //Copy inventoryBalance for customization
    // inventoryBalance.TransactionDate = this.commonDateService.GetDateOnly();
    //set 'expiryDate' value with 'null' ,it will show empty on first show when initial date picker.
    if (!inventoryBalance.ItemLot) {
      inventoryBalance.ItemLot = {};
    }
    if (!inventoryBalance.ItemLot.ExpiryDate) {
      inventoryBalance.ItemLot.ExpiryDate = null;
    }
    inventoryBalance.Keeper = null;
    const tempInventoryBalance: CommonInventoryBalance = JSON.parse(JSON.stringify(inventoryBalance));
    tempInventoryBalance['MaxQuantity'] = inventoryBalance.Quantity;
    tempInventoryBalance['TransactionDate'] = this.commonDateService.GetDateOnly();
    tempInventoryBalance.Quantity = 1; //set default value 1;
    tempInventoryBalance.OriginalKeeper = inventoryBalance.Keeper;
    this.itemList = [...this.itemList, tempInventoryBalance];
    this.itemListChange.emit(this.itemList);
  }

  addInventoryBalances(selectionResultList: CommonInventoryBalance[]) {
    console.log('selectionResultList: ', selectionResultList);
    selectionResultList.forEach((inventoryBalance) => {
      let selectedIbs = this.itemList.filter((balance) => balance.ID === inventoryBalance.ID);
      let selectedIbCount = 0;
      selectedIbs.forEach((selectedIb) => {
        selectedIbCount += selectedIb.Quantity;
      });
      if (!selectedIbs || selectedIbs.length <= 0 || selectedIbCount < inventoryBalance.Quantity) {
        this.addInventoryBalance(inventoryBalance);
      }
    });
  }

  setSelectionList(itemListSelection: CommonInventoryBalance[]) {
    this.itemListSelection = itemListSelection;

    this.itemListChange.emit(this.itemList);
  }

  //Detect changes for qr code input
  qrCodeInputChange() {
    if (!this.quickSearchItemInput || (this.quickSearchItemInput && this.quickSearchItemInput.length == 0)) {
      this.searchItem = null;
    }
  }

  /**
   * +++++++++++++++++++++++++++++++++++++++++
   * |         Request Location/Area         |
   * +++++++++++++++++++++++++++++++++++++++++
   */
  onRequestLocationChange($event) {
    this.requestLocation = $event;
    this.requestLocationChange.emit(this.requestLocation);
  }

  onRequestAreaChange($event) {
    this.requestArea = $event;
    this.requestAreaChange.emit(this.requestArea);
  }

  /**
   * ++++++++++++++++++++++++
   * |         Team         |
   * ++++++++++++++++++++++++
   */
  onToggleResponsibleTeam(event: boolean) {
    // clear all keeper & team of each item
    this.itemList.forEach((item) => {
      item.Keeper = null;
      item.Team = null;
    });

    this.toggleResponsibleTeam.emit(event);
  }

  onTeamChange(event: Team) {
    console.log('onTeamChange emitting...');
    this.teamChange.emit(event);
  }

  /**
   * ++++++++++++++++++++++++++++++++
   * |         Site Entrance        |
   * ++++++++++++++++++++++++++++++++
   */
  onSiteEntranceChange(siteEntrance: SiteEntrance) {
    this.siteEntrance = siteEntrance;
    this.siteEntranceChange.emit(this.siteEntrance);
  }

  /**
   * +++++++++++++++++++++++++++++++++
   * |    Site Entrance Transport    |
   * +++++++++++++++++++++++++++++++++
   */
  onSiteEntranceTransportChange(siteEntranceTransport: SiteEntranceTransport) {
    this.siteEntranceTransport = siteEntranceTransport;

    const transportType = this.siteEntranceTransport?.TransportType;
    this.transportTypeChange.emit(transportType);
  }

  /**
   * +++++++++++++++++++++++++++++++++++++++
   * |         License Plate Number        |
   * +++++++++++++++++++++++++++++++++++++++
   */

  addLicensePlateNumber() {
    if (this.licensePlateNumberInput?.length) {
      const licensePlatePattern = new RegExp(/[^A-Za-z0-9]+/);
      if (licensePlatePattern.test(this.licensePlateNumberInput)) {
        this.licensePlateNumberInput = null;
        this.modalService.showNotification(ModalStatus.danger, 'INVALID_LICENSE_PLATE_NUMBER', 2000);
        return;
      }

      this.licensePlateNumberInput = this.licensePlateNumberInput.toUpperCase();

      if (!this.licensePlateNumberList?.includes(this.licensePlateNumberInput)) {
        this.licensePlateNumberList.push(this.licensePlateNumberInput);
        this.licensePlateNumberInput = null;
      } else {
        this.modalService.showNotification(ModalStatus.danger, 'LICENSE_PLATE_NUMBER_ALREADY_EXIST', 2000);
      }
    }
    this.onLicensePlateNumberListChange();
  }

  removeLicensePlateNumber() {
    // pop from last
    if (this.licensePlateNumberList?.length && !this.licensePlateNumberInput?.length) {
      this.licensePlateNumberList.pop();
    }
    this.onLicensePlateNumberListChange();
  }

  checkLicensePlateNumberInput() {
    if (this.licensePlateNumberInput?.length) {
      this.addLicensePlateNumber();
    }
  }

  onLicensePlateNumberListChange() {
    this.licensePlateNumberListChange.emit(this.licensePlateNumberList);
  }

  // onLicensePlateNumberListChange(licensePlateNumberList: string[]) {
  //   console.log('model change: ', licensePlateNumberList);
  //   this.licensePlateNumberList = licensePlateNumberList;
  //   this.licensePlateNumberListChange.emit(this.licensePlateNumberList);
  // }

  /**
   * ++++++++++++++++++++++
   * |       Modals       |
   * ++++++++++++++++++++++
   */

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  showSearchStockModal() {
    this.closeModal();
    const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
    console.log(this.actionType);
    //determine actions here...
    switch (this.actionType) {
      case ActionTypeEnum.RECEIPT:
      case ActionTypeEnum.RECEIPT_REQUEST:
      case ActionTypeEnum.ISSUE_REQUEST:
        this.bsModalRef = this.bsModalService.show(this.searchItemVariantModal, config);
        break;
      case ActionTypeEnum.ISSUE:
      case ActionTypeEnum.WRITE_OFF:
      case ActionTypeEnum.RETURN:
      case ActionTypeEnum.CHANGE_LOCATION:
      case ActionTypeEnum.TRANSFER:
        this.bsModalRef = this.bsModalService.show(this.searchInventoryBalanceModal, config);
        break;
    }
  }

  showReturnLocationModal() {
    const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
    switch (this.actionType) {
      case ActionTypeEnum.RETURN:
        this.bsModalRef = this.bsModalService.show(this.setLocationModal, config);
        break;
    }
  }

  showSourceTargetLocationAreaModal() {
    const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
    this.bsModalRef = this.bsModalService.show(this.sourceTargetLocationAreaModal, config);
  }

  //Use this to determine if the search criteria of a modal should be locked
  disableModalLocationSearch(): boolean {
    switch (this.actionType) {
      case ActionTypeEnum.CHANGE_LOCATION:
      //disable search-inventory-balance location search criteria (dropdown) if this has approval
      // return this.withApproval;
      default:
        return false;
    }
  }

  /**
   * Disable/Enable Search Item Button
   */
  disableSearchItemButton(): boolean {
    switch (this.actionType) {
      case ActionTypeEnum.CHANGE_LOCATION:
        return this.withApproval ? !this.sourceLocation : false;
      default:
        return false;
    }
  }

  downloadIbPlantNosTemplateExcel() {
    let rowsTemplateExcelData = [];
    rowsTemplateExcelData.push(['p45-0001']);
    rowsTemplateExcelData.push(['p45-0002']);
    rowsTemplateExcelData.push(['p45-0003']);
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rowsTemplateExcelData);
    ws['!cols'] = [{ width: 20 }];
    //  const ws2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.itemVariantExcelData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.utils.book_append_sheet(wb, ws2, 'Sheet2');
    console.log(wb);
    /* save to file */
    let currentDate = new Date();
    let year = currentDate.getFullYear();
    let month = currentDate.getMonth() + 1;
    let day = currentDate.getDate();
    let projectNo = (this.currentProject || {}).ProjectNumber || '';
    let fileName = 'MM' + '_' + 'Project' + '_' + projectNo + '_' + 'Batch Write Off' + '_' + 'Template Plant Nos' + '_' + year + month + day + '.xlsx';

    XLSX.writeFile(wb, fileName);
  }

  batchWriteOffClick(fileUpload) {
    this.modalService.showConfirmModal(ModalStatus.primary, 'ALERT', ['BATCH_WRITE_OFF_NOTIFICATION_DOWNLOAD_TEMPLATE_FILE'], 'YES', 'NO').subscribe((event) => {
      if (event === 'confirm') {
        this.downloadIbPlantNosTemplateExcel();
      } else if (event === 'cancel') {
        fileUpload.click();
      }
    });
  }

  handleImportPlantNos(evt: any) {
    if (evt.target.files && evt.target.files[0]) {
      const file = evt.target.files[0];
      if (file.size > 10000000) {
        this.modalService.showMessageModal(ModalStatus.danger, 'ERROR', 'ATTACHMENT_SIZE_EXCEEDED');
        return;
      }
      const extension = this.getFileExtension(file.name);
      if (extension.toUpperCase().indexOf('XLSX') < 0 && extension.toUpperCase().indexOf('XLS') < 0) {
        this.modalService.showMessageModal(ModalStatus.danger, 'ERROR', 'ATTACHMENT_TYPE_ERROR');
        return;
      }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const formData = new FormData();
        formData.append('files', file as Blob, file.name);

        this.inventoryBalanceService.inventoryBalancesUploadPlantNosToGetBalances({ formData, projectId: this.generalService.currentProject.ID , fileType: extension }).subscribe((result: UploadPlantNosToGetBalancesResult) => {
          if (!result) {
            // file type error
            this.modalService.showMessageModal(ModalStatus.danger, 'ERROR', 'ATTACHMENT_TYPE_ERROR');
            return;
          } else if (!result.InventoryBalancePage && (!result.RowsErrorMessage || result.RowsErrorMessage.length <= 0)) {
            // file input format error
            let errorFormatMsg = this.generalService.translate.instant('BATCH_WRITE_OFF_PLANT_NO_FORMAT_ERROR');
            let formatTipMsg = this.generalService.translate.instant('BATCH_WRITE_OFF_TIP');
            let formatError = errorFormatMsg + formatTipMsg;
            this.modalService.showMessageModal(ModalStatus.danger, 'ERROR', formatError);
            return;
          }
          if (result.InventoryBalancePage) {
            if (result.RowsErrorMessage && result.RowsErrorMessage.length > 0) {
              this.modalService.showConfirmModal(ModalStatus.danger, 'ALERT', ['BATCH_WRITE_OFF_NO_FOUNED_PLANT_NO']).subscribe((event) => {
                if (event === 'confirm') {
                  // need show no finded pant no.
                  this.downloadErrorIbPlantNosExcel(result.RowsErrorMessage); //end generate excel file to download
                }
              });
            } else {
              let ibs = result.InventoryBalancePage.Items as CommonInventoryBalance[];
              this.addInventoryBalances(ibs);
            }
          } //end if
        });
        evt.target.value = '';
      };
      reader.readAsDataURL(evt.target.files[0]);
    }
  }

  downloadErrorIbPlantNosExcel(rowsErrorMessage: Array<RowErrorInformation>) {
    //set data
    const rowsErrorMessageExcelData = [];

    rowsErrorMessage.forEach((item) => {
      const row = [];
      const msg = 'Line ' + item.RowNumber + ': Plant No [' + item.PlantNo + '] ' + item.Reason;
      row.push(msg);
      rowsErrorMessageExcelData.push(row);
    });
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(rowsErrorMessageExcelData);
    ws['!cols'] = [{ width: 200 }];
    //  const ws2: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.itemVariantExcelData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // XLSX.utils.book_append_sheet(wb, ws2, 'Sheet2');
    console.log(wb);
    /* save to file */
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const projectNo: string = (this.currentProject || {}).ProjectNumber || '';
    const fileName = 'MM' + '_' + 'Project' + '_' + projectNo + '_' + 'Batch Write Off' + '_' + 'Error Plant Nos' + '_' + year + month + day + '.xlsx';

    XLSX.writeFile(wb, fileName);
  }

  reset() {
    switch (this.actionType) {
      case ActionTypeEnum.RECEIPT:
        this.itemList = [];
        this.itemListChange.emit(this.itemList);
        this.quickSearchItemInput = null;
        break;

      case ActionTypeEnum.ISSUE:
        this.itemList = [];
        this.keeper = null;

        this.itemListChange.emit(this.itemList);
        this.keeperChange.emit(this.keeper);
        this.quickSearchItemInput = null;
        break;

      case ActionTypeEnum.WRITE_OFF:
        this.itemList = [];
        this.itemListChange.emit(this.itemList);
        this.quickSearchItemInput = null;
        break;

      case ActionTypeEnum.RETURN:
        this.itemList = [];
        this.itemListSelection = [];
        this.keeper = null;

        this.itemListChange.emit(this.itemList);
        this.keeperChange.emit(this.keeper);
        this.quickSearchItemInput = null;
        break;

      case ActionTypeEnum.CHANGE_LOCATION:
        this.sourceLocation = null;
        this.sourceLocationChange.emit(this.sourceLocation);

        this.itemList = [];
        this.itemListChange.emit(this.itemList);
        this.quickSearchItemInput = null;
        break;

      case ActionTypeEnum.RECEIPT_REQUEST:
        this.itemList = [];
        this.targetProject = null;
        this.supplier = null;
        this.supplierName = null;
        this.targetDate = new Date(Date.now());
        this.targetTime = null;
        this.period = 'time';
        this.hour = '00';
        this.minute = '00';
        this.siteEntrance = null;

        this.siteEntranceChange.emit(this.siteEntrance);
        this.itemListChange.emit(this.itemList);
        this.targetProjectChange.emit(this.targetProject);
        this.supplierChange.emit(this.supplier);
        this.supplierNameChange.emit(this.supplierName);
        this.targetDateChange.emit(this.targetDate);
        this.quickSearchItemInput = null;
        this.targetTimeChange.emit(this.targetTime);
        this.periodChange.emit(this.period);
        this.requestLiftingQuantity = null;
        this.requestLiftingQuantityChange.emit(this.requestLiftingQuantity);
        this.requestInstallationDate = null;
        this.requestInstallationDateChange.emit(this.requestInstallationDate);
        this.onRequestAreaChange(null);
        // this.requestLocation = null;
        // this.requestLocationChange.emit(this.requestLocation);
        this.onRequestLocationChange(null);
        break;

      case ActionTypeEnum.ISSUE_REQUEST:
        this.itemList = [];
        this.targetProject = null;
        this.targetDate = new Date(Date.now());
        this.keeper = null;
        this.targetTime = null;
        this.period = 'time';
        this.hour = '00';
        this.minute = '00';
        this.siteEntrance = null;

        this.siteEntranceChange.emit(this.siteEntrance);
        this.itemListChange.emit(this.itemList);
        this.targetProjectChange.emit(this.targetProject);
        this.targetDateChange.emit(this.targetDate);
        this.keeperChange.emit(this.keeper);
        this.targetTimeChange.emit(this.targetTime);
        this.periodChange.emit(this.period);
        this.quickSearchItemInput = null;
        break;

      case ActionTypeEnum.TRANSFER:
        this.itemList = [];
        this.remarks = null;
        this.planRemarks = null;
        this.targetDate = new Date(Date.now());
        //this.sourceProject = null;
        this.targetProject = null;

        this.itemListChange.emit(this.itemList);
        this.remarksChange.emit(this.remarks);
        this.planRemarksChange.emit(this.planRemarks);
        this.targetDateChange.emit(this.targetDate);
        //this.sourceProjectChange.emit(this.sourceProject);
        this.targetProjectChange.emit(this.targetProject);
        this.quickSearchItemInput = null;
        break;
    }
  }

  //import item Lot Update Fail Template
  @ViewChild('updateFailTemplate', { static: true }) updateFailTemplate: TemplateRef<any>;
  itemLotsUpdateFailMessages: string[] = [];
  confirmClicked = false;

  showExportFilterModal() {
    this.closeModal();
    const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
    console.log(this.actionType);
    //determine actions here...
    switch (this.actionType) {
      case ActionTypeEnum.RECEIPT:
        this.bsModalRef = this.bsModalService.show(this.exportItemVariantFilterModal, config);
        break;
    }
  }

  handleExportAllItemVariant() {
    const projectID = this.generalService.currentProject.ID;
    const categoryID = this.selectedCategory ? this.selectedCategory.ID : null;
    const subCategoryID = this.selectedSubCategory ? this.selectedSubCategory.ID : null;
    const itemID = this.selectedItem ? this.selectedItem.ID : null;
    // if size value is less than or equal to 0 , it will export all data.
    this.inventoryBalanceService
      .inventoryBalancesDownloadNeedStockReceiveVariantsExcel({
        param_projectID: projectID,
        param_categoryID: categoryID,
        param_subCategoryID: subCategoryID,
        param_itemID: itemID,
        size: 0
      })
      .subscribe((data) => {
        const downloadURL = window.URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = downloadURL;
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        let month = currentDate.getMonth() + 1;
        let day = currentDate.getDate();
        let currentProjectNumber = this.generalService.currentProject.ProjectNumber;
        let fileName = 'export_' + currentProjectNumber + '_stock_receipt_' + year + '/' + month + '/' + day + '.xlsx';
        link.download = fileName;
        link.click();

        link.addEventListener('click', function () {
          URL.revokeObjectURL(downloadURL);
          document.getElementById('download').remove();
        });
      });
  }

  getFileExtension(path: string): string {
    return path.split('.').pop().split(/\#|\?/)[0];
  }

  handleImportAllItemVariant(evt: any) {
    console.log('handleAttachment');
    if (evt.target.files && evt.target.files[0]) {
      const file = evt.target.files[0];
      if (file.size > 10000000) {
        this.modalService.showMessageModal(ModalStatus.danger, 'ERROR', 'ATTACHMENT_SIZE_EXCEEDED');
        return;
      }
      const extension = this.getFileExtension(file.name);
      if (extension.toUpperCase().indexOf('XLSX') < 0 && extension.toUpperCase().indexOf('XLS') < 0) {
        this.modalService.showMessageModal(ModalStatus.danger, 'ERROR', 'ATTACHMENT_TYPE_ERROR');
        return;
      }
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const formData = new FormData();
        formData.append('files', file as Blob, file.name);
        this.inventoryBalanceService.inventoryBalancesUploadNeedStockReceiveVariantsAttachment({ formData, fileType: extension }).subscribe((result: UploadToStockReceiptResult) => {
          if (result.DataStatus.toUpperCase() === UploadToStockReceiptResult.DataStatusEnum.UPDATEOK) {
            this.modalService.showMessageModal(ModalStatus.success, 'OPERATION_SUCCESS', 'SUCCESS');
          } else if (result.DataStatus.toUpperCase() === UploadToStockReceiptResult.DataStatusEnum.UPDATEFAIL) {
            if (this.confirmClicked) {
              return;
            }

            this.confirmClicked = true;
            this.itemLotsUpdateFailMessages.length = 0;
            const sErrorMsg = result.SummaryErrorMessage || 'OPERATION_FAIL';
            let iLUpdateFailMsg = this.translate.instant(sErrorMsg);
            this.itemLotsUpdateFailMessages.push(iLUpdateFailMsg);
            if (result.ErrorData) {
              const bin = this.translate.instant('BIN');
              const itemVariant = this.translate.instant('ITEM_VARIANT');
              const keeper = this.translate.instant('KEEPER');
              const team = this.translate.instant('TEAM');
              const quantity = this.translate.instant('QUANTITY');
              const onlyOne = this.translate.instant('KEEPER_AND_TEAM_ONLY_ONE');
              for (let index = 0; index < result.ErrorData.length; index++) {
                const item = result.ErrorData[index];
                if (index > 10) {
                  this.itemLotsUpdateFailMessages.push('...');
                  break;
                }
                let detailMessage = '';
                detailMessage += index + 1;
                detailMessage += '. ';
                detailMessage += itemVariant;
                detailMessage += ':';
                detailMessage += item.ItemVariantID;
                detailMessage += ',';
                if (item.BinID != null && item.BinID != undefined) {
                  detailMessage += bin;
                  detailMessage += ':';
                  detailMessage += item.BinID;
                  detailMessage += ',';
                }
                if (item.KeeperID != null && item.KeeperID != undefined) {
                  detailMessage += keeper;
                  detailMessage += ':';
                  detailMessage += item.KeeperID;
                  detailMessage += ',';
                }
                if (item.TeamID != null && item.TeamID != undefined) {
                  detailMessage += team;
                  detailMessage += ':';
                  detailMessage += item.TeamID;
                  detailMessage += ',';
                }

                if (item.Quantity != null && item.Quantity != undefined) {
                  detailMessage += quantity;
                  detailMessage += ':';
                  detailMessage += item.Quantity;
                  detailMessage += ',';
                }
                if (item.TeamID != null && item.TeamID != undefined && item.KeeperID != null && item.KeeperID != undefined) {
                  detailMessage += onlyOne;
                  detailMessage += ',';
                }
                detailMessage = detailMessage.substring(0, detailMessage.length - 1);
                this.itemLotsUpdateFailMessages.push(detailMessage);
              }
            }
            if (this.bsModalRef) {
              this.bsModalRef.hide();
            }
            const config: ModalOptions = { ignoreBackdropClick: true };
            this.bsModalRef = this.bsModalService.show(this.updateFailTemplate, config);
            this.confirmClicked = false;
            return;
          }
        });
        evt.target.value = '';
      };
      // reader.readAsBinaryString(target.files[0]);
      reader.readAsDataURL(evt.target.files[0]);
    }
  }

  selectedCategory: ItemCategory = {};
  selectedSubCategory: ItemSubCategory = {};
  selectedItem: Item = {};
  onCategoryChange(event) {
    this.selectedCategory = event;
  }

  onSubCategoryChange(event: ItemSubCategory) {
    this.selectedSubCategory = event;
  }

  onItemChange(event: Item) {
    this.selectedItem = event;
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  checklpr() {
    console.log(this.licensePlateNumberList);
  }
}
