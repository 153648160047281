import { NgModule, ModuleWithProviders } from '@angular/core';
import { DataStoreComponent } from './data-store.component';
import { CommonModule } from '@angular/common';
import { DataStoreService, DataStoreInjector } from './data-store.service';

@NgModule({
  declarations: [DataStoreComponent],
  imports: [
    CommonModule,
  ],
  exports: [DataStoreComponent]
})
export class DataStoreModule { 
  static forRoot(): ModuleWithProviders<DataStoreModule> {
    return {
      ngModule: DataStoreModule,
      providers: [
        { provide: "DataStoreService", useClass: DataStoreService},
        { provide: "DataStoreInjector", useClass: DataStoreInjector}
      ]
    }
  }
}
