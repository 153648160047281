/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CheckItemLotsExpireDateResult } from '../model/checkItemLotsExpireDateResult';
import { InventoryBalance } from '../model/inventoryBalance';
import { ItemLot } from '../model/itemLot';
import { PageItemLot } from '../model/pageItemLot';
import { PagePlantNoDailyBalanceSearchListResult } from '../model/pagePlantNoDailyBalanceSearchListResult';
import { UpdateExpireDateParam } from '../model/updateExpireDateParam';
import { UpdateItemLot } from '../model/updateItemLot';
import { UpdateItemLotParam } from '../model/updateItemLotParam';
import { UpdateItemLotResult } from '../model/updateItemLotResult';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ItemLotsServiceInterface, IitemLotsCheckItemLotsExpireDateParams,IitemLotsCheckPlantNoIsDuplicatedParams,IitemLotsCreateParams,IitemLotsDeleteParams,IitemLotsDownloadPlantNoDailyBalancesExcelParams,IitemLotsGetItemLotByIDParams,IitemLotsGetItemLotBySerialParams,IitemLotsGetItemLotListByPlantNoListParams,IitemLotsGetItemLotListBySerialNoListParams,IitemLotsGetListWithInventoryBalancesParams,IitemLotsGetSearchListParams,IitemLotsGetSearchPlantDailyBalanceListParams,IitemLotsUpdateParams,IitemLotsUpdateExpireDateParams,IitemLotsUpdateItemLogAndInventoryBalanceForTaggingParams,IitemLotsUpdateItemLotParams,IitemLotsUpdateItemLotExpireDateParams,IitemLotsUploadAttachmentParams, }                            from './itemLots.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ItemLotsService implements ItemLotsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param itemLotIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsCheckItemLotsExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotIds } : IitemLotsCheckItemLotsExpireDateParams, observe?: 'body', reportProgress?: boolean): Observable<CheckItemLotsExpireDateResult>;
    public itemLotsCheckItemLotsExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotIds } : IitemLotsCheckItemLotsExpireDateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CheckItemLotsExpireDateResult>>;
    public itemLotsCheckItemLotsExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotIds } : IitemLotsCheckItemLotsExpireDateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CheckItemLotsExpireDateResult>>;
    @datastore
    public itemLotsCheckItemLotsExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotIds } : IitemLotsCheckItemLotsExpireDateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotIds === null || itemLotIds === undefined) {
            throw new Error('Required parameter itemLotIds was null or undefined when calling itemLotsCheckItemLotsExpireDate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<CheckItemLotsExpireDateResult>(`${this.basePath}/ItemLots/CheckItemLotsExpireDate`,
            itemLotIds,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param needCheckDuplicateItemLostList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsCheckPlantNoIsDuplicated({ deviceId, message, ignoreLoadingBar, formData, needCheckDuplicateItemLostList } : IitemLotsCheckPlantNoIsDuplicatedParams, observe?: 'body', reportProgress?: boolean): Observable<Array<UpdateItemLot>>;
    public itemLotsCheckPlantNoIsDuplicated({ deviceId, message, ignoreLoadingBar, formData, needCheckDuplicateItemLostList } : IitemLotsCheckPlantNoIsDuplicatedParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UpdateItemLot>>>;
    public itemLotsCheckPlantNoIsDuplicated({ deviceId, message, ignoreLoadingBar, formData, needCheckDuplicateItemLostList } : IitemLotsCheckPlantNoIsDuplicatedParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UpdateItemLot>>>;
    @datastore
    public itemLotsCheckPlantNoIsDuplicated({ deviceId, message, ignoreLoadingBar, formData, needCheckDuplicateItemLostList } : IitemLotsCheckPlantNoIsDuplicatedParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (needCheckDuplicateItemLostList === null || needCheckDuplicateItemLostList === undefined) {
            throw new Error('Required parameter needCheckDuplicateItemLostList was null or undefined when calling itemLotsCheckPlantNoIsDuplicated.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (needCheckDuplicateItemLostList) {
            needCheckDuplicateItemLostList.forEach((element) => {
                queryParameters = queryParameters.append('needCheckDuplicateItemLostList', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<UpdateItemLot>>(`${this.basePath}/ItemLots/CheckPlantNoIsDuplicated`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemLotsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ItemLot>;
    public itemLotsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemLotsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemLot>>;
    public itemLotsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemLotsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemLot>>;
    @datastore
    public itemLotsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemLotsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemLotsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ItemLot>(`${this.basePath}/ItemLots`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsDelete({ deviceId, message, ignoreLoadingBar, formData, itemLotID } : IitemLotsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemLotsDelete({ deviceId, message, ignoreLoadingBar, formData, itemLotID } : IitemLotsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemLotsDelete({ deviceId, message, ignoreLoadingBar, formData, itemLotID } : IitemLotsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemLotsDelete({ deviceId, message, ignoreLoadingBar, formData, itemLotID } : IitemLotsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotID === null || itemLotID === undefined) {
            throw new Error('Required parameter itemLotID was null or undefined when calling itemLotsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ItemLots/${encodeURIComponent(String(itemLotID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param param_projectID 
     * @param param_id 
     * @param param_itemCategoryId 
     * @param param_subCategoryID 
     * @param param_itemId 
     * @param param_plantNo 
     * @param param_inCount 
     * @param param_outCount 
     * @param param_startBalanceDate 
     * @param param_endBalanceDate 
     * @param param_startCreateDate 
     * @param param_endCreateDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsDownloadPlantNoDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_plantNo, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemLotsDownloadPlantNoDailyBalancesExcelParams, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public itemLotsDownloadPlantNoDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_plantNo, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemLotsDownloadPlantNoDailyBalancesExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public itemLotsDownloadPlantNoDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_plantNo, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemLotsDownloadPlantNoDailyBalancesExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    @datastore
    public itemLotsDownloadPlantNoDailyBalancesExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_id, param_itemCategoryId, param_subCategoryID, param_itemId, param_plantNo, param_inCount, param_outCount, param_startBalanceDate, param_endBalanceDate, param_startCreateDate, param_endCreateDate } : IitemLotsDownloadPlantNoDailyBalancesExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_id !== undefined && param_id !== null) {
            queryParameters = queryParameters.set('param.id', <any>param_id);
        }
        if (param_itemCategoryId !== undefined && param_itemCategoryId !== null) {
            queryParameters = queryParameters.set('param.itemCategoryId', <any>param_itemCategoryId);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemId !== undefined && param_itemId !== null) {
            queryParameters = queryParameters.set('param.itemId', <any>param_itemId);
        }
        if (param_plantNo !== undefined && param_plantNo !== null) {
            queryParameters = queryParameters.set('param.plantNo', <any>param_plantNo);
        }
        if (param_inCount !== undefined && param_inCount !== null) {
            queryParameters = queryParameters.set('param.inCount', <any>param_inCount);
        }
        if (param_outCount !== undefined && param_outCount !== null) {
            queryParameters = queryParameters.set('param.outCount', <any>param_outCount);
        }
        if (param_startBalanceDate !== undefined && param_startBalanceDate !== null) {
            queryParameters = queryParameters.set('param.startBalanceDate', <any>param_startBalanceDate.toISOString());
        }
        if (param_endBalanceDate !== undefined && param_endBalanceDate !== null) {
            queryParameters = queryParameters.set('param.endBalanceDate', <any>param_endBalanceDate.toISOString());
        }
        if (param_startCreateDate !== undefined && param_startCreateDate !== null) {
            queryParameters = queryParameters.set('param.startCreateDate', <any>param_startCreateDate.toISOString());
        }
        if (param_endCreateDate !== undefined && param_endCreateDate !== null) {
            queryParameters = queryParameters.set('param.endCreateDate', <any>param_endCreateDate.toISOString());
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get(`${this.basePath}/ItemLots/DownloadPlantNoDailyBalancesExcel`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotID 
     * @param restrictActiveParents 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsGetItemLotByID({ deviceId, message, ignoreLoadingBar, formData, itemLotID, restrictActiveParents, views } : IitemLotsGetItemLotByIDParams, observe?: 'body', reportProgress?: boolean): Observable<ItemLot>;
    public itemLotsGetItemLotByID({ deviceId, message, ignoreLoadingBar, formData, itemLotID, restrictActiveParents, views } : IitemLotsGetItemLotByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemLot>>;
    public itemLotsGetItemLotByID({ deviceId, message, ignoreLoadingBar, formData, itemLotID, restrictActiveParents, views } : IitemLotsGetItemLotByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemLot>>;
    @datastore
    public itemLotsGetItemLotByID({ deviceId, message, ignoreLoadingBar, formData, itemLotID, restrictActiveParents, views } : IitemLotsGetItemLotByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotID === null || itemLotID === undefined) {
            throw new Error('Required parameter itemLotID was null or undefined when calling itemLotsGetItemLotByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restrictActiveParents !== undefined && restrictActiveParents !== null) {
            queryParameters = queryParameters.set('restrictActiveParents', <any>restrictActiveParents);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ItemLot>(`${this.basePath}/ItemLots/${encodeURIComponent(String(itemLotID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param serialNo 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsGetItemLotBySerial({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNo, views } : IitemLotsGetItemLotBySerialParams, observe?: 'body', reportProgress?: boolean): Observable<ItemLot>;
    public itemLotsGetItemLotBySerial({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNo, views } : IitemLotsGetItemLotBySerialParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemLot>>;
    public itemLotsGetItemLotBySerial({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNo, views } : IitemLotsGetItemLotBySerialParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemLot>>;
    @datastore
    public itemLotsGetItemLotBySerial({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNo, views } : IitemLotsGetItemLotBySerialParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemLotsGetItemLotBySerial.');
        }
        if (serialNo === null || serialNo === undefined) {
            throw new Error('Required parameter serialNo was null or undefined when calling itemLotsGetItemLotBySerial.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (serialNo !== undefined && serialNo !== null) {
            queryParameters = queryParameters.set('serialNo', <any>serialNo);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ItemLot>(`${this.basePath}/ItemLots/GetItemLotBySerial`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param plantNoList 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsGetItemLotListByPlantNoList({ deviceId, message, ignoreLoadingBar, formData, plantNoList, views } : IitemLotsGetItemLotListByPlantNoListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemLot>>;
    public itemLotsGetItemLotListByPlantNoList({ deviceId, message, ignoreLoadingBar, formData, plantNoList, views } : IitemLotsGetItemLotListByPlantNoListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemLot>>>;
    public itemLotsGetItemLotListByPlantNoList({ deviceId, message, ignoreLoadingBar, formData, plantNoList, views } : IitemLotsGetItemLotListByPlantNoListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemLot>>>;
    @datastore
    public itemLotsGetItemLotListByPlantNoList({ deviceId, message, ignoreLoadingBar, formData, plantNoList, views } : IitemLotsGetItemLotListByPlantNoListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (plantNoList === null || plantNoList === undefined) {
            throw new Error('Required parameter plantNoList was null or undefined when calling itemLotsGetItemLotListByPlantNoList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<ItemLot>>(`${this.basePath}/ItemLots/GetItemLotListByPlantNoList`,
            plantNoList,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param serialNoList 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsGetItemLotListBySerialNoList({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNoList, views } : IitemLotsGetItemLotListBySerialNoListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemLot>>;
    public itemLotsGetItemLotListBySerialNoList({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNoList, views } : IitemLotsGetItemLotListBySerialNoListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemLot>>>;
    public itemLotsGetItemLotListBySerialNoList({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNoList, views } : IitemLotsGetItemLotListBySerialNoListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemLot>>>;
    @datastore
    public itemLotsGetItemLotListBySerialNoList({ deviceId, message, ignoreLoadingBar, formData, projectID, serialNoList, views } : IitemLotsGetItemLotListBySerialNoListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemLotsGetItemLotListBySerialNoList.');
        }
        if (serialNoList === null || serialNoList === undefined) {
            throw new Error('Required parameter serialNoList was null or undefined when calling itemLotsGetItemLotListBySerialNoList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (serialNoList) {
            serialNoList.forEach((element) => {
                queryParameters = queryParameters.append('serialNoList', <any>element);
            })
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemLot>>(`${this.basePath}/ItemLots/GetItemLotListBySerialNoList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotID 
     * @param inventoryBalanceID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, itemLotID, inventoryBalanceID, page, views } : IitemLotsGetListWithInventoryBalancesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemLot>>;
    public itemLotsGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, itemLotID, inventoryBalanceID, page, views } : IitemLotsGetListWithInventoryBalancesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemLot>>>;
    public itemLotsGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, itemLotID, inventoryBalanceID, page, views } : IitemLotsGetListWithInventoryBalancesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemLot>>>;
    @datastore
    public itemLotsGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, itemLotID, inventoryBalanceID, page, views } : IitemLotsGetListWithInventoryBalancesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotID === null || itemLotID === undefined) {
            throw new Error('Required parameter itemLotID was null or undefined when calling itemLotsGetListWithInventoryBalances.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryBalanceID !== undefined && inventoryBalanceID !== null) {
            queryParameters = queryParameters.set('inventoryBalanceID', <any>inventoryBalanceID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemLot>>(`${this.basePath}/ItemLots/${encodeURIComponent(String(itemLotID))}/InventoryBalances`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotID 
     * @param originalItemLotID 
     * @param itemVariantID 
     * @param serialNo 
     * @param expiryDate 
     * @param sensorKey 
     * @param unitRate 
     * @param supplierNo 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, itemLotID, originalItemLotID, itemVariantID, serialNo, expiryDate, sensorKey, unitRate, supplierNo, page, take, views } : IitemLotsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemLot>;
    public itemLotsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, itemLotID, originalItemLotID, itemVariantID, serialNo, expiryDate, sensorKey, unitRate, supplierNo, page, take, views } : IitemLotsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemLot>>;
    public itemLotsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, itemLotID, originalItemLotID, itemVariantID, serialNo, expiryDate, sensorKey, unitRate, supplierNo, page, take, views } : IitemLotsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemLot>>;
    @datastore
    public itemLotsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, itemLotID, originalItemLotID, itemVariantID, serialNo, expiryDate, sensorKey, unitRate, supplierNo, page, take, views } : IitemLotsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemLotID !== undefined && itemLotID !== null) {
            queryParameters = queryParameters.set('itemLotID', <any>itemLotID);
        }
        if (originalItemLotID !== undefined && originalItemLotID !== null) {
            queryParameters = queryParameters.set('originalItemLotID', <any>originalItemLotID);
        }
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (serialNo !== undefined && serialNo !== null) {
            queryParameters = queryParameters.set('serialNo', <any>serialNo);
        }
        if (expiryDate !== undefined && expiryDate !== null) {
            queryParameters = queryParameters.set('expiryDate', <any>expiryDate.toISOString());
        }
        if (sensorKey !== undefined && sensorKey !== null) {
            queryParameters = queryParameters.set('sensorKey', <any>sensorKey);
        }
        if (unitRate !== undefined && unitRate !== null) {
            queryParameters = queryParameters.set('unitRate', <any>unitRate);
        }
        if (supplierNo !== undefined && supplierNo !== null) {
            queryParameters = queryParameters.set('supplierNo', <any>supplierNo);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemLot>(`${this.basePath}/ItemLots/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param id 
     * @param itemCategoryId 
     * @param subCategoryID 
     * @param itemId 
     * @param plantNo 
     * @param inCount 
     * @param outCount 
     * @param startBalanceDate 
     * @param endBalanceDate 
     * @param startCreateDate 
     * @param endCreateDate 
     * @param page 
     * @param size 
     * @param orderByDesc 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsGetSearchPlantDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, plantNo, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemLotsGetSearchPlantDailyBalanceListParams, observe?: 'body', reportProgress?: boolean): Observable<PagePlantNoDailyBalanceSearchListResult>;
    public itemLotsGetSearchPlantDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, plantNo, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemLotsGetSearchPlantDailyBalanceListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PagePlantNoDailyBalanceSearchListResult>>;
    public itemLotsGetSearchPlantDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, plantNo, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemLotsGetSearchPlantDailyBalanceListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PagePlantNoDailyBalanceSearchListResult>>;
    @datastore
    public itemLotsGetSearchPlantDailyBalanceList({ deviceId, message, ignoreLoadingBar, formData, projectID, id, itemCategoryId, subCategoryID, itemId, plantNo, inCount, outCount, startBalanceDate, endBalanceDate, startCreateDate, endCreateDate, page, size, orderByDesc } : IitemLotsGetSearchPlantDailyBalanceListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }
        if (itemCategoryId !== undefined && itemCategoryId !== null) {
            queryParameters = queryParameters.set('itemCategoryId', <any>itemCategoryId);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemId !== undefined && itemId !== null) {
            queryParameters = queryParameters.set('itemId', <any>itemId);
        }
        if (plantNo !== undefined && plantNo !== null) {
            queryParameters = queryParameters.set('plantNo', <any>plantNo);
        }
        if (inCount !== undefined && inCount !== null) {
            queryParameters = queryParameters.set('inCount', <any>inCount);
        }
        if (outCount !== undefined && outCount !== null) {
            queryParameters = queryParameters.set('outCount', <any>outCount);
        }
        if (startBalanceDate !== undefined && startBalanceDate !== null) {
            queryParameters = queryParameters.set('startBalanceDate', <any>startBalanceDate.toISOString());
        }
        if (endBalanceDate !== undefined && endBalanceDate !== null) {
            queryParameters = queryParameters.set('endBalanceDate', <any>endBalanceDate.toISOString());
        }
        if (startCreateDate !== undefined && startCreateDate !== null) {
            queryParameters = queryParameters.set('startCreateDate', <any>startCreateDate.toISOString());
        }
        if (endCreateDate !== undefined && endCreateDate !== null) {
            queryParameters = queryParameters.set('endCreateDate', <any>endCreateDate.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (orderByDesc !== undefined && orderByDesc !== null) {
            queryParameters = queryParameters.set('orderByDesc', <any>orderByDesc);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PagePlantNoDailyBalanceSearchListResult>(`${this.basePath}/ItemLots/GetSearchPlantDailyBalanceList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, model } : IitemLotsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemLotsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, model } : IitemLotsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemLotsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, model } : IitemLotsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemLotsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, model } : IitemLotsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotID === null || itemLotID === undefined) {
            throw new Error('Required parameter itemLotID was null or undefined when calling itemLotsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemLotsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ItemLots/${encodeURIComponent(String(itemLotID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotID 
     * @param newExpireDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsUpdateExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, newExpireDate } : IitemLotsUpdateExpireDateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemLotsUpdateExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, newExpireDate } : IitemLotsUpdateExpireDateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemLotsUpdateExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, newExpireDate } : IitemLotsUpdateExpireDateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemLotsUpdateExpireDate({ deviceId, message, ignoreLoadingBar, formData, itemLotID, newExpireDate } : IitemLotsUpdateExpireDateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotID === null || itemLotID === undefined) {
            throw new Error('Required parameter itemLotID was null or undefined when calling itemLotsUpdateExpireDate.');
        }
        if (newExpireDate === null || newExpireDate === undefined) {
            throw new Error('Required parameter newExpireDate was null or undefined when calling itemLotsUpdateExpireDate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemLotID !== undefined && itemLotID !== null) {
            queryParameters = queryParameters.set('itemLotID', <any>itemLotID);
        }
        if (newExpireDate !== undefined && newExpireDate !== null) {
            queryParameters = queryParameters.set('newExpireDate', <any>newExpireDate.toISOString());
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/ItemLots/UpdateExpireDate`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param taggingList 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsUpdateItemLogAndInventoryBalanceForTagging({ deviceId, message, ignoreLoadingBar, formData, taggingList } : IitemLotsUpdateItemLogAndInventoryBalanceForTaggingParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryBalance>>;
    public itemLotsUpdateItemLogAndInventoryBalanceForTagging({ deviceId, message, ignoreLoadingBar, formData, taggingList } : IitemLotsUpdateItemLogAndInventoryBalanceForTaggingParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryBalance>>>;
    public itemLotsUpdateItemLogAndInventoryBalanceForTagging({ deviceId, message, ignoreLoadingBar, formData, taggingList } : IitemLotsUpdateItemLogAndInventoryBalanceForTaggingParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryBalance>>>;
    @datastore
    public itemLotsUpdateItemLogAndInventoryBalanceForTagging({ deviceId, message, ignoreLoadingBar, formData, taggingList } : IitemLotsUpdateItemLogAndInventoryBalanceForTaggingParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (taggingList === null || taggingList === undefined) {
            throw new Error('Required parameter taggingList was null or undefined when calling itemLotsUpdateItemLogAndInventoryBalanceForTagging.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<InventoryBalance>>(`${this.basePath}/ItemLots/UpdateItemLogAndInventoryBalanceForTagging`,
            taggingList,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsUpdateItemLot({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotParams, observe?: 'body', reportProgress?: boolean): Observable<UpdateItemLotResult>;
    public itemLotsUpdateItemLot({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateItemLotResult>>;
    public itemLotsUpdateItemLot({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateItemLotResult>>;
    @datastore
    public itemLotsUpdateItemLot({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemLotsUpdateItemLot.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<UpdateItemLotResult>(`${this.basePath}/ItemLots/UpdateItemLot`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsUpdateItemLotExpireDate({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotExpireDateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemLotsUpdateItemLotExpireDate({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotExpireDateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemLotsUpdateItemLotExpireDate({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotExpireDateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemLotsUpdateItemLotExpireDate({ deviceId, message, ignoreLoadingBar, formData, model } : IitemLotsUpdateItemLotExpireDateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemLotsUpdateItemLotExpireDate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ItemLots/UpdateItemLotExpireDate`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param fileType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemLotsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IitemLotsUploadAttachmentParams, observe?: 'body', reportProgress?: boolean): Observable<UpdateItemLotResult>;
    public itemLotsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IitemLotsUploadAttachmentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UpdateItemLotResult>>;
    public itemLotsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IitemLotsUploadAttachmentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UpdateItemLotResult>>;
    @datastore
    public itemLotsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IitemLotsUploadAttachmentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileType === null || fileType === undefined) {
            throw new Error('Required parameter fileType was null or undefined when calling itemLotsUploadAttachment.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileType !== undefined && fileType !== null) {
            queryParameters = queryParameters.set('fileType', <any>fileType);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<UpdateItemLotResult>(`${this.basePath}/ItemLots/UploadAttachment`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
