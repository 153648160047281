<div class="input-group">
  <ng-select style="width: 250px"
      placeholder="{{ placeholder |translate}}"
      class="p-0"
      (open)="searchLocation()"
      [items]="locationList"
      [(ngModel)]="location"
      (ngModelChange)="locationChange.emit(location)"
      appendTo="body"
      >
      <ng-template ng-label-tmp let-item="item">
          <span>{{item['LOCATION_DESC'|translate]||''}}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
          <span>{{item['LOCATION_DESC'|translate]||''}}</span>
      </ng-template>
  </ng-select>
</div>
