<table style="border: 3px solid black" *ngIf="decisionMap">
    <tr *ngFor="let commonDecision of commonDecisionList" style="border: 2px solid black;">
        <td class="decision-map-content" >
            {{'APPROVER' | translate}}:

            <ng-container *ngIf="commonDecision.adUser as user; else defaultUserName">
                <span><u>{{user['Name']}}</u></span>
            </ng-container>

            <ng-template #defaultUserName>
                <span><u>{{commonDecision.userName}}</u></span>
            </ng-template>

        </td>

        <td class="decision-map-content">
            {{'DATE' | translate}}: {{commonDecision.timestamp|date:'MM/dd/yyyy hh:mm:ss a'}}
        </td>

        <td class="decision-map-content">
          {{'APPROVAL_STATUS' | translate}}:
          <span [ngClass]="[generalService.getApprovalStatusCss(commonDecision.decision)]">
            {{commonDecision.decision}}
          </span>
        </td>

        <td class="decision-map-content">
            {{'COMMENT' | translate}}: {{commonDecision.comment}}
        </td>

        <ng-container *ngIf="commonDecision['ShowDetails']==true">
            <approver-card [approver]="(commonDecision.adUser||{})"></approver-card>
        </ng-container>
    </tr>
</table>
