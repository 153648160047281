<div class="row px-2">
  <div class="input-group date">
    <div class="input-group-prepend">
      <span class="input-group-text"><i class="fa fa-calendar"></i></span>
    </div>

    <input *ngIf="dateRange"
      class="form-control cursor-pointer"
      bsDaterangepicker
      readonly
      [(ngModel)]="selectedDate"
      (ngModelChange)="selectedDateChange.emit(selectedDate)"
      [bsConfig]="bsConfig"
      [maxDate]="maxDate"
      [minDate]="minDate"
      placement="bottom"
      style="background: white; color: black;"
      [style.border]="required && !selectedDate ?'1px solid red' : ''"
    />

    <input *ngIf="!dateRange"
      class="form-control cursor-pointer"
      bsDatepicker
      readonly
      [(ngModel)]="selectedDate"
      (ngModelChange)="selectedDateChange.emit(selectedDate)"
      [bsConfig]="bsConfig"
      [maxDate]="maxDate"
      [minDate]="minDate"
      placement="bottom"
      style="background: white; color: black;"
      [style.border]="required && !selectedDate ?'1px solid red' : ''"
    />

    <div class="input-group-append">
      <span
        class="input-group-text cursor-pointer"
        (click)="this.selectedDateChange.emit(null);"
      >
        <i class="fa fa-times"></i>
      </span>
    </div>
  </div>
</div>
