import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Bin, InventoryBalancesService, ItemLot, Movement, MovementsService, Project, StockMovement } from '@gammon/inventory-api';
import { ActionTypeEnum, CommonInventoryPlan, CommonIssuePlan } from '@material/common';
import { Subscription } from 'rxjs';
import { GeneralService, ModalService } from '../../services';
import { ModalStatus } from '../../services/modal.service';
@Component({
  selector: 'issued-confirmation-history',
  templateUrl: './issued-confirmation-history.component.html',
  styleUrls: ['./issued-confirmation-history.component.scss']
})
export class IssuedConfirmationHistoryComponent implements OnInit, OnDestroy {

  @Input() currentProject: Project = {};
  @Input() actionType = '';
  @Input() inventoryPlan: CommonInventoryPlan;
  @Input() issuePlan: CommonIssuePlan;
  @Input() title = 'ISSUED_CONFIRMED_HISTORY';

  @Input() showIssuedHistory = true;
  @Input() showConfirmedHistory = false;

  @Output() close = new EventEmitter<any>();
  @Output() refreshItems = new EventEmitter<any>();

  subscriptionList: Subscription[] = [];

  deliveryQuantity = 0;

  issuedHistoryMovementList: Movement[] = [];
  issuedConfirmedHistoryMovementList: Movement[] = [];

  issuedLotList: ItemLot[] = [];
  selectedIssuedLot: ItemLot;

  issuedBinList: Bin[] = [];
  selectedIssuedBin: Bin;

  constructor(
    private generalService: GeneralService,
    private inventoryBalancesService: InventoryBalancesService,
    private movementService: MovementsService,
    private modalService: ModalService
  ) { }

  ngOnInit() {
    this.searchIssuedHistory();
    // this.searchConfirmedHistory();
  }

  searchIssuedHistory() {
    this.movementService.movementsGetIssuedHistory({issuePlanID: this.issuePlan.ID, views: ['BIN', 'ITEM_LOT']}).subscribe(data => {
      this.issuedHistoryMovementList = data;
    });
  }

  searchConfirmedHistory() {
    this.issuedConfirmedHistoryMovementList = [];

    console.log('searchConfirmedHistory');
    this.movementService.movementsGetSearchList({
      actionType: ActionTypeEnum.ISSUE_REQUEST,
      referencePlanID: this.issuePlan.ID,
      itemVariantID: this.issuePlan.ItemVariantID,
      minQuantity: 0,
      views: ['BIN', 'ITEM_LOT']}).subscribe(data => {
      console.log('searchConfirmedHistory', data.Items);

      this.issuedConfirmedHistoryMovementList = data.Items;
    });
  }

  confirmIssuedItem(movement: Movement) {
    const confirmMovement: Movement = {
      ID: movement.ID,
      BinID: movement.BinID,
      ItemVariantID: movement.ItemVariantID,
      ItemLotID: movement.ItemLotID,
      ItemLot: movement.ItemLot,
      ActionType: ActionTypeEnum.ISSUE_REQUEST,
      Quantity: movement.Quantity,
      Source: 'INVENTORY',
      InventoryPlanID: movement.InventoryPlanID
    };

    const stockMovement: StockMovement = {
      movement: confirmMovement,
      issuePlanID: this.issuePlan.ID,
      issueConfirm: true
    };

    const stockMovementList: StockMovement[] = [stockMovement];

    this.inventoryBalancesService.inventoryBalancesCreateOrUpdateInventoryBalanceByMovements({model: stockMovementList}).subscribe(data => {
      console.log(data);
      this.searchIssuedHistory();
      this.refreshItems.emit();
      this.modalService.showMessageModal(ModalStatus.success, 'SUCCEEDED', 'ITEMS_ISSUE_CONFIRMED');
      // this.searchConfirmedHistory();
    }, error => {
      console.error(error);
      this.modalService.showMessageModal(ModalStatus.danger, 'FAILED', 'FAILED_TO_CONFIRM_ISSUED_ITEMS');
    });
  }


  closeModal() {
    this.close.emit();
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(subscription => {
      if (subscription) { subscription.unsubscribe(); }
    });
  }
}
