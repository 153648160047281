import { Directive, HostBinding, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: 'input[decimalPrecision]'
})
export class DecimalPrecisionDirective {
  //private regex: RegExp = new RegExp(/^\d+(\.\d{1,2})?/g);
  specialKeys: string[] = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Ctrl', 'Alt'];
  disallowedChars: string[] = ['\'', '\\', '/', ',', ';', '?', '[', ']', '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '`', ' '];
  ctrlKeys: string[] = ['a', 'c', 'v'];
  numberKeys: string[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];
  letterRegex = new RegExp(/^[a-zA-Z]+$/g);

  // tslint:disable-next-line: variable-name
  @Input() decimalPrecision;
  @Input() allowNegativeNumbers;

  constructor(private el: ElementRef) { }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!this.allowNegativeNumbers) {
      this.specialKeys = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete', 'Ctrl', 'Alt'];
      this.disallowedChars = ['\'', '\\', '/', ',', ';', '?', '[', ']', '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '+', '=', '`', ' ', '-'];
    }

    // allow special keys, ctrl keys
    if (
      (this.specialKeys.indexOf(event.key) !== -1) ||
      (this.ctrlKeys.indexOf(event.key) !== -1 && (event.ctrlKey === true || event.metaKey === true))) {
        return;
    }

    if ((event.key).match(this.letterRegex) || this.disallowedChars.indexOf(event.key) !== -1) {
      event.preventDefault();
    }

    // check if . exist before
    if (this.el.nativeElement.value.indexOf('.') !== -1) {
      // dot already exist before
      if (event.key == '.') {
        event.preventDefault();
      } else {
        const dotIndex = this.el.nativeElement.value.indexOf('.');
        if (dotIndex != this.el.nativeElement.value.length - 1) {
          const firstDecimalIndex = dotIndex + 1;
          const lastDeicimalIndex = this.el.nativeElement.value.length - 1;
          const decimalLength = lastDeicimalIndex - firstDecimalIndex + 1;
          // console.log('decimal length: ' + decimalLength);
          // console.log('decimal precision: ' + this.decimalPrecision);

          if (decimalLength >= this.decimalPrecision && (this.numberKeys.indexOf(event.key) !== -1)) {
            event.preventDefault();
          }
        }
      }
    } else {
      return;
    }
  }
}
