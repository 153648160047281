import { Injectable } from '@angular/core';

@Injectable(
  { providedIn: 'root' }
)
export class CommonDateService {
  GetDateOnly(date: Date = new Date()): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  }
}
