/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InventoryBalance } from './inventoryBalance';
import { ItemVariant } from './itemVariant';
import { Movement } from './movement';
import { PlantNoDailyBalance } from './plantNoDailyBalance';
import { ReceiptPlan } from './receiptPlan';


export interface ItemLot { 
    OriginalItemLotID?: number;
    ItemVariantID?: number;
    SerialNo?: string;
    ExpiryDate?: Date;
    SensorKey?: string;
    UnitRate?: number;
    SupplierNo?: string;
    PrNo?: string;
    PoNo?: string;
    Remarks?: string;
    Threshold?: number;
    PrLine?: number;
    PoLine?: number;
    PlantNo?: string;
    InventoryBalances?: Array<InventoryBalance>;
    ItemVariant?: ItemVariant;
    Movements?: Array<Movement>;
    ToItemLots?: Array<ItemLot>;
    OriginalItemLot?: ItemLot;
    ReceiptPlans?: Array<ReceiptPlan>;
    PlantNoDailyBalances?: Array<PlantNoDailyBalance>;
    readonly TransactionDate?: Date;
    ID?: number;
    CreatedDate?: Date;
    Status?: string;
    DataStatus?: ItemLot.DataStatusEnum;
    DataDate?: Date;
}
export namespace ItemLot {
    export type DataStatusEnum = 'BACKEND' | 'CACHE' | 'CREATE_OK' | 'CREATE_FAIL' | 'CREATE_PENDING' | 'CREATE_QUEUE' | 'DELETE_OK' | 'DELETE_FAIL' | 'DELETE_PENDING' | 'DELETE_QUEUE' | 'UPDATE_OK' | 'UPDATE_FAIL' | 'UPDATE_PENDING' | 'UPDATE_QUEUE' | 'VALIDATION_OK' | 'VALIDATION_FAIL' | 'WAITING_FOR_APPROVAL';
    export const DataStatusEnum = {
        BACKEND: 'BACKEND' as DataStatusEnum,
        CACHE: 'CACHE' as DataStatusEnum,
        CREATEOK: 'CREATE_OK' as DataStatusEnum,
        CREATEFAIL: 'CREATE_FAIL' as DataStatusEnum,
        CREATEPENDING: 'CREATE_PENDING' as DataStatusEnum,
        CREATEQUEUE: 'CREATE_QUEUE' as DataStatusEnum,
        DELETEOK: 'DELETE_OK' as DataStatusEnum,
        DELETEFAIL: 'DELETE_FAIL' as DataStatusEnum,
        DELETEPENDING: 'DELETE_PENDING' as DataStatusEnum,
        DELETEQUEUE: 'DELETE_QUEUE' as DataStatusEnum,
        UPDATEOK: 'UPDATE_OK' as DataStatusEnum,
        UPDATEFAIL: 'UPDATE_FAIL' as DataStatusEnum,
        UPDATEPENDING: 'UPDATE_PENDING' as DataStatusEnum,
        UPDATEQUEUE: 'UPDATE_QUEUE' as DataStatusEnum,
        VALIDATIONOK: 'VALIDATION_OK' as DataStatusEnum,
        VALIDATIONFAIL: 'VALIDATION_FAIL' as DataStatusEnum,
        WAITINGFORAPPROVAL: 'WAITING_FOR_APPROVAL' as DataStatusEnum
    };
}
