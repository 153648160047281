import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Item, ItemCategory, ItemSubCategory, ItemVariant, ItemVariantsService, Project } from '@gammon/inventory-api';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';
@Component({
    selector: 'search-item-variant',
    templateUrl: './search-item-variant.component.html',
    styleUrls: ['./search-item-variant.component.scss']
})
export class SearchItemVariantComponent implements OnInit, OnDestroy {

    @Output() close = new EventEmitter<any>();
    @Output() add = new EventEmitter<any>();

    @Input() currentProject: Project;
    @Input() tableTakeRows: number = 10;

    subscriptionList: Subscription[] = [];

    searchCategoryList: ItemCategory[] = [];
    selectedCategory: ItemCategory = {};

    searchSubCategoryList: ItemSubCategory[] = [];
    selectedSubCategory: ItemSubCategory = {};

    searchItemList: Item[] = [];
    selectedItem: Item = {};

    searchItemVariantList: ItemVariant[] = [];

    universalProductCode: string;
    gammonProductCode: string;
    model: string;
    description: string;
    selectionResultList: ItemVariant[] = [];

    //table pagination
    isLazyLoad: boolean = false;
    totalRecords = 0;
    tableRowOffset: number;
    tableRowCount: number;
    tablePage: number = 1;

    // type head
    dataSourceItemVariants: ItemVariant[] = [];
 
    constructor(private itemVariantsService: ItemVariantsService) {}

    ngOnInit() {
     this.tableRowCount = this.tableTakeRows;
     this.initTypeHeadModelData();   
    }

    initTypeHeadModelData(){
        this.subscriptionList.push(this.itemVariantsService.itemVariantsGetSearchList({
            projectID: this.currentProject.ID,
            // gammonProductCode: this.gammonProductCode,
            // model: this.model ? this.model.trim() : null,
            //   itemDescription: this.description ? this.description.trim() : null,
            page: 0, take: 0, views: []
        }).subscribe(pageData => {
            if (pageData.Items && pageData.Items.length > 0) {
                this.dataSourceItemVariants = this.filterTypeHeadModelData(pageData.Items);
            }
        }))
    }

    filterTypeHeadModelData(source:ItemVariant[]) : ItemVariant[] {
        let returnSrouce: ItemVariant[] = [];
        source.forEach(itemVariant => {
            if(itemVariant != null && itemVariant.ModelNo && !returnSrouce.find(i=>i.ModelNo == itemVariant.ModelNo)){
                returnSrouce.push(itemVariant);
            }
        });
        return returnSrouce;
    }


    
    loadItemVariantLazy(event: LazyLoadEvent) {
        this.tableRowOffset = event.first;
        this.tableRowCount = event.rows;
        this.tablePage = event.first / event.rows + 1;

        this.searchItemVariant();
    }

    searchItemVariant() {
        this.isLazyLoad = true;
        this.subscriptionList.push(this.itemVariantsService.itemVariantsGetSearchList({
            projectID: this.currentProject.ID,
            categoryID: this.selectedCategory ? this.selectedCategory.ID : null,
            subCategoryID: this.selectedSubCategory ? this.selectedSubCategory.ID : null,
            itemID: this.selectedItem ? this.selectedItem.ID : null,
            gammonProductCode: this.gammonProductCode,
            model: this.model ? this.model.trim() : null,
            itemDescription: this.description ? this.description.trim() : null,
            page: this.tablePage, take: this.tableRowCount, views: ['ITEM', 'ITEM_SUB_CATEGORY', 'ITEM_CATEGORY', 'PROJECT']}).subscribe(pageData => {
            this.searchItemVariantList = [];
            this.selectionResultList = [];

            if (pageData.Items && pageData.Items.length > 0) {
                this.totalRecords = pageData.TotalItems;

                this.searchItemVariantList = pageData.Items;
                console.log(this.searchItemVariantList);
            }
        }));
    }
   
    
  
    onCategoryChange(event) {
        this.selectedCategory = event;
    }

    onSubCategoryChange(event) {
        this.selectedSubCategory = event;
    }

    onItemChange(event) {
        this.selectedItem = event;
    }
    // selectionCategoryChange() {
    //     //Clear child
    //     this.searchSubCategoryList = [];
    //     this.selectedSubCategory = null;

    //     this.searchItemList = [];
    //     this.selectedItem = null;
    // }

    // selectionSubCategoryChange() {
    //     //Clear child
    //     this.searchItemList = [];
    //     this.selectedItem = null;
    // }

    addItemVariant() {
        this.add.emit(this.selectionResultList);
        this.selectionResultList = [];
    }

    closeModal() {
        this.close.emit();
        this.selectionResultList = [];
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }

    determineItemType(category: ItemCategory):string {
      if (category.ProjectID) { return 'PROJECT'; }
      if (category.DivisionCode) { return 'DIVISION'; }
      
      return 'GLOBAL';
    }

}
