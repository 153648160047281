import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { FactoryProvider, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule as GammonCommonModule } from '@gammon/common';
import { MaterialCommonModule } from '@material/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NvD3Module } from 'ng2-nvd3';
import {
  AccordionModule,
  AlertModule,
  BsDatepickerModule,
  BsDropdownModule,
  ButtonsModule,
  CollapseModule,
  ModalModule,
  ProgressbarModule,
  TabsModule,
  TooltipModule,
  TimepickerModule
} from 'ngx-bootstrap';
import { NgxEchartsModule } from 'ngx-echarts';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgxPrintModule } from 'ngx-print';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { QuillModule } from 'ngx-quill';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { ToastrModule } from 'ngx-toastr';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { pgCardSocialModule } from './@pages/components/card-social/card-social.module';
import { pgCardModule } from './@pages/components/card/card.module';
import { pgDatePickerModule } from './@pages/components/datepicker/datepicker.module';
import { HeaderComponent } from './@pages/components/header/header.component';
import { HorizontalMenuComponent } from './@pages/components/horizontal-menu/horizontal-menu.component';
import { pgListViewModule } from './@pages/components/list-view/list-view.module';
import { MessageModule } from './@pages/components/message/message.module';
import { ProgressModule } from './@pages/components/progress/progress.module';
import { QuickviewComponent } from './@pages/components/quickview/quickview.component';
import { SearchOverlayComponent } from './@pages/components/search-overlay/search-overlay.component';
import { pgSelectModule } from './@pages/components/select/select.module';
import { SharedModule as PageSharedModule } from './@pages/components/shared.module';
import { SidebarComponent } from './@pages/components/sidebar/sidebar.component';
import { pgSliderModule } from './@pages/components/slider/slider.module';
import { pgSwitchModule } from './@pages/components/switch/switch.module';
import { pgTabsModule } from './@pages/components/tabs/tabs.module';
import { pgUploadModule } from './@pages/components/upload/upload.module';
import { BlankComponent, CasualLayout, CondensedComponent, CorporateLayout, ExecutiveLayout, RootLayout, SimplyWhiteLayout } from './@pages/layouts';
//Demo Layout
import { BlankCasualComponent } from './@pages/layouts/blank-casual/blank-casual.component';
import { BlankCorporateComponent } from './@pages/layouts/blank-corporate/blank-corporate.component';
import { BlankSimplywhiteComponent } from './@pages/layouts/blank-simplywhite/blank-simplywhite.component';
//Widgets
import { BarTileWidgetComponent } from './@pages/widgets/bar-tile-widget/bar-tile-widget.component';
import { GraphLiveWidgetComponent } from './@pages/widgets/graph-live-widget/graph-live-widget.component';
import { GraphOptionsWidgetComponent } from './@pages/widgets/graph-options-widget/graph-options-widget.component';
import { GraphTileFlatWidgetComponent } from './@pages/widgets/graph-tile-flat-widget/graph-tile-flat-widget.component';
import { GraphTileWidgetComponent } from './@pages/widgets/graph-tile-widget/graph-tile-widget.component';
import { GraphWidgetBasicComponent } from './@pages/widgets/graph-widget-basic/graph-widget-basic.component';
import { GraphWidgetComponent } from './@pages/widgets/graph-widget/graph-widget.component';
import { ImageWidgetBasicComponent } from './@pages/widgets/image-widget-basic/image-widget-basic.component';
import { ImageWidgetComponent } from './@pages/widgets/image-widget/image-widget.component';
import { MapWidgetComponent } from './@pages/widgets/map-widget/map-widget.component';
import { PlainLiveWidgetComponent } from './@pages/widgets/plain-live-widget/plain-live-widget.component';
import { PlainWidgetComponent } from './@pages/widgets/plain-widget/plain-widget.component';
import { ProgressTileFlatWidgetComponent } from './@pages/widgets/progress-tile-flat-widget/progress-tile-flat-widget.component';
import { ProjectProgressWidgetComponent } from './@pages/widgets/project-progress-widget/project-progress-widget.component';
import { QuickStatsWidgetComponent } from './@pages/widgets/quick-stats-widget/quick-stats-widget.component';
import { RealtimeWidgetComponent } from './@pages/widgets/realtime-widget/realtime-widget.component';
import { SocialImageTileWidgetComponent } from './@pages/widgets/social-image-tile-widget/social-image-tile-widget.component';
import { SocialPostTileWidgetComponent } from './@pages/widgets/social-post-tile-widget/social-post-tile-widget.component';
import { StackedBarWidgetComponent } from './@pages/widgets/stacked-bar-widget/stacked-bar-widget.component';
import { StatTileWidgetComponent } from './@pages/widgets/stat-tile-widget/stat-tile-widget.component';
import { TableBasicWidgetComponent } from './@pages/widgets/table-basic-widget/table-basic-widget.component';
import { TableWidgetComponent } from './@pages/widgets/table-widget/table-widget.component';
import { TodoListWidgetComponent } from './@pages/widgets/todo-list-widget/todo-list-widget.component';
import { WeatherWidgetComponent } from './@pages/widgets/weather-widget/weather-widget.component';
import { WeeklySalesWidgetComponent } from './@pages/widgets/weekly-sales-widget/weekly-sales-widget.component';
import { pgTagModule } from './@pages/components/tag/tag.module';
import { ColorPickerModule } from 'ngx-color-picker';

const DECLIARATIONS = [];

const FOR_ROOT = [ModalModule.forRoot()];

const IMPORTS = [
  AccordionModule,
  AlertModule,
  AngularCommonModule,
  GammonCommonModule,
  MaterialCommonModule,
  BsDatepickerModule,
  BsDropdownModule,
  ButtonsModule,
  CollapseModule,
  ContextMenuModule,
  DragDropModule,
  FormsModule,
  MessageModule,
  NgSelectModule,
  NgxEchartsModule,
  NgxPrintModule,
  NgxQRCodeModule,
  NvD3Module,
  PageSharedModule,
  PerfectScrollbarModule,
  pgCardModule,
  pgCardSocialModule,
  pgDatePickerModule,
  pgListViewModule,
  pgSelectModule,
  pgSliderModule,
  pgSwitchModule,
  pgTabsModule,
  pgUploadModule,
  pgTagModule,
  ProgressbarModule,
  ProgressModule,
  QuillModule,
  ReactiveFormsModule,
  RouterModule,
  SwiperModule,
  TableModule,
  TabsModule,
  ToastrModule,
  TooltipModule,
  TimepickerModule,
  TranslateModule,
  TabViewModule,
  DialogModule,
  CalendarModule,
  ColorPickerModule
];

const PAGEUI_COMPONENT = [
  BlankCasualComponent,
  BlankComponent,
  BlankCorporateComponent,
  BlankSimplywhiteComponent,
  CasualLayout,
  CondensedComponent,
  CorporateLayout,
  ExecutiveLayout,
  HeaderComponent,
  HorizontalMenuComponent,
  RootLayout,
  SearchOverlayComponent,
  SidebarComponent,
  SimplyWhiteLayout,
  BarTileWidgetComponent,
  GraphLiveWidgetComponent,
  GraphOptionsWidgetComponent,
  GraphTileFlatWidgetComponent,
  GraphTileWidgetComponent,
  GraphWidgetBasicComponent,
  GraphWidgetComponent,
  ImageWidgetBasicComponent,
  ImageWidgetComponent,
  MapWidgetComponent,
  PlainLiveWidgetComponent,
  PlainWidgetComponent,
  ProgressTileFlatWidgetComponent,
  ProjectProgressWidgetComponent,
  QuickStatsWidgetComponent,
  QuickviewComponent,
  RealtimeWidgetComponent,
  SocialImageTileWidgetComponent,
  SocialPostTileWidgetComponent,
  StackedBarWidgetComponent,
  StatTileWidgetComponent,
  TableBasicWidgetComponent,
  TableWidgetComponent,
  TodoListWidgetComponent,
  WeatherWidgetComponent,
  WeeklySalesWidgetComponent
];

export const WINDOW = new InjectionToken<Window>('window');

export const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: () => window
};

const PROVIDERS = [];

@NgModule({
  declarations: [...PAGEUI_COMPONENT, ...DECLIARATIONS],
  imports: [...IMPORTS, ...FOR_ROOT],
  exports: [...PAGEUI_COMPONENT, ...DECLIARATIONS, ...IMPORTS]
})
export class AppImportModules {
  static forRoot(): ModuleWithProviders<AppImportModules> {
    return {
      ngModule: AppImportModules,
      providers: PROVIDERS
    };
  }
}
