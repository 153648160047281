import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Project, Team, TeamsService } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';
import { GeneralService, ModalService, ModalStatus } from '../../services';
@Component({
    selector: 'search-team',
    templateUrl: './search-team.component.html',
    styleUrls: ['./search-team.component.scss']
})
export class SearchTeamComponent implements OnInit, OnDestroy {

  @Input() currentProject: Project;
  subscriptionList: Subscription[] = [];

  teamList: Team[] = [];
  @Input() team: Team;
  @Output() teamChange = new EventEmitter<Team>();

  constructor(
      private generalService: GeneralService,
      private teamsService: TeamsService,
      private modalService: ModalService
  ) { }

  ngOnInit() {
      
  }

  searchTeamList() {
    if (!this.currentProject) { 
      console.error('Missing Project as input to search team in template'); 
      return; 
    };

    this.subscriptionList.push(this.teamsService.teamsSearchTeam({projectID: this.currentProject.ID}).subscribe(pageData => {
      if (pageData.Items) {
        console.log(this.teamList);
        this.teamList = pageData.Items;

        if(this.teamList && this.teamList.length === 1) {
          this.team = this.teamList[0];
          this.onTeamChange(this.team);
        }
      }
    }));
  }

  onTeamChange(event) {
    this.teamChange.emit(event);
  }
  
  ngOnDestroy() {
    this.subscriptionList.forEach(subscription => {
      if (subscription) { subscription.unsubscribe(); }
    });
  }
}
