/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Area } from '../model/area';
import { PageArea } from '../model/pageArea';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { AreasServiceInterface, IareasCreateParams,IareasDeleteParams,IareasDeleteByListParams,IareasGetAreaByIDParams,IareasGetListParams,IareasGetListWithInventoryBalancesParams,IareasGetSearchListParams,IareasUpdateParams,IareasUpdateByListParams,IareasUploadLocationsParams, }                            from './areas.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class AreasService implements AreasServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareasCreateParams, observe?: 'body', reportProgress?: boolean): Observable<Area>;
    public areasCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareasCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Area>>;
    public areasCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareasCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Area>>;
    @datastore
    public areasCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareasCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling areasCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Area>(`${this.basePath}/Areas`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasDelete({ deviceId, message, ignoreLoadingBar, formData, areaID } : IareasDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areasDelete({ deviceId, message, ignoreLoadingBar, formData, areaID } : IareasDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areasDelete({ deviceId, message, ignoreLoadingBar, formData, areaID } : IareasDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areasDelete({ deviceId, message, ignoreLoadingBar, formData, areaID } : IareasDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling areasDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Areas/${encodeURIComponent(String(areaID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaIDs } : IareasDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areasDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaIDs } : IareasDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areasDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaIDs } : IareasDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areasDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaIDs } : IareasDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaIDs === null || areaIDs === undefined) {
            throw new Error('Required parameter areaIDs was null or undefined when calling areasDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (areaIDs) {
            areaIDs.forEach((element) => {
                queryParameters = queryParameters.append('areaIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Areas/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasGetAreaByID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareasGetAreaByIDParams, observe?: 'body', reportProgress?: boolean): Observable<Area>;
    public areasGetAreaByID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareasGetAreaByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Area>>;
    public areasGetAreaByID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareasGetAreaByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Area>>;
    @datastore
    public areasGetAreaByID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareasGetAreaByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling areasGetAreaByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Area>(`${this.basePath}/Areas/GetAreaByID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasGetList({ deviceId, message, ignoreLoadingBar, formData, areaID, page, views } : IareasGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Area>>;
    public areasGetList({ deviceId, message, ignoreLoadingBar, formData, areaID, page, views } : IareasGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Area>>>;
    public areasGetList({ deviceId, message, ignoreLoadingBar, formData, areaID, page, views } : IareasGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Area>>>;
    @datastore
    public areasGetList({ deviceId, message, ignoreLoadingBar, formData, areaID, page, views } : IareasGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Area>>(`${this.basePath}/Areas`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param binID 
     * @param inventoryBalanceID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, areaID, binID, inventoryBalanceID, page, views } : IareasGetListWithInventoryBalancesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Area>>;
    public areasGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, areaID, binID, inventoryBalanceID, page, views } : IareasGetListWithInventoryBalancesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Area>>>;
    public areasGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, areaID, binID, inventoryBalanceID, page, views } : IareasGetListWithInventoryBalancesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Area>>>;
    @datastore
    public areasGetListWithInventoryBalances({ deviceId, message, ignoreLoadingBar, formData, areaID, binID, inventoryBalanceID, page, views } : IareasGetListWithInventoryBalancesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling areasGetListWithInventoryBalances.');
        }
        if (binID === null || binID === undefined) {
            throw new Error('Required parameter binID was null or undefined when calling areasGetListWithInventoryBalances.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryBalanceID !== undefined && inventoryBalanceID !== null) {
            queryParameters = queryParameters.set('inventoryBalanceID', <any>inventoryBalanceID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Area>>(`${this.basePath}/Areas/${encodeURIComponent(String(areaID))}/Bins/${encodeURIComponent(String(binID))}/InventoryBalances`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param areaDescription 
     * @param areaChineseDescription 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasGetSearchList({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, page, take, views } : IareasGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageArea>;
    public areasGetSearchList({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, page, take, views } : IareasGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageArea>>;
    public areasGetSearchList({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, page, take, views } : IareasGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageArea>>;
    @datastore
    public areasGetSearchList({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, page, take, views } : IareasGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling areasGetSearchList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (areaDescription !== undefined && areaDescription !== null) {
            queryParameters = queryParameters.set('areaDescription', <any>areaDescription);
        }
        if (areaChineseDescription !== undefined && areaChineseDescription !== null) {
            queryParameters = queryParameters.set('areaChineseDescription', <any>areaChineseDescription);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageArea>(`${this.basePath}/Areas/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasUpdate({ deviceId, message, ignoreLoadingBar, formData, areaID, model } : IareasUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areasUpdate({ deviceId, message, ignoreLoadingBar, formData, areaID, model } : IareasUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areasUpdate({ deviceId, message, ignoreLoadingBar, formData, areaID, model } : IareasUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areasUpdate({ deviceId, message, ignoreLoadingBar, formData, areaID, model } : IareasUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling areasUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling areasUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/Areas/${encodeURIComponent(String(areaID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IareasUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areasUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IareasUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areasUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IareasUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areasUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IareasUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling areasUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/Areas/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areasUploadLocations({ deviceId, message, ignoreLoadingBar, formData, locationID } : IareasUploadLocationsParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areasUploadLocations({ deviceId, message, ignoreLoadingBar, formData, locationID } : IareasUploadLocationsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areasUploadLocations({ deviceId, message, ignoreLoadingBar, formData, locationID } : IareasUploadLocationsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areasUploadLocations({ deviceId, message, ignoreLoadingBar, formData, locationID } : IareasUploadLocationsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling areasUploadLocations.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Areas/UploadArea/${encodeURIComponent(String(locationID))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
