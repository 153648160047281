<div [ngClass]="{'row py-1': orientation == 'row', 'd-flex flex-column mb-2': orientation == 'column'}">
  <div class="my-auto" [ngClass]="{'col-auto': orientation == 'column', 'col-md-3 col-sm-3 col-lg-3': orientation == 'row'}">
      <span class="fs-12">{{ 'ITEM_SUBCATEGORY' | translate }}</span>
  </div>
  <div class="my-auto" [ngClass]="{'col-auto': orientation == 'column', 'col-8': orientation == 'row'}">
      <ng-select style="width: 100%" (open)="searchSubCategories();" [items]="searchSubCategoryList" appendTo="body"
          [(ngModel)]="subCategory" (ngModelChange)="onSubCategoryChange($event)"
          [disabled]="!category">

          <ng-template ng-label-tmp let-item="item">
              <span>{{item.SubCategoryCode}} - {{ item['SUB_CATEGORY_DESC'|translate] }}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
              <span>{{item.SubCategoryCode}} - {{ item['SUB_CATEGORY_DESC'|translate] }}</span>
          </ng-template>

      </ng-select>
  </div>
</div>
