import { Component, ElementRef, EventEmitter, ViewChild } from '@angular/core';
import { ReturnDecisionMapDTO } from '@gammon/inventory-api';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
    selector: 'approval-modal',
    templateUrl: './approval-modal.component.html',
    styleUrls: ['./approval-modal.component.scss']
})
export class ApprovalModalComponent {
  @ViewChild('CommentsTextarea') CommentsTextarea: ElementRef;

  decision: ReturnDecisionMapDTO.DecisionEnum;
  comments: string;
  event = new EventEmitter<{decision: ReturnDecisionMapDTO.DecisionEnum, comments: string}>();
  DecisionType = ReturnDecisionMapDTO.DecisionEnum;
  constructor(
    private bsModalRef: BsModalRef
  ) {
    setTimeout(() => {
      this.CommentsTextarea.nativeElement.focus();
    }, 300);
  }

  closeModal() {
    this.bsModalRef.hide();
  }
  confirm() {
    this.event.emit({decision: this.decision, comments: this.comments || ''});
    this.closeModal();
  }

}
