<pgcard [MinimalHeader]="true" [Refresh]="false" [Controls]="false" [AdditionalClasses]="'h-100'">
    <ng-template #CardTitle>
        <span *ngIf="user">{{user.Title}}</span>
    </ng-template>

    <div *ngIf="user">
        <div class="row">
            <div class="col text-center" style="min-height:113px; max-height:113px">
                <img default="assets/images/avatar.png" [src]="'https://intranet.gammonconstruction.com/PeopleDirectory_Picture/'+user.PhotoUrl" width="90px"/>
            </div>
        </div>

        <div class="row">
            <div class="col-4 my-auto">
                <h6 class="semi-bold">{{'NAME'|translate}}</h6>
            </div>
            <div class="col-8 my-auto">
                {{user.Name}}
            </div>
        </div>

        <div class="row">
            <div class="col-4 my-auto">
                <h6 class="semi-bold">{{'STAFF_ID'|translate}}</h6 >
            </div>
            <div class="col-8 my-auto">
                {{user.StaffID}}
            </div>
        </div>
    </div>
</pgcard>