<request-status-tiles [actionType]="actionType" [data]="statusCount"> </request-status-tiles>

<div class="row my-2">
  <div class="col my-auto">
    <div class="form-group">
      <div class="d-flex flex-column">
        <span class="fs-12">{{ 'STATUS' | translate }}</span>

        <ng-select style="width: 100%" [items]="statusList" [(ngModel)]="searchPlanStatus" appendTo="body">
          <ng-template ng-label-tmp let-item="item">
            <ng-container *ngIf="item">
              <span>{{ generalService.statusToString(item) | translate }}</span>
            </ng-container>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span>{{ generalService.statusToString(item) | translate }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="col my-auto">
    <div class="form-group">
      <div class="d-flex flex-column">
        <span class="fs-12">{{ 'TARGETED_DELIVERY_DATE' | translate }}</span>
        <div class="input-group date">
          <div class="input-group-prepend">
            <span class="input-group-text"><fa-icon [icon]="faCalendar"></fa-icon></span>
          </div>

          <input
            class="form-control cursor-pointer"
            bsDatepicker
            readonly
            placeholder="{{ 'SELECT_DELIVERY_DATE' | translate }}"
            [(ngModel)]="searchDeliveryDate"
            [bsConfig]="{
              showWeekNumbers: false,
              dateInputFormat: 'YYYY-MM-DD',
              adaptivePosition: true
            }"
            placement="bottom"
            (keyup.enter)="searchRequests()"
            style="background: white; color: black"
          />

          <div class="input-group-append" (click)="searchDeliveryDate = null">
            <span class="input-group-text"><fa-icon [icon]="faTimes"></fa-icon></span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col my-auto">
    <div class="form-group">
      <div class="d-flex flex-column">
        <span class="fs-12">{{ 'SUPPLIER_NO' | translate }}</span>
        <search-supplier [(supplier)]="searchSupplier"></search-supplier>
      </div>
    </div>
  </div>

  <div class="col my-auto" *ngIf="showAll">
    <div class="form-group">
      <div class="d-flex flex-column">
        <span class="fs-12">{{ 'REQUESTOR' | translate }}</span>
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text"><fa-icon [icon]="faUser"></fa-icon></span>
          </div>
          <input
            class="form-control"
            type="text"
            (keyup.enter)="searchRequests()"
            [(ngModel)]="searchRequestor"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="col my-auto" *ngIf="actionType === 'RECEIPT_REQUEST'">
    <div class="form-group">
      <div class="d-flex flex-column">
        <span class="fs-12">{{ 'LOCATION' | translate }}</span>
        <search-location [project]="currentProject" (locationChange)="searchLocation = $event">
        </search-location>
      </div>
    </div>
  </div>

  <div class="col my-auto">
    <div class="form-group">
      <div class="d-flex flex-column">
        <span class="fs-12">{{ 'TEAM' | translate }}</span>

        <ng-select style="width: 100%" [items]="teamList" [(ngModel)]="searchTeam" appendTo="body">
          <ng-template ng-label-tmp let-item="item">
            <ng-container *ngIf="item">
              <span>{{ teamName(item.ID) }}</span>
            </ng-container>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            <span>{{ teamName(item.ID) }}</span>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>

  <div class="col my-auto" *ngIf="actionType === 'RECEIPT_REQUEST'">
    <div class="form-group">
      <div class="d-flex flex-column">
        <span class="fs-12">{{ 'SITE_ENTRANCE' | translate }}</span>
        <search-site-entrance [project]="currentProject" (siteEntranceChange)="searchSiteEntrance = $event">
        </search-site-entrance>
      </div>
    </div>
  </div>

  <div class="col my-auto">
    <div class="d-flex flex-row-reverse">
      <div class="p-1">
        <button class="btn btn-complete w-abs-120" (click)="searchRequests()">
          <fa-icon [icon]="faSearch" class="mr-1"></fa-icon>{{ 'SEARCH' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<div class="d-flex table-responsive">
  <p-table
    [value]="requestList"
    [scrollable]="true"
    scrollHeight="40vh"
    [paginator]="true"
    [rows]="20"
    [lazy]="true"
    (onLazyLoad)="loadRequestLazy($event)"
    [totalRecords]="totalRecords"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ 'ID' | translate }}</th>
        <th>{{ 'REFERENCE_NUMBER' | translate }}</th>
        <th>{{ 'STATUS' | translate }}</th>
        <th>{{ 'SUBMISSION_DATE' | translate }}</th>
        <th>{{ 'TARGETED_DELIVERY_DATE' | translate }}</th>
        <th>{{ 'SUPPLIER_NO' | translate }}</th>
        <th>{{ 'REQUESTOR' | translate }}</th>
        <th>{{ 'SOURCE' | translate }}</th>
        <th>{{ 'TEAM' | translate }}</th>
        <th>{{ 'LIFTING_QUANTITY' | translate }}</th>
        <th>{{ 'INSTALLATION_DATE' | translate }}</th>
        <th>{{ 'SITE_ENTRANCE' | translate }}</th>
        <th>{{ 'TRANSPORT_TYPE' | translate }}</th>
        <th>{{ 'REMARKS' | translate }}</th>
        <th></th>
      </tr>

      <tr>
        <td class="text-center no-padding-top w-100" colspan="15">
          <div class="p-1">
            <span class="btn btn-block" (click)="onAddRequest()"
              ><fa-icon [icon]="faPlusCircle" class="mr-1"></fa-icon>{{ 'ADD_REQUEST' | translate }}</span
            >
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-request let-ri="rowIndex">
      <tr class="animation-fade-in-bottom" style="--animation-order: {{ ri + 1 }}">
        <td>
          <span class="cursor-pointer" (click)="showReqestQrModal(request.ID)"
            ><fa-icon [icon]="faQrcode"></fa-icon>{{ request.ID }}
          </span>
        </td>
        <td>
          <span
            class="cursor-pointer"
            (click)="showDecisionMapModal((request.ApprovalHeader || {}).ReferenceNumber)"
            >{{ (request.ApprovalHeader || {}).ReferenceNumber }}</span
          >
        </td>
        <td>
          <span
            class="bold"
            [ngClass]="[
              request.PlanStatus.toUpperCase() == 'ACCEPTED' ? 'text-success' : '',
              request.PlanStatus.toUpperCase() == 'WAITING' ||
              request.PlanStatus.toUpperCase() == 'WAITING_FOR_APPROVAL'
                ? 'text-warning'
                : '',
              request.PlanStatus.toUpperCase() == 'REJECTED' ? 'text-danger' : '',
              request.PlanStatus.toUpperCase() == 'RECEIVED' ? 'text-complete' : '',
              request.PlanStatus.toUpperCase() == 'ISSUED' ? 'text-complete' : '',
              request.PlanStatus.toUpperCase() == 'CANCELLED' ? 'text-dark' : ''
            ]"
          >
            {{ (request || {}).PlanStatus | translate }}
          </span>
        </td>
        <ng-container *ngIf="request.PlanStatus.toUpperCase() == 'DRAFT'; else noDraftStatus">
          <td>
            {{ request.SubmissionDate | date : 'yyyy-MM-dd' }} {{ request.SubmissionDate | date : 'HH:mm' }}
          </td>
        </ng-container>
        <ng-template #noDraftStatus>
          <td>
            {{ request.SubmissionDate || request.CreatedDate | date : 'yyyy-MM-dd' }}
            {{ request.SubmissionDate || request.CreatedDate | date : 'HH:mm' }}
          </td>
        </ng-template>

        <td>
          <span
            [style.color]="getTargetDeliveryDateColor(request.TargetDeliveryDate)"
            [ngClass]="{ 'text-bold': shouldBoldTargetDeliveryColor(request.TargetDeliveryDate) }"
            >{{ request.TargetDeliveryDate | date : 'yyyy-MM-dd' }}</span
          >
          {{ request.DatePeriod ? request.DatePeriod : (request.TargetDeliveryDate | date : 'HH:mm') }}
        </td>
        <!-- <td>{{ request.SupplierNo }} - {{ (((request.SupplierObservable|async)||{})?.AddressBookName) || ''}}</td> -->
        <td>
          <ng-container *ngIf="request.SupplierNo; else noSupplierName">
            <div class="d-flex gap-1">
              <span>{{ request.SupplierNo }}</span>
              <span> - </span>
              <ng-container *ngIf="request.SupplierObservable | async as supplierData; else skeletonTemplate">
                {{ supplierData?.AddressBookName || '' }}
              </ng-container>
              <ng-template #skeletonTemplate>
                <skeleton></skeleton>
              </ng-template>
            </div>
          </ng-container>
          <ng-template #noSupplierName>
            {{ request.SupplierName ? request.SupplierName : '' }}
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="request.RequestorDetails">
            {{ request.RequestorDetails | desc : 'AD_DESC' : 'Name' }}
          </ng-container>
        </td>
        <td>
          <ng-container
            *ngIf="
              request.Source.indexOf('TRANSFER') >= 0 && request.Source.split(' ').length > 1;
              else commonSource
            "
          >
            {{ 'TRANSFER_FROM_PROJECT' | translate }} {{ request.Source.split(' ')[1] || '' }}
          </ng-container>
          <ng-template #commonSource>
            {{ request.Source | translate }}
          </ng-template>
        </td>
        <td>
          <span>{{ teamName(request.TeamID) }}</span>
        </td>
        <td>
          {{ request.LiftingQuantity | translate }}
        </td>
        <td>
          {{ request.InstallationDate | date : 'yyyy-MM-dd' }}
        </td>
        <td>
          {{
            request.SiteEntrance ? (request.SiteEntrance | desc : 'SITE_ENTRANCE_DESC' : 'NameEnglish') : ''
          }}
        </td>

        <td>
          {{
            request.TransportType
              ? (request.TransportType | desc : 'TRANSPORT_TYPE_DESC' : 'TransportTypeEnglish')
              : ''
          }}
        </td>

        <td class="ellipsis">
          <span>{{ request.Remarks }}</span>
        </td>
        <td>
          <span
            class="cursor-pointer fs-24 mx-2"
            (click)="showInventoryPlanDetails(request)"
            tooltip="{{ 'DETAILS' | translate }}"
            ><fa-icon [icon]="faFileAlt"></fa-icon
          ></span>
          <ng-container *ShowByRoles="['RECEIPT_REQ_UPDATE', 'ISSUE_REQ_UPDATE']">
            <span
              class="cursor-pointer fs-24 mx-4 text-danger"
              *ngIf="
                (request.PlanStatus.toUpperCase() === 'ACCEPTED' ||
                  request.PlanStatus.toUpperCase() === 'WAITING' ||
                  request.PlanStatus.toUpperCase() === 'WAITING_FOR_APPROVAL' ||
                  request.PlanStatus.toUpperCase() === 'DRAFT') &&
                !checkExistReceivedItems(request)
              "
              (click)="showCancelInventoryPlanDialog(request)"
              tooltip="{{ 'CANCEL' | translate }}"
              ><fa-icon [icon]="faTimes"></fa-icon
            ></span>
          </ng-container>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>

<ng-template #requestQrModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ actionType | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row d-flex flex-row">
      <div class="col d-flex flex-column">
        <div class="d-flex justify-content-center">
          <ngx-qrcode [elementType]="qrCodeElementType" [value]="currentQrCode" cssClass="zoom-qr qrDiv">
          </ngx-qrcode>
        </div>
        <p class="fs-12 text-center" style="word-wrap: break-word">
          {{ currentQrCode }}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex flex-row justify-content-end">
        <button class="btn btn-info mx-1" (click)="downloadQrCode()">
          <span><fa-icon [icon]="faDownload"></fa-icon></span>{{ 'DOWNLOAD' | translate }}
        </button>
        <button class="btn btn-warning mx-1" (click)="closeModal()">{{ 'CLOSE' | translate }}</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #decisionMapModal>
  <decision-map
    [decisionMapHtml]="decisionMapHtml"
    [referenceNumber]="referenceNumber"
    (close)="closeModal()"
  >
  </decision-map>
</ng-template>
