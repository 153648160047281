/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ItemSubCategory } from '../model/itemSubCategory';
import { PageItemSubCategory } from '../model/pageItemSubCategory';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ItemSubCategoriesServiceInterface, IitemSubCategoriesCheckIsExistCodeParams,IitemSubCategoriesCheckIsExistSubCategoriesParams,IitemSubCategoriesCreateParams,IitemSubCategoriesDeleteParams,IitemSubCategoriesDeleteByListParams,IitemSubCategoriesGetItemSubCategoryByIDParams,IitemSubCategoriesGetListParams,IitemSubCategoriesGetListWithIbQtyGreaterThanZeroParams,IitemSubCategoriesGetListWithItemAccessesParams,IitemSubCategoriesGetListWithItemVariantParams,IitemSubCategoriesGetListWithItemsParams,IitemSubCategoriesGetSearchListParams,IitemSubCategoriesUpdateParams,IitemSubCategoriesUpdateByListParams,IitemSubCategoriesUploadSubCategoriesParams, }                            from './itemSubCategories.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ItemSubCategoriesService implements ItemSubCategoriesServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param categoryID 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, categoryID, code } : IitemSubCategoriesCheckIsExistCodeParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public itemSubCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, categoryID, code } : IitemSubCategoriesCheckIsExistCodeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public itemSubCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, categoryID, code } : IitemSubCategoriesCheckIsExistCodeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public itemSubCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, categoryID, code } : IitemSubCategoriesCheckIsExistCodeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemSubCategoriesCheckIsExistCode.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling itemSubCategoriesCheckIsExistCode.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/ItemSubCategories/CheckIsExistCode/${encodeURIComponent(String(categoryID))}/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesCheckIsExistSubCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesCheckIsExistSubCategoriesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemSubCategory>>;
    public itemSubCategoriesCheckIsExistSubCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesCheckIsExistSubCategoriesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemSubCategory>>>;
    public itemSubCategoriesCheckIsExistSubCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesCheckIsExistSubCategoriesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemSubCategory>>>;
    @datastore
    public itemSubCategoriesCheckIsExistSubCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesCheckIsExistSubCategoriesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemSubCategoriesCheckIsExistSubCategories.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<ItemSubCategory>>(`${this.basePath}/ItemSubCategories/CheckIsExistSubCategories`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemSubCategoriesCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ItemSubCategory>;
    public itemSubCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemSubCategoriesCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemSubCategory>>;
    public itemSubCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemSubCategoriesCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemSubCategory>>;
    @datastore
    public itemSubCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemSubCategoriesCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemSubCategoriesCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ItemSubCategory>(`${this.basePath}/ItemSubCategories`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemSubCategoriesDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemSubCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemSubCategoriesDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemSubCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemSubCategoriesDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemSubCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, subCategoryID } : IitemSubCategoriesDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemSubCategoriesDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ItemSubCategories/${encodeURIComponent(String(subCategoryID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, subCategoryIDs } : IitemSubCategoriesDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemSubCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, subCategoryIDs } : IitemSubCategoriesDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemSubCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, subCategoryIDs } : IitemSubCategoriesDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemSubCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, subCategoryIDs } : IitemSubCategoriesDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryIDs === null || subCategoryIDs === undefined) {
            throw new Error('Required parameter subCategoryIDs was null or undefined when calling itemSubCategoriesDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (subCategoryIDs) {
            subCategoryIDs.forEach((element) => {
                queryParameters = queryParameters.append('subCategoryIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ItemSubCategories/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param restrictActiveParents 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesGetItemSubCategoryByID({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, restrictActiveParents, views } : IitemSubCategoriesGetItemSubCategoryByIDParams, observe?: 'body', reportProgress?: boolean): Observable<ItemSubCategory>;
    public itemSubCategoriesGetItemSubCategoryByID({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, restrictActiveParents, views } : IitemSubCategoriesGetItemSubCategoryByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemSubCategory>>;
    public itemSubCategoriesGetItemSubCategoryByID({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, restrictActiveParents, views } : IitemSubCategoriesGetItemSubCategoryByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemSubCategory>>;
    @datastore
    public itemSubCategoriesGetItemSubCategoryByID({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, restrictActiveParents, views } : IitemSubCategoriesGetItemSubCategoryByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemSubCategoriesGetItemSubCategoryByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restrictActiveParents !== undefined && restrictActiveParents !== null) {
            queryParameters = queryParameters.set('restrictActiveParents', <any>restrictActiveParents);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ItemSubCategory>(`${this.basePath}/ItemSubCategories/${encodeURIComponent(String(subCategoryID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, views } : IitemSubCategoriesGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemSubCategory>>;
    public itemSubCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, views } : IitemSubCategoriesGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemSubCategory>>>;
    public itemSubCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, views } : IitemSubCategoriesGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemSubCategory>>>;
    @datastore
    public itemSubCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, views } : IitemSubCategoriesGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemSubCategory>>(`${this.basePath}/ItemSubCategories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, views } : IitemSubCategoriesGetListWithIbQtyGreaterThanZeroParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemSubCategory>>;
    public itemSubCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, views } : IitemSubCategoriesGetListWithIbQtyGreaterThanZeroParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemSubCategory>>>;
    public itemSubCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, views } : IitemSubCategoriesGetListWithIbQtyGreaterThanZeroParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemSubCategory>>>;
    @datastore
    public itemSubCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, views } : IitemSubCategoriesGetListWithIbQtyGreaterThanZeroParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemSubCategoriesGetListWithIbQtyGreaterThanZero.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemSubCategoriesGetListWithIbQtyGreaterThanZero.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemSubCategory>>(`${this.basePath}/ItemSubCategories/GetListWithIbQtyGreaterThanZero`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param itemID 
     * @param itemAccessID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemAccessID, page, views } : IitemSubCategoriesGetListWithItemAccessesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemSubCategory>>;
    public itemSubCategoriesGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemAccessID, page, views } : IitemSubCategoriesGetListWithItemAccessesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemSubCategory>>>;
    public itemSubCategoriesGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemAccessID, page, views } : IitemSubCategoriesGetListWithItemAccessesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemSubCategory>>>;
    @datastore
    public itemSubCategoriesGetListWithItemAccesses({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemAccessID, page, views } : IitemSubCategoriesGetListWithItemAccessesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemSubCategoriesGetListWithItemAccesses.');
        }
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemSubCategoriesGetListWithItemAccesses.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemAccessID !== undefined && itemAccessID !== null) {
            queryParameters = queryParameters.set('itemAccessID', <any>itemAccessID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemSubCategory>>(`${this.basePath}/ItemSubCategories/${encodeURIComponent(String(subCategoryID))}/Items/${encodeURIComponent(String(itemID))}/ItemAccesses`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param itemID 
     * @param itemVariantID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesGetListWithItemVariant({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemVariantID, page, views } : IitemSubCategoriesGetListWithItemVariantParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemSubCategory>>;
    public itemSubCategoriesGetListWithItemVariant({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemVariantID, page, views } : IitemSubCategoriesGetListWithItemVariantParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemSubCategory>>>;
    public itemSubCategoriesGetListWithItemVariant({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemVariantID, page, views } : IitemSubCategoriesGetListWithItemVariantParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemSubCategory>>>;
    @datastore
    public itemSubCategoriesGetListWithItemVariant({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, itemVariantID, page, views } : IitemSubCategoriesGetListWithItemVariantParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemSubCategoriesGetListWithItemVariant.');
        }
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemSubCategoriesGetListWithItemVariant.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemSubCategory>>(`${this.basePath}/ItemSubCategories/${encodeURIComponent(String(subCategoryID))}/items/${encodeURIComponent(String(itemID))}/itemVariants`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param itemID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, page, views } : IitemSubCategoriesGetListWithItemsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemSubCategory>>;
    public itemSubCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, page, views } : IitemSubCategoriesGetListWithItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemSubCategory>>>;
    public itemSubCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, page, views } : IitemSubCategoriesGetListWithItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemSubCategory>>>;
    @datastore
    public itemSubCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, itemID, page, views } : IitemSubCategoriesGetListWithItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemSubCategoriesGetListWithItems.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemSubCategory>>(`${this.basePath}/ItemSubCategories/${encodeURIComponent(String(subCategoryID))}/Items`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param subCategoryCode 
     * @param description 
     * @param chineseDescription 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryCode, description, chineseDescription, page, take, views } : IitemSubCategoriesGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemSubCategory>;
    public itemSubCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryCode, description, chineseDescription, page, take, views } : IitemSubCategoriesGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemSubCategory>>;
    public itemSubCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryCode, description, chineseDescription, page, take, views } : IitemSubCategoriesGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemSubCategory>>;
    @datastore
    public itemSubCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryCode, description, chineseDescription, page, take, views } : IitemSubCategoriesGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryCode !== undefined && subCategoryCode !== null) {
            queryParameters = queryParameters.set('subCategoryCode', <any>subCategoryCode);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (chineseDescription !== undefined && chineseDescription !== null) {
            queryParameters = queryParameters.set('chineseDescription', <any>chineseDescription);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemSubCategory>(`${this.basePath}/ItemSubCategories/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param subCategoryID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, model } : IitemSubCategoriesUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemSubCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, model } : IitemSubCategoriesUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemSubCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, model } : IitemSubCategoriesUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemSubCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, subCategoryID, model } : IitemSubCategoriesUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemSubCategoriesUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemSubCategoriesUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ItemSubCategories/${encodeURIComponent(String(subCategoryID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemSubCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemSubCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemSubCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemSubCategoriesUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemSubCategoriesUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/ItemSubCategories/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemSubCategoriesUploadSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemSubCategoriesUploadSubCategoriesParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemSubCategoriesUploadSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemSubCategoriesUploadSubCategoriesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemSubCategoriesUploadSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemSubCategoriesUploadSubCategoriesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemSubCategoriesUploadSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemSubCategoriesUploadSubCategoriesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemSubCategoriesUploadSubCategories.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/ItemSubCategories/UploadSubCategory/${encodeURIComponent(String(categoryID))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
