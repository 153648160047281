/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { IssuePlan } from '../model/issuePlan';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { IssuePlansServiceInterface, IissuePlansCreateParams,IissuePlansDeleteParams,IissuePlansGetListParams,IissuePlansUpdateParams,IissuePlansUpdateByListParams, }                            from './issuePlans.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class IssuePlansService implements IssuePlansServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issuePlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IissuePlansCreateParams, observe?: 'body', reportProgress?: boolean): Observable<IssuePlan>;
    public issuePlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IissuePlansCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<IssuePlan>>;
    public issuePlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IissuePlansCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<IssuePlan>>;
    @datastore
    public issuePlansCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IissuePlansCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling issuePlansCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<IssuePlan>(`${this.basePath}/IssuePlans`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param issuePlanID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issuePlansDelete({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : IissuePlansDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public issuePlansDelete({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : IissuePlansDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public issuePlansDelete({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : IissuePlansDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public issuePlansDelete({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : IissuePlansDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (issuePlanID === null || issuePlanID === undefined) {
            throw new Error('Required parameter issuePlanID was null or undefined when calling issuePlansDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/IssuePlans/${encodeURIComponent(String(issuePlanID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param issuePlanID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issuePlansGetList({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, page, views } : IissuePlansGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<IssuePlan>>;
    public issuePlansGetList({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, page, views } : IissuePlansGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IssuePlan>>>;
    public issuePlansGetList({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, page, views } : IissuePlansGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IssuePlan>>>;
    @datastore
    public issuePlansGetList({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, page, views } : IissuePlansGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issuePlanID !== undefined && issuePlanID !== null) {
            queryParameters = queryParameters.set('issuePlanID', <any>issuePlanID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<IssuePlan>>(`${this.basePath}/IssuePlans`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param issuePlanID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issuePlansUpdate({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, model } : IissuePlansUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public issuePlansUpdate({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, model } : IissuePlansUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public issuePlansUpdate({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, model } : IissuePlansUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public issuePlansUpdate({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, model } : IissuePlansUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (issuePlanID === null || issuePlanID === undefined) {
            throw new Error('Required parameter issuePlanID was null or undefined when calling issuePlansUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling issuePlansUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/IssuePlans/${encodeURIComponent(String(issuePlanID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public issuePlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IissuePlansUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public issuePlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IissuePlansUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public issuePlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IissuePlansUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public issuePlansUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IissuePlansUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling issuePlansUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/IssuePlans/UpdateByList`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
