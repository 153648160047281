import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap';
import { ProgressModule } from '../progress/progress.module';
import { pgUploadBtnComponent } from './upload-btn.component';
import { pgUploadListComponent } from './upload-list.component';
import { pgUploadComponent } from './upload.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { createTranslateLoader } from 'projects/AppConfig';
import { HttpClient } from '@angular/common/http';
@NgModule({
  imports: [CommonModule, FormsModule, TooltipModule, ProgressModule, TranslateModule.forChild({
    loader: {
      provide: TranslateLoader,
      useFactory: createTranslateLoader,
      deps: [HttpClient]
    }
  })],
  declarations: [pgUploadComponent, pgUploadBtnComponent, pgUploadListComponent],
  exports: [pgUploadComponent]
})
export class pgUploadModule {}
