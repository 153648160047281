<div class="menu-bar header-sm-height" #menuWrapper [class.open]="_horizontalMobileMenu">
  <!--<a href="javascript:void(0)" (click)="toggleHorizontalMenu()" class="btn-link toggle-sidebar d-lg-none pg pg-close">
  </a>-->
  <ul (cdkObserveContent)="_onContentChanges()" class="cursor-pointer">
    <li *ngFor="let item of _renduerMenuItems" [class.open]="item.open || item.mToggle ==='open'" [class.opening]="item.opening" [class.more]="item.type" [routerLinkActive]="['active']" [routerLinkActiveOptions]="{exact: true}">
      <a href="javascript:void(0);" *ngIf="item.submenu;else singleLink" (click)="toggleLink($event,item)">
        <span class="title pt-1" >
          <i *ngIf="item.iconType=='fa' || item.iconType=='pg'; else otherIcons" class="fs-16 mr-1 {{item.iconType}} {{item.iconType}}-{{item.iconName}}" style="display: inline"></i>
          <ng-template #otherIcons>
              <i class="fs-16 mr-1 {{item.iconName}}" style="display: inline"></i>
          </ng-template>
          <ng-template [ngIf]="item.type != 'more'"><span class="fs-15">{{ item.label | translate }}</span></ng-template>
          <i *ngIf="item.type" class="pg pg-more"></i>  
        </span>
        <span *ngIf="item.submenu && !item.type" class="ml-2 my-auto fa fa-caret-down" [class.open]="item.mToggle ==='open'"></span>
      </a>
      <ng-template #singleLink>
        <a *ngIf="item.routerLink" [routerLink]="[item.routerLink]">
          <span class="title" (click)="setCurrentMenu(item.routerLink)" [ngClass]="getCurrentMenu()==item.routerLink? 'active':'text-white'">
            <i *ngIf="item.iconType=='fa' || item.iconType=='pg'; else otherIcons" class="fs-16 mr-1 {{item.iconType}} {{item.iconType}}-{{item.iconName}}" style="display: inline"></i>
            <ng-template #otherIcons>
                <i class="fs-16 mr-1 {{item.iconName}}" style="display: inline"></i>
            </ng-template>
            <span class="fs-15">{{ item.label | translate }}</span>
          </span>
        </a>
        <a *ngIf="item.externalLink" href="{{item.externalLink}}" [attr.target]="item.target" >
          <span class="title">
            <i *ngIf="item.iconType=='fa' || item.iconType=='pg'; else otherIcons" class="fs-16 mr-1 {{item.iconType}} {{item.iconType}}-{{item.iconName}}" style="display: inline"></i>
            <ng-template #otherIcons>
                <i class="fs-16 mr-1 {{item.iconName}}" style="display: inline"></i>
            </ng-template>
            <span class="fs-15">{{ item.label | translate }}</span>
          </span>
        </a>
      </ng-template>
      <ul *ngIf="item.submenu">
          <li *ngFor="let child of item.submenu">
            <a href="javascript:void(0);" *ngIf="child.submenu;else singleLinkChild"><span class="title">{{ child.label | translate }}</span>
                <span  *ngIf="item.submenu" class=" arrow" [class.open]="item.mToggle ==='open'"></span>
            </a>
            <ng-template #singleLinkChild>
              <a *ngIf="child.routerLink" [routerLink]="[child.routerLink]"><span class="title">{{ child.label | translate }}</span>
              </a>
              <a *ngIf="child.externalLink" href="{{child.externalLink}}" [attr.target]="child.target"><span class="title">{{ child.label | translate }}</span>
              </a>
            </ng-template>
              <ul *ngIf="child.submenu" class="sub-menu">
                  <li *ngFor="let lvl2 of child.submenu">
                      <a *ngIf="lvl2.routerLink" [routerLink]="[lvl2.routerLink]"><span class="title">{{ lvl2.label | translate }}</span>
                      </a>
                      <a *ngIf="lvl2.externalLink" [routerLink]="[lvl2.externalLink]" [attr.target]="lvl2.target" ><span class="title">{{ item.label | translate }}</span>
                      </a>
                  </li>
              </ul>
          </li>
      </ul>
      <div class="ghost-nav-dropdown" [ngStyle]="item.ghost"></div>
    </li>
  </ul>
  <ul #menuItemsList class="ghost-ul d-none d-lg-block">
      <li *ngFor="let item of menuItems">
          <a href="javascript:void(0);">
            <span class="title">{{ item.label | translate }}</span>
            <span  *ngIf="item.submenu" class=" arrow"></span>
          </a>
        </li>
  </ul>
  <ng-template [ngTemplateOutlet]="mobileSidebarFooter"></ng-template>
</div>
<div class="horizontal-menu-backdrop-dekstop" [style.display]="(currentItem?.open) ? 'block':'none'" (click)="closeHorizontalMenu()"></div>
<div class="horizontal-menu-backdrop" [style.display]="_horizontalMobileMenu ? 'block':'none'" (click)="toggleHorizontalMenu()"></div>