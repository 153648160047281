import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Area, AreasService, Location } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'search-area',
    templateUrl: './search-area.component.html',
    styleUrls: ['./search-area.component.scss']
})
export class SearchAreaComponent implements OnInit, OnDestroy {


  @Input() location: Location;
  @Input() area: Area;
  @Output() areaChange = new EventEmitter<Area>();
  areaList: Area[] = [];

  subscriptionList: Subscription[] = [];

  constructor(
    private areasService: AreasService
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
      this.subscriptionList.forEach(subscription => {
          if (subscription) { subscription.unsubscribe(); }
      });
    }

    filterAreaList() {
      this.subscriptionList.push(this.areasService.areasGetSearchList({locationID: this.location.ID, views: ['AREA','BIN']}).subscribe(pageData => {
          this.areaList = pageData.Items;
      }));
  }


}
