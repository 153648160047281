import { Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'mm-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MMToggleComponent),
      multi: true
    }
  ]
})
export class MMToggleComponent implements OnInit {
  @Input() toggleOffBackgroundColor: string = '#c9c9c9';
  @Input() toggleOnBackgroundColor: string = '#7252d3';
  @Input() disabled: boolean = false;
  toggleBackgroundColor: string = this.toggleOffBackgroundColor;

  isToggled: boolean = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  onToggle() {
    this.isToggled = !this.isToggled;

    this.toggleBackgroundColor = this.isToggled ? this.toggleOnBackgroundColor : this.toggleOffBackgroundColor;

    this.onChange(this.isToggled);
    this.onTouch(this.isToggled);
  }

  ngOnInit(): void {}

  writeValue(value: any): void {
    this.isToggled = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }
}
