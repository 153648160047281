/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ProjectApprover } from '../model/projectApprover';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ProjectApproversServiceInterface, IprojectApproversCreateParams,IprojectApproversDeleteParams,IprojectApproversGetApprovalRouteParams,IprojectApproversGetListParams,IprojectApproversGetListWithStockTransferHeadersParams,IprojectApproversGetNotifierListParams,IprojectApproversGetSearchListParams,IprojectApproversUpdateParams,IprojectApproversUpdateByListParams, }                            from './projectApprovers.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ProjectApproversService implements ProjectApproversServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectApproversCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ProjectApprover>;
    public projectApproversCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectApproversCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ProjectApprover>>;
    public projectApproversCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectApproversCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ProjectApprover>>;
    @datastore
    public projectApproversCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectApproversCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling projectApproversCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ProjectApprover>(`${this.basePath}/ProjectApprovers`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectApproverID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversDelete({ deviceId, message, ignoreLoadingBar, formData, projectApproverID } : IprojectApproversDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public projectApproversDelete({ deviceId, message, ignoreLoadingBar, formData, projectApproverID } : IprojectApproversDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public projectApproversDelete({ deviceId, message, ignoreLoadingBar, formData, projectApproverID } : IprojectApproversDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public projectApproversDelete({ deviceId, message, ignoreLoadingBar, formData, projectApproverID } : IprojectApproversDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectApproverID === null || projectApproverID === undefined) {
            throw new Error('Required parameter projectApproverID was null or undefined when calling projectApproversDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ProjectApprovers/${encodeURIComponent(String(projectApproverID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param approvalType 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversGetApprovalRoute({ deviceId, message, ignoreLoadingBar, formData, projectID, approvalType, views } : IprojectApproversGetApprovalRouteParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectApprover>>;
    public projectApproversGetApprovalRoute({ deviceId, message, ignoreLoadingBar, formData, projectID, approvalType, views } : IprojectApproversGetApprovalRouteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectApprover>>>;
    public projectApproversGetApprovalRoute({ deviceId, message, ignoreLoadingBar, formData, projectID, approvalType, views } : IprojectApproversGetApprovalRouteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectApprover>>>;
    @datastore
    public projectApproversGetApprovalRoute({ deviceId, message, ignoreLoadingBar, formData, projectID, approvalType, views } : IprojectApproversGetApprovalRouteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectApproversGetApprovalRoute.');
        }
        if (approvalType === null || approvalType === undefined) {
            throw new Error('Required parameter approvalType was null or undefined when calling projectApproversGetApprovalRoute.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (approvalType !== undefined && approvalType !== null) {
            queryParameters = queryParameters.set('approvalType', <any>approvalType);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ProjectApprover>>(`${this.basePath}/ProjectApprovers/GetApprovalRoute`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectApproverID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversGetList({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, page, views } : IprojectApproversGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectApprover>>;
    public projectApproversGetList({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, page, views } : IprojectApproversGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectApprover>>>;
    public projectApproversGetList({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, page, views } : IprojectApproversGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectApprover>>>;
    @datastore
    public projectApproversGetList({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, page, views } : IprojectApproversGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectApproverID !== undefined && projectApproverID !== null) {
            queryParameters = queryParameters.set('projectApproverID', <any>projectApproverID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ProjectApprover>>(`${this.basePath}/ProjectApprovers`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectApproverID 
     * @param stockTransferHeaderID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversGetListWithStockTransferHeaders({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, stockTransferHeaderID, page, views } : IprojectApproversGetListWithStockTransferHeadersParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectApprover>>;
    public projectApproversGetListWithStockTransferHeaders({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, stockTransferHeaderID, page, views } : IprojectApproversGetListWithStockTransferHeadersParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectApprover>>>;
    public projectApproversGetListWithStockTransferHeaders({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, stockTransferHeaderID, page, views } : IprojectApproversGetListWithStockTransferHeadersParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectApprover>>>;
    @datastore
    public projectApproversGetListWithStockTransferHeaders({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, stockTransferHeaderID, page, views } : IprojectApproversGetListWithStockTransferHeadersParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectApproverID === null || projectApproverID === undefined) {
            throw new Error('Required parameter projectApproverID was null or undefined when calling projectApproversGetListWithStockTransferHeaders.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stockTransferHeaderID !== undefined && stockTransferHeaderID !== null) {
            queryParameters = queryParameters.set('stockTransferHeaderID', <any>stockTransferHeaderID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ProjectApprover>>(`${this.basePath}/ProjectApprovers/${encodeURIComponent(String(projectApproverID))}/StockTransferHeaders`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param emailNotification 
     * @param appNotification 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversGetNotifierList({ deviceId, message, ignoreLoadingBar, formData, projectID, emailNotification, appNotification, views } : IprojectApproversGetNotifierListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectApprover>>;
    public projectApproversGetNotifierList({ deviceId, message, ignoreLoadingBar, formData, projectID, emailNotification, appNotification, views } : IprojectApproversGetNotifierListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectApprover>>>;
    public projectApproversGetNotifierList({ deviceId, message, ignoreLoadingBar, formData, projectID, emailNotification, appNotification, views } : IprojectApproversGetNotifierListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectApprover>>>;
    @datastore
    public projectApproversGetNotifierList({ deviceId, message, ignoreLoadingBar, formData, projectID, emailNotification, appNotification, views } : IprojectApproversGetNotifierListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectApproversGetNotifierList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (emailNotification !== undefined && emailNotification !== null) {
            queryParameters = queryParameters.set('emailNotification', <any>emailNotification);
        }
        if (appNotification !== undefined && appNotification !== null) {
            queryParameters = queryParameters.set('appNotification', <any>appNotification);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ProjectApprover>>(`${this.basePath}/ProjectApprovers/GetNotifierList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param locationID 
     * @param approvalType 
     * @param type 
     * @param approverAD 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, approvalType, type, approverAD, page, take, views } : IprojectApproversGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectApprover>>;
    public projectApproversGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, approvalType, type, approverAD, page, take, views } : IprojectApproversGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectApprover>>>;
    public projectApproversGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, approvalType, type, approverAD, page, take, views } : IprojectApproversGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectApprover>>>;
    @datastore
    public projectApproversGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, approvalType, type, approverAD, page, take, views } : IprojectApproversGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectApproversGetSearchList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (approvalType !== undefined && approvalType !== null) {
            queryParameters = queryParameters.set('approvalType', <any>approvalType);
        }
        if (type !== undefined && type !== null) {
            queryParameters = queryParameters.set('type', <any>type);
        }
        if (approverAD !== undefined && approverAD !== null) {
            queryParameters = queryParameters.set('approverAD', <any>approverAD);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ProjectApprover>>(`${this.basePath}/ProjectApprovers/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectApproverID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversUpdate({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, model } : IprojectApproversUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public projectApproversUpdate({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, model } : IprojectApproversUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public projectApproversUpdate({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, model } : IprojectApproversUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public projectApproversUpdate({ deviceId, message, ignoreLoadingBar, formData, projectApproverID, model } : IprojectApproversUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectApproverID === null || projectApproverID === undefined) {
            throw new Error('Required parameter projectApproverID was null or undefined when calling projectApproversUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling projectApproversUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ProjectApprovers/${encodeURIComponent(String(projectApproverID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectApproversUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectApproversUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public projectApproversUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectApproversUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public projectApproversUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectApproversUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public projectApproversUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectApproversUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling projectApproversUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/ProjectApprovers/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
