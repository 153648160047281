import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { ApprovalService } from './api/approval.service';
import { ApprovalDetailsService } from './api/approvalDetails.service';
import { ApprovalHeadersService } from './api/approvalHeaders.service';
import { AreaMetricsService } from './api/areaMetrics.service';
import { AreasService } from './api/areas.service';
import { AttachmentsService } from './api/attachments.service';
import { BinsService } from './api/bins.service';
import { BudgetsService } from './api/budgets.service';
import { DeviceInfosService } from './api/deviceInfos.service';
import { ExcelsService } from './api/excels.service';
import { InventoryBalancesService } from './api/inventoryBalances.service';
import { InventoryPlansService } from './api/inventoryPlans.service';
import { IssuePlansService } from './api/issuePlans.service';
import { ItemAccessesService } from './api/itemAccesses.service';
import { ItemCategoriesService } from './api/itemCategories.service';
import { ItemLotsService } from './api/itemLots.service';
import { ItemSubCategoriesService } from './api/itemSubCategories.service';
import { ItemVariantsService } from './api/itemVariants.service';
import { ItemsService } from './api/items.service';
import { JdeMappingsService } from './api/jdeMappings.service';
import { KeeperService } from './api/keeper.service';
import { LicensePlatesService } from './api/licensePlates.service';
import { LocationAccessesService } from './api/locationAccesses.service';
import { LocationsService } from './api/locations.service';
import { MailService } from './api/mail.service';
import { MovementsService } from './api/movements.service';
import { PlanningService } from './api/planning.service';
import { ProjectApproversService } from './api/projectApprovers.service';
import { ProjectInformationService } from './api/projectInformation.service';
import { ProjectTransferService } from './api/projectTransfer.service';
import { ProjectsService } from './api/projects.service';
import { QueryService } from './api/query.service';
import { ReceiptPlansService } from './api/receiptPlans.service';
import { ScheduleTaskService } from './api/scheduleTask.service';
import { SiteEntranceHistoryService } from './api/siteEntranceHistory.service';
import { SiteEntranceTransportsService } from './api/siteEntranceTransports.service';
import { SiteEntrancesService } from './api/siteEntrances.service';
import { StockLevelsService } from './api/stockLevels.service';
import { SupplierInformationService } from './api/supplierInformation.service';
import { TeamsService } from './api/teams.service';
import { TransportTypesService } from './api/transportTypes.service';
import { UserPreferenceService } from './api/userPreference.service';
import { UsersService } from './api/users.service';
import { ValuesService } from './api/values.service';
import { WarehouseDashboardService } from './api/warehouseDashboard.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    ApprovalService,
    ApprovalDetailsService,
    ApprovalHeadersService,
    AreaMetricsService,
    AreasService,
    AttachmentsService,
    BinsService,
    BudgetsService,
    DeviceInfosService,
    ExcelsService,
    InventoryBalancesService,
    InventoryPlansService,
    IssuePlansService,
    ItemAccessesService,
    ItemCategoriesService,
    ItemLotsService,
    ItemSubCategoriesService,
    ItemVariantsService,
    ItemsService,
    JdeMappingsService,
    KeeperService,
    LicensePlatesService,
    LocationAccessesService,
    LocationsService,
    MailService,
    MovementsService,
    PlanningService,
    ProjectApproversService,
    ProjectInformationService,
    ProjectTransferService,
    ProjectsService,
    QueryService,
    ReceiptPlansService,
    ScheduleTaskService,
    SiteEntranceHistoryService,
    SiteEntranceTransportsService,
    SiteEntrancesService,
    StockLevelsService,
    SupplierInformationService,
    TeamsService,
    TransportTypesService,
    UserPreferenceService,
    UsersService,
    ValuesService,
    WarehouseDashboardService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [
                { provide: Configuration, useFactory: configurationFactory },
                { provide: 'ApprovalService', useClass: ApprovalService }, 
                { provide: 'ApprovalDetailsService', useClass: ApprovalDetailsService }, 
                { provide: 'ApprovalHeadersService', useClass: ApprovalHeadersService }, 
                { provide: 'AreaMetricsService', useClass: AreaMetricsService }, 
                { provide: 'AreasService', useClass: AreasService }, 
                { provide: 'AttachmentsService', useClass: AttachmentsService }, 
                { provide: 'BinsService', useClass: BinsService }, 
                { provide: 'BudgetsService', useClass: BudgetsService }, 
                { provide: 'DeviceInfosService', useClass: DeviceInfosService }, 
                { provide: 'ExcelsService', useClass: ExcelsService }, 
                { provide: 'InventoryBalancesService', useClass: InventoryBalancesService }, 
                { provide: 'InventoryPlansService', useClass: InventoryPlansService }, 
                { provide: 'IssuePlansService', useClass: IssuePlansService }, 
                { provide: 'ItemAccessesService', useClass: ItemAccessesService }, 
                { provide: 'ItemCategoriesService', useClass: ItemCategoriesService }, 
                { provide: 'ItemLotsService', useClass: ItemLotsService }, 
                { provide: 'ItemSubCategoriesService', useClass: ItemSubCategoriesService }, 
                { provide: 'ItemVariantsService', useClass: ItemVariantsService }, 
                { provide: 'ItemsService', useClass: ItemsService }, 
                { provide: 'JdeMappingsService', useClass: JdeMappingsService }, 
                { provide: 'KeeperService', useClass: KeeperService }, 
                { provide: 'LicensePlatesService', useClass: LicensePlatesService }, 
                { provide: 'LocationAccessesService', useClass: LocationAccessesService }, 
                { provide: 'LocationsService', useClass: LocationsService }, 
                { provide: 'MailService', useClass: MailService }, 
                { provide: 'MovementsService', useClass: MovementsService }, 
                { provide: 'PlanningService', useClass: PlanningService }, 
                { provide: 'ProjectApproversService', useClass: ProjectApproversService }, 
                { provide: 'ProjectInformationService', useClass: ProjectInformationService }, 
                { provide: 'ProjectTransferService', useClass: ProjectTransferService }, 
                { provide: 'ProjectsService', useClass: ProjectsService }, 
                { provide: 'QueryService', useClass: QueryService }, 
                { provide: 'ReceiptPlansService', useClass: ReceiptPlansService }, 
                { provide: 'ScheduleTaskService', useClass: ScheduleTaskService }, 
                { provide: 'SiteEntranceHistoryService', useClass: SiteEntranceHistoryService }, 
                { provide: 'SiteEntranceTransportsService', useClass: SiteEntranceTransportsService }, 
                { provide: 'SiteEntrancesService', useClass: SiteEntrancesService }, 
                { provide: 'StockLevelsService', useClass: StockLevelsService }, 
                { provide: 'SupplierInformationService', useClass: SupplierInformationService }, 
                { provide: 'TeamsService', useClass: TeamsService }, 
                { provide: 'TransportTypesService', useClass: TransportTypesService }, 
                { provide: 'UserPreferenceService', useClass: UserPreferenceService }, 
                { provide: 'UsersService', useClass: UsersService }, 
                { provide: 'ValuesService', useClass: ValuesService }, 
                { provide: 'WarehouseDashboardService', useClass: WarehouseDashboardService }
                
            ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
