<div class="input-group">
    <div class="input-group-prepend">
        <span class="input-group-text">
            <fa-icon [icon]="faDolly"></fa-icon>
        </span>
    </div>
    <ng-select
        class="flex-1"
        [addTag]="addTagFn"
        placeholder="{{ placeholder | translate }}"
        (focus)="onFocus()"
        (blur)="onBlur()"
        (clear)="clearSupplierList()"
        [items]="supplierList"
        [typeahead]="supplierTypeahead"
        [(ngModel)]="supplier"
        (ngModelChange)="onSupplierChange()"
        [clearable]="true"
        [appendTo]="'body'"
    >
        <ng-template ng-tag-tmp let-search="searchTerm">
            <b>{{ 'MANUALLY_INPUT_SUPPLIER' | translate }}</b
            >: {{ search }}
        </ng-template>

        <ng-template ng-label-tmp let-item="item">
            <span *ngIf="item.AddressBookNumber">{{ item.AddressBookNumber }} - </span
            ><span>{{ item.AddressBookName }}</span>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
            <span>{{ item.AddressBookNumber }} - {{ item.AddressBookName }}</span>
        </ng-template>
    </ng-select>
</div>
