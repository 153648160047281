import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService, ModalStatus } from '@material/web/shared/services';

@Component({
  selector: 'import-excel',
  templateUrl: './import-excel.component.html',
  styleUrls: ['./import-excel.component.scss']
})
export class ImportExcelComponent implements OnInit {

    _title = 'IMPORT_EXCEL';
    @Input() set title(value) {
        this._title = value;
    }
    @Input() uploadLink: string;
    @Input() authHeader: string;
    @Input() isShowErrorMessageModel: boolean =  true;
    // tslint:disable-next-line: no-output-native
    @Output() close = new EventEmitter<any>();
    @Output() afterImport = new EventEmitter<any>();

    constructor(
        private modalService: ModalService
    ) { }

    ngOnInit() { }

    closeModal() {
        this.close.emit();
    }

    handleChange(event) {
        if ((event.file.response == 'SUCCESS' || event.file.response === true) && event.file.status == 'complete') {
            this.modalService.showMessageModal(ModalStatus.success, 'OPERATION_SUCCESS', 'UPLOADED_SUCCESSFULLY').subscribe();
            this.closeModal();
            this.afterImport.emit();
        } else if (event.file.response && event.file.response != 'SUCCESS') {
            if (this.isShowErrorMessageModel){
                this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', event.file.response);
            }
            this.closeModal();
            this.afterImport.emit(event.file.response);
        }
    }

}
