import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalService, ModalStatus } from '../services/modal.service';

export abstract class GenericCanDeactivate {
    showModal: boolean;
    abstract canDeactivate(): Observable<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<GenericCanDeactivate> {
    constructor(
        private modalService: ModalService
    ) {}

    /**
     * Calls when the component has canDeactivate in the route
     * @param component
     */
    canDeactivate(component: GenericCanDeactivate): Observable<boolean> | boolean {

        if (component && component.showModal) {
            return this.modalService.showConfirmModal(
              ModalStatus.warning, 'LEAVE_PAGE', 'YOU_HAVE_UNSAVED_CHANGES_ARE_YOU_SURE_YOU_WANT_TO_LEAVE_THIS_PAGE').pipe(
                map(
                    (data: string) => {
                        if (data == 'confirm') {
                            return true;
                        } else {
                            return false;
                        }
                    }
                )
            );
        } else {
            //default allow to change navigation route
            return true;
        }

    }
}
