<div id="approvalConfirm">
    <div id="approvalConfirmTitle" class="d-flex flex-row justify-content-between" [ngClass]="[decision == DecisionType.APPROVED ? 'approveTitle' : 'rejectTitle']">
        <span>{{(decision == DecisionType.APPROVED ? 'APPROVE' : 'REJECT') | translate}}</span>
        <span class="cursor-pointer" (click)="closeModal()"><i class="pg-icon">close</i></span>
    </div>
    <div id="approvalConfirmComments">
        <textarea #CommentsTextarea [(ngModel)]="comments" placeholder="{{'COMMENT' | translate}}" autofocus="true"></textarea>
    </div>
    <div id="approvalConfirmSubmit">
        <div class="d-flex flex-row">
            <button (click)="confirm()" class="btn" [ngClass]="[decision == DecisionType.APPROVED ? 'btn-success' : 'btn-danger']">
                {{'CONFIRM' | translate}}
            </button>
        </div>
    </div>
</div>