import { Directive, HostBinding, Input, ElementRef } from '@angular/core';

@Directive({
  selector: 'img',
  host: {
    '(error)': 'defaultImage()',
    '(load)': 'load()',
    '[src]': 'src'
  }
})
export class ImageDefaultDirective {
  @Input() default: string = 'assets/images/items/unknown.png';
  @Input() src: string;
  @HostBinding('class') className: string;

  constructor(private el: ElementRef) {
   }
  
  defaultImage() {
    this.src = this.default;
    this.className = 'default-image';
    this.el.nativeElement.style.maxWidth = "100%";
  }

  load() {
    
  }

}
