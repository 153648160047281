<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'SEARCH_ITEM' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row d-flex flex-row">
        <div class="col my-auto">
            <div class="row mb-4">
                <div class="col-6">
                    
                    <search-category [componentType]="'item-variant'" [orientation]="'row'" [project]="currentProject" [(category)]="selectedCategory"
                        (categoryChange)="onCategoryChange($event)">
                    </search-category>
                    <search-subcategory [componentType]="'item-variant'" [orientation]="'row'" [project]="currentProject" [category]="selectedCategory" [(subCategory)]="selectedSubCategory"
                        (subCategoryChange)="onSubCategoryChange($event)">
                    </search-subcategory>
                    <search-item [componentType]="'item-variant'" [orientation]="'row'" [project]="currentProject" [category]="selectedCategory" [subCategory]="selectedSubCategory"
                        [(item)]="selectedItem" (onItemChange)="onItemChange($event)">
                    </search-item>
                    
                </div>

                <div class="col-6">
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'UNIVERSAL_PRODUCT_CODE' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control" type="text" [(ngModel)]="universalProductCode" />
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'GAMMON_PRODUCT_CODE' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control" type="text" [(ngModel)]="gammonProductCode" />
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'MODEL' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input [(ngModel)]="model" [typeahead]="dataSourceItemVariants" 
                            typeaheadOptionField="ModelNo" [typeaheadScrollable]="true" [typeaheadOptionsInScrollableView]="10" [adaptivePosition]="true" class="form-control">
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'DESCRIPTION' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control" type="text" [(ngModel)]="description" />
                        </div>
                    </div>
                    <div class="row py-1 pull-right pr-3">
                        <button class="btn btn-complete w-abs-120" (click)="searchItemVariant()">{{ 'SEARCH' | translate }}</button>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="table-responsive" style="border-radius: 5px;">
                        <p-table
                            [lazy]="isLazyLoad"
                            (onLazyLoad)="loadItemVariantLazy($event)"
                            selectionMode="multiple"
                            [value]="searchItemVariantList"
                            [responsive]="true"
                            [scrollable]="true"
                            [paginator]="true"
                            [rows]="this.tableTakeRows"
                            [totalRecords]="totalRecords"
                           
                            scrollHeight="250px"
                            [(selection)]="selectionResultList"
                            dataKey="ID" #dt>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width: 60px">
                                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                    </th>

                                    <th>{{ 'ITEM_CODE' | translate }}</th>
                                    <th>{{ 'DESCRIPTION' | translate }}</th>
                                    <th>{{ 'CHINESE_DESCRIPTION' | translate }}</th>
                                    <th>{{ 'BRAND' | translate }}</th>
                                    <th>{{ 'MODEL' | translate}}</th>
                                    <th>{{ 'SIZE' | translate }}</th>
                                    <th>{{ 'REMARK' | translate }}</th>
                                    <th>{{ 'UNIT' | translate }}</th>
                                    <!-- <th>{{ 'UNIVERSAL_PRODUCT_CODE' | translate }}</th> -->
                                    <th>{{ 'GAMMON_PRODUCT_CODE' | translate }}</th>
                                    <th>{{ 'ITEM_TYPE' | translate }}</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-ri="rowIndex">
                                <tr [pSelectableRow]="item">
                                    <td style="width: 60px" [pSelectableRow]="item">
                                        <p-tableCheckbox [value]="item"></p-tableCheckbox>
                                    </td>
                                    <td class="fs-12">{{ item.Item.ItemCode }}</td>
                                    <td class="fs-12">{{ item.Item.ItemEnglish }}</td>
                                    <td class="fs-12">{{ item.Item.ItemChinese }}</td>
                                    <td class="fs-12">{{ item.Brand }}</td>
                                    <td class="fs-12">{{ item.ModelNo }}</td>
                                    <td class="fs-12">{{ item.Size }}</td>
                                    <td class="fs-12">{{ item.Remark }}</td>
                                    <td class="fs-12">{{ item.Item.UOM }}</td>
                                    <!-- <td class="fs-12">Empty</td> -->
                                    <td class="fs-12">{{ item.GammonProductCode }}</td>
                                    <td class="fs-12">
                                        {{ determineItemType(item.Item.ItemSubCategory.ItemCategory)|translate }} 
                                        <span *ngIf="item.Item.ItemSubCategory.ItemCategory.Project">{{(item.Item.ItemSubCategory.ItemCategory.Project||{}).ProjectNumber || ''}}</span>
                                        <span *ngIf="item.Item.ItemSubCategory.ItemCategory.DivisionCode">{{item.Item.ItemSubCategory.ItemCategory.DivisionCode}}</span>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>

                    <div class="d-flex flex-row justify-content-end">
                        <button class="btn btn-info mx-1" (click)="closeModal()">{{ 'CANCEL' | translate }}</button>
                        <button class="btn btn-primary mx-1" (click)="addItemVariant(); closeModal()">{{ 'SELECT' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


