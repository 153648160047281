import { NgModule } from '@angular/core';
import { MessageService } from '../@pages/components/message/message.service';
import { AppImportModules } from '../app-import.module';
import { ApprovalModalComponent } from './components/approval-modal/approval-modal.component';
import { ApprovalAddComponent } from './components/approval-add/approval-add.component';
import { ApprovalRouteComponent } from './components/approval-route/approval-route.component';
import { ApproverCardComponent } from './components/approver-card/approver-card.component';
import { DateBinKeeperComponent } from './components/date-bin-keeper/date-bin-keeper.component';
import { DecisionMapTableComponent } from './components/decision-map-table/decision-map-table.component';
import { DropdownByArrayComponent } from './components/dropdown-by-array/dropdown-by-array.component';
import { ImportExcelComponent } from './components/import-excel/import-excel.component';
import { ImportWordPdfComponent } from './components/import-word-pdf/import-word-pdf.component';
import { ModalTemplateComponent } from './components/modal-template/modal-template.component';
import { DecisionMapComponent } from './components/modal/decision-map/decision-map.component';
import { SearchStockLevelComponent } from './components/modal/search-stock-level/search-stock-level.component';
import { QrItemComponent } from './components/qr-item/qr-item.component';
import { RequestStatusTileComponent } from './components/request-status-tiles/request-status-tile/request-status-tile.component';
import { RequestStatusTilesComponent } from './components/request-status-tiles/request-status-tiles.component';
import { SearchADUserComponent } from './components/search-aduser/search-aduser.component';
import { SearchAreaComponent } from './components/search-area/search-area.component';
import { SearchBinLineComponent } from './components/search-bin-line/search-bin-line.component';
import { SearchBinComponent } from './components/search-bin/search-bin.component';
import { SearchCategoryComponent } from './components/search-category/search-category.component';
import { SearchInventoryBalanceComponent } from './components/search-inventory-balance/search-inventory-balance.component';
import { SearchItemVariantComponent } from './components/search-item-variant/search-item-variant.component';
import { SearchItemComponent } from './components/search-item/search-item.component';
import { SearchJobComponent } from './components/search-job/search-job.component';
import { SearchLocationComponent } from './components/search-location/search-location.component';
import { SearchSubcategoryComponent } from './components/search-subcategory/search-subcategory.component';
import { SearchUserComponent } from './components/search-user/search-user.component';
import { SelectDateComponent } from './components/select-date/select-date.component';
import { ApprovalListTabComponent } from './components/tabs/approval-list-tab/approval-list-tab.component';
import { ApprovalRouteTabComponent } from './components/tabs/approval-route-tab/approval-route-tab.component';
import { ItemsTabComponent } from './components/tabs/items-tab/items-tab.component';
import { LocationTabComponent } from './components/tabs/location-tab/location-tab.component';
import { ProjectsTabComponent } from './components/tabs/projects-tab/projects-tab.component';
import { RequestItemsTabComponent } from './components/tabs/request-items-tab/request-items-tab.component';
import { RequestTabComponent } from './components/tabs/request-tab/request-tab.component';
import { SummaryTabComponent } from './components/tabs/summary-tab/summary-tab.component';
import { SearchSupplierComponent } from './components/search-supplier/search-supplier.component';
import { AttachmentsTabComponent } from './components/tabs/attachments-tab/attachments-tab.component';
import { SearchTeamComponent } from './components/search-team/search-team.component';
import { IssuedConfirmationHistoryComponent } from './components/issued-confirmation-history/issued-confirmation-history.component';
import { TypeaheadModule } from 'ngx-bootstrap';

import { UserCardComponent } from './components/user-card/user-card.component';
import { ImageDefaultDirective } from './directives/image-default.directive';
import { VisibilityByRoleDirective } from './directives/visibility-by-role.directive';
import { DecimalPrecisionDirective } from './directives/decimal-precision.directive';
import { CanDeactivateGuard, RedirectGuard } from './guards';
import { AbsolutePipe } from './pipes/absolute.pipe';
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { ADUserPipe } from './pipes';
import { CustomDatepickerComponent } from './components/custom-datepicker/custom-datepicker/custom-datepicker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SearchSiteEntranceComponent } from './components/search-site-entrance/search-site-entrance.component';
import { SkeletonComponent } from './components/skeleton/skeleton.component';
import { SearchSiteEntranceTransportComponent } from './components/search-site-entrance-transport/search-site-entrance-transport.component';
import { MMToggleComponent } from './components/toggle/toggle.component';

const DIRECTIVES = [ImageDefaultDirective, VisibilityByRoleDirective, DecimalPrecisionDirective];

const GUARDS = [RedirectGuard, CanDeactivateGuard];

const PIPES = [AbsolutePipe, CapitalizePipe, SanitizePipe, ADUserPipe];

const COMPONENTS = [
  ApprovalModalComponent,
  ApprovalAddComponent,
  ApproverCardComponent,
  ApprovalListTabComponent,
  ApprovalRouteComponent,
  ApprovalRouteTabComponent,
  DecisionMapComponent,
  DecisionMapTableComponent,
  ProjectsTabComponent,
  ImportExcelComponent,
  ImportWordPdfComponent,
  ItemsTabComponent,
  SummaryTabComponent,
  LocationTabComponent,
  AttachmentsTabComponent,
  ModalTemplateComponent,
  QrItemComponent,
  SearchItemVariantComponent,
  SearchInventoryBalanceComponent,
  SearchBinComponent,
  SearchStockLevelComponent,
  RequestTabComponent,
  RequestStatusTileComponent,
  RequestStatusTilesComponent,
  RequestItemsTabComponent,
  UserCardComponent,
  SearchADUserComponent,
  SearchUserComponent,
  SearchJobComponent,
  SearchLocationComponent,
  SearchAreaComponent,
  SearchBinLineComponent,
  DateBinKeeperComponent,
  SearchCategoryComponent,
  SearchSubcategoryComponent,
  SearchItemComponent,
  SelectDateComponent,
  DropdownByArrayComponent,
  CustomDatepickerComponent,
  SearchSupplierComponent,
  SearchTeamComponent,
  IssuedConfirmationHistoryComponent,
  SearchSiteEntranceComponent,
  SkeletonComponent,
  SearchSiteEntranceTransportComponent,
  MMToggleComponent
];

const DECLARATIONS = [...DIRECTIVES, ...PIPES, ...COMPONENTS];

const IMPORTS = [AppImportModules, FontAwesomeModule, TypeaheadModule.forRoot()];

const EXPORTS = [...DECLARATIONS];

const PROVIDERS = [MessageService];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...EXPORTS],
  providers: [...PROVIDERS, ...GUARDS]
})
export class InventoryWebSharedModule {}
