<div class="row">
    <div class="col">

        <form>
            <div class="form-group">
                <fieldset class="form-group">
                    <input #fileUpload type="file" (change)="fileChangeEvent($event)" style="display: none;"/>
                    <button (click)="fileUpload.click()" class="btn btn-primary btn-block">{{'SELECT_FILE'|translate}}</button>
                </fieldset>
            </div>
        </form>

        <div *ngFor="let attachment of attachmentList; index as i" style="position: relative;">
            <div class="card">
                <div class="card-body">
                    <div class="d-flex flex-row justify-content-between">
                        <div class="d-flex flex-column cursor-pointer" (mouseover)="previewAttachment(i)" (mouseleave)="previewIndex=null">
                            <span class="fs-24"><i class="fa " [ngClass]="'fa-'+getThumbnailByExtension(attachment.extension)"></i></span>
                            <span>{{attachment.extension}}</span>
                        </div>

                        <span class="my-auto">{{attachment.fileName}}</span>
                        
                        <div class="my-auto">
                            <button class="btn btn-primary h-abs-35 mx-1" (click)="downloadAttachment(attachment, true)">
                                <i class="fa fa-download mx-1"></i>
                                {{'DOWNLOAD'|translate}}
                            </button>

                            <button class="btn btn-danger h-abs-35 mx-1" (click)="removeAttachment(i)" *ngIf="!_folderID">
                                <i class="fa fa-trash mx-1"></i>
                                {{'REMOVE'|translate}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div style="position: absolute; left: 80px; top: 20px; z-index: 100000" *ngIf="previewIndex===i">
                <img [src]="('data:image/*;base64,'+attachment.base64) | sanitize: 'resourceUrl'" style="max-width: 300px; max-height: 300px;" *ngIf="isImage(attachment.extension);"/>
            </div>

        </div>


    </div>
</div>

