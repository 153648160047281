/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ADUser } from '../model/aDUser';
import { UserSecurity } from '../model/userSecurity';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { UsersServiceInterface, IusersGetListByQueryParams,IusersGetUserByADParams,IusersGetUserByEmployeeIDParams,IusersGetUserSecurityParams,IusersGetUsersByADsParams, }                            from './users.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class UsersService implements UsersServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param query 
     * @param page 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetListByQuery({ deviceId, message, ignoreLoadingBar, formData, query, page } : IusersGetListByQueryParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ADUser>>;
    public usersGetListByQuery({ deviceId, message, ignoreLoadingBar, formData, query, page } : IusersGetListByQueryParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ADUser>>>;
    public usersGetListByQuery({ deviceId, message, ignoreLoadingBar, formData, query, page } : IusersGetListByQueryParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ADUser>>>;
    @datastore
    public usersGetListByQuery({ deviceId, message, ignoreLoadingBar, formData, query, page } : IusersGetListByQueryParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (query === null || query === undefined) {
            throw new Error('Required parameter query was null or undefined when calling usersGetListByQuery.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (query !== undefined && query !== null) {
            queryParameters = queryParameters.set('query', <any>query);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ADUser>>(`${this.basePath}/Users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userAD 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetUserByAD({ deviceId, message, ignoreLoadingBar, formData, userAD } : IusersGetUserByADParams, observe?: 'body', reportProgress?: boolean): Observable<ADUser>;
    public usersGetUserByAD({ deviceId, message, ignoreLoadingBar, formData, userAD } : IusersGetUserByADParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ADUser>>;
    public usersGetUserByAD({ deviceId, message, ignoreLoadingBar, formData, userAD } : IusersGetUserByADParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ADUser>>;
    @datastore
    public usersGetUserByAD({ deviceId, message, ignoreLoadingBar, formData, userAD } : IusersGetUserByADParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userAD === null || userAD === undefined) {
            throw new Error('Required parameter userAD was null or undefined when calling usersGetUserByAD.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userAD !== undefined && userAD !== null) {
            queryParameters = queryParameters.set('userAD', <any>userAD);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ADUser>(`${this.basePath}/Users/GetUserByAD`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param EmployeeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetUserByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IusersGetUserByEmployeeIDParams, observe?: 'body', reportProgress?: boolean): Observable<ADUser>;
    public usersGetUserByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IusersGetUserByEmployeeIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ADUser>>;
    public usersGetUserByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IusersGetUserByEmployeeIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ADUser>>;
    @datastore
    public usersGetUserByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IusersGetUserByEmployeeIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (EmployeeID === null || EmployeeID === undefined) {
            throw new Error('Required parameter EmployeeID was null or undefined when calling usersGetUserByEmployeeID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (EmployeeID !== undefined && EmployeeID !== null) {
            queryParameters = queryParameters.set('EmployeeID', <any>EmployeeID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ADUser>(`${this.basePath}/Users/GetUserByEmployeeID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetUserSecurity({ deviceId, message, ignoreLoadingBar, formData,  } : IusersGetUserSecurityParams, observe?: 'body', reportProgress?: boolean): Observable<UserSecurity>;
    public usersGetUserSecurity({ deviceId, message, ignoreLoadingBar, formData,  } : IusersGetUserSecurityParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserSecurity>>;
    public usersGetUserSecurity({ deviceId, message, ignoreLoadingBar, formData,  } : IusersGetUserSecurityParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserSecurity>>;
    @datastore
    public usersGetUserSecurity({ deviceId, message, ignoreLoadingBar, formData,  } : IusersGetUserSecurityParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<UserSecurity>(`${this.basePath}/Users/UserSecurity`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param usernames 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetUsersByADs({ deviceId, message, ignoreLoadingBar, formData, usernames } : IusersGetUsersByADsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ADUser>>;
    public usersGetUsersByADs({ deviceId, message, ignoreLoadingBar, formData, usernames } : IusersGetUsersByADsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ADUser>>>;
    public usersGetUsersByADs({ deviceId, message, ignoreLoadingBar, formData, usernames } : IusersGetUsersByADsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ADUser>>>;
    @datastore
    public usersGetUsersByADs({ deviceId, message, ignoreLoadingBar, formData, usernames } : IusersGetUsersByADsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (usernames === null || usernames === undefined) {
            throw new Error('Required parameter usernames was null or undefined when calling usersGetUsersByADs.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (usernames) {
            usernames.forEach((element) => {
                queryParameters = queryParameters.append('usernames', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ADUser>>(`${this.basePath}/Users/GetUsersByADs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
