import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { Bin, Project } from '@gammon/inventory-api';
import { ActionType, CommonInventoryBalance, CommonItemVariant } from '@material/common';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';

@Component({
  selector: 'search-bin-line',
  templateUrl: './search-bin-line.component.html',
  styleUrls: ['./search-bin-line.component.scss'],
})
export class SearchBinLineComponent implements OnInit, OnDestroy {
  @Input() shortDesc = false;
  @Input() preventChange = false;
  @Input() actionType: ActionType;
  @Input() currentProject: Project;
  @Input() bin: Bin;
  @Input() excludeBin: Bin;
  @Input() item?: CommonInventoryBalance | CommonItemVariant;
  @Output() binChange = new EventEmitter<Bin>();

  subscriptionList: Subscription[] = [];
  bsModalRef: BsModalRef;

  @ViewChild('setLocationByLineModal')
  setLocationByLineModal: TemplateRef<any>;

  constructor(private bsModalService: BsModalService) {}

  ngOnInit() {}

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  showSearchBin() {
    const config: ModalOptions = {
      ignoreBackdropClick: true,
      class: 'modal-xl'
    };
    this.bsModalRef = this.bsModalService.show(
      this.setLocationByLineModal,
      config
    );
  }

  onBinChange(bin: Bin) {
    this.binChange.emit(bin);
  }

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

}
