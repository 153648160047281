import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'desc', pure: false })
export class DescPipe implements PipeTransform {
  result: string;
  translated: { [key: string]: string } = {};
  id: number;
  constructor(private translate: TranslateService, private cdr: ChangeDetectorRef) {}

  transform(obj: any, desc: string, alt: string = 'description', type: 'DESC' | 'BIN_DESC' = 'DESC'): any {
    if (obj) {
      if ((obj.id || obj.ID || obj.Name) === this.id && this.translated[this.translate.currentLang]) {
        this.result = this.translated[this.translate.currentLang];
        return this.result;
      } else {
        this.id = obj.id || obj.ID || obj.Name;
        switch (type) {
          case 'BIN_DESC':
            this.translate.get(['LOCATION_DESC', 'AREA_DESC', 'BIN_DESC']).subscribe((values) => {
              this.result = `${((obj.Area || {}).Location || {})[values.LOCATION_DESC] || ((obj.Area || {}).Location || {}).LocationEnglish} / ${
                (obj.Area || {})[values.AREA_DESC] || (obj.Area || {}).AreaEnglish
              } / ${obj[values.BIN_DESC] || obj.BinEnglish}`;
              this.translated[this.translate.currentLang] = this.result;
              this.cdr.markForCheck();
            });
            break;
          default:
            this.translate.get([desc]).subscribe((values) => {
              this.result = obj[values[desc]] || obj[alt] || '';
              this.translated[this.translate.currentLang] = this.result;
              this.cdr.markForCheck();
            });
            break;
        }
      }
    } else {
      this.result = '--';
    }
    return this.result;
  }
}
