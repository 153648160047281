import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Area, AreasService, Bin, BinsService, Location, LocationsService, Project } from '@gammon/inventory-api';
import { ActionType, ActionTypeEnum } from '@material/common';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { GeneralService } from '../../../services/general.service';
import { ModalService, ModalStatus } from '../../../services/modal.service';

@Component({
    selector: 'location-tab',
    templateUrl: './location-tab.component.html',
    styleUrls: ['./location-tab.component.scss']
})
export class LocationTabComponent implements OnInit, OnDestroy {

    @Input() actionType: ActionType;
    @Input() currentProject: Project;

    @Output() binChange = new EventEmitter<Bin>();
    @Output() areaChange = new EventEmitter<Area>();
    @Output() locationChange = new EventEmitter<Location>();

    quickSearchBinInput: string;
    term$ = new Subject<string>();
    searchBinSubscription: Subscription;
    subscriptionList: Subscription[] = [];

    locationList: Location[] = [];
    selectedLocation: Location;

    areaList: Area[] = [];
    selectedArea: Area;

    binList: Bin[] = [];
    selectedBin: Bin;

    constructor(
        private generalService: GeneralService,
        private modalService: ModalService,
        private locationsService: LocationsService,
        private areasService: AreasService,
        private binsService: BinsService
    ) { }

    ngOnInit() {
        this.searchBinSubscription = this.term$.pipe(debounceTime(500), switchMap(term => {
            //The search should be done in quickSearchBin()
            this.quickSearchBin();

            return EMPTY;
        })).subscribe();
    }

    quickSearchBin() {
        //Reset selections
        this.resetSelections();

        if (!this.quickSearchBinInput || this.quickSearchBinInput.length == 0) { return; }

        if (this.generalService.parseQrCodeApplicationCode(this.quickSearchBinInput) == 'MM') {
            if (this.generalService.parseQrCodeType(this.quickSearchBinInput) == 'LOC') {
                //start parsing
                const binID = this.generalService.parseQrCodeID(this.quickSearchBinInput);

                //Call api to search bin by bin ID
                this.subscriptionList.push(this.binsService.binsGetBinByID({binID: +binID, views: ['AREA', 'LOCATION']}).subscribe(data => {
                    console.log('Quick Search Bin: ', data);
                    if (data && data.Area.Location.ProjectID == this.currentProject.ID) {
                        this.selectedBin = data;
                        this.selectedArea = data.Area;
                        this.selectedLocation = data.Area.Location;
                    } else {
                        this.selectedBin = null;
                        this.selectedArea = null;
                        this.selectedLocation = null;

                        this.quickSearchBinInput = null;
                        this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'BIN_DOES_NOT_EXIST');
                    }

                    this.binChange.emit(this.selectedBin);
                    this.areaChange.emit(this.selectedArea);
                    this.locationChange.emit(this.selectedLocation);
                }));

            } else {
                this.modalService.showNotification(ModalStatus.danger, 'INVALID_QR_CODE', 2000);
            }
        } else {
            this.modalService.showNotification(ModalStatus.danger, 'INVALID_QR_CODE', 2000);
        }

    }

    resetSelections() {
        this.selectedBin = null;
        this.selectedArea = null;
        this.selectedLocation = null;
        this.binChange.emit(this.selectedBin);
        this.areaChange.emit(this.selectedArea);
        this.locationChange.emit(this.selectedLocation);
    }

    searchLocationList() {
        this.subscriptionList.push(this.locationsService.locationsGetSearchList({projectID: this.currentProject.ID, views: ['AREA', 'BIN']}).subscribe(pageData => {
            if (pageData.Items) {
                this.locationList = pageData.Items;
            }
        }));
    }

    searchAreaList() {
        this.subscriptionList.push(this.areasService.areasGetSearchList({locationID: this.selectedLocation.ID}).subscribe(pageData => {
            if (pageData.Items) {
                this.areaList = pageData.Items;
            }
        }));
    }

    searchBinList() {
        this.subscriptionList.push(this.binsService.binsGetSearchList({areaID: this.selectedArea.ID, views: ['AREA', 'LOCATION']}).subscribe(pageData => {
            if (pageData.Items) {
                this.binList = pageData.Items;
            }
        }));
    }

    changeLocation() {
        this.locationChange.emit(this.selectedLocation);

        if (this.selectedLocation.Areas.length == 1) {
            this.selectedArea = this.selectedLocation.Areas[0];
            if (this.selectedArea.Bins.length == 1) {
                this.selectedBin = this.selectedArea.Bins[0];
            }
        } else {
            this.areaList = [];
            this.selectedArea = null;
            this.binList = [];
            this.selectedBin = null;
        }
        this.areaChange.emit(this.selectedArea);
        this.binChange.emit(this.selectedBin);
    }

    changeArea() {
        this.areaChange.emit(this.selectedArea);

        this.binList = [];
        this.selectedBin = null;
        this.binChange.emit(this.selectedBin);
    }

    changeBin() {
        this.binChange.emit(this.selectedBin);
    }

    reset() {
        switch (this.actionType) {
            case ActionTypeEnum.RECEIPT:
                this.quickSearchBinInput = null;
                this.resetSelections();
                break;
            case ActionTypeEnum.ISSUE:
                break;
            case ActionTypeEnum.CHANGE_LOCATION:
                this.quickSearchBinInput = null;
                this.resetSelections();
                break;
        }
    }

    ngOnDestroy() {
        this.searchBinSubscription.unsubscribe();

        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }
}
