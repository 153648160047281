<div [ngClass]="{'row py-1': orientation == 'row', 'd-flex flex-column mb-2': orientation == 'column'}">
  <div class="my-auto" [ngClass]="{'col-auto': orientation == 'column', 'col-md-3 col-sm-3 col-lg-3': orientation == 'row'}">
      <span class="fs-12">{{ 'ITEM' | translate }}</span>
  </div>
  <div class="my-auto" [ngClass]="{'col-auto': orientation == 'column', 'col-8': orientation == 'row'}">
      <ng-select style="width: 100%" (open)="searchItems();" [items]="searchItemList" appendTo="body"
          [(ngModel)]="item" (ngModelChange)="onItemChange($event)"
          [disabled]="!category || !subCategory">

          <ng-template ng-label-tmp let-item="item">
              <span>{{item.ItemCode}} - {{ item['ITEM_DESC'|translate] }}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
              <span>{{item.ItemCode}} - {{ item['ITEM_DESC'|translate] }}</span>
          </ng-template>

      </ng-select>

  </div>
</div>
