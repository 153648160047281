import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'request-status-tile',
  templateUrl: './request-status-tile.component.html',
  styleUrls: ['./request-status-tile.component.scss']
})
export class RequestStatusTileComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() number: number = 0;
  @Input() size: 'sm' | 'md' = 'md';
  @Input() icon: IconDefinition;
  @Input() color: 'success' | 'danger' | 'warning' | 'complete' | 'info' = 'info';

  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
