import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Item, ItemCategory, ItemsService, ItemSubCategory, Project } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';
import { ShareStateService } from '../../services/share-state.service';

@Component({
  selector: 'search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss']
})
export class SearchItemComponent implements OnInit, OnDestroy {

  @Input() project: Project;  
  @Input() subCategory: ItemSubCategory;
  @Input() category: ItemCategory;
  @Input() item: Item;
  @Input() orientation: 'row'|'column';
  @Output() itemChange = new EventEmitter<Item>();
  @Input() componentType: 'item-variant' | 'inventory-balance' = 'inventory-balance';
  subscriptionList: Subscription[] = [];
  subscriptionItem: Subscription;
  subscriptionSelectedItem: Subscription;
  searchItemList: Item[] = [];

  constructor(
    private itemsService: ItemsService,
    private shareStateService: ShareStateService
  ) {
    this.subscriptionItem = this.shareStateService.itemListObservable.subscribe(item => {
      this.searchItemList = item as Item[];
    });
    this.subscriptionSelectedItem = this.shareStateService.selectedItemObservable.subscribe(selectionItem => {
      this.item = selectionItem;
    });
  }

  ngOnInit() {
  }

  searchItems() {
    // this.subscriptionList.push(
      // this.itemsService.itemsGetSearchList({ subCategoryID: this.subCategory.ID })
      // .subscribe(pageData => {
      //   if (pageData.Items) {
      //     this.searchItemList = pageData.Items;
      //   }
      // })
    // );
      switch (this.componentType) {
        case 'item-variant': // search from item variant (e.g. receipt)
          this.itemsService.itemsGetSearchList({ subCategoryID: this.subCategory.ID })
          .subscribe(pageData => {
              if (pageData.Items) { this.searchItemList = pageData.Items; }
          });
          break;
        default: // search from inventory balance (e.g. issue, change-location, etc...)
            this.subscriptionList.push(
                this.itemsService.itemsGetListWithIbQtyGreaterThanZero({
                  projectID: this.project.ID,
                  subCategoryID: this.subCategory.ID
                }).subscribe(items => {
                    if (items) {
                      this.searchItemList = items;
                    }
                })
            );
            break;
        }
  }

  onItemChange(event) {
    this.itemChange.emit(event);
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(subscription => {
      if (subscription) { subscription.unsubscribe(); }
    });
  }
}
