import { Directive, Input, ElementRef, OnInit, Renderer2, ViewContainerRef, TemplateRef } from '@angular/core';
import { Router, ActivatedRoute, Data } from '@angular/router';
import { CommonSecurityService } from '@gammon/common';
import { BehaviorSubject } from 'rxjs';

@Directive({
  selector: '[ShowByRoles]'
})
export class VisibilityByRoleDirective implements OnInit {
  @Input() ShowByRoles: string[] = [];
  @Input() ShowByRolesRolesCondition: 'some' | 'every' = 'some';
  templateAdded: boolean;
  constructor(
    private el: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private snapshot: ActivatedRoute,
    private commonSecurityService: CommonSecurityService,
    private router: Router) {  }

  ngOnInit(): void {
    const self = this;
    self.commonSecurityService.RolesAsync(self.ShowByRoles, self.ShowByRolesRolesCondition)
      .subscribe(shouldShow => {
        if (!shouldShow) {
          // self.el.nativeElement.style.visibility = 'hidden';
          // self.el.nativeElement.remove();
          self.viewContainer.clear();
          self.templateAdded = false;
          // if(!self.mmaGuard.allowActiveByFunction((self.snapshot.data as BehaviorSubject<Data>).value["roles"] || [])) {
          //   console.info(`ShowByRoles: require ${self.ShowByRoles} redirect to /`);
          //   self.router.navigate(['/']);
          // }
        } else {
          // self.el.nativeElement.style.visibility = '';
          if (!self.templateAdded) {
            self.viewContainer.createEmbeddedView(self.templateRef);
            self.templateAdded = true;
          }
        }
      });
  }
}
