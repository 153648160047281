import { Component, Input, OnInit } from '@angular/core';
import { SkeletonType } from '../../models';

@Component({
  selector: 'skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {
  @Input() type: SkeletonType = SkeletonType.INPUT;

  ngOnInit() {}
}
