/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PageSiteEntrance } from '../model/pageSiteEntrance';
import { SiteEntrance } from '../model/siteEntrance';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { SiteEntrancesServiceInterface, IsiteEntrancesCreateParams,IsiteEntrancesDeleteParams,IsiteEntrancesSearchSiteEntrancesParams,IsiteEntrancesUpdateParams,IsiteEntrancesUpdateByListParams, }                            from './siteEntrances.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class SiteEntrancesService implements SiteEntrancesServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public siteEntrancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IsiteEntrancesCreateParams, observe?: 'body', reportProgress?: boolean): Observable<SiteEntrance>;
    public siteEntrancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IsiteEntrancesCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SiteEntrance>>;
    public siteEntrancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IsiteEntrancesCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SiteEntrance>>;
    @datastore
    public siteEntrancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IsiteEntrancesCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling siteEntrancesCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<SiteEntrance>(`${this.basePath}/SiteEntrances`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param siteEntranceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public siteEntrancesDelete({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID } : IsiteEntrancesDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public siteEntrancesDelete({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID } : IsiteEntrancesDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public siteEntrancesDelete({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID } : IsiteEntrancesDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public siteEntrancesDelete({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID } : IsiteEntrancesDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (siteEntranceID === null || siteEntranceID === undefined) {
            throw new Error('Required parameter siteEntranceID was null or undefined when calling siteEntrancesDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/SiteEntrances/${encodeURIComponent(String(siteEntranceID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param siteEntranceID 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public siteEntrancesSearchSiteEntrances({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, page, size, views } : IsiteEntrancesSearchSiteEntrancesParams, observe?: 'body', reportProgress?: boolean): Observable<PageSiteEntrance>;
    public siteEntrancesSearchSiteEntrances({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, page, size, views } : IsiteEntrancesSearchSiteEntrancesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSiteEntrance>>;
    public siteEntrancesSearchSiteEntrances({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, page, size, views } : IsiteEntrancesSearchSiteEntrancesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSiteEntrance>>;
    @datastore
    public siteEntrancesSearchSiteEntrances({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, page, size, views } : IsiteEntrancesSearchSiteEntrancesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling siteEntrancesSearchSiteEntrances.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (siteEntranceID !== undefined && siteEntranceID !== null) {
            queryParameters = queryParameters.set('siteEntranceID', <any>siteEntranceID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageSiteEntrance>(`${this.basePath}/SiteEntrances/SearchSiteEntrances`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param siteEntranceID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public siteEntrancesUpdate({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID, model } : IsiteEntrancesUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public siteEntrancesUpdate({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID, model } : IsiteEntrancesUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public siteEntrancesUpdate({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID, model } : IsiteEntrancesUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public siteEntrancesUpdate({ deviceId, message, ignoreLoadingBar, formData, siteEntranceID, model } : IsiteEntrancesUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (siteEntranceID === null || siteEntranceID === undefined) {
            throw new Error('Required parameter siteEntranceID was null or undefined when calling siteEntrancesUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling siteEntrancesUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/SiteEntrances/${encodeURIComponent(String(siteEntranceID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public siteEntrancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IsiteEntrancesUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public siteEntrancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IsiteEntrancesUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public siteEntrancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IsiteEntrancesUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public siteEntrancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IsiteEntrancesUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling siteEntrancesUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/SiteEntrances/UpdateByList`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
