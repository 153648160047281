import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Item, ItemCategoriesService, ItemCategory, ItemsService, ItemSubCategoriesService, ItemSubCategory, ItemVariantsService, Location, LocationsService, Project } from '@gammon/inventory-api';
import { StockLevelItemVariant } from '@material/web/shared';
import { StockLevelAlertType, StockLevelType } from '@material/web/shared/models';
import { LazyLoadEvent } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
    selector: 'search-stock-level',
    templateUrl: './search-stock-level.component.html',
    styleUrls: ['./search-stock-level.component.scss']
})
export class SearchStockLevelComponent implements OnInit, OnDestroy {

    @Input() currentProject: Project;
    @Output() close = new EventEmitter<any>();
    @Output() add = new EventEmitter<any>();

    subscriptionList: Subscription[] = [];

    //Table
    selectionResultList: StockLevelItemVariant[] = []; //mutated ItemVariant[] with AlertLevel in each ItemVariant
    itemResultList: StockLevelItemVariant[] = [];

    tableRowOffset: number;
    tableRowCount: number;
    totalRecords = 0;
    tablePage: number;

    _stockLevelType: StockLevelType = 'PROJECT';
    @Input() set stockLevelType(value: StockLevelType) {
        if (!value) {
            this._stockLevelType = 'PROJECT';
        } else {
            this._stockLevelType = value;
        }
    }
    @Input() alertType: StockLevelAlertType = 'LOW_INVENTORY_LEVEL';

    searchCategoryList: ItemCategory[] = [];
    selectedCategory: ItemCategory = null;

    searchSubCategoryList: ItemSubCategory[] = [];
    selectedSubCategory: ItemSubCategory = null;

    searchItemList: Item[] = [];
    selectedItem: Item = null;

    searchLocationList: Location[] = [];
    selectedLocation: Location = null;

    constructor(
        private itemCategoriesService: ItemCategoriesService,
        private itemSubCategoriesService: ItemSubCategoriesService,
        private itemsService: ItemsService,
        private itemVariantsService: ItemVariantsService,
        private locationsService: LocationsService
    ) { }

    ngOnInit() {
        if(! this.alertType)
            this.alertType = 'LOW_INVENTORY_LEVEL';
    }

    closeModal() {
        this.close.emit();
        this.selectionResultList = [];
    }


    searchCategories() {
        this.subscriptionList.push(this.itemCategoriesService.itemCategoriesGetSearchList({ projectNumber: this.currentProject.ProjectNumber, by: 'PROJECT', withGlobal: true }).subscribe(pageData => {
            if (pageData.Items) {
                this.searchCategoryList = pageData.Items;
            }
        }));
    }

    searchSubCategories() {
        this.subscriptionList.push(this.itemSubCategoriesService.itemSubCategoriesGetSearchList({ categoryID: this.selectedCategory.ID }).subscribe(pageData => {
            if (pageData.Items) {
                this.searchSubCategoryList = pageData.Items;
            }
        }));
    }

    searchItems() {
        this.subscriptionList.push(this.itemsService.itemsGetSearchList({ subCategoryID: this.selectedSubCategory.ID }).subscribe(pageData => {
            if (pageData.Items) {
                this.searchItemList = pageData.Items;
            }
        }));
    }

    searchLocations() {
        this.subscriptionList.push(this.locationsService.locationsGetSearchList({ projectID: this.currentProject.ID }).subscribe(pageData => {
            if (pageData.Items) {
                this.searchLocationList = pageData.Items;
            }
        }));
    }

    onCategoryChange(event) {
        this.selectedCategory = event;
    }

    onSubCategoryChange(event) {
        this.selectedSubCategory = event;
    }

    onItemChange(event) {
        this.selectedItem = event;
        //auto search
        if (this._stockLevelType == 'PROJECT') {
            this.search();
        }
    }

    // selectionCategoryChange() {
    //     //Clear child
    //     this.searchSubCategoryList = [];
    //     this.selectedSubCategory = null;

    //     this.searchItemList = [];
    //     this.selectedItem = null;
    // }

    // selectionSubCategoryChange() {
    //     //Clear child
    //     this.searchItemList = [];
    //     this.selectedItem = null;
    // }

    // selectionItemChange() {
    //     //auto search
    //     if (this._stockLevelType == 'PROJECT') {
    //         this.search();
    //     }
    // }

    selectionLocationChange(event) {
        this.selectedLocation = event;

        //auto search
        this.search();
    }

    loadItemVariantLazy(event: LazyLoadEvent) {
        this.tableRowOffset = event.first;
        this.tableRowCount = event.rows;
        this.tablePage = event.first / event.rows + 1;

        this.search();
    }

    //This search the item variants of the project without any stock level
    search() {
        if (this.selectedItem) {
            this.itemVariantsService.itemVariantsGetListByItemExcludeExistsInStockLevel({
                stockLevelType: this._stockLevelType,
                alertType: this.alertType,
                itemID: this.selectedItem.ID,
                projectID: this.currentProject.ID,
                locationID: this._stockLevelType == 'LOCATION' ? this.selectedLocation.ID : null,
                page: this.tablePage,
                size: this.tableRowCount,
                views: ['ITEM', 'ITEM_VARIANT', 'LOCATION']
            }).subscribe(pageData => {
                this.itemResultList = [];
                if (pageData && pageData.Items) {
                    this.totalRecords = pageData.TotalItems;
                    this.itemResultList = pageData.Items as StockLevelItemVariant[];
                }
            });
        }
    }

    addStockLevels() {
        this.selectionResultList.forEach(mutatedItemVariant => {
            mutatedItemVariant.StockLevelType = this._stockLevelType;
            mutatedItemVariant.AlertType = this.alertType;
            //add missing location id
            if (this._stockLevelType == 'LOCATION') {
                mutatedItemVariant.LocationID = this.selectedLocation.ID;
            }
        });

        this.add.emit(this.selectionResultList); //emits a mutated ItemVariant[] with AlertLevel in each ItemVariant
        this.selectionResultList = [];

        this.closeModal();
    }

    disableSelectIv(): boolean {
        return this.selectionResultList.length == 0 || this.selectionResultList.some(iv => !iv['AlertLevel']);
    }

    clearSelection() {
        this.selectedCategory = null;
        this.searchCategoryList = [];

        this.selectedSubCategory = null;
        this.searchSubCategoryList = [];

        this.selectedItem = null;
        this.searchItemList = [];

        this.selectedLocation = null;
        this.searchLocationList = [];

        this.itemResultList = [];
        this.selectionResultList = [];

    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }

}
