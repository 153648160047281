import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataStoreModule } from '@gammon/data-store';
import { ApiModule as InventoryApi, BASE_PATH } from '@gammon/inventory-api';
import { GlobalErrorHandler, LoggingModule, LOG_CONFIG } from '@gammon/logging';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  AccordionModule,
  AlertModule,
  BsDatepickerModule,
  BsDropdownModule,
  ButtonsModule,
  CollapseModule,
  defineLocale,
  enGbLocale,
  ModalModule,
  ProgressbarModule,
  TabsModule,
  TooltipModule,
  zhCnLocale,
  TimepickerModule
} from 'ngx-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { createTranslateLoader, getApiBase, getApiConfig, getLogConfig, MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from 'projects/AppConfig';
import { AppImportModules, windowProvider } from './app-import.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InventoryWebSharedModule } from './shared/public-api';
import { Interceptor } from './shared/http-interceptor/interceptor';
import { CommonSecurityService } from '@gammon/common';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

defineLocale('zh-cn', zhCnLocale); // 日历显示中文
defineLocale('en-gb', enGbLocale); //日历显示英文

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AppImportModules,
    InventoryWebSharedModule,
    LoggingModule,
    DataStoreModule.forRoot(),
    InventoryApi.forRoot(getApiConfig),
    AccordionModule.forRoot(),
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    BsDatepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ButtonsModule.forRoot(),
    CollapseModule.forRoot(),
    LoadingBarHttpClientModule,
    ProgressbarModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TimepickerModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [NO_ERRORS_SCHEMA],
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOG_CONFIG, useFactory: getLogConfig },
    { provide: BASE_PATH, useFactory: getApiBase },
    { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
    windowProvider,
    { provide: 'CommonGuardSecurity', useExisting: CommonSecurityService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
