import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { faCalendar, faDownload, faFileAlt, faPlusCircle, faQrcode, faSearch, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import {
  ApprovalHeadersService,
  ApprovalService,
  Location,
  InventoryPlan,
  InventoryPlansService,
  Project,
  ProjectsService,
  SupplierInformation,
  SupplierInformationService,
  UsersService,
  AreasService,
  LocationsService,
  MovementsService,
  Team,
  TeamsService,
  PlanningService,
  SiteEntrance,
  ApprovalHeader,
  ADUser
} from '@gammon/inventory-api';
import { ActionTypeEnum, CommonIssuePlan, RequestType, RequestTypeEnum, ToggleValue } from '@material/common';
import { CommonInventoryPlan, InventoryPlanStatus } from '@material/common/models/CommonInventoryPlan';
import { ModalService, ModalStatus } from '@material/web/shared/services/modal.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { LazyLoadEvent } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { GeneralService } from '../../../services/general.service';
import { concatMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
/**
 *  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 *  |             This component only outputs the Request             |
 *  |   Request-Items-Tab will handle the items inside this request   |
 *  +++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
 */
@Component({
  selector: 'request-tab',
  templateUrl: './request-tab.component.html',
  styleUrls: ['./request-tab.component.scss']
})
export class RequestTabComponent implements OnInit, OnDestroy {
  @Input() actionType: RequestType;
  @Input() showAll = false;
  @Output() addRequest = new EventEmitter<void>();

  statusCount: number[] = [0, 0, 0, 0, 0];
  @ViewChild('decisionMapModal') decisionMapModal: TemplateRef<any>;
  @ViewChild('requestQrModal') requestQrModal: TemplateRef<any>;
  qrCodeElementType: 'url' | 'canvas' | 'img' = 'url';
  currentQrCode: string;

  requestList: CommonInventoryPlan[] = [];
  requestListSelection: InventoryPlan; //single selection only
  @Output() requestChange = new EventEmitter<InventoryPlan>();

  // Searching Criteria
  statusList = [InventoryPlanStatus.ACCEPTED, InventoryPlanStatus.REJECTED, InventoryPlanStatus.CANCELLED, InventoryPlanStatus.WAITING_FOR_APPROVAL, InventoryPlanStatus.DRAFT]; // InventoryPlanStatus.WAITING,
  searchPlanStatus: InventoryPlanStatus;
  searchDeliveryDate: Date = null;
  searchSupplier: SupplierInformation = null;
  searchRequestor: string = null;
  searchLocation: Location = null;
  searchTeam: Team = null;
  searchSiteEntrance: SiteEntrance = null;

  //approval
  referenceNumber: string;
  decisionMapHtml: string;

  //Pagination
  tableRowOffset: number;
  tableRowCount: number;
  tablePage: number;
  totalRecords = 0;

  currentProject: Project;

  subscriptionList: Subscription[] = [];
  RequestType = RequestTypeEnum;

  bsModalRef: BsModalRef;
  lang: string;
  team: Team;
  teamList: Team[] = [];

  faQrcode = faQrcode;
  faDownload = faDownload;
  faCalendar = faCalendar;
  faTimes = faTimes;
  faUser = faUser;
  faSearch = faSearch;
  faPlusCircle = faPlusCircle;
  faFileAlt = faFileAlt;

  constructor(
    public generalService: GeneralService,
    private projectsService: ProjectsService,
    private inventoryPlansService: InventoryPlansService,
    private planningsService: PlanningService,
    private bsModalService: BsModalService,
    private suppliersService: SupplierInformationService,
    private locationsService: LocationsService,
    private areasService: AreasService,
    private approvalHeadersService: ApprovalHeadersService,
    private approvalService: ApprovalService,
    private usersService: UsersService,
    private movementsService: MovementsService,
    private teamsService: TeamsService,
    private modalService: ModalService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.lang = this.generalService.translate.currentLang;
    this.currentProject = this.generalService.currentProject;
    this.subscriptionList.push(
      this.generalService.OnProjectChanged.subscribe((project) => {
        this.currentProject = project;
        this.searchRequests();
      })
    );

    switch (this.actionType) {
      case RequestTypeEnum.RECEIPT_REQUEST:
        this.statusList = [
          InventoryPlanStatus.ACCEPTED,
          InventoryPlanStatus.REJECTED,
          InventoryPlanStatus.RECEIVED,
          InventoryPlanStatus.CANCELLED,
          InventoryPlanStatus.WAITING_FOR_APPROVAL,
          InventoryPlanStatus.DRAFT
        ]; //InventoryPlanStatus.WAITING,
        break;
      case RequestTypeEnum.ISSUE_REQUEST:
        this.statusList = [InventoryPlanStatus.ACCEPTED, InventoryPlanStatus.WAITING, InventoryPlanStatus.REJECTED, InventoryPlanStatus.ISSUED, InventoryPlanStatus.CANCELLED];
        break;
    }
    this.getTeams();
  }

  loadRequestLazy(event: LazyLoadEvent) {
    this.tableRowOffset = event.first;
    this.tableRowCount = event.rows;
    this.tablePage = event.first / event.rows + 1;

    this.searchRequests();
  }

  getProject(projectID: number): Observable<Project> {
    return this.projectsService.projectsGetProjectByID({ projectID, views: ['AREA', 'LOCATION'] });
  }

  searchRequests() {
    const searchMode: any = this.actionType === RequestTypeEnum.RECEIPT_REQUEST ? RequestTypeEnum.RECEIPT_REQUEST : RequestTypeEnum.ISSUE_REQUEST; //Can be 'ALL' as well, but we dont use it here

    this.requestList = [];
    let usernames: string[] = [];

    this.inventoryPlansService
      .inventoryPlansSearchInventoryPlan({
        searchMode,
        supplierNo: this.searchSupplier && this.searchSupplier.AddressBookNumber.toString().trim() ? this.searchSupplier.AddressBookNumber.toString() : null,
        requestor: this.searchRequestor && this.searchRequestor.trim() ? this.searchRequestor : null,
        status: this.searchPlanStatus ? [this.searchPlanStatus] : [],
        fromDeliveryDate: this.searchDeliveryDate,
        toDeliveryDate: this.searchDeliveryDate,
        showAll: this.showAll,
        projectID: this.currentProject?.ID,
        locationID: this.searchLocation?.ID,
        teamID: this.searchTeam?.ID,
        siteEntranceID: this.searchSiteEntrance?.ID,
        page: this.tablePage,
        take: this.tableRowCount,
        views: this.actionType == RequestTypeEnum.RECEIPT_REQUEST ? ['APPROVAL_HEADER', 'SITE_ENTRANCE', 'TRANSPORT_TYPE'] : ['ISSUE_PLAN']
      })
      .pipe(
        concatMap((inventoryPlanSummary) => {
          this.requestList = inventoryPlanSummary?.Plans?.Items ?? [];
          this.totalRecords = inventoryPlanSummary?.Plans?.TotalItems;

          this.requestList?.forEach((request) => {
            request.SupplierObservable = request.SupplierNo
              ? this.suppliersService.supplierInformationGetSupplierBySupplierNumber({ supplierNumber: +request.SupplierNo, ignoreLoadingBar: true })
              : null;
            request.TargetLocationObservable = request.TargetLocationID ? this.locationsService.locationsGetLocationByID({ locationID: request.TargetLocationID }) : null;
            request.TargetAreaObservable = request.TargetAreaID ? this.areasService.areasGetAreaByID({ areaID: request.TargetAreaID }) : null;
          });

          this.statusCount = [
            inventoryPlanSummary.AcceptedCount,
            inventoryPlanSummary.WaitingCount,
            inventoryPlanSummary.RejectedCount,
            this.actionType == RequestTypeEnum.RECEIPT_REQUEST ? inventoryPlanSummary.ReceivedCount : inventoryPlanSummary.IssuedCount,
            inventoryPlanSummary.DraftCount
          ];

          usernames = [...new Set(this.requestList?.map((inventoryPlan) => inventoryPlan.Requestor))];

          return this.usersService.usersGetUsersByADs({ usernames });
        })
      )
      .subscribe((userADs) => {
        console.log('usernames: ', userADs);

        this.requestList.forEach((inventoryPlan) => {
          const requestorAD: ADUser = userADs.find((ad) => ad.OnlineName === inventoryPlan.Requestor);

          if (requestorAD) {
            inventoryPlan.RequestorDetails = requestorAD;
          } else {
            inventoryPlan.RequestorDetails = {
              Name: inventoryPlan.Requestor,
              ChineseName: inventoryPlan.Requestor
            };
          }
        });
      });

    // //project ID can be null so that users without default job can also search their own record
    // this.subscriptionList.push(
    //   this.inventoryPlansService
    //     .inventoryPlansSearchInventoryPlan({
    //       searchMode,
    //       supplierNo: this.searchSupplier && this.searchSupplier.AddressBookNumber.toString().trim() ? this.searchSupplier.AddressBookNumber.toString() : null,
    //       requestor: this.searchRequestor && this.searchRequestor.trim() ? this.searchRequestor : null,
    //       status: this.searchPlanStatus ? [this.searchPlanStatus] : [],
    //       fromDeliveryDate: this.searchDeliveryDate,
    //       toDeliveryDate: this.searchDeliveryDate,
    //       showAll: this.showAll,
    //       projectID: this.currentProject?.ID,
    //       locationID: this.searchLocation?.ID,
    //       teamID: this.searchTeam?.ID,
    //       siteEntranceID: this.searchSiteEntrance?.ID,
    //       page: this.tablePage,
    //       take: this.tableRowCount,
    //       views: this.actionType == RequestTypeEnum.RECEIPT_REQUEST ? ['APPROVAL_HEADER', 'SITE_ENTRANCE', 'TRANSPORT_TYPE'] : ['ISSUE_PLAN']
    //     })
    //     .subscribe((summary) => {
    //       console.log('InventoryPlanSummary: ', summary);
    //       this.totalRecords = summary.Plans.TotalItems;

    //       if (summary?.Plans) {
    //         this.requestList = summary?.Plans?.Items ?? [];
    //         console.log('Request List: ', this.requestList);

    //         this.requestList.forEach((request) => {
    //           request.SupplierObservable = request.SupplierNo
    //             ? this.suppliersService.supplierInformationGetSupplierBySupplierNumber({ supplierNumber: +request.SupplierNo, ignoreLoadingBar: true })
    //             : null;
    //           request.TargetLocationObservable = request.TargetLocationID ? this.locationsService.locationsGetLocationByID({ locationID: request.TargetLocationID }) : null;
    //           request.TargetAreaObservable = request.TargetAreaID ? this.areasService.areasGetAreaByID({ areaID: request.TargetAreaID }) : null;
    //         });
    //       }

    //       this.statusCount = [
    //         summary.AcceptedCount,
    //         summary.WaitingCount,
    //         summary.RejectedCount,
    //         this.actionType == RequestTypeEnum.RECEIPT_REQUEST ? summary.ReceivedCount : summary.IssuedCount,
    //         summary.DraftCount
    //       ];
    //     })
    // );
  }

  checkExistReceivedItems(inventoryPlan: InventoryPlan) {
    if (!inventoryPlan.ReceiptPlans) {
      return false;
    }
    const existReceivedItems = inventoryPlan.ReceiptPlans.some((s) => s.ReceiptPlanStatus == 'RECEIVED');
    return existReceivedItems;
  }

  // Deprecated
  onRowSelect(event) {
    console.log('Row Select', event.data);

    //Load details of the request

    this.subscriptionList.push(
      this.inventoryPlansService
        .inventoryPlansGetInventoryPlanByID({
          inventoryPlanID: event.data.ID,
          views: [this.actionType == RequestTypeEnum.RECEIPT_REQUEST ? 'RECEIPT_PLAN' : 'ISSUE_PLAN', 'ITEM_LOT', 'ITEM_VARIANT', 'ITEM', 'LOCATION', 'AREA', 'BIN', 'TEAM']
        })
        .subscribe((inventoryPlan) => {
          // this.requestListSelection = inventoryPlan;
          // console.log('Request Selection: ', this.requestListSelection);
          this.requestChange.emit(inventoryPlan);

          // Reference items
          this.requestListSelection.ReceiptPlans = inventoryPlan.ReceiptPlans;
          this.requestListSelection.IssuePlans = inventoryPlan.IssuePlans;
        })
    );
  }

  // Deprecated
  onRowUnselect(event) {
    this.requestListSelection = null;
    this.requestChange.emit(this.requestListSelection);
  }

  showDecisionMapModal(referenceNumber: string) {
    if (!referenceNumber) {
      console.log(referenceNumber);
      return;
    }
    this.decisionMapHtml = '';
    this.referenceNumber = referenceNumber;
    this.approvalService.approvalReturnDecisionMapTemplate({ refNo: referenceNumber }).subscribe((data) => {
      this.decisionMapHtml = data;
      const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
      this.bsModalRef = this.bsModalService.show(this.decisionMapModal, config);
      const parser = new DOMParser();
      const doc = parser.parseFromString(this.decisionMapHtml, 'text/xml');

      console.log(doc);
    });
  }

  showInventoryPlanDetails(request: CommonInventoryPlan) {
    this.subscriptionList.push(
      this.inventoryPlansService
        .inventoryPlansGetInventoryPlanByID({
          inventoryPlanID: request.ID,
          views: [
            'ITEM_LOT',
            'ITEM_VARIANT',
            'ITEM',
            'ITEM_SUB_CATEGORY',
            'ITEM_CATEGORY',
            'RECEIPT_PLAN',
            'ISSUE_PLAN',
            'LOCATION',
            'AREA',
            'BIN',
            'KEEPER',
            'SITE_ENTRANCE',
            'TEAM',
            'APPROVAL_HEADER'
          ]
        })
        .subscribe((inventoryPlan: CommonInventoryPlan) => {
          console.log('inventoryPlan details: ', inventoryPlan);

          if (request.SupplierObservable) {
            inventoryPlan.SupplierObservable = request.SupplierObservable;
          }

          // Load Keeper for Issue Plan
          if (inventoryPlan?.IssuePlans?.length) {
            inventoryPlan.IssuePlans.forEach((issuePlan: CommonIssuePlan) => {
              issuePlan.QuantityToBeIssued = issuePlan.Quantity;
              issuePlan.IssuedQuantityObservable = this.movementsService.movementsGetIssuedQuantity({ issuePlanID: issuePlan.ID });
            });
          }

          // Target Location / Area
          inventoryPlan.TargetLocationObservable = inventoryPlan.TargetLocationID ? this.locationsService.locationsGetLocationByID({ locationID: inventoryPlan.TargetLocationID }) : null;
          inventoryPlan.TargetAreaObservable = inventoryPlan.TargetAreaID ? this.areasService.areasGetAreaByID({ areaID: inventoryPlan.TargetAreaID }) : null;

          inventoryPlan.ApprovalHeader = request.ApprovalHeader;
          this.requestChange.emit(inventoryPlan);
        })
    );
  }

  // showInventoryPlanDetails(request: CommonInventoryPlan) {
  //   let viewsArr: Array<'NONE' | 'SKIP_CHILD' | 'AREA' | 'BIN' | 'INVENTORY_BALANCE' | 'INVENTORY_PLAN' | 'ISSUE_PLAN' | 'ITEM' | 'ITEM_ACCESS' | 'ITEM_CATEGORY' | 'ITEM_LOT' | 'ITEM_SUB_CATEGORY' | 'ITEM_VARIANT' | 'JDE_MAPPING' | 'LOCATION' | 'LOCATION_ACCESS' | 'MOVEMENT' | 'PROJECT' | 'PROJECT_APPROVER' | 'RECEIPT_PLAN' | 'STOCK_LEVEL' | 'APPROVAL_DETAIL' | 'APPROVAL_HEADER' | 'KEEPER' | 'BUDGET' | 'TEAM' | 'SKIP_PARENT'>
  //   = ['PROJECT',  'ITEM_VARIANT', 'ITEM', 'LOCATION','AREA', 'TEAM'];
  //   if(this.actionType == RequestTypeEnum.RECEIPT_REQUEST){
  //     viewsArr.push('RECEIPT_PLAN');
  //     viewsArr.push('ITEM_LOT');
  //     viewsArr.push('BIN');
  //   }else{
  //     // RequestTypeEnum.ISSUE_REQUEST
  //     viewsArr.push('ISSUE_PLAN');
  //     viewsArr.push('KEEPER');
  //   }

  //   this.subscriptionList.push(this.inventoryPlansService.inventoryPlansGetInventoryPlanByID({
  //     inventoryPlanID: request.ID,
  //     views: viewsArr}).subscribe((inventoryPlan: CommonInventoryPlan) => {
  //       if (request.SupplierObservable) {
  //       inventoryPlan.SupplierObservable = request.SupplierObservable;
  //     }

  //     // Load Keeper for Issue Plan
  //     if (inventoryPlan.IssuePlans && inventoryPlan.IssuePlans.length > 0) {
  //       inventoryPlan.IssuePlans.forEach((issuePlan: CommonIssuePlan) => {
  //         issuePlan.QuantityToBeIssued = issuePlan.Quantity;
  //         issuePlan.IssuedQuantityObservable = this.movementsService.movementsGetIssuedQuantity({issuePlanID: issuePlan.ID});
  //       });
  //     }

  //     // Target Location / Area
  //     inventoryPlan.TargetLocationObservable = inventoryPlan.TargetLocationID ? this.locationsService.locationsGetLocationByID({locationID: inventoryPlan.TargetLocationID}) : null;
  //     inventoryPlan.TargetAreaObservable = inventoryPlan.TargetAreaID ? this.areasService.areasGetAreaByID({areaID: inventoryPlan.TargetAreaID}) : null;
  //     this.requestChange.emit(inventoryPlan);
  //   }));
  // }

  isReceiptRequest(request: CommonInventoryPlan): boolean {
    return request.Source.indexOf('RECEIPT') > -1;
  }

  showCancelInventoryPlanDialog(request: CommonInventoryPlan) {
    this.modalService.showConfirmModal(ModalStatus.warning, 'WARNING', 'CANCEL_REQUEST').subscribe((modalAction) => {
      if (modalAction === 'confirm') {
        if (request.PlanStatus.toUpperCase() === 'WAITING_FOR_APPROVAL') {
          this.abortApprovalAndCancel(request);
        } else {
          this.cancelInventoryPlan(request);
        }
      }
    });
  }

  cancelInventoryPlan(request: CommonInventoryPlan) {
    this.planningsService.planningMaterialCancel({ planID: request.ID }).subscribe(
      (data) => {
        // refresh
        if (data) {
          this.modalService.showMessageModal(ModalStatus.success, 'SUCCEEDED', 'SUCCESSFULLY_CANCELLED_REQUEST');
          this.searchRequests();
        } else {
          this.modalService.showMessageModal(ModalStatus.danger, 'FAILED', 'FAILED_TO_CANCEL_REQUEST');
        }
      },
      (error) => {
        console.error(error);
        this.modalService.showMessageModal(ModalStatus.danger, 'FAILED', 'FAILED_TO_CANCEL_REQUEST');
      }
    );
  }

  abortApprovalAndCancel(request: CommonInventoryPlan) {
    this.subscriptionList.push(
      this.approvalHeadersService.approvalHeadersGetSearchList({ approvalHeaderID: request.PlanID, page: 0, size: 0, views: [] }).subscribe((pageData) => {
        let headerList: ApprovalHeader[] = pageData.Items;

        if (!headerList.length) {
          throw new Error('headerList length less than 0.');
        }

        let activeApprovalHeader: ApprovalHeader = headerList[0];
        if (!activeApprovalHeader.ReferenceNumber) {
          throw new Error('the reference number is null.');
        }

        //to abort on wf
        this.approvalService.approvalAbortApproval({ refNo: activeApprovalHeader.ReferenceNumber.toString() }).subscribe(
          async (data) => {
            if (data == true) {
              // to cancel on db
              this.cancelInventoryPlan(request);
            } else {
              this.modalService.showMessageModal(ModalStatus.danger, 'FAILED', 'FAILED_TO_CANCEL_REQUEST');
            }
          },
          (error) => {
            console.error(error);
            this.modalService.showMessageModal(ModalStatus.danger, 'FAILED', 'FAILED_TO_CANCEL_REQUEST');
          }
        );
      })
    );
  }

  onAddRequest() {
    this.addRequest.emit();
  }

  showReqestQrModal(requestID: number) {
    let invCode = null;
    switch (this.actionType) {
      case RequestTypeEnum.RECEIPT_REQUEST:
        invCode = 'SRR';
        break;
      case RequestTypeEnum.ISSUE_REQUEST:
        invCode = 'SIR';
        break;
      default:
        break;
    }
    if (invCode) {
      this.currentQrCode = 'MM-' + invCode + '-' + requestID;
      const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xl' };
      this.bsModalRef = this.bsModalService.show(this.requestQrModal, config);
    }
  }

  downloadQrCode() {
    const qrDiv = document.getElementsByClassName('qrDiv');
    if (qrDiv && qrDiv.length > 0) {
      const qrImg = qrDiv[0].querySelector('img');
      console.log(qrImg.src);
      const blob = this.base64ToBlob(qrImg.src);

      const downloadURL = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = this.currentQrCode + '.png';
      link.click();
    }
  }

  base64ToBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  closeModal() {
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  getTeams() {
    if (!this.teamList || (this.teamList && this.teamList.length === 0)) {
      this.teamsService.teamsSearchTeam({ projectID: this.currentProject.ID }).subscribe((pageData) => {
        if (pageData && pageData.Items) {
          this.teamList = pageData.Items;
        }
      });
    }
  }

  teamName(id?: number) {
    if (id) {
      const team = this.teamList.filter((e) => e.ID === id)[0];
      const name = this.lang === 'ENG' ? team.Name : team.NameChinese ? team.NameChinese : team.Name;
      return name;
    }
  }

  getTargetDeliveryDateColor(date: Date | string): string {
    let color: string = '#000';
    const targetDate = new Date(date);
    if (this.currentProject.DeliveryDateColorIndicator === ToggleValue.Y) {
      const newDaysAhead1Date = new Date();
      newDaysAhead1Date.setDate(newDaysAhead1Date.getDate() + this.currentProject.DeliveryDateAhead1 ?? 0);
      newDaysAhead1Date.setTime(newDaysAhead1Date.getTime());
      if (targetDate.getFullYear() === newDaysAhead1Date.getFullYear() && targetDate.getMonth() === newDaysAhead1Date.getMonth() && targetDate.getDate() === newDaysAhead1Date.getDate()) {
        color = `#${this.currentProject.DeliveryDateAhead1Color}` ?? '#000';
        return color;
      }

      const newDaysAhead2Date = new Date();
      newDaysAhead2Date.setDate(newDaysAhead2Date.getDate() + this.currentProject.DeliveryDateAhead2 ?? 0);
      newDaysAhead2Date.setTime(newDaysAhead2Date.getTime());
      if (targetDate.getFullYear() === newDaysAhead2Date.getFullYear() && targetDate.getMonth() === newDaysAhead2Date.getMonth() && targetDate.getDate() === newDaysAhead2Date.getDate()) {
        color = `#${this.currentProject.DeliveryDateAhead2Color}` ?? '#000';
        return color;
      }

      const newDaysAhead3Date = new Date();
      newDaysAhead3Date.setDate(newDaysAhead3Date.getDate() + this.currentProject.DeliveryDateAhead3 ?? 0);
      newDaysAhead3Date.setTime(newDaysAhead3Date.getTime());
      if (targetDate.getFullYear() === newDaysAhead3Date.getFullYear() && targetDate.getMonth() === newDaysAhead3Date.getMonth() && targetDate.getDate() === newDaysAhead3Date.getDate()) {
        color = `#${this.currentProject.DeliveryDateAhead3Color}` ?? '#000';
        return color;
      }
    }

    return color;
  }

  shouldBoldTargetDeliveryColor(date: Date | string): boolean {
    return this.getTargetDeliveryDateColor(date) !== '#000';
  }
}
