import { Component, Input, OnInit } from '@angular/core';
import { ADUser, Area, Bin, Location } from '@gammon/inventory-api';
import { ActionType, ActionTypeEnum, CommonInventoryBalance, CommonItemVariant } from '@material/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'summary-tab',
    templateUrl: './summary-tab.component.html',
    styleUrls: ['./summary-tab.component.scss']
})
export class SummaryTabComponent implements OnInit {

    @Input() actionType: ActionType;

    _itemList: Array<CommonInventoryBalance|CommonItemVariant> = []; // can be iv[] or ib[]
    @Input() set itemList(value: Array<CommonInventoryBalance|CommonItemVariant>) {
      this._itemList = value;
    }
    @Input() bin: Bin = {};
    @Input() area: Area = {};
    @Input() location: Location = {};
    @Input() keeper: ADUser;
    @Input() teamIsResponsible: boolean = false;

    ActionType = ActionTypeEnum;
    constructor(
      public translate: TranslateService
    ) { }

    ngOnInit() {}


}
