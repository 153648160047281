import { Component, OnInit } from '@angular/core';
import { CommonSecurityService } from '../../services/common-security.service';

@Component({
  selector: 'common-access-denied',
  templateUrl: './common-access-denied.component.html',
  styleUrls: ['./common-access-denied.component.scss']
})
export class CommonAccessDeniedComponent implements OnInit {

  constructor(private commonSecurityService: CommonSecurityService) { }

  ngOnInit() {
  }

  SignOut() {
    this.commonSecurityService.SignOut();
  }

}
