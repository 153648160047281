import { NgModule } from '@angular/core';
import { CommonModule as AngularCommonModule} from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ChangeEnvComponent, CommonAccessDeniedComponent, CommonLockComponent, EnvMarkComponent } from './components/components';
const DECLARATION = [
  ChangeEnvComponent,
  EnvMarkComponent,
  CommonLockComponent,
  CommonAccessDeniedComponent
]
// @dynamic
@NgModule({
    declarations: [...DECLARATION],
    imports: [
        AngularCommonModule,
        FormsModule
    ],
    exports: [...DECLARATION]
})
export class CommonModule { }
