import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Project, SiteEntrance, SiteEntranceTransport, SiteEntranceTransportsService, TransportType } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'search-site-entrance-transport',
  templateUrl: './search-site-entrance-transport.component.html',
  styleUrls: ['./search-site-entrance-transport.component.scss']
})
export class SearchSiteEntranceTransportComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() siteEntrance: SiteEntrance;
  @Input() disabled: boolean = false;

  @Output() siteEntranceTransportChange = new EventEmitter<SiteEntranceTransport>();
  siteEntranceTransportList: SiteEntranceTransport[] = [];

  @Input() siteEntranceTransport: SiteEntranceTransport;

  subscriptionList: Subscription[] = [];

  constructor(private siteEntranceTransportsService: SiteEntranceTransportsService) {}

  ngOnInit() {}

  searchSiteEntranceTransports() {
    if (!this.siteEntrance) {
      console.error('Missing site entrance as input');
      return;
    }

    this.subscriptionList.push(
      this.siteEntranceTransportsService
        .siteEntranceTransportsSearchSiteEntranceTransports({ projectID: this.project.ID, siteEntranceID: this.siteEntrance.ID, allow: true, views: ['TRANSPORT_TYPE'] })
        .subscribe((pageSiteEntranceTransport) => {
          this.siteEntranceTransportList = pageSiteEntranceTransport?.Items ?? [];
          console.log('allowed site entrance transport: ', this.siteEntranceTransportList);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }
}
