/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ItemCategory } from '../model/itemCategory';
import { PageItemCategory } from '../model/pageItemCategory';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ItemCategoriesServiceInterface, IitemCategoriesCheckIsExistCategoriesParams,IitemCategoriesCheckIsExistCodeParams,IitemCategoriesCheckItemCategorySubCatItemItemVariantIsUsedParams,IitemCategoriesCreateParams,IitemCategoriesDeleteParams,IitemCategoriesDeleteByListParams,IitemCategoriesGetItemCategoryByIDParams,IitemCategoriesGetListParams,IitemCategoriesGetListWithIbQtyGreaterThanZeroParams,IitemCategoriesGetListWithItemsParams,IitemCategoriesGetListWithSubCategoriesParams,IitemCategoriesGetSearchListParams,IitemCategoriesUpdateParams,IitemCategoriesUpdateByListParams,IitemCategoriesUploadCategoriesParams, }                            from './itemCategories.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ItemCategoriesService implements ItemCategoriesServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesCheckIsExistCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesCheckIsExistCategoriesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemCategory>>;
    public itemCategoriesCheckIsExistCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesCheckIsExistCategoriesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemCategory>>>;
    public itemCategoriesCheckIsExistCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesCheckIsExistCategoriesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemCategory>>>;
    @datastore
    public itemCategoriesCheckIsExistCategories({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesCheckIsExistCategoriesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemCategoriesCheckIsExistCategories.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<ItemCategory>>(`${this.basePath}/ItemCategories/CheckIsExistCategories`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param code 
     * @param projectID 
     * @param divisionCode 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, code, projectID, divisionCode } : IitemCategoriesCheckIsExistCodeParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public itemCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, code, projectID, divisionCode } : IitemCategoriesCheckIsExistCodeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public itemCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, code, projectID, divisionCode } : IitemCategoriesCheckIsExistCodeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public itemCategoriesCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, code, projectID, divisionCode } : IitemCategoriesCheckIsExistCodeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling itemCategoriesCheckIsExistCode.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (divisionCode !== undefined && divisionCode !== null) {
            queryParameters = queryParameters.set('divisionCode', <any>divisionCode);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/ItemCategories/CheckIsExistCode`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryIDs 
     * @param subCategoryIDs 
     * @param itemIDs 
     * @param itemVariantIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed({ deviceId, message, ignoreLoadingBar, formData, categoryIDs, subCategoryIDs, itemIDs, itemVariantIDs } : IitemCategoriesCheckItemCategorySubCatItemItemVariantIsUsedParams, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed({ deviceId, message, ignoreLoadingBar, formData, categoryIDs, subCategoryIDs, itemIDs, itemVariantIDs } : IitemCategoriesCheckItemCategorySubCatItemItemVariantIsUsedParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed({ deviceId, message, ignoreLoadingBar, formData, categoryIDs, subCategoryIDs, itemIDs, itemVariantIDs } : IitemCategoriesCheckItemCategorySubCatItemItemVariantIsUsedParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    @datastore
    public itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed({ deviceId, message, ignoreLoadingBar, formData, categoryIDs, subCategoryIDs, itemIDs, itemVariantIDs } : IitemCategoriesCheckItemCategorySubCatItemItemVariantIsUsedParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryIDs === null || categoryIDs === undefined) {
            throw new Error('Required parameter categoryIDs was null or undefined when calling itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed.');
        }
        if (subCategoryIDs === null || subCategoryIDs === undefined) {
            throw new Error('Required parameter subCategoryIDs was null or undefined when calling itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed.');
        }
        if (itemIDs === null || itemIDs === undefined) {
            throw new Error('Required parameter itemIDs was null or undefined when calling itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed.');
        }
        if (itemVariantIDs === null || itemVariantIDs === undefined) {
            throw new Error('Required parameter itemVariantIDs was null or undefined when calling itemCategoriesCheckItemCategorySubCatItemItemVariantIsUsed.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryIDs) {
            categoryIDs.forEach((element) => {
                queryParameters = queryParameters.append('categoryIDs', <any>element);
            })
        }
        if (subCategoryIDs) {
            subCategoryIDs.forEach((element) => {
                queryParameters = queryParameters.append('subCategoryIDs', <any>element);
            })
        }
        if (itemIDs) {
            itemIDs.forEach((element) => {
                queryParameters = queryParameters.append('itemIDs', <any>element);
            })
        }
        if (itemVariantIDs) {
            itemVariantIDs.forEach((element) => {
                queryParameters = queryParameters.append('itemVariantIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<string>>(`${this.basePath}/ItemCategories/CheckItemCategorySubCatItemItemVariantIsUsed`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemCategoriesCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ItemCategory>;
    public itemCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemCategoriesCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemCategory>>;
    public itemCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemCategoriesCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemCategory>>;
    @datastore
    public itemCategoriesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemCategoriesCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemCategoriesCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ItemCategory>(`${this.basePath}/ItemCategories`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemCategoriesDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemCategoriesDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemCategoriesDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemCategoriesDelete({ deviceId, message, ignoreLoadingBar, formData, categoryID } : IitemCategoriesDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemCategoriesDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ItemCategories/${encodeURIComponent(String(categoryID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, categoryIDs } : IitemCategoriesDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, categoryIDs } : IitemCategoriesDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, categoryIDs } : IitemCategoriesDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemCategoriesDeleteByList({ deviceId, message, ignoreLoadingBar, formData, categoryIDs } : IitemCategoriesDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryIDs === null || categoryIDs === undefined) {
            throw new Error('Required parameter categoryIDs was null or undefined when calling itemCategoriesDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryIDs) {
            categoryIDs.forEach((element) => {
                queryParameters = queryParameters.append('categoryIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ItemCategories/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param restrictActiveParents 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesGetItemCategoryByID({ deviceId, message, ignoreLoadingBar, formData, categoryID, restrictActiveParents, views } : IitemCategoriesGetItemCategoryByIDParams, observe?: 'body', reportProgress?: boolean): Observable<ItemCategory>;
    public itemCategoriesGetItemCategoryByID({ deviceId, message, ignoreLoadingBar, formData, categoryID, restrictActiveParents, views } : IitemCategoriesGetItemCategoryByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemCategory>>;
    public itemCategoriesGetItemCategoryByID({ deviceId, message, ignoreLoadingBar, formData, categoryID, restrictActiveParents, views } : IitemCategoriesGetItemCategoryByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemCategory>>;
    @datastore
    public itemCategoriesGetItemCategoryByID({ deviceId, message, ignoreLoadingBar, formData, categoryID, restrictActiveParents, views } : IitemCategoriesGetItemCategoryByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemCategoriesGetItemCategoryByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restrictActiveParents !== undefined && restrictActiveParents !== null) {
            queryParameters = queryParameters.set('restrictActiveParents', <any>restrictActiveParents);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ItemCategory>(`${this.basePath}/ItemCategories/${encodeURIComponent(String(categoryID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param onlyGlobalAndProject 
     * @param categoryID 
     * @param offset 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, onlyGlobalAndProject, categoryID, offset, take, views } : IitemCategoriesGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemCategory>>;
    public itemCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, onlyGlobalAndProject, categoryID, offset, take, views } : IitemCategoriesGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemCategory>>>;
    public itemCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, onlyGlobalAndProject, categoryID, offset, take, views } : IitemCategoriesGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemCategory>>>;
    @datastore
    public itemCategoriesGetList({ deviceId, message, ignoreLoadingBar, formData, onlyGlobalAndProject, categoryID, offset, take, views } : IitemCategoriesGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (onlyGlobalAndProject !== undefined && onlyGlobalAndProject !== null) {
            queryParameters = queryParameters.set('onlyGlobalAndProject', <any>onlyGlobalAndProject);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemCategory>>(`${this.basePath}/ItemCategories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNo 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IitemCategoriesGetListWithIbQtyGreaterThanZeroParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemCategory>>;
    public itemCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IitemCategoriesGetListWithIbQtyGreaterThanZeroParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemCategory>>>;
    public itemCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IitemCategoriesGetListWithIbQtyGreaterThanZeroParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemCategory>>>;
    @datastore
    public itemCategoriesGetListWithIbQtyGreaterThanZero({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IitemCategoriesGetListWithIbQtyGreaterThanZeroParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNo === null || projectNo === undefined) {
            throw new Error('Required parameter projectNo was null or undefined when calling itemCategoriesGetListWithIbQtyGreaterThanZero.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectNo !== undefined && projectNo !== null) {
            queryParameters = queryParameters.set('projectNo', <any>projectNo);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemCategory>>(`${this.basePath}/ItemCategories/GetListWithIbQtyGreaterThanZero`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param subCategoryID 
     * @param onlyGlobalAndProject 
     * @param itemID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, onlyGlobalAndProject, itemID, page, views } : IitemCategoriesGetListWithItemsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemCategory>>;
    public itemCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, onlyGlobalAndProject, itemID, page, views } : IitemCategoriesGetListWithItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemCategory>>>;
    public itemCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, onlyGlobalAndProject, itemID, page, views } : IitemCategoriesGetListWithItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemCategory>>>;
    @datastore
    public itemCategoriesGetListWithItems({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, onlyGlobalAndProject, itemID, page, views } : IitemCategoriesGetListWithItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemCategoriesGetListWithItems.');
        }
        if (subCategoryID === null || subCategoryID === undefined) {
            throw new Error('Required parameter subCategoryID was null or undefined when calling itemCategoriesGetListWithItems.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (onlyGlobalAndProject !== undefined && onlyGlobalAndProject !== null) {
            queryParameters = queryParameters.set('onlyGlobalAndProject', <any>onlyGlobalAndProject);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemCategory>>(`${this.basePath}/ItemCategories/${encodeURIComponent(String(categoryID))}/ItemSubCategories/${encodeURIComponent(String(subCategoryID))}/Items`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param onlyGlobalAndProject 
     * @param subCategoryID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesGetListWithSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID, onlyGlobalAndProject, subCategoryID, page, views } : IitemCategoriesGetListWithSubCategoriesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemCategory>>;
    public itemCategoriesGetListWithSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID, onlyGlobalAndProject, subCategoryID, page, views } : IitemCategoriesGetListWithSubCategoriesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemCategory>>>;
    public itemCategoriesGetListWithSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID, onlyGlobalAndProject, subCategoryID, page, views } : IitemCategoriesGetListWithSubCategoriesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemCategory>>>;
    @datastore
    public itemCategoriesGetListWithSubCategories({ deviceId, message, ignoreLoadingBar, formData, categoryID, onlyGlobalAndProject, subCategoryID, page, views } : IitemCategoriesGetListWithSubCategoriesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemCategoriesGetListWithSubCategories.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (onlyGlobalAndProject !== undefined && onlyGlobalAndProject !== null) {
            queryParameters = queryParameters.set('onlyGlobalAndProject', <any>onlyGlobalAndProject);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemCategory>>(`${this.basePath}/ItemCategories/${encodeURIComponent(String(categoryID))}/ItemSubCategories`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNumber 
     * @param divisionCode 
     * @param categoryCode 
     * @param description 
     * @param chineseDescription 
     * @param by 
     * @param withGlobal 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectNumber, divisionCode, categoryCode, description, chineseDescription, by, withGlobal, page, take, views } : IitemCategoriesGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemCategory>;
    public itemCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectNumber, divisionCode, categoryCode, description, chineseDescription, by, withGlobal, page, take, views } : IitemCategoriesGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemCategory>>;
    public itemCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectNumber, divisionCode, categoryCode, description, chineseDescription, by, withGlobal, page, take, views } : IitemCategoriesGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemCategory>>;
    @datastore
    public itemCategoriesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectNumber, divisionCode, categoryCode, description, chineseDescription, by, withGlobal, page, take, views } : IitemCategoriesGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectNumber !== undefined && projectNumber !== null) {
            queryParameters = queryParameters.set('projectNumber', <any>projectNumber);
        }
        if (divisionCode !== undefined && divisionCode !== null) {
            queryParameters = queryParameters.set('divisionCode', <any>divisionCode);
        }
        if (categoryCode !== undefined && categoryCode !== null) {
            queryParameters = queryParameters.set('categoryCode', <any>categoryCode);
        }
        if (description !== undefined && description !== null) {
            queryParameters = queryParameters.set('description', <any>description);
        }
        if (chineseDescription !== undefined && chineseDescription !== null) {
            queryParameters = queryParameters.set('chineseDescription', <any>chineseDescription);
        }
        if (by !== undefined && by !== null) {
            queryParameters = queryParameters.set('by', <any>by);
        }
        if (withGlobal !== undefined && withGlobal !== null) {
            queryParameters = queryParameters.set('withGlobal', <any>withGlobal);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemCategory>(`${this.basePath}/ItemCategories/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, categoryID, model } : IitemCategoriesUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, categoryID, model } : IitemCategoriesUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, categoryID, model } : IitemCategoriesUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemCategoriesUpdate({ deviceId, message, ignoreLoadingBar, formData, categoryID, model } : IitemCategoriesUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling itemCategoriesUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemCategoriesUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ItemCategories/${encodeURIComponent(String(categoryID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemCategoriesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemCategoriesUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemCategoriesUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/ItemCategories/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemCategoriesUploadCategories({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryType } : IitemCategoriesUploadCategoriesParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemCategoriesUploadCategories({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryType } : IitemCategoriesUploadCategoriesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemCategoriesUploadCategories({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryType } : IitemCategoriesUploadCategoriesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemCategoriesUploadCategories({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryType } : IitemCategoriesUploadCategoriesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemCategoriesUploadCategories.');
        }
        if (categoryType === null || categoryType === undefined) {
            throw new Error('Required parameter categoryType was null or undefined when calling itemCategoriesUploadCategories.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/ItemCategories/UploadCategory/${encodeURIComponent(String(projectID))}/${encodeURIComponent(String(categoryType))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
