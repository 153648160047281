<div [ngClass]="{'row py-1': orientation == 'row', 'd-flex flex-column mb-2': orientation == 'column'}">
  <div class="col-auto my-auto" [ngClass]="{'col-auto': orientation == 'column', 'col-md-3 col-sm-3 col-lg-3': orientation == 'row'}">
      <span class="fs-12">{{ 'ITEM_CATEGORY' | translate }}</span>
  </div>
  <div class="my-auto" [ngClass]="{'col-auto': orientation == 'column', 'col-8': orientation == 'row'}">

      <ng-select
          style="width: 100%"
          (open)="searchCategories();"
          [items]="searchCategoryList"
          [(ngModel)]="category"
          appendTo="body"
          (ngModelChange)="onCategoryChange($event)">

          <ng-template ng-label-tmp let-item="item">
              <span>{{item.CategoryCode}} - {{ item['CATEGORY_DESC'|translate] }}</span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
              <span>{{item.CategoryCode}} - {{ item['CATEGORY_DESC'|translate] }}</span>
          </ng-template>

      </ng-select>

  </div>
</div>
