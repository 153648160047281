<div class="d-flex tiles-gap justify-content-between">
  <request-status-tile
    class="w-25 animation-fade-in-bottom"
    style="--animation-order: 0"
    [title]="'ACCEPTED'"
    [number]="data[0]"
    [color]="'success'"
    [icon]="faCheckCircle"
  ></request-status-tile>
  <request-status-tile
    class="w-25 animation-fade-in-bottom"
    style="--animation-order: 1"
    [title]="'WAITING'"
    [number]="data[1]"
    [color]="'warning'"
    [icon]="faHourglass"
  ></request-status-tile>
  <request-status-tile
    class="w-25 animation-fade-in-bottom"
    style="--animation-order: 2"
    [title]="'REJECTED'"
    [number]="data[2]"
    [color]="'danger'"
    [icon]="faBan"
  ></request-status-tile>
  <request-status-tile
    class="w-25 animation-fade-in-bottom"
    style="--animation-order: 3"
    [title]="actionType === RequestType.RECEIPT_REQUEST ? 'RECEIVED' : 'ISSUED'"
    [number]="data[3]"
    [color]="'complete'"
    [icon]="faHandshake"
  ></request-status-tile>
</div>
