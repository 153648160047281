import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ADUser, Location, LocationsService, Project, ProjectApprover, UsersService } from '@gammon/inventory-api';
import { ApprovalType, ApprovalTypeEnum } from '@material/common';
import { EMPTY, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'approval-add',
  templateUrl: './approval-add.component.html',
  styleUrls: ['./approval-add.component.scss']
})
export class ApprovalAddComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() approvalType: ApprovalType;
  @Output() close = new EventEmitter<any>();
  @Input() approvalRoute: ProjectApprover[] = [];
  @Output() add = new EventEmitter<ProjectApprover>();

  searchUserInput: string;
  userSearchTerm$ = new Subject<string>();

  userSearchList: ADUser[] = [];
  searchUser: ProjectApprover;

  _searchLocationList: Location[] = [];
  _selectedLocation: Location;

  @Input() searchLocationList: Location[] = [];
  @Input() selectedLocation: Location;

  subscriptionList: Subscription[] = [];

  ApprovalType = ApprovalTypeEnum;

  @Input() sequence: number;

  constructor(private userService: UsersService, private locationsService: LocationsService) {}

  ngOnInit() {
    //Debounce for 1 second before apply search
    this.subscriptionList.push(
      this.userSearchTerm$
        .pipe(
          debounceTime(500),
          switchMap((term) => {
            this.quickSearchUser();
            return EMPTY;
          })
        )
        .subscribe()
    );
  }

  quickSearchUser() {
    //Reset values
    this.userSearchList = null;
    this.searchUser = null;

    if (this.searchUserInput && this.searchUserInput.length >= 3) {
      this.userService.usersGetListByQuery({ query: this.searchUserInput }).subscribe((userList) => {
        this.userSearchList = userList;
      });
    }
  }

  disableAdd(): boolean {
    switch (this.approvalType) {
      case ApprovalTypeEnum.CHANGE_LOCATION:
        return !this._selectedLocation;
      default:
        return false;
    }
  }

  searchLocations() {
    this.locationsService.locationsGetSearchList({ projectID: this.project.ID }).subscribe((pageData) => {
      if (pageData && pageData.Items) {
        this._searchLocationList = pageData.Items;
      }
    });
  }

  selectUser(user: ADUser) {
    console.log('selected user:', user);

    this.searchUser = {
      ProjectID: this.project.ID,
      Type: user.Title ? user.Title.substring(0, 20) : '',
      ApproverAD: user.OnlineName,
      ApprovalType: this.approvalType,
      User: user,
      ApprovalSequence: this.sequence
    };
  }

  closeModal() {
    this.close.emit();
  }

  addApprover() {
    switch (this.approvalType) {
      case ApprovalTypeEnum.CHANGE_LOCATION:
        this.searchUser.LocationID = this._selectedLocation.ID;
        break;
      default:
        break;
    }

    this.add.emit(this.searchUser);
    this.closeModal();
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
