<div class="col input-group" [style.padding.px]="0">
    <div class="input-group-prepend">
        <span class="input-group-text"><i class="fa fa-users"></i></span>
    </div>
    <ng-select
        style="width: calc(100% - 40px); min-width:150px;"
        (open)="searchTeamList()"
        [items]="teamList"
        [(ngModel)]="team"
        (ngModelChange)="onTeamChange($event)"
        [disabled]="!currentProject"
        placeholder="{{'RESPONSIBLE_TEAM'|translate}}"
        appendTo="body">
        <ng-template ng-label-tmp let-item="item" >
            <span>{{ (item||{})['TEAM_NAME_DESC'|translate] }}</span>
        </ng-template>
        
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <span>{{ (item||{})['TEAM_NAME_DESC'|translate] }}</span>
        </ng-template>
    </ng-select>

</div>
