/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PageSiteEntranceTransport } from '../model/pageSiteEntranceTransport';
import { PageTransportType } from '../model/pageTransportType';
import { TransportType } from '../model/transportType';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { TransportTypesServiceInterface, ItransportTypesCreateParams,ItransportTypesDeleteParams,ItransportTypesSearchSiteEntranceTransportsParams,ItransportTypesSearchTransportTypesParams,ItransportTypesUpdateParams,ItransportTypesUpdateByListParams, }                            from './transportTypes.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class TransportTypesService implements TransportTypesServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transportTypesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ItransportTypesCreateParams, observe?: 'body', reportProgress?: boolean): Observable<TransportType>;
    public transportTypesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ItransportTypesCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TransportType>>;
    public transportTypesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ItransportTypesCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TransportType>>;
    @datastore
    public transportTypesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ItransportTypesCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling transportTypesCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<TransportType>(`${this.basePath}/TransportTypes`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param transportTypeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transportTypesDelete({ deviceId, message, ignoreLoadingBar, formData, transportTypeID } : ItransportTypesDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public transportTypesDelete({ deviceId, message, ignoreLoadingBar, formData, transportTypeID } : ItransportTypesDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public transportTypesDelete({ deviceId, message, ignoreLoadingBar, formData, transportTypeID } : ItransportTypesDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public transportTypesDelete({ deviceId, message, ignoreLoadingBar, formData, transportTypeID } : ItransportTypesDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (transportTypeID === null || transportTypeID === undefined) {
            throw new Error('Required parameter transportTypeID was null or undefined when calling transportTypesDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/TransportTypes/${encodeURIComponent(String(transportTypeID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param siteEntranceID 
     * @param transportTypeID 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transportTypesSearchSiteEntranceTransports({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, transportTypeID, page, size, views } : ItransportTypesSearchSiteEntranceTransportsParams, observe?: 'body', reportProgress?: boolean): Observable<PageSiteEntranceTransport>;
    public transportTypesSearchSiteEntranceTransports({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, transportTypeID, page, size, views } : ItransportTypesSearchSiteEntranceTransportsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageSiteEntranceTransport>>;
    public transportTypesSearchSiteEntranceTransports({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, transportTypeID, page, size, views } : ItransportTypesSearchSiteEntranceTransportsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageSiteEntranceTransport>>;
    @datastore
    public transportTypesSearchSiteEntranceTransports({ deviceId, message, ignoreLoadingBar, formData, projectID, siteEntranceID, transportTypeID, page, size, views } : ItransportTypesSearchSiteEntranceTransportsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling transportTypesSearchSiteEntranceTransports.');
        }
        if (siteEntranceID === null || siteEntranceID === undefined) {
            throw new Error('Required parameter siteEntranceID was null or undefined when calling transportTypesSearchSiteEntranceTransports.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (siteEntranceID !== undefined && siteEntranceID !== null) {
            queryParameters = queryParameters.set('siteEntranceID', <any>siteEntranceID);
        }
        if (transportTypeID !== undefined && transportTypeID !== null) {
            queryParameters = queryParameters.set('transportTypeID', <any>transportTypeID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageSiteEntranceTransport>(`${this.basePath}/TransportTypes/SearchSiteEntranceTransports`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param transportTypeID 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transportTypesSearchTransportTypes({ deviceId, message, ignoreLoadingBar, formData, projectID, transportTypeID, page, size, views } : ItransportTypesSearchTransportTypesParams, observe?: 'body', reportProgress?: boolean): Observable<PageTransportType>;
    public transportTypesSearchTransportTypes({ deviceId, message, ignoreLoadingBar, formData, projectID, transportTypeID, page, size, views } : ItransportTypesSearchTransportTypesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageTransportType>>;
    public transportTypesSearchTransportTypes({ deviceId, message, ignoreLoadingBar, formData, projectID, transportTypeID, page, size, views } : ItransportTypesSearchTransportTypesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageTransportType>>;
    @datastore
    public transportTypesSearchTransportTypes({ deviceId, message, ignoreLoadingBar, formData, projectID, transportTypeID, page, size, views } : ItransportTypesSearchTransportTypesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling transportTypesSearchTransportTypes.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (transportTypeID !== undefined && transportTypeID !== null) {
            queryParameters = queryParameters.set('transportTypeID', <any>transportTypeID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageTransportType>(`${this.basePath}/TransportTypes/SearchTransportTypes`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param transportTypeID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transportTypesUpdate({ deviceId, message, ignoreLoadingBar, formData, transportTypeID, model } : ItransportTypesUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public transportTypesUpdate({ deviceId, message, ignoreLoadingBar, formData, transportTypeID, model } : ItransportTypesUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public transportTypesUpdate({ deviceId, message, ignoreLoadingBar, formData, transportTypeID, model } : ItransportTypesUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public transportTypesUpdate({ deviceId, message, ignoreLoadingBar, formData, transportTypeID, model } : ItransportTypesUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (transportTypeID === null || transportTypeID === undefined) {
            throw new Error('Required parameter transportTypeID was null or undefined when calling transportTypesUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling transportTypesUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/TransportTypes/${encodeURIComponent(String(transportTypeID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public transportTypesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : ItransportTypesUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public transportTypesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : ItransportTypesUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public transportTypesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : ItransportTypesUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public transportTypesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : ItransportTypesUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling transportTypesUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/TransportTypes/UpdateByList`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
