import { Injectable, ViewContainerRef } from '@angular/core';
import { CommonSecurityService } from '@gammon/common';
import { Project, ProjectsService, UserPreference, UserSecurity, UsersService } from '@gammon/inventory-api';
import { InventoryPlanStatus } from '@material/common';
import { TranslateService } from '@ngx-translate/core';
import { config } from 'projects/AppConfig';
import { Observable, Subject, Subscription } from 'rxjs';

export interface ITabLabel {
  name: string;
  labels: string[];
}

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  menuItems: MenuItem[] = [{ label: '', iconType: '', iconName: '', submenu: [] }];

  constructor(private projectService: ProjectsService, private userService: UsersService, public commonSecurityService: CommonSecurityService, public translate: TranslateService) {}

  public userPreference: UserPreference;
  public userSecurity: UserSecurity;
  public userSecuritySubject: Subject<UserSecurity> = new Subject<UserSecurity>();
  public getUserSecurity(): Observable<UserSecurity> {
    return this.userService.usersGetUserSecurity({}).map((userSecurity) => {
      this.userSecurity = userSecurity;
      this.commonSecurityService.functions = userSecurity.Functions || [];
      this.commonSecurityService.commonSecurityRoles = userSecurity.Roles || [];
      this.userSecuritySubject.next(userSecurity);
      return userSecurity;
    });
  }

  private toggleQuickViewSubject: Subject<boolean> = new Subject<boolean>();
  public OnToggleQuickView = this.toggleQuickViewSubject.asObservable();
  ToggleQuickView(fullscreen: boolean) {
    this.toggleQuickViewSubject.next(fullscreen);
  }

  private reloadQuickViewSubject: Subject<Project[]> = new Subject<Project[]>();
  projectList: Project[] = [];
  projectNumbers: string[] = [];
  public OnReloadQuickView = this.reloadQuickViewSubject.asObservable();
  async ReloadQuickView(projectNumber: string = null) {
    // const projectList = await this.SearchProjectQuickView(projectNumber);
    console.log('reload quick view: ' + projectNumber);
    this.projectList = await this.SearchUserProjectsByJobs(projectNumber);
    this.projectNumbers = this.projectList.map((p) => p.ProjectNumber);
    this.reloadQuickViewSubject.next(this.projectList);
  }

  public tabLabel: ITabLabel[] = [];

  ShowWhen(conditions: ('PROJECT_SELECTED' | 'PROJECT_UPDATE' | 'ENV_DEV' | 'ENV_LOC')[], method: 'some' | 'every' = 'some'): boolean {
    const results = [];
    if (conditions.includes('PROJECT_SELECTED')) {
      results.push(this.currentProject.ProjectNumber != null);
    }
    if (conditions.includes('PROJECT_UPDATE')) {
      results.push(this.commonSecurityService.Roles(['PROJECT_UPDATE']));
    }
    if (conditions.includes('ENV_DEV')) {
      results.push(config.env === 'DEV');
    }

    if (conditions.includes('ENV_LOC')) {
      results.push(config.env === 'LOC');
    }

    return results[method]((r) => r === true);
  }

  SearchProjectQuickView(projectNumber: string) {
    return new Promise<Project[]>((resolve) => {
      this.projectService.projectsGetSearchList({ projectNumber }).subscribe((page) => {
        if (page) {
          resolve(page.Items);
        }
      });
    });
  }

  SearchUserProjectsByJobs(projectNumber: string = null) {
    return new Promise<Project[]>((resolve) => {
      this.projectService.projectsGetSearchList({ checkJobAll: true }).subscribe((page) => {
        if (page && page.Items) {
          if (!projectNumber) {
            // without filter
            resolve(page.Items);
          } else {
            const filteredProject = page.Items.filter((project) => project.ProjectNumber == projectNumber);
            resolve(filteredProject);
          }
        }
      });
    });
  }

  SelectDefaultProject() {
    this.SearchProjectQuickView(this.userPreference.DefaultProject).then((projects) => {
      if (projects && projects.length === 1) {
        this.ChangeProject(projects[0]);
      } else if (!projects || projects.length === 0) {
        this.ToggleQuickView(true);
      }
    });
  }

  private _currentProject: Project;
  get currentProject() {
    return this._currentProject || {};
  }
  set currentProject(project: Project) {
    this._currentProject = project;
  }
  private projectChangedSubject = new Subject<Project>();
  public OnProjectChanged = this.projectChangedSubject.asObservable();
  ChangeProject(project: Project) {
    this.currentProject = project;
    this.projectChangedSubject.next(project);
  }

  RefreshCurrentProject() {
    const subscription: Subscription = this.projectService.projectsGetProjectByID({ projectID: this.currentProject.ID }).subscribe(
      (project) => {
        if (project) {
          this.currentProject = project;
          this.ChangeProject(this.currentProject);
        }
      },
      (error) => {
        console.error(error);
      },
      () => {
        subscription.unsubscribe();
      }
    );
  }

  statusToString(status: InventoryPlanStatus): string {
    if (status.toUpperCase() == InventoryPlanStatus.ACCEPTED) {
      return 'ACCEPTED';
    } else if (status.toUpperCase() == InventoryPlanStatus.WAITING) {
      return 'WAITING';
    } else if (status.toUpperCase() == InventoryPlanStatus.REJECTED) {
      return 'REJECTED';
    } else if (status.toUpperCase() == InventoryPlanStatus.RECEIVED) {
      return 'RECEIVED';
    } else if (status.toUpperCase() == InventoryPlanStatus.ISSUED) {
      return 'ISSUED';
    } else if (status.toUpperCase() == InventoryPlanStatus.CANCELLED) {
      return 'CANCELLED';
    } else if (status.toUpperCase() == InventoryPlanStatus.WAITING_FOR_APPROVAL) {
      return 'WAITING_FOR_APPROVAL';
    }else if (status.toUpperCase() == InventoryPlanStatus.DRAFT) {
      return 'DRAFT';
    }
  }

  actionTypeToApprovalType(actionType): string {
    return actionType.toUpperCase().replace('-', '_');
  }

  qrCodeValid(text: string): boolean {
    const split: string[] = text?.split('-');

    const patternValid: boolean = split.length === 3;
    const applicationCodeValid: boolean = this.parseQrCodeApplicationCode(text) === 'MM';
    const typeValid: boolean = ['ITM', 'LOC', 'SIR', 'SRR'].indexOf(this.parseQrCodeType(text)) >= 0;

    return patternValid && applicationCodeValid && typeValid;
  }

  parseQrCodeID(qrCode: string): number {
    const split = qrCode.split('-');
    // tslint:disable-next-line: radix
    return parseInt(split[2]);
  }

  parseQrCodeType(qrCode: string): string {
    const split = qrCode.split('-');
    return split[1];
  }

  parseQrCodeApplicationCode(qrCode: string): string {
    const split = qrCode.split('-');
    return split[0];
  }

  getApprovalStatusCss(status: string): string {
    return status == 'APPROVED'
      ? 'text-success'
      : status == 'REJECTED'
      ? 'text-danger'
      : status == 'SUBMITTING'
      ? 'text-warning'
      : status == 'SUBMITTED'
      ? 'text-complete'
      : status == 'CANCELLED'
      ? 'text-dark'
      : '';
  }

  getInventoryPlanStatusCssText(status: InventoryPlanStatus): string {
    switch (status.toString().toUpperCase()) {
      case InventoryPlanStatus.ACCEPTED.toString().toUpperCase():
        return 'text-success';
      case InventoryPlanStatus.WAITING.toString().toUpperCase():
        return 'text-warning';
      case InventoryPlanStatus.REJECTED.toString().toUpperCase():
        return 'text-danger';
      case InventoryPlanStatus.RECEIVED.toString().toUpperCase():
        return 'text-complete';
      case InventoryPlanStatus.ISSUED.toString().toUpperCase():
        return 'text-complete';
      default:
        return '';
    }
  }

  getInventoryPlanStatusCssBackground(status: InventoryPlanStatus): string {
    switch (status.toString().toUpperCase()) {
      case InventoryPlanStatus.ACCEPTED.toString().toUpperCase():
        return 'bg-success';
      case InventoryPlanStatus.WAITING.toString().toUpperCase():
        return 'bg-warning';
      case InventoryPlanStatus.REJECTED.toString().toUpperCase():
        return 'bg-danger';
      case InventoryPlanStatus.RECEIVED.toString().toUpperCase():
        return 'bg-complete';
      case InventoryPlanStatus.ISSUED.toString().toUpperCase():
        return 'bg-complete';
      default:
        return '';
    }
  }

  getInventoryPlanStatusIcon(status: InventoryPlanStatus): string {
    switch (status.toString().toUpperCase()) {
      case InventoryPlanStatus.ACCEPTED.toString().toUpperCase():
        return 'fa fa-check-circle';
      case InventoryPlanStatus.WAITING.toString().toUpperCase():
        return 'fa fa-hourglass';
      case InventoryPlanStatus.REJECTED.toString().toUpperCase():
        return 'fa fa-ban';
      case InventoryPlanStatus.RECEIVED.toString().toUpperCase():
        return 'fa fa-check-circle';
      case InventoryPlanStatus.ISSUED.toString().toUpperCase():
        return 'fa fa-check-circle';
      default:
        return '';
    }
  }

  getStylePath(): string {
    const links: HTMLCollectionOf<HTMLLinkElement> = document.getElementsByTagName('link');
    for (var i = 0; i < links.length; i++) {
      const link = links.item(i);
      if (link.href.indexOf('styles.') > -1) {
        return link.href;
      }
    }
    return '';
  }

  onTabLabel(name: string, label: string) {
    const index = this.tabLabel.findIndex((e) => e.name == name);
    if (index > -1) {
      const arrIndex = this.tabLabel[index].labels.indexOf(label);
      if (arrIndex > -1) {
        this.tabLabel[index].labels.splice(arrIndex, 1);
      } else {
        this.tabLabel[index].labels.push(label);
      }
    } else {
      this.tabLabel.push({ name: name, labels: [label] });
    }
  }

  setupMenu() {
    this.menuItems = MenuItemBuilder.Create()
      .Add(
        {
          label: 'WAREHOUSE_DASHBOARD',
          routerLink: '/inventory/main/warehouse-dashboard',
          iconType: 'fa',
          iconName: 'chart-line'
        },
        this.commonSecurityService.Roles(['WH_DASHBOARD_ENQ']) && this.ShowWhen(['PROJECT_SELECTED'])
      )
      .Add(
        {
          label: 'ITEM_DASHBOARD',
          routerLink: '/inventory/main/item-dashboard',
          iconType: 'fa',
          iconName: 'chart-bar'
        },
        true
      )
      .Add(
        {
          label: 'TEAM_DASHBOARD',
          routerLink: '/inventory/main/team-dashboard',
          iconType: 'fa',
          iconName: 'chart-pie'
        },
        this.commonSecurityService.Roles(['TEAM_DASHBOARD_ENQ']) && this.ShowWhen(['PROJECT_SELECTED'])
      )
      .Add(
        {
          label: 'MATERIAL_MANAGEMENT',
          routerLink: '/inventory/main/admin-dashboard',
          iconType: 'fa',
          iconName: 'warehouse'
        },
        this.commonSecurityService.Roles(['MENU_HOME_ADMIN']) && this.ShowWhen(['PROJECT_SELECTED'])
      )
      .AddWithSubmenu(
        {
          label: 'SET_UP',
          iconType: 'fa',
          iconName: 'cogs',
          toggle: 'close',
          mToggle: 'close'
        },
        [
          {
            item: {
              label: 'PROJECT_MASTER',
              routerLink: '/inventory/main/projects',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd: this.commonSecurityService.Roles(['PROJECT_ENQ', 'PROJECT_UPDATE']) && this.ShowWhen(['PROJECT_SELECTED', 'PROJECT_UPDATE'])
          },
          {
            item: {
              label: 'ITEM_MASTER',
              routerLink: '/inventory/main/items',
              iconType: 'letter',
              iconName: 'im'
            },
            shouldAdd: this.commonSecurityService.Roles(['ITEM_ENQ', 'ITEM_UPDATE']) && this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'TEAM',
              routerLink: '/inventory/main/teams',
              iconType: 'letter',
              iconName: 'te'
            },
            shouldAdd: true
          },
          {
            item: {
              label: 'SITE_ENTRANCE',
              routerLink: '/inventory/main/site-entrance',
              iconType: 'letter',
              iconName: 'se'
            },
            shouldAdd: this.commonSecurityService.Roles(['SITE_ENT_ENQ', 'SITE_ENT_UPDATE']) && this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'TRANSPORT_TYPE',
              routerLink: '/inventory/main/transport-type',
              iconType: 'letter',
              iconName: 'tt'
            },
            shouldAdd: this.commonSecurityService.Roles(['SITE_ENT_ENQ', 'SITE_ENT_UPDATE']) && this.ShowWhen(['PROJECT_SELECTED'])
          }
        ],
        this.commonSecurityService.Roles(['MENU_SETUP'])
      )
      .Add(
        {
          label: 'ENQUIRY',
          routerLink: '/inventory/main/enquiry',
          iconType: 'fa',
          iconName: 'filter'
        },
        this.commonSecurityService.Roles(['MENU_ENQ'])
      )
      .Add(
        {
          label: 'APPROVAL',
          routerLink: '/inventory/main/approval',
          iconType: 'fa',
          iconName: 'clipboard-check'
        },
        this.commonSecurityService.Roles(['APPROVAL_ENQ', 'APPROVAL_UPDATE'])
      )
      .AddWithSubmenu(
        {
          label: 'ADMIN',
          iconType: 'fa',
          iconName: 'cogs',
          toggle: 'close',
          mToggle: 'close'
        },
        [
          {
            item: {
              label: 'REVERSE_MOVEMENT',
              routerLink: '/inventory/main/admin/reverse-movement',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd: this.commonSecurityService.Roles(['ADM_REVERSE_ENQ', 'ADM_REVERSE_UPDATE']) && this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'DELIVERY_FROM_SUPPLIER_REQUEST',
              routerLink: '/inventory/main/admin/delivery-from-supplier-request',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd: this.commonSecurityService.Roles(['ADM_ENQ']) && this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'TAGGING',
              routerLink: '/inventory/main/admin/tagging',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd: this.commonSecurityService.Roles(['ADM_ENQ']) && this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'INVENTORY_BALANCE',
              routerLink: '/inventory/main/admin/admin-inventory-balance',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd: this.commonSecurityService.Roles(['ADM_ENQ']) && this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'MOVEMENT',
              routerLink: '/inventory/main/admin/admin-movement',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd: this.commonSecurityService.Roles(['ADM_ENQ']) && this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'ITEM_DAILY_BALANCE',
              routerLink: '/inventory/main/admin/admin-item-daily-balance',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd:
              (this.commonSecurityService.hasRole(['ROLE_MM_SITE_ADMIN', 'JOB_FDN'], 'every') ||
                this.commonSecurityService.hasRole(['ROLE_MM_ADMIN', 'ROLE_MM_CORP_ADMIN', 'ROLE_MM_IDT_ENQ'], 'some')) &&
              this.ShowWhen(['PROJECT_SELECTED'])
          },
          {
            item: {
              label: 'PLANT_DAILY_BALANCE',
              routerLink: '/inventory/main/admin/admin-plant-daily-balance',
              iconType: 'letter',
              iconName: 'pm'
            },
            shouldAdd:
              (this.commonSecurityService.hasRole(['ROLE_MM_SITE_ADMIN', 'JOB_FDN'], 'every') ||
                this.commonSecurityService.hasRole(['ROLE_MM_ADMIN', 'ROLE_MM_CORP_ADMIN', 'ROLE_MM_IDT_ENQ'], 'some')) &&
              this.ShowWhen(['PROJECT_SELECTED'])
          }
        ],
        this.commonSecurityService.Roles(['MENU_ADMIN'])
      )
      .Add(
        {
          label: 'SCHEDULER',
          routerLink: '/inventory/main/scheduler',
          iconType: 'fa',
          iconName: 'stopwatch'
        },
        this.commonSecurityService.hasRole(['ROLE_MM_IDT_ENQ'])
      ).menuItems;
  }
}

export enum ApprovalStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export enum InventoryPlanSource {
  RECEIPT = 'RECEIPT', // Receipt Request created by user/admin
  ISSUE = 'ISSUE', // Issue Request created by user/admin
  TRANSFER = 'TRANSFER', // Project Transfer
  SUPPLIER = 'SUPPLIER',
  GSP = 'GSP'
}

export enum StockActionType {
  RECEIPT = 'RECEIPT',
  ISSUE = 'ISSUE',
  RETURN = 'RETURN',
  WRITE_OFF = 'WRITE_OFF',
  TRANSFER = 'TRANSFER',
  CHANGE_LOCATION = 'CHANGE_LOCATION',
  ISSUE_PROJECT = 'ISSUE_PROJECT',
  ISSUE_PARTIAL = 'ISSUE_PARTIAL'
}

export class MenuItem {
  label: string;
  iconType: string;
  iconName: string;
  routerLink?: string;
  toggle?: 'close' | 'open';
  mToggle?: 'close' | 'open';
  externalLink?: string;
  target?: string;
  submenu?: MenuItem[];
}

export class MenuItemBuilder {
  menuItems: MenuItem[] = [];

  public static Create(): MenuItemBuilder {
    return new MenuItemBuilder();
  }

  public Add(item: MenuItem, shouldAdd?: boolean): MenuItemBuilder {
    if (shouldAdd) {
      this.menuItems.push(item);
    }
    return this;
  }

  public AddWithSubmenu(item: MenuItem, submenu: { item: MenuItem; shouldAdd?: boolean }[], shouldAdd?: boolean): MenuItemBuilder {
    if (shouldAdd) {
      let subCount = 0;
      submenu.forEach((menu) => {
        if (menu.shouldAdd) {
          item.submenu ? item.submenu.push(menu.item) : (item.submenu = [menu.item]);
          subCount++;
        }
      });
      this.Add(item, subCount > 0);
    }
    return this;
  }
}
