import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ADUser, KeeperService, UsersService } from '@gammon/inventory-api';
import { ActionType, ActionTypeEnum } from '@material/common';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';


@Component({
    selector: 'search-user',
    templateUrl: './search-user.component.html',
    styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit, OnDestroy {

  @Input() disabled = false;
  @Output() keeperChange = new EventEmitter<ADUser>();
  @Input() keeper: ADUser;
  @Input() actionType: ActionType;
  keeperList: ADUser[] = [];
  keeperTypeahead = new EventEmitter<string>();

  subscriptionList: Subscription[] = [];
  ActionType = ActionTypeEnum;
  constructor(
      private usersService: UsersService,
      private keeperService: KeeperService
  ) { }

  ngOnInit() {
    this.subscriptionList.push(
      this.keeperTypeahead.pipe(debounceTime(300), switchMap(term => this.filterKeeper(term))).subscribe(keeperList => {
        if (keeperList) {
            this.keeperList = keeperList;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(subscription => {
        if (subscription) { subscription.unsubscribe(); }
    });
  }

  filterKeeper(input?): Observable<any> {
    if (input && input.length >= 3) {
        return this.usersService.usersGetListByQuery({query: input});
    } else {
        return EMPTY;
    }
  }

  clearKeeperList() {
      this.keeperList = [];
  }

  onKeeperChange() {
    // work around for MSDTC
    if (this.keeper) {
      this.keeperService.keeperCreateKeeper({ model: this.keeper})
      .subscribe((data) => {
        if (data) { console.log('keeper created'); }
      }, (error) => {
        console.error(error);
      });
    }
    this.keeperChange.emit(this.keeper);
  }
}
