/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ScheduleTask } from '../model/scheduleTask';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ScheduleTaskServiceInterface, IscheduleTaskDatabaseGetAllTasksParams,IscheduleTaskDatabaseGetTaskNamesParams,IscheduleTaskSchedulerGetRunningTasksParams,IscheduleTaskSchedulerGetStatusParams,IscheduleTaskSchedulerLoadTasksParams,IscheduleTaskSchedulerRescheduleTaskByIDParams,IscheduleTaskSchedulerStartParams,IscheduleTaskSchedulerStartTaskByIDParams,IscheduleTaskSchedulerStopParams,IscheduleTaskTranslateCronExpressionParams, }                            from './scheduleTask.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ScheduleTaskService implements ScheduleTaskServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskDatabaseGetAllTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetAllTasksParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ScheduleTask>>;
    public scheduleTaskDatabaseGetAllTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetAllTasksParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScheduleTask>>>;
    public scheduleTaskDatabaseGetAllTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetAllTasksParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScheduleTask>>>;
    @datastore
    public scheduleTaskDatabaseGetAllTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetAllTasksParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ScheduleTask>>(`${this.basePath}/Scheduler/DatabaseGetAllTasks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskDatabaseGetTaskNames({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetTaskNamesParams, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public scheduleTaskDatabaseGetTaskNames({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetTaskNamesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public scheduleTaskDatabaseGetTaskNames({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetTaskNamesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    @datastore
    public scheduleTaskDatabaseGetTaskNames({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskDatabaseGetTaskNamesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<string>>(`${this.basePath}/Scheduler/DatabaseGetTaskNames`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskSchedulerGetRunningTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetRunningTasksParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ScheduleTask>>;
    public scheduleTaskSchedulerGetRunningTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetRunningTasksParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ScheduleTask>>>;
    public scheduleTaskSchedulerGetRunningTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetRunningTasksParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ScheduleTask>>>;
    @datastore
    public scheduleTaskSchedulerGetRunningTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetRunningTasksParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ScheduleTask>>(`${this.basePath}/Scheduler/SchedulerGetRunningTasks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskSchedulerGetStatus({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetStatusParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scheduleTaskSchedulerGetStatus({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetStatusParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scheduleTaskSchedulerGetStatus({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetStatusParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public scheduleTaskSchedulerGetStatus({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerGetStatusParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Scheduler/SchedulerGetStatus`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskSchedulerLoadTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerLoadTasksParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scheduleTaskSchedulerLoadTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerLoadTasksParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scheduleTaskSchedulerLoadTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerLoadTasksParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public scheduleTaskSchedulerLoadTasks({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerLoadTasksParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Scheduler/SchedulerLoadTasks`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ID 
     * @param cronExpression 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskSchedulerRescheduleTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID, cronExpression } : IscheduleTaskSchedulerRescheduleTaskByIDParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scheduleTaskSchedulerRescheduleTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID, cronExpression } : IscheduleTaskSchedulerRescheduleTaskByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scheduleTaskSchedulerRescheduleTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID, cronExpression } : IscheduleTaskSchedulerRescheduleTaskByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public scheduleTaskSchedulerRescheduleTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID, cronExpression } : IscheduleTaskSchedulerRescheduleTaskByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (ID === null || ID === undefined) {
            throw new Error('Required parameter ID was null or undefined when calling scheduleTaskSchedulerRescheduleTaskByID.');
        }
        if (cronExpression === null || cronExpression === undefined) {
            throw new Error('Required parameter cronExpression was null or undefined when calling scheduleTaskSchedulerRescheduleTaskByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cronExpression !== undefined && cronExpression !== null) {
            queryParameters = queryParameters.set('cronExpression', <any>cronExpression);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Scheduler/SchedulerRescheduleTaskByID/${encodeURIComponent(String(ID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskSchedulerStart({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStartParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scheduleTaskSchedulerStart({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStartParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scheduleTaskSchedulerStart({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStartParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public scheduleTaskSchedulerStart({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStartParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Scheduler/SchedulerStart`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param ID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskSchedulerStartTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID } : IscheduleTaskSchedulerStartTaskByIDParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scheduleTaskSchedulerStartTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID } : IscheduleTaskSchedulerStartTaskByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scheduleTaskSchedulerStartTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID } : IscheduleTaskSchedulerStartTaskByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public scheduleTaskSchedulerStartTaskByID({ deviceId, message, ignoreLoadingBar, formData, ID } : IscheduleTaskSchedulerStartTaskByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (ID === null || ID === undefined) {
            throw new Error('Required parameter ID was null or undefined when calling scheduleTaskSchedulerStartTaskByID.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Scheduler/SchedulerStartTaskByID/${encodeURIComponent(String(ID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskSchedulerStop({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStopParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public scheduleTaskSchedulerStop({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStopParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public scheduleTaskSchedulerStop({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStopParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public scheduleTaskSchedulerStop({ deviceId, message, ignoreLoadingBar, formData,  } : IscheduleTaskSchedulerStopParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Scheduler/SchedulerStop`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param cronExpression 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public scheduleTaskTranslateCronExpression({ deviceId, message, ignoreLoadingBar, formData, cronExpression } : IscheduleTaskTranslateCronExpressionParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public scheduleTaskTranslateCronExpression({ deviceId, message, ignoreLoadingBar, formData, cronExpression } : IscheduleTaskTranslateCronExpressionParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public scheduleTaskTranslateCronExpression({ deviceId, message, ignoreLoadingBar, formData, cronExpression } : IscheduleTaskTranslateCronExpressionParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public scheduleTaskTranslateCronExpression({ deviceId, message, ignoreLoadingBar, formData, cronExpression } : IscheduleTaskTranslateCronExpressionParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (cronExpression === null || cronExpression === undefined) {
            throw new Error('Required parameter cronExpression was null or undefined when calling scheduleTaskTranslateCronExpression.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (cronExpression !== undefined && cronExpression !== null) {
            queryParameters = queryParameters.set('cronExpression', <any>cronExpression);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<string>(`${this.basePath}/Scheduler/TranslateCronExpression`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
