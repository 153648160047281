import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonDateService } from '@gammon/common/lib/services/common-date.service';
import { ADUser, Bin, InventoryBalance, Project, Team } from '@gammon/inventory-api';
import { ActionType, ActionTypeEnum, CommonInventoryBalance, CommonItemVariant } from '@material/common';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Subscription } from 'rxjs';

@Component({
  selector: 'date-bin-keeper',
  templateUrl: './date-bin-keeper.component.html',
  styleUrls: ['./date-bin-keeper.component.scss'],
})
export class DateBinKeeperComponent implements OnInit, OnDestroy {

  @Input() itemList: Array<CommonInventoryBalance | CommonItemVariant> = [];
  @Output() itemListChange = new EventEmitter<(CommonInventoryBalance | CommonItemVariant)[]>();
  @Input() shortDesc = false;
  @Input() preventChange = false;
  @Input() actionType: ActionType;
  @Input() currentProject: Project;

  team: Team;
  @Input() teamIsResponsible = false;
  ActionType = ActionTypeEnum;
  subscriptionList: Subscription[] = [];

  AllTransactionDate: Date;
  AllBin: Bin;
  AllKeeper: ADUser;
  AllIssueToProject: boolean;
  AllAmount = 0;
  minDate: Date = new Date();
  poNumber: string;
  keeperName: string;
  bsConfig: Partial<BsDatepickerConfig>;
  maxTransactionDate : Date = new Date();
  constructor(
    private commonDateService: CommonDateService,
    private localeService: BsLocaleService
  ) {
    this.AllTransactionDate = this.commonDateService.GetDateOnly();
    this.minDate = this.commonDateService.GetDateOnly();
  }

  ngOnInit() {

  }


  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  onApplyAll() {
    this.itemList.forEach(item => {
      item.TransactionDate = this.AllTransactionDate;
      //item.Quantity = this.AllAmount;
      item.Team = this.team;
      if ([
          ActionTypeEnum.RECEIPT,
          ActionTypeEnum.CHANGE_LOCATION,
          ActionTypeEnum.ISSUE,
          ActionTypeEnum.WRITE_OFF
        ].indexOf(this.actionType) > -1) {
        item.Keeper = this.AllKeeper;
        item.OriginalKeeper = this.AllKeeper;
      }
      if ([ActionTypeEnum.RECEIPT].indexOf(this.actionType) > -1) {
        item['PoNo'] = this.poNumber;
      }

      if ([ActionTypeEnum.RECEIPT, ActionTypeEnum.RETURN].indexOf(this.actionType) > -1) {
        item.TargetBin = this.AllBin;
      }

      if ([ActionTypeEnum.CHANGE_LOCATION].indexOf(this.actionType) > -1) {
        if ((item as InventoryBalance).Bin.ID != this.AllBin.ID || item.OriginalKeeper != this.AllKeeper) {
          item.TargetBin = this.AllBin;
        } else {
          item.TargetBin = null;
        }
      }

      if ([ActionTypeEnum.ISSUE].indexOf(this.actionType) > -1) {
        item = item as CommonInventoryBalance;
        item.IssueToProject = this.AllIssueToProject;
        item.KeeperName = this.keeperName;
      }

    });
    this.itemListChange.emit(this.itemList);
  }

}
