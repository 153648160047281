import { CdkDragEnter, CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Location, LocationsService, Project, ProjectApprover, ProjectApproversService } from '@gammon/inventory-api';
import { ApprovalType, ApprovalTypeEnum } from '@material/common';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalService, ModalStatus, ShareStateService } from '../../services';

type MoveApprovalCardDirection = -1 | 1;

const FORWARD = 1;
const BACKWARD = -1;

@Component({
    selector: 'approval-route',
    templateUrl: './approval-route.component.html',
    styleUrls: ['./approval-route.component.scss']
})
export class ApprovalRouteComponent implements OnInit, OnDestroy {

    bsModalRef: BsModalRef;
    @ViewChild('addApproverModal') addApproverModal: TemplateRef<any>;

    private _currentProject: Project;
    @Input() set project(value: Project) {
        this._currentProject = value;
        this.searchLocationList = [];
        this.selectedLocation = null;
    }

    get project(): Project {
      return this._currentProject;
    }

    private _approvalType: ApprovalType;
    @Input() set approvalType(value: ApprovalType) {
        this._approvalType = value;
        this.searchLocationList = [];
        this.selectedLocation = null;
    }

    get approvalType(): ApprovalType {
      return this._approvalType;
    }

    selectedLocation: Location;
    searchLocationList: Location[] = [];

    subscriptionList: Subscription[] = [];

    @Input() showAdd = false;
    approvalRoute: ProjectApprover[] = [];
    approvalRouteList: ProjectApprover[][] = [];

    @Input() approverRemovable = false;

    //DnD
    hasMoved = false;

    ApprovalType = ApprovalTypeEnum;

    selectSequence: number = 1;
    constructor(
        private projectApproversService: ProjectApproversService,
        private locationsService: LocationsService,
        private modalService: ModalService,
        private bsModalService: BsModalService,
        private shareStateService: ShareStateService
    ) { }

    ngOnInit() {
      this.searchApprovalRoute();
    }

    searchApprovalRoute() {
      this.approvalRouteList = [];
      this.projectApproversService.projectApproversGetSearchList({
        projectID: this.project.ID,
        locationID: (this.selectedLocation || {}).ID,
        approvalType: this.approvalType
      }).pipe(take(1))
      .subscribe(approvalRoute => {
        // this.approvalRoute = approvalRoute;
        approvalRoute.forEach(item => {
            if (item.ApprovalSequence) {
                let items = this.approvalRouteList[item.ApprovalSequence - 1]
                items ? this.approvalRouteList[item.ApprovalSequence - 1] = items : this.approvalRouteList[item.ApprovalSequence - 1] = [];
                this.approvalRouteList[item.ApprovalSequence - 1].push(item);
            }
        });
        this.sortApprovalRouteItem();
      });
    }

    sortApprovalRouteItem() {
        this.approvalRouteList.forEach(items => {
            items.forEach(item => {
                item.Approval_Sequence_Type === 'OR' ? item.Approval_Sequence_Type = 'OR' : item.Approval_Sequence_Type = 'AND'
            });
            items = items.sort((a, b) => b.Approval_Sequence_Type.localeCompare(a.Approval_Sequence_Type));
        });
    }

    searchLocations() {
        this.locationsService.locationsGetSearchList({projectID: this.project.ID})
        .pipe(take(1))
        .subscribe(pageData => {
            if (pageData && pageData.Items) {
                this.searchLocationList = pageData.Items;
                //this.selectedLocation = this.searchLocationList[0];
            }
        });
    }

    addApprover(event) {
        if (!!!this.approvalRouteList[this.selectSequence - 1]) {
            this.approvalRouteList[this.selectSequence - 1] = []
        }
        if (this.approvalRouteList[this.selectSequence - 1].findIndex(e => e.User.StaffID === event.User.StaffID) > -1) {
            this.modalService.showNotification(ModalStatus.danger, 'OPERATION_FAIL', 2000);
        } else {
            this.approvalRouteList[this.selectSequence - 1].push(event);
            this.sortApprovalRouteItem();
            this.projectApproversService.projectApproversCreate({model: event})
            .pipe(take(1))
            .subscribe(data => {
                if (data) {
                    this.modalService.showNotification(ModalStatus.success, 'OPERATION_SUCCESS', 2000);
                    this.searchApprovalRoute();
                }
            });
        }
    }

    removeApprover(approver: ProjectApprover) {
        this.projectApproversService.projectApproversDelete({projectApproverID: approver.ID})
        .pipe(take(1))
        .subscribe(data => {
            if (data) {
                this.modalService.showNotification(ModalStatus.success, 'OPERATION_SUCCESS', 2000);

                //Refresh approval route
                this.searchApprovalRoute();
            }
        });
    }

    /**
     * ++++++++++++++++++
     * |     Modals     |
     * ++++++++++++++++++
     */

    closeModal() {
        if (this.bsModalRef != null) { this.bsModalRef.hide(); }
    }

    showAddApproverModal(sequence: number) {
        this.selectSequence = sequence;
        const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
        this.bsModalRef = this.bsModalService.show(this.addApproverModal, config);
    }

    /**
     * +++++++++++++++++++++++++
     * |     Drag and Drop     |
     * +++++++++++++++++++++++++
     */
    entered(event: CdkDragDrop<string[]>) {
        // this.hasMoved = true;
        // this.shareStateService.emitBoolean(this.hasMoved);
        //this.moveApprovalCard(n, event, event.container.data < event.item.data ? FORWARD : BACKWARD);
        this.moveApprovalList(event.previousIndex, event.currentIndex);
    }

    moveApprovalList(previousIndex: number, currentIndex: number) {
        const routeList = this.approvalRouteList[previousIndex];
        this.approvalRouteList.splice(currentIndex, 0, routeList);
        this.approvalRouteList.splice(previousIndex + 1, 1);
        const arr = [];
        this.approvalRouteList.forEach((items, i) => {
            items.forEach(item => {
                item.ApprovalSequence = i + 1;
                arr.push(item);
            })
        });
        this.saveApprovalList();
    }

    saveApprovalList(list = this.approvalRouteList) {
        this.shareStateService.emitBoolean(true);
        this.approvalRoute = [];
        list.forEach(items => {
            items.forEach(item => {
                this.approvalRoute.push(item);
            });
        });
        this.shareStateService.emitApprovalRoute(this.approvalRoute);
    }

    moveApprovalCard(n: number, event: CdkDragEnter, direction: MoveApprovalCardDirection) {
      const sourceApprover: ProjectApprover = this.approvalRouteList[n].find(r => r.ApprovalSequence == event.item.data + 1);
      const targetApprover: ProjectApprover = this.approvalRouteList[n].find(r => r.ApprovalSequence == event.container.data + 1);
      const datas = [event.item.data, , event.container.data];

      // forward: datas[1 + (+1)] + 0, backward: datas[1 + (-1)] + 1
      let i = datas[1 + direction] + (direction > 0 ? 0 : 1);
      // forward: datas[1 - (+1)] + 0, backward: datas[1 - (-1)] + 1 *for change <= to <
      const condition = datas[1 - direction] + (direction > 0 ? 0 : 1);
      if (sourceApprover.ApprovalSequence - (1 * direction) == targetApprover.ApprovalSequence) {
        sourceApprover.ApprovalSequence = sourceApprover.ApprovalSequence - (1 * direction);
        targetApprover.ApprovalSequence = targetApprover.ApprovalSequence + (1 * direction);
      } else {
        for (; i < condition; i++) {
        this.approvalRouteList[n][i].ApprovalSequence = (this.approvalRouteList[n][i].ApprovalSequence) + (1 * direction);
        }
        sourceApprover.ApprovalSequence = event.container.data + 1;
      }
      this.sortApprovalRoute();
      this.shareStateService.emitApprovalRoute(this.approvalRoute);
    }

    sortApprovalRoute() {
        this.approvalRoute = this.approvalRoute.sort((a, b) => {
            if (a.ApprovalSequence < b.ApprovalSequence) { return -1; } else if (a.ApprovalSequence > b.ApprovalSequence) { return 1; } else { return 0; }
        });
    }

    /** SAVE button move to approval-controller component **/
    // saveApprovalRoute(){
    //     this.projectApproversService.projectApproversUpdateByList({models:this.approvalRoute}).subscribe(data=>{
    //        //Todo show toast;
    //        if(data) this.hasMoved = false;
    //        this.shareStateService.emitBoolean(this.hasMoved);
    //     });
    // }

    changeType() {
        this.sortApprovalRouteItem();
        this.saveApprovalList();
    }

    addSequence() {
        this.approvalRouteList.push([]);
        let ul = document.getElementsByClassName('p-tabview-nav')[0].querySelectorAll('li')
        let index = 0;
        for (let i = 0; i < ul.length; i++){
            if (ul[i].classList.contains('p-highlight')){
                index = i;
                break;
            }
        
        }
        let n = 0;
        let time = setInterval(() => {
            n++;
            document.getElementsByClassName('cdk-drop-list')[index].scrollTop += 100;
            if (n > 60) {
                clearInterval(time);
            }
        }, 10)
    }

    removeSequence(index: number) {
        const deleteList = this.approvalRouteList[index] || [];
        this.approvalRouteList.splice(index, 1);
        deleteList.forEach(item => item.Status = 'INACTIVE')
        this.approvalRouteList.forEach((items, i) => {
            items.forEach(item => {
                item.ApprovalSequence = i + 1;
            })
        });
        const list = [...this.approvalRouteList, deleteList];
        this.saveApprovalList(list);
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }
}
