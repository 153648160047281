import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Attachment, AttachmentsService, Project } from '@gammon/inventory-api';
import { CommonAttachment, RequestType } from '@material/common';
import { forkJoin, Observable, Subscription } from 'rxjs';
import * as mime from 'mime';
import { UUID } from 'uuid-generator-ts';
import { ModalService, ModalStatus } from '../../../services/modal.service';

@Component({
    selector: 'attachments-tab',
    templateUrl: './attachments-tab.component.html',
    styleUrls: ['./attachments-tab.component.scss']
})
export class AttachmentsTabComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() actionType: RequestType;

  _folderID: string;
  @Input() set folderID(value: string) {
      this._folderID = value;
      this.searchAttachments();
  }
  @Input() currentProject: Project;

  attachmentList: Attachment[] = [];
  subscriptionList: Subscription[] = [];

  previewIndex: number = null;

  @ViewChild('fileUpload') fileUpload: ElementRef;

  constructor(
    private attachmentsService: AttachmentsService,
    private modalService: ModalService) { }

  ngOnInit() {}

  ngAfterViewInit() { }

  searchAttachments() {
    if (this._folderID) {
      this.subscriptionList.push(this.attachmentsService.attachmentsGetAttachmentListMetaData({actionType: this.actionType, folderID: this._folderID}).subscribe(attachmentList => {
          this.attachmentList = attachmentList;
      }));
    }
  }

  downloadAttachment(attachment: Attachment, download: boolean = true) {
    if (!attachment.base64) {
      this.attachmentsService.attachmentsGetAttachment({actionType: attachment.actionType, folderID: attachment.folderID, fileName: attachment.fileName}).subscribe(data => {
        if (data && data.base64) {
            attachment.base64 = data.base64;
            this.download(attachment, download);
        }
      }, error => {
        console.error(error);
      });
    } else {
      this.download(attachment, download);
    }
  }

  download(attachment: Attachment, download: boolean = true) {
    const contentType = mime.getType(attachment.extension);
    console.log('content type: ' + contentType);
    const blob = this.base64toBlob(attachment.base64, contentType);

    if (download) {
      // const url = window.URL.createObjectURL(blob);
      // window.open(url);

      const link = window.document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      // Construct filename dynamically and set to link.download
      link.download = link.href.split('/').pop() + '.' + attachment.extension;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    }
  }

  removeAttachment(index: number) {
    const actionType = this.actionType;
    const folderID = this.attachmentList[index].folderID;
    const fileName = this.attachmentList[index].fileName;
    if(!folderID){
      // only removed attachment on the Attachment List, Not removed attachment on DB
      this.attachmentList.splice(index, 1);
      return;
    }
    //else to delete on DB
    this.attachmentsService.attachmentsDeleteAttachment({actionType, folderID, fileName}).subscribe(data => {
      console.log('delete attachment: ' + data);
      if (data === 'SUCCESS') {
          this.attachmentList.splice(index, 1);
          this.modalService.showNotification(ModalStatus.success, 'ATTACHMENT_DELETED', 2000);
      } else {
          this.modalService.showNotification(ModalStatus.danger, 'ATTACHMENT_DELETE_FAILED');
      }

    }, error => {
      console.error(error);
      this.modalService.showNotification(ModalStatus.danger, 'ATTACHMENT_DELETE_FAILED');
    });
  }

  previewAttachment(index: number) {
    this.previewIndex = index;

    if (!this.isImage(this.attachmentList[this.previewIndex].extension)) { return; }

    console.log('preview file: ' + this.previewIndex);
    this.downloadAttachment(this.attachmentList[this.previewIndex], false);
  }

  base64toBlob(base64: string, contentType?: string, sliceSize?: number) {
    console.log('base64 contentType: ' + contentType);
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  getThumbnailByExtension(extension: string): string {
    switch (extension) {
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'bmp':
        return'images';
      case 'doc':
      case 'docx':
        return 'file-word';
      case 'pdf':
        return 'file-pdf';
      case 'mp3':
      case 'wav':
      case 'mdi':
      case 'ogg':
        return 'file-audio';
      case 'zip':
      case 'rar':
      case '7z':
        return 'file-zip';
      case 'mp4':
      case 'wmv':
      case '3gp':
      case 'flv':
      case 'avi':
      case 'mkv':
        return 'file-video';
      default:
        return 'file';
    }
  }

  isImage(extension: string): boolean {
    switch (extension) {
      case 'jpg':
      case 'png':
      case 'jpeg':
      case 'gif':
      case 'tiff':
      case 'bmp':
        return true;
      default:
        return false;
    }
  }

  uuid: string;

  fileChangeEvent(fileInput: any) {
    // let moveTempFilesAfterUploadFolderID = null;
    // let cleanLocalAttachmentListAfterUploaded = true;
    // if (this._folderID) {
    //   moveTempFilesAfterUploadFolderID = this._folderID;
    //   cleanLocalAttachmentListAfterUploaded = false;
    // }

    this.handleAttachment(fileInput);
  }

  handleAttachment(fileInput: any) {
    console.log('handleAttachment');
    if (fileInput.target.files && fileInput.target.files[0]) {
      const file = fileInput.target.files[0];
      if (file.size > 10000000) {
        this.modalService.showNotification(ModalStatus.danger, 'ATTACHMENT_SIZE_EXCEEDED', 2000);
        return;
      }

      const actionType = this.actionType;

      const reader = new FileReader();
      reader.onload = (e: any) => {
        const base64 = e.target.result.substr(e.target.result.indexOf(',') + 1);
        const extension = this.getFileExtension(file.name);
        const attachment: CommonAttachment = {
          actionType,
          fileName: file.name,
          filePath: '',
          extension,
          folderID: this._folderID,
          base64,
          file
        };

        if (this._folderID) { // direct upload again, no need to confirm/submit
          this.uploadAttachmentObservable(file, attachment).subscribe(data => {
            if (data === 'SUCCESS') {
              this.attachmentList.push(attachment);
              this.modalService.showNotification(ModalStatus.success, 'ATTACHMENT_UPLOADED', 2000);
            } else {
              this.modalService.showNotification(ModalStatus.danger, 'ATTACHMENT_UPLOAD_FAILED');
            }
          });
        } else {
          this.attachmentList.push(attachment); // push locally and do upload when submitted
        }
      };

      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }

  uploadAttachmentObservable(file: any, attachment: Attachment): Observable<any> {
    const formData = new FormData();
    formData.append('files', file as Blob, file.name);

    const actionType = attachment.actionType;
    const folderID = attachment.folderID;
    return this.attachmentsService.attachmentsUploadAttachment({formData, actionType, folderID});
  }

  uploadTempAttachmentObservable(actionType: string, folderID: string): Observable<any[]> {
    const forkObservables = [];
    this.attachmentList.forEach((attachment: CommonAttachment) => {
      const file = attachment.file;
      const formData = new FormData();
      formData.append('files', file as Blob, file.name);

      const observable = this.attachmentsService.attachmentsUploadAttachment({formData, actionType, folderID});
      forkObservables.push(observable);
    });

    return forkJoin(forkObservables);
  }

  uploadTempAttachmentList(actionType: string, folderID: string) {
    if (this.attachmentList && this.attachmentList.length > 0) {
      this.uploadTempAttachmentObservable(actionType, folderID).subscribe(forkResults => {
        console.log(forkResults);
      });
    }
  }

  getFileExtension(path: string): string {
    return path.split('.').pop().split(/\#|\?/)[0];
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(subscription => {
      if (subscription) { subscription.unsubscribe(); }
    });
  }

}
