/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ActiveTaskDTO } from '../model/activeTaskDTO';
import { Approval } from '../model/approval';
import { EFormResponseDTO } from '../model/eFormResponseDTO';
import { ReturnDecisionMapDTO } from '../model/returnDecisionMapDTO';
import { UpdateApprovalDTO } from '../model/updateApprovalDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ApprovalServiceInterface, IapprovalAbortApprovalParams,IapprovalActiveTaskParams,IapprovalCompleteApprovalParams,IapprovalReturnDecisionMapParams,IapprovalReturnDecisionMapTemplateParams,IapprovalStartApprovalParams,IapprovalStartApprovalProcessParams,IapprovalTaskStartParams,IapprovalUpdateApprovalParams, }                            from './approval.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ApprovalService implements ApprovalServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param refNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalAbortApproval({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalAbortApprovalParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public approvalAbortApproval({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalAbortApprovalParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public approvalAbortApproval({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalAbortApprovalParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public approvalAbortApproval({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalAbortApprovalParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (refNo === null || refNo === undefined) {
            throw new Error('Required parameter refNo was null or undefined when calling approvalAbortApproval.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<boolean>(`${this.basePath}/Approval/AbortApproval/${encodeURIComponent(String(refNo))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param referenceNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalActiveTask({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalActiveTaskParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ActiveTaskDTO>>;
    public approvalActiveTask({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalActiveTaskParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ActiveTaskDTO>>>;
    public approvalActiveTask({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalActiveTaskParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ActiveTaskDTO>>>;
    @datastore
    public approvalActiveTask({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalActiveTaskParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (referenceNumber === null || referenceNumber === undefined) {
            throw new Error('Required parameter referenceNumber was null or undefined when calling approvalActiveTask.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ActiveTaskDTO>>(`${this.basePath}/Approval/ActiveTask/${encodeURIComponent(String(referenceNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param refNo 
     * @param decision 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalCompleteApproval({ deviceId, message, ignoreLoadingBar, formData, refNo, decision } : IapprovalCompleteApprovalParams, observe?: 'body', reportProgress?: boolean): Observable<Array<EFormResponseDTO>>;
    public approvalCompleteApproval({ deviceId, message, ignoreLoadingBar, formData, refNo, decision } : IapprovalCompleteApprovalParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EFormResponseDTO>>>;
    public approvalCompleteApproval({ deviceId, message, ignoreLoadingBar, formData, refNo, decision } : IapprovalCompleteApprovalParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EFormResponseDTO>>>;
    @datastore
    public approvalCompleteApproval({ deviceId, message, ignoreLoadingBar, formData, refNo, decision } : IapprovalCompleteApprovalParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (refNo === null || refNo === undefined) {
            throw new Error('Required parameter refNo was null or undefined when calling approvalCompleteApproval.');
        }
        if (decision === null || decision === undefined) {
            throw new Error('Required parameter decision was null or undefined when calling approvalCompleteApproval.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<Array<EFormResponseDTO>>(`${this.basePath}/Approval/CompleteApproval/${encodeURIComponent(String(refNo))}/${encodeURIComponent(String(decision))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param referenceNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalReturnDecisionMap({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalReturnDecisionMapParams, observe?: 'body', reportProgress?: boolean): Observable<ReturnDecisionMapDTO>;
    public approvalReturnDecisionMap({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalReturnDecisionMapParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReturnDecisionMapDTO>>;
    public approvalReturnDecisionMap({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalReturnDecisionMapParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReturnDecisionMapDTO>>;
    @datastore
    public approvalReturnDecisionMap({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalReturnDecisionMapParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (referenceNumber === null || referenceNumber === undefined) {
            throw new Error('Required parameter referenceNumber was null or undefined when calling approvalReturnDecisionMap.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ReturnDecisionMapDTO>(`${this.basePath}/Approval/ReturnDecisionMap/${encodeURIComponent(String(referenceNumber))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param refNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalReturnDecisionMapTemplate({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalReturnDecisionMapTemplateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalReturnDecisionMapTemplate({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalReturnDecisionMapTemplateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalReturnDecisionMapTemplate({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalReturnDecisionMapTemplateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalReturnDecisionMapTemplate({ deviceId, message, ignoreLoadingBar, formData, refNo } : IapprovalReturnDecisionMapTemplateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (refNo === null || refNo === undefined) {
            throw new Error('Required parameter refNo was null or undefined when calling approvalReturnDecisionMapTemplate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (refNo !== undefined && refNo !== null) {
            queryParameters = queryParameters.set('refNo', <any>refNo);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<string>(`${this.basePath}/Approval/ReturnDecisionMapTemplate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param referenceNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalStartApproval({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalStartApprovalParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalStartApproval({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalStartApprovalParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalStartApproval({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalStartApprovalParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalStartApproval({ deviceId, message, ignoreLoadingBar, formData, referenceNumber } : IapprovalStartApprovalParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (referenceNumber === null || referenceNumber === undefined) {
            throw new Error('Required parameter referenceNumber was null or undefined when calling approvalStartApproval.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (referenceNumber !== undefined && referenceNumber !== null) {
            queryParameters = queryParameters.set('referenceNumber', <any>referenceNumber);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Approval/StartApproval`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param approval 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalStartApprovalProcess({ deviceId, message, ignoreLoadingBar, formData, approval } : IapprovalStartApprovalProcessParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public approvalStartApprovalProcess({ deviceId, message, ignoreLoadingBar, formData, approval } : IapprovalStartApprovalProcessParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public approvalStartApprovalProcess({ deviceId, message, ignoreLoadingBar, formData, approval } : IapprovalStartApprovalProcessParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public approvalStartApprovalProcess({ deviceId, message, ignoreLoadingBar, formData, approval } : IapprovalStartApprovalProcessParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (approval === null || approval === undefined) {
            throw new Error('Required parameter approval was null or undefined when calling approvalStartApprovalProcess.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/Approval/StartApprovalProcess`,
            approval,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param refNo 
     * @param approver 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalTaskStart({ deviceId, message, ignoreLoadingBar, formData, refNo, approver } : IapprovalTaskStartParams, observe?: 'body', reportProgress?: boolean): Observable<Array<EFormResponseDTO>>;
    public approvalTaskStart({ deviceId, message, ignoreLoadingBar, formData, refNo, approver } : IapprovalTaskStartParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<EFormResponseDTO>>>;
    public approvalTaskStart({ deviceId, message, ignoreLoadingBar, formData, refNo, approver } : IapprovalTaskStartParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<EFormResponseDTO>>>;
    @datastore
    public approvalTaskStart({ deviceId, message, ignoreLoadingBar, formData, refNo, approver } : IapprovalTaskStartParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (refNo === null || refNo === undefined) {
            throw new Error('Required parameter refNo was null or undefined when calling approvalTaskStart.');
        }
        if (approver === null || approver === undefined) {
            throw new Error('Required parameter approver was null or undefined when calling approvalTaskStart.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<Array<EFormResponseDTO>>(`${this.basePath}/Approval/TaskStart/${encodeURIComponent(String(refNo))}/${encodeURIComponent(String(approver))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public approvalUpdateApproval({ deviceId, message, ignoreLoadingBar, formData, model } : IapprovalUpdateApprovalParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public approvalUpdateApproval({ deviceId, message, ignoreLoadingBar, formData, model } : IapprovalUpdateApprovalParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public approvalUpdateApproval({ deviceId, message, ignoreLoadingBar, formData, model } : IapprovalUpdateApprovalParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    @datastore
    public approvalUpdateApproval({ deviceId, message, ignoreLoadingBar, formData, model } : IapprovalUpdateApprovalParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling approvalUpdateApproval.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<any>(`${this.basePath}/Approval/UpdateApproval`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
