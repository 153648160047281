import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalService, ModalStatus } from '@material/web/shared/services';
import {
    UploadFile
  } from '../../../@pages/components/upload/interface';
@Component({
  selector: 'import-word-pdf',
  templateUrl: './import-word-pdf.component.html',
  styleUrls: ['./import-word-pdf.component.scss']
})

export class ImportWordPdfComponent implements OnInit {

    _title = 'IMPORT_WORD_PDF';
    @Input() set title(value) {
        this._title = value;
    }
    @Input() uploadLink: string;
    @Input() authHeader: string;
    @Input() FileMaxSize: number = 10240;//unit KB defult 10M
    @Input() Multiple: boolean = true;
    @Input() Accept: string ='.doc,.docx,application/pdf,.xlsx,.xls,.csv,image/jpeg,image/png,.bmp';
    @Input() FileType: string='doc,docx,pdf,xlsx,xls,csv,png,jpg,jpeg,bmp';
    @Input() FileList: UploadFile[] = [];//init fileList
    @Input() Preview: (file: UploadFile) => void;
    @Input() IsNeedCallApiToRemoveFile: boolean = false;
    @Input() IsNeedShowNoteOfFile: boolean = false;
    @Output() HandleRemove: EventEmitter<any> = new EventEmitter<any>();
    // tslint:disable-next-line: no-output-native
    @Output() close = new EventEmitter<any>();
    @Input() IsNeedCallApiToDownloadFile: boolean = false;
    @Output() handleDownloadFile: EventEmitter<any> = new EventEmitter<any>();
    @Output() handleDownloadAllFile: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        private modalService: ModalService
    ) { }

    ngOnInit() {


    }

    downloadFile(file: UploadFile): void {
        this.handleDownloadFile.emit(file);
    }

    handleDownloadAll(files:UploadFile[]):void{
        this.handleDownloadAllFile.emit(files);
    }

    handleChange(data): void {
        if (data && data.file && data.file.status == 'complete') {
            this.modalService.showMessageModal(ModalStatus.success, 'OPERATION_SUCCESS', 'UPLOADED_SUCCESSFULLY');
        } else if (data && data.file && data.file.status == 'error') {
            let errorMsg = (data.file.error && data.file.error.error && data.file.error.error.ExceptionMessage && typeof (data.file.error.error.ExceptionMessage) === 'string') ? data.file.error.error.ExceptionMessage : (data.file.message || 'UPLOADED_FAIL');
            this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', errorMsg);
        }
    }

    handleClose(file: UploadFile): void {
        this.HandleRemove.emit(file);
    }

    closeModal() {
        this.close.emit();
    }
}
