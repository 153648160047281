import { Injectable } from '@angular/core';;
import 'rxjs/add/operator/map';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class QuickviewService {

  constructor(private http: HttpClient) { }

  // Get from the API
  getNotes() {
    return this.http.get('assets/data/notes.json')
      //.map(res => res.json());// change from Http => HttpClient
  }

  getUsers() {
    return this.http.get('assets/data/users.json')
      //.map(res => res.json());
  }

  getChatMessages() {
    return this.http.get('assets/data/messages.json')
      //.map(res => res.json());
  }

  getProjects() {
    return this.http.get('assets/data/projects.json')
      //.map(res => res.json());
  }

}
