/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { CheckInventoryBalanceResult } from '../model/checkInventoryBalanceResult';
import { GetTaggingFilesResult } from '../model/getTaggingFilesResult';
import { InventoryBalance } from '../model/inventoryBalance';
import { ItemBalance } from '../model/itemBalance';
import { MmReceipt } from '../model/mmReceipt';
import { MonthlyItemBalance } from '../model/monthlyItemBalance';
import { PageInventoryBalance } from '../model/pageInventoryBalance';
import { PageInventoryBalanceForSearchItemsDTO } from '../model/pageInventoryBalanceForSearchItemsDTO';
import { PageItemVariantBalance } from '../model/pageItemVariantBalance';
import { PageItemVariantLocationBalance } from '../model/pageItemVariantLocationBalance';
import { PageLocationItemVariantBalance } from '../model/pageLocationItemVariantBalance';
import { SplitAllInventoryBalanceResult } from '../model/splitAllInventoryBalanceResult';
import { StockMovement } from '../model/stockMovement';
import { UploadPlantNosToGetBalancesResult } from '../model/uploadPlantNosToGetBalancesResult';
import { UploadToStockReceiptResult } from '../model/uploadToStockReceiptResult';
import { XYChart } from '../model/xYChart';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { InventoryBalancesServiceInterface, IinventoryBalancesBalanceByPeriodParams,IinventoryBalancesCheckInventoryBalanceParams,IinventoryBalancesCreateParams,IinventoryBalancesCreateOrUpdateInventoryBalanceByMovementsParams,IinventoryBalancesDeleteParams,IinventoryBalancesDeleteTaggingFilesParams,IinventoryBalancesDownloadItemTaggingExcelParams,IinventoryBalancesDownloadItemTaggingFileParams,IinventoryBalancesDownloadNeedStockReceiveVariantsExcelParams,IinventoryBalancesGetExpiredCountParams,IinventoryBalancesGetExpiredDateParams,IinventoryBalancesGetExpiredItemsParams,IinventoryBalancesGetInventoryBalanceSearchListParams,IinventoryBalancesGetListParams,IinventoryBalancesGetSearchListParams,IinventoryBalancesGetTaggingFilesParams,IinventoryBalancesGroupByItemVariantParams,IinventoryBalancesImportItemTaggingFileParams,IinventoryBalancesQuantitySumByBinParams,IinventoryBalancesQuantitySumByLotParams,IinventoryBalancesQuantitySumByVariantParams,IinventoryBalancesReceiptByProductCodeParams,IinventoryBalancesSplitAllInventoryBalanceParams,IinventoryBalancesStatMaterialLocationStatusParams,IinventoryBalancesStatMaterialStatusParams,IinventoryBalancesStatReceivedItemsByMonthParams,IinventoryBalancesStatReceivedTrendParams,IinventoryBalancesStatTopIssuedItemsParams,IinventoryBalancesStatTotalReceivedParams,IinventoryBalancesUpdateParams,IinventoryBalancesUpdateByListParams,IinventoryBalancesUpdateTaggingParams,IinventoryBalancesUploadNeedStockReceiveVariantsAttachmentParams,IinventoryBalancesUploadPlantNosToGetBalancesParams, }                            from './inventoryBalances.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class InventoryBalancesService implements InventoryBalancesServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param ivID 
     * @param projectNumber 
     * @param months 
     * @param groupBy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesBalanceByPeriod({ deviceId, message, ignoreLoadingBar, formData, ivID, projectNumber, months, groupBy } : IinventoryBalancesBalanceByPeriodParams, observe?: 'body', reportProgress?: boolean): Observable<Array<XYChart>>;
    public inventoryBalancesBalanceByPeriod({ deviceId, message, ignoreLoadingBar, formData, ivID, projectNumber, months, groupBy } : IinventoryBalancesBalanceByPeriodParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<XYChart>>>;
    public inventoryBalancesBalanceByPeriod({ deviceId, message, ignoreLoadingBar, formData, ivID, projectNumber, months, groupBy } : IinventoryBalancesBalanceByPeriodParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<XYChart>>>;
    @datastore
    public inventoryBalancesBalanceByPeriod({ deviceId, message, ignoreLoadingBar, formData, ivID, projectNumber, months, groupBy } : IinventoryBalancesBalanceByPeriodParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (ivID === null || ivID === undefined) {
            throw new Error('Required parameter ivID was null or undefined when calling inventoryBalancesBalanceByPeriod.');
        }
        if (projectNumber === null || projectNumber === undefined) {
            throw new Error('Required parameter projectNumber was null or undefined when calling inventoryBalancesBalanceByPeriod.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ivID !== undefined && ivID !== null) {
            queryParameters = queryParameters.set('ivID', <any>ivID);
        }
        if (projectNumber !== undefined && projectNumber !== null) {
            queryParameters = queryParameters.set('projectNumber', <any>projectNumber);
        }
        if (months !== undefined && months !== null) {
            queryParameters = queryParameters.set('months', <any>months);
        }
        if (groupBy !== undefined && groupBy !== null) {
            queryParameters = queryParameters.set('groupBy', <any>groupBy);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<XYChart>>(`${this.basePath}/InventoryBalances/BalanceByPeriod`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param idQuantitysJsonString 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesCheckInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, idQuantitysJsonString } : IinventoryBalancesCheckInventoryBalanceParams, observe?: 'body', reportProgress?: boolean): Observable<Array<CheckInventoryBalanceResult>>;
    public inventoryBalancesCheckInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, idQuantitysJsonString } : IinventoryBalancesCheckInventoryBalanceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<CheckInventoryBalanceResult>>>;
    public inventoryBalancesCheckInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, idQuantitysJsonString } : IinventoryBalancesCheckInventoryBalanceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<CheckInventoryBalanceResult>>>;
    @datastore
    public inventoryBalancesCheckInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, idQuantitysJsonString } : IinventoryBalancesCheckInventoryBalanceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (idQuantitysJsonString === null || idQuantitysJsonString === undefined) {
            throw new Error('Required parameter idQuantitysJsonString was null or undefined when calling inventoryBalancesCheckInventoryBalance.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (idQuantitysJsonString !== undefined && idQuantitysJsonString !== null) {
            queryParameters = queryParameters.set('idQuantitysJsonString', <any>idQuantitysJsonString);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<CheckInventoryBalanceResult>>(`${this.basePath}/InventoryBalances/CheckInventoryBalance`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryBalancesCreateParams, observe?: 'body', reportProgress?: boolean): Observable<InventoryBalance>;
    public inventoryBalancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryBalancesCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InventoryBalance>>;
    public inventoryBalancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryBalancesCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InventoryBalance>>;
    @datastore
    public inventoryBalancesCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IinventoryBalancesCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling inventoryBalancesCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<InventoryBalance>(`${this.basePath}/InventoryBalances`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesCreateOrUpdateInventoryBalanceByMovements({ deviceId, message, ignoreLoadingBar, formData, model } : IinventoryBalancesCreateOrUpdateInventoryBalanceByMovementsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryBalance>>;
    public inventoryBalancesCreateOrUpdateInventoryBalanceByMovements({ deviceId, message, ignoreLoadingBar, formData, model } : IinventoryBalancesCreateOrUpdateInventoryBalanceByMovementsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryBalance>>>;
    public inventoryBalancesCreateOrUpdateInventoryBalanceByMovements({ deviceId, message, ignoreLoadingBar, formData, model } : IinventoryBalancesCreateOrUpdateInventoryBalanceByMovementsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryBalance>>>;
    @datastore
    public inventoryBalancesCreateOrUpdateInventoryBalanceByMovements({ deviceId, message, ignoreLoadingBar, formData, model } : IinventoryBalancesCreateOrUpdateInventoryBalanceByMovementsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling inventoryBalancesCreateOrUpdateInventoryBalanceByMovements.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<InventoryBalance>>(`${this.basePath}/InventoryBalances/CreateOrUpdateInventoryBalanceByMovements`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryBalanceID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID } : IinventoryBalancesDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryBalancesDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID } : IinventoryBalancesDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryBalancesDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID } : IinventoryBalancesDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryBalancesDelete({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID } : IinventoryBalancesDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryBalanceID === null || inventoryBalanceID === undefined) {
            throw new Error('Required parameter inventoryBalanceID was null or undefined when calling inventoryBalancesDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/InventoryBalances/${encodeURIComponent(String(inventoryBalanceID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotId 
     * @param fileName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesDeleteTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDeleteTaggingFilesParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public inventoryBalancesDeleteTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDeleteTaggingFilesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public inventoryBalancesDeleteTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDeleteTaggingFilesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public inventoryBalancesDeleteTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDeleteTaggingFilesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotId === null || itemLotId === undefined) {
            throw new Error('Required parameter itemLotId was null or undefined when calling inventoryBalancesDeleteTaggingFiles.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling inventoryBalancesDeleteTaggingFiles.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemLotId !== undefined && itemLotId !== null) {
            queryParameters = queryParameters.set('itemLotId', <any>itemLotId);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<boolean>(`${this.basePath}/InventoryBalances/DeleteTaggingFiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param param_projectID 
     * @param param_categoryID 
     * @param param_subCategoryID 
     * @param param_itemID 
     * @param param_itemLotID 
     * @param param_serialNumber 
     * @param param_plantNumber 
     * @param param_selectItemLotIDs 
     * @param param_model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesDownloadItemTaggingExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesDownloadItemTaggingExcelParams, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public inventoryBalancesDownloadItemTaggingExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesDownloadItemTaggingExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public inventoryBalancesDownloadItemTaggingExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesDownloadItemTaggingExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    @datastore
    public inventoryBalancesDownloadItemTaggingExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesDownloadItemTaggingExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_categoryID !== undefined && param_categoryID !== null) {
            queryParameters = queryParameters.set('param.categoryID', <any>param_categoryID);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemID !== undefined && param_itemID !== null) {
            queryParameters = queryParameters.set('param.itemID', <any>param_itemID);
        }
        if (param_itemLotID !== undefined && param_itemLotID !== null) {
            queryParameters = queryParameters.set('param.itemLotID', <any>param_itemLotID);
        }
        if (param_serialNumber !== undefined && param_serialNumber !== null) {
            queryParameters = queryParameters.set('param.serialNumber', <any>param_serialNumber);
        }
        if (param_plantNumber !== undefined && param_plantNumber !== null) {
            queryParameters = queryParameters.set('param.plantNumber', <any>param_plantNumber);
        }
        if (param_selectItemLotIDs) {
            param_selectItemLotIDs.forEach((element) => {
                queryParameters = queryParameters.append('param.selectItemLotIDs', <any>element);
            })
        }
        if (param_model !== undefined && param_model !== null) {
            queryParameters = queryParameters.set('param.model', <any>param_model);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get(`${this.basePath}/InventoryBalances/DownloadItemTaggingExcel`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotId 
     * @param fileName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesDownloadItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDownloadItemTaggingFileParams, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public inventoryBalancesDownloadItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDownloadItemTaggingFileParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public inventoryBalancesDownloadItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDownloadItemTaggingFileParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    @datastore
    public inventoryBalancesDownloadItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId, fileName } : IinventoryBalancesDownloadItemTaggingFileParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotId === null || itemLotId === undefined) {
            throw new Error('Required parameter itemLotId was null or undefined when calling inventoryBalancesDownloadItemTaggingFile.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling inventoryBalancesDownloadItemTaggingFile.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemLotId !== undefined && itemLotId !== null) {
            queryParameters = queryParameters.set('itemLotId', <any>itemLotId);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get(`${this.basePath}/InventoryBalances/DownloadItemTaggingFile`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param size 
     * @param param_projectID 
     * @param param_categoryID 
     * @param param_subCategoryID 
     * @param param_itemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesDownloadNeedStockReceiveVariantsExcel({ deviceId, message, ignoreLoadingBar, formData, size, param_projectID, param_categoryID, param_subCategoryID, param_itemID } : IinventoryBalancesDownloadNeedStockReceiveVariantsExcelParams, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public inventoryBalancesDownloadNeedStockReceiveVariantsExcel({ deviceId, message, ignoreLoadingBar, formData, size, param_projectID, param_categoryID, param_subCategoryID, param_itemID } : IinventoryBalancesDownloadNeedStockReceiveVariantsExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public inventoryBalancesDownloadNeedStockReceiveVariantsExcel({ deviceId, message, ignoreLoadingBar, formData, size, param_projectID, param_categoryID, param_subCategoryID, param_itemID } : IinventoryBalancesDownloadNeedStockReceiveVariantsExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    @datastore
    public inventoryBalancesDownloadNeedStockReceiveVariantsExcel({ deviceId, message, ignoreLoadingBar, formData, size, param_projectID, param_categoryID, param_subCategoryID, param_itemID } : IinventoryBalancesDownloadNeedStockReceiveVariantsExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (size === null || size === undefined) {
            throw new Error('Required parameter size was null or undefined when calling inventoryBalancesDownloadNeedStockReceiveVariantsExcel.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_categoryID !== undefined && param_categoryID !== null) {
            queryParameters = queryParameters.set('param.categoryID', <any>param_categoryID);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemID !== undefined && param_itemID !== null) {
            queryParameters = queryParameters.set('param.itemID', <any>param_itemID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get(`${this.basePath}/InventoryBalances/DownloadNeedStockReceiveVariantsExcel`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGetExpiredCount({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredCountParams, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public inventoryBalancesGetExpiredCount({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredCountParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public inventoryBalancesGetExpiredCount({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredCountParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    @datastore
    public inventoryBalancesGetExpiredCount({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredCountParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesGetExpiredCount.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<number>(`${this.basePath}/InventoryBalances/GetExpiredCount`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGetExpiredDate({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredDateParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Date>>;
    public inventoryBalancesGetExpiredDate({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredDateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Date>>>;
    public inventoryBalancesGetExpiredDate({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredDateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Date>>>;
    @datastore
    public inventoryBalancesGetExpiredDate({ deviceId, message, ignoreLoadingBar, formData, projectID } : IinventoryBalancesGetExpiredDateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesGetExpiredDate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Date>>(`${this.basePath}/InventoryBalances/GetExpiredDate`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param year 
     * @param month 
     * @param day 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGetExpiredItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, month, day, views } : IinventoryBalancesGetExpiredItemsParams, observe?: 'body', reportProgress?: boolean): Observable<PageInventoryBalance>;
    public inventoryBalancesGetExpiredItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, month, day, views } : IinventoryBalancesGetExpiredItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageInventoryBalance>>;
    public inventoryBalancesGetExpiredItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, month, day, views } : IinventoryBalancesGetExpiredItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageInventoryBalance>>;
    @datastore
    public inventoryBalancesGetExpiredItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, month, day, views } : IinventoryBalancesGetExpiredItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesGetExpiredItems.');
        }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling inventoryBalancesGetExpiredItems.');
        }
        if (month === null || month === undefined) {
            throw new Error('Required parameter month was null or undefined when calling inventoryBalancesGetExpiredItems.');
        }
        if (day === null || day === undefined) {
            throw new Error('Required parameter day was null or undefined when calling inventoryBalancesGetExpiredItems.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (day !== undefined && day !== null) {
            queryParameters = queryParameters.set('day', <any>day);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageInventoryBalance>(`${this.basePath}/InventoryBalances/GetExpiredItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param size 
     * @param allStatus 
     * @param isGroupBy 
     * @param param_inventoryBalanceID 
     * @param param_projectID 
     * @param param_division 
     * @param param_locationID 
     * @param param_areaID 
     * @param param_binID 
     * @param param_fromDate 
     * @param param_toDate 
     * @param param_employeeID 
     * @param param_keeperName 
     * @param param_categoryID 
     * @param param_subCategoryID 
     * @param param_itemID 
     * @param param_itemVariantID 
     * @param param_itemLotID 
     * @param param_quantity 
     * @param param_minQuantity 
     * @param param_maxQuantity 
     * @param param_brand 
     * @param param_model 
     * @param param_serialNumber 
     * @param param_gammonProductCode 
     * @param param_plantNumber 
     * @param param_itemChinese 
     * @param param_itemEnglish 
     * @param param_itemDescription 
     * @param param_filterWildcard 
     * @param param_showKeeper 
     * @param param_startCreateDate 
     * @param param_endCreateDate 
     * @param param_movementRemarks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGetInventoryBalanceSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, allStatus, isGroupBy, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetInventoryBalanceSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageInventoryBalanceForSearchItemsDTO>;
    public inventoryBalancesGetInventoryBalanceSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, allStatus, isGroupBy, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetInventoryBalanceSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageInventoryBalanceForSearchItemsDTO>>;
    public inventoryBalancesGetInventoryBalanceSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, allStatus, isGroupBy, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetInventoryBalanceSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageInventoryBalanceForSearchItemsDTO>>;
    @datastore
    public inventoryBalancesGetInventoryBalanceSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, allStatus, isGroupBy, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetInventoryBalanceSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (allStatus !== undefined && allStatus !== null) {
            queryParameters = queryParameters.set('allStatus', <any>allStatus);
        }
        if (isGroupBy !== undefined && isGroupBy !== null) {
            queryParameters = queryParameters.set('isGroupBy', <any>isGroupBy);
        }
        if (param_inventoryBalanceID !== undefined && param_inventoryBalanceID !== null) {
            queryParameters = queryParameters.set('param.inventoryBalanceID', <any>param_inventoryBalanceID);
        }
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_division !== undefined && param_division !== null) {
            queryParameters = queryParameters.set('param.division', <any>param_division);
        }
        if (param_locationID !== undefined && param_locationID !== null) {
            queryParameters = queryParameters.set('param.locationID', <any>param_locationID);
        }
        if (param_areaID !== undefined && param_areaID !== null) {
            queryParameters = queryParameters.set('param.areaID', <any>param_areaID);
        }
        if (param_binID !== undefined && param_binID !== null) {
            queryParameters = queryParameters.set('param.binID', <any>param_binID);
        }
        if (param_fromDate !== undefined && param_fromDate !== null) {
            queryParameters = queryParameters.set('param.fromDate', <any>param_fromDate.toISOString());
        }
        if (param_toDate !== undefined && param_toDate !== null) {
            queryParameters = queryParameters.set('param.toDate', <any>param_toDate.toISOString());
        }
        if (param_employeeID !== undefined && param_employeeID !== null) {
            queryParameters = queryParameters.set('param.employeeID', <any>param_employeeID);
        }
        if (param_keeperName !== undefined && param_keeperName !== null) {
            queryParameters = queryParameters.set('param.keeperName', <any>param_keeperName);
        }
        if (param_categoryID !== undefined && param_categoryID !== null) {
            queryParameters = queryParameters.set('param.categoryID', <any>param_categoryID);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemID !== undefined && param_itemID !== null) {
            queryParameters = queryParameters.set('param.itemID', <any>param_itemID);
        }
        if (param_itemVariantID !== undefined && param_itemVariantID !== null) {
            queryParameters = queryParameters.set('param.itemVariantID', <any>param_itemVariantID);
        }
        if (param_itemLotID !== undefined && param_itemLotID !== null) {
            queryParameters = queryParameters.set('param.itemLotID', <any>param_itemLotID);
        }
        if (param_quantity !== undefined && param_quantity !== null) {
            queryParameters = queryParameters.set('param.quantity', <any>param_quantity);
        }
        if (param_minQuantity !== undefined && param_minQuantity !== null) {
            queryParameters = queryParameters.set('param.minQuantity', <any>param_minQuantity);
        }
        if (param_maxQuantity !== undefined && param_maxQuantity !== null) {
            queryParameters = queryParameters.set('param.maxQuantity', <any>param_maxQuantity);
        }
        if (param_brand !== undefined && param_brand !== null) {
            queryParameters = queryParameters.set('param.brand', <any>param_brand);
        }
        if (param_model !== undefined && param_model !== null) {
            queryParameters = queryParameters.set('param.model', <any>param_model);
        }
        if (param_serialNumber !== undefined && param_serialNumber !== null) {
            queryParameters = queryParameters.set('param.serialNumber', <any>param_serialNumber);
        }
        if (param_gammonProductCode !== undefined && param_gammonProductCode !== null) {
            queryParameters = queryParameters.set('param.gammonProductCode', <any>param_gammonProductCode);
        }
        if (param_plantNumber !== undefined && param_plantNumber !== null) {
            queryParameters = queryParameters.set('param.plantNumber', <any>param_plantNumber);
        }
        if (param_itemChinese !== undefined && param_itemChinese !== null) {
            queryParameters = queryParameters.set('param.itemChinese', <any>param_itemChinese);
        }
        if (param_itemEnglish !== undefined && param_itemEnglish !== null) {
            queryParameters = queryParameters.set('param.itemEnglish', <any>param_itemEnglish);
        }
        if (param_itemDescription !== undefined && param_itemDescription !== null) {
            queryParameters = queryParameters.set('param.itemDescription', <any>param_itemDescription);
        }
        if (param_filterWildcard !== undefined && param_filterWildcard !== null) {
            queryParameters = queryParameters.set('param.filterWildcard', <any>param_filterWildcard);
        }
        if (param_showKeeper !== undefined && param_showKeeper !== null) {
            queryParameters = queryParameters.set('param.showKeeper', <any>param_showKeeper);
        }
        if (param_startCreateDate !== undefined && param_startCreateDate !== null) {
            queryParameters = queryParameters.set('param.startCreateDate', <any>param_startCreateDate.toISOString());
        }
        if (param_endCreateDate !== undefined && param_endCreateDate !== null) {
            queryParameters = queryParameters.set('param.endCreateDate', <any>param_endCreateDate.toISOString());
        }
        if (param_movementRemarks !== undefined && param_movementRemarks !== null) {
            queryParameters = queryParameters.set('param.movementRemarks', <any>param_movementRemarks);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageInventoryBalanceForSearchItemsDTO>(`${this.basePath}/InventoryBalances/GetInventoryBalanceSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryBalanceID 
     * @param itemLotID 
     * @param itemVariantID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGetList({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, itemLotID, itemVariantID, page, views } : IinventoryBalancesGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryBalance>>;
    public inventoryBalancesGetList({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, itemLotID, itemVariantID, page, views } : IinventoryBalancesGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryBalance>>>;
    public inventoryBalancesGetList({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, itemLotID, itemVariantID, page, views } : IinventoryBalancesGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryBalance>>>;
    @datastore
    public inventoryBalancesGetList({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, itemLotID, itemVariantID, page, views } : IinventoryBalancesGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (inventoryBalanceID !== undefined && inventoryBalanceID !== null) {
            queryParameters = queryParameters.set('inventoryBalanceID', <any>inventoryBalanceID);
        }
        if (itemLotID !== undefined && itemLotID !== null) {
            queryParameters = queryParameters.set('itemLotID', <any>itemLotID);
        }
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<InventoryBalance>>(`${this.basePath}/InventoryBalances`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param page 
     * @param size 
     * @param views 
     * @param allStatus 
     * @param param_inventoryBalanceID 
     * @param param_projectID 
     * @param param_division 
     * @param param_locationID 
     * @param param_areaID 
     * @param param_binID 
     * @param param_fromDate 
     * @param param_toDate 
     * @param param_employeeID 
     * @param param_keeperName 
     * @param param_categoryID 
     * @param param_subCategoryID 
     * @param param_itemID 
     * @param param_itemVariantID 
     * @param param_itemLotID 
     * @param param_quantity 
     * @param param_minQuantity 
     * @param param_maxQuantity 
     * @param param_brand 
     * @param param_model 
     * @param param_serialNumber 
     * @param param_gammonProductCode 
     * @param param_plantNumber 
     * @param param_itemChinese 
     * @param param_itemEnglish 
     * @param param_itemDescription 
     * @param param_filterWildcard 
     * @param param_showKeeper 
     * @param param_startCreateDate 
     * @param param_endCreateDate 
     * @param param_movementRemarks 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, views, allStatus, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageInventoryBalance>;
    public inventoryBalancesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, views, allStatus, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageInventoryBalance>>;
    public inventoryBalancesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, views, allStatus, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageInventoryBalance>>;
    @datastore
    public inventoryBalancesGetSearchList({ deviceId, message, ignoreLoadingBar, formData, page, size, views, allStatus, param_inventoryBalanceID, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_fromDate, param_toDate, param_employeeID, param_keeperName, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_quantity, param_minQuantity, param_maxQuantity, param_brand, param_model, param_serialNumber, param_gammonProductCode, param_plantNumber, param_itemChinese, param_itemEnglish, param_itemDescription, param_filterWildcard, param_showKeeper, param_startCreateDate, param_endCreateDate, param_movementRemarks } : IinventoryBalancesGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        if (allStatus !== undefined && allStatus !== null) {
            queryParameters = queryParameters.set('allStatus', <any>allStatus);
        }
        if (param_inventoryBalanceID !== undefined && param_inventoryBalanceID !== null) {
            queryParameters = queryParameters.set('param.inventoryBalanceID', <any>param_inventoryBalanceID);
        }
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_division !== undefined && param_division !== null) {
            queryParameters = queryParameters.set('param.division', <any>param_division);
        }
        if (param_locationID !== undefined && param_locationID !== null) {
            queryParameters = queryParameters.set('param.locationID', <any>param_locationID);
        }
        if (param_areaID !== undefined && param_areaID !== null) {
            queryParameters = queryParameters.set('param.areaID', <any>param_areaID);
        }
        if (param_binID !== undefined && param_binID !== null) {
            queryParameters = queryParameters.set('param.binID', <any>param_binID);
        }
        if (param_fromDate !== undefined && param_fromDate !== null) {
            queryParameters = queryParameters.set('param.fromDate', <any>param_fromDate.toISOString());
        }
        if (param_toDate !== undefined && param_toDate !== null) {
            queryParameters = queryParameters.set('param.toDate', <any>param_toDate.toISOString());
        }
        if (param_employeeID !== undefined && param_employeeID !== null) {
            queryParameters = queryParameters.set('param.employeeID', <any>param_employeeID);
        }
        if (param_keeperName !== undefined && param_keeperName !== null) {
            queryParameters = queryParameters.set('param.keeperName', <any>param_keeperName);
        }
        if (param_categoryID !== undefined && param_categoryID !== null) {
            queryParameters = queryParameters.set('param.categoryID', <any>param_categoryID);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemID !== undefined && param_itemID !== null) {
            queryParameters = queryParameters.set('param.itemID', <any>param_itemID);
        }
        if (param_itemVariantID !== undefined && param_itemVariantID !== null) {
            queryParameters = queryParameters.set('param.itemVariantID', <any>param_itemVariantID);
        }
        if (param_itemLotID !== undefined && param_itemLotID !== null) {
            queryParameters = queryParameters.set('param.itemLotID', <any>param_itemLotID);
        }
        if (param_quantity !== undefined && param_quantity !== null) {
            queryParameters = queryParameters.set('param.quantity', <any>param_quantity);
        }
        if (param_minQuantity !== undefined && param_minQuantity !== null) {
            queryParameters = queryParameters.set('param.minQuantity', <any>param_minQuantity);
        }
        if (param_maxQuantity !== undefined && param_maxQuantity !== null) {
            queryParameters = queryParameters.set('param.maxQuantity', <any>param_maxQuantity);
        }
        if (param_brand !== undefined && param_brand !== null) {
            queryParameters = queryParameters.set('param.brand', <any>param_brand);
        }
        if (param_model !== undefined && param_model !== null) {
            queryParameters = queryParameters.set('param.model', <any>param_model);
        }
        if (param_serialNumber !== undefined && param_serialNumber !== null) {
            queryParameters = queryParameters.set('param.serialNumber', <any>param_serialNumber);
        }
        if (param_gammonProductCode !== undefined && param_gammonProductCode !== null) {
            queryParameters = queryParameters.set('param.gammonProductCode', <any>param_gammonProductCode);
        }
        if (param_plantNumber !== undefined && param_plantNumber !== null) {
            queryParameters = queryParameters.set('param.plantNumber', <any>param_plantNumber);
        }
        if (param_itemChinese !== undefined && param_itemChinese !== null) {
            queryParameters = queryParameters.set('param.itemChinese', <any>param_itemChinese);
        }
        if (param_itemEnglish !== undefined && param_itemEnglish !== null) {
            queryParameters = queryParameters.set('param.itemEnglish', <any>param_itemEnglish);
        }
        if (param_itemDescription !== undefined && param_itemDescription !== null) {
            queryParameters = queryParameters.set('param.itemDescription', <any>param_itemDescription);
        }
        if (param_filterWildcard !== undefined && param_filterWildcard !== null) {
            queryParameters = queryParameters.set('param.filterWildcard', <any>param_filterWildcard);
        }
        if (param_showKeeper !== undefined && param_showKeeper !== null) {
            queryParameters = queryParameters.set('param.showKeeper', <any>param_showKeeper);
        }
        if (param_startCreateDate !== undefined && param_startCreateDate !== null) {
            queryParameters = queryParameters.set('param.startCreateDate', <any>param_startCreateDate.toISOString());
        }
        if (param_endCreateDate !== undefined && param_endCreateDate !== null) {
            queryParameters = queryParameters.set('param.endCreateDate', <any>param_endCreateDate.toISOString());
        }
        if (param_movementRemarks !== undefined && param_movementRemarks !== null) {
            queryParameters = queryParameters.set('param.movementRemarks', <any>param_movementRemarks);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageInventoryBalance>(`${this.basePath}/InventoryBalances/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGetTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesGetTaggingFilesParams, observe?: 'body', reportProgress?: boolean): Observable<GetTaggingFilesResult>;
    public inventoryBalancesGetTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesGetTaggingFilesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GetTaggingFilesResult>>;
    public inventoryBalancesGetTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesGetTaggingFilesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GetTaggingFilesResult>>;
    @datastore
    public inventoryBalancesGetTaggingFiles({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesGetTaggingFilesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotId === null || itemLotId === undefined) {
            throw new Error('Required parameter itemLotId was null or undefined when calling inventoryBalancesGetTaggingFiles.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemLotId !== undefined && itemLotId !== null) {
            queryParameters = queryParameters.set('itemLotId', <any>itemLotId);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<GetTaggingFilesResult>(`${this.basePath}/InventoryBalances/GetTaggingFiles`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesGroupByItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, page, size } : IinventoryBalancesGroupByItemVariantParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariantBalance>;
    public inventoryBalancesGroupByItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, page, size } : IinventoryBalancesGroupByItemVariantParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariantBalance>>;
    public inventoryBalancesGroupByItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, page, size } : IinventoryBalancesGroupByItemVariantParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariantBalance>>;
    @datastore
    public inventoryBalancesGroupByItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, page, size } : IinventoryBalancesGroupByItemVariantParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesGroupByItemVariant.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling inventoryBalancesGroupByItemVariant.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariantBalance>(`${this.basePath}/InventoryBalances/GroupByItemVariant`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemLotId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesImportItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesImportItemTaggingFileParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public inventoryBalancesImportItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesImportItemTaggingFileParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public inventoryBalancesImportItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesImportItemTaggingFileParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    @datastore
    public inventoryBalancesImportItemTaggingFile({ deviceId, message, ignoreLoadingBar, formData, itemLotId } : IinventoryBalancesImportItemTaggingFileParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemLotId === null || itemLotId === undefined) {
            throw new Error('Required parameter itemLotId was null or undefined when calling inventoryBalancesImportItemTaggingFile.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<any>(`${this.basePath}/InventoryBalances/ImportItemTaggingFile/${encodeURIComponent(String(itemLotId))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param locationID 
     * @param areaID 
     * @param binID 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesQuantitySumByBin({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, areaID, binID, page, size } : IinventoryBalancesQuantitySumByBinParams, observe?: 'body', reportProgress?: boolean): Observable<PageLocationItemVariantBalance>;
    public inventoryBalancesQuantitySumByBin({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, areaID, binID, page, size } : IinventoryBalancesQuantitySumByBinParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageLocationItemVariantBalance>>;
    public inventoryBalancesQuantitySumByBin({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, areaID, binID, page, size } : IinventoryBalancesQuantitySumByBinParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageLocationItemVariantBalance>>;
    @datastore
    public inventoryBalancesQuantitySumByBin({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, areaID, binID, page, size } : IinventoryBalancesQuantitySumByBinParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesQuantitySumByBin.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (binID !== undefined && binID !== null) {
            queryParameters = queryParameters.set('binID', <any>binID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageLocationItemVariantBalance>(`${this.basePath}/InventoryBalances/QuantitySumByBin`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param searchByEmployeeID 
     * @param projectID 
     * @param division 
     * @param locationID 
     * @param areaID 
     * @param binID 
     * @param fromDate 
     * @param toDate 
     * @param EmployeeID 
     * @param keeperName 
     * @param categoryID 
     * @param subCategoryID 
     * @param itemID 
     * @param itemLotID 
     * @param brand 
     * @param model 
     * @param serialNumber 
     * @param plantNumber 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesQuantitySumByLot({ deviceId, message, ignoreLoadingBar, formData, searchByEmployeeID, projectID, division, locationID, areaID, binID, fromDate, toDate, EmployeeID, keeperName, categoryID, subCategoryID, itemID, itemLotID, brand, model, serialNumber, plantNumber, page, size, views } : IinventoryBalancesQuantitySumByLotParams, observe?: 'body', reportProgress?: boolean): Observable<PageInventoryBalance>;
    public inventoryBalancesQuantitySumByLot({ deviceId, message, ignoreLoadingBar, formData, searchByEmployeeID, projectID, division, locationID, areaID, binID, fromDate, toDate, EmployeeID, keeperName, categoryID, subCategoryID, itemID, itemLotID, brand, model, serialNumber, plantNumber, page, size, views } : IinventoryBalancesQuantitySumByLotParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageInventoryBalance>>;
    public inventoryBalancesQuantitySumByLot({ deviceId, message, ignoreLoadingBar, formData, searchByEmployeeID, projectID, division, locationID, areaID, binID, fromDate, toDate, EmployeeID, keeperName, categoryID, subCategoryID, itemID, itemLotID, brand, model, serialNumber, plantNumber, page, size, views } : IinventoryBalancesQuantitySumByLotParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageInventoryBalance>>;
    @datastore
    public inventoryBalancesQuantitySumByLot({ deviceId, message, ignoreLoadingBar, formData, searchByEmployeeID, projectID, division, locationID, areaID, binID, fromDate, toDate, EmployeeID, keeperName, categoryID, subCategoryID, itemID, itemLotID, brand, model, serialNumber, plantNumber, page, size, views } : IinventoryBalancesQuantitySumByLotParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (searchByEmployeeID === null || searchByEmployeeID === undefined) {
            throw new Error('Required parameter searchByEmployeeID was null or undefined when calling inventoryBalancesQuantitySumByLot.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchByEmployeeID !== undefined && searchByEmployeeID !== null) {
            queryParameters = queryParameters.set('searchByEmployeeID', <any>searchByEmployeeID);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (division !== undefined && division !== null) {
            queryParameters = queryParameters.set('division', <any>division);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (binID !== undefined && binID !== null) {
            queryParameters = queryParameters.set('binID', <any>binID);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (EmployeeID !== undefined && EmployeeID !== null) {
            queryParameters = queryParameters.set('EmployeeID', <any>EmployeeID);
        }
        if (keeperName !== undefined && keeperName !== null) {
            queryParameters = queryParameters.set('keeperName', <any>keeperName);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (itemLotID !== undefined && itemLotID !== null) {
            queryParameters = queryParameters.set('itemLotID', <any>itemLotID);
        }
        if (brand !== undefined && brand !== null) {
            queryParameters = queryParameters.set('brand', <any>brand);
        }
        if (model !== undefined && model !== null) {
            queryParameters = queryParameters.set('model', <any>model);
        }
        if (serialNumber !== undefined && serialNumber !== null) {
            queryParameters = queryParameters.set('serialNumber', <any>serialNumber);
        }
        if (plantNumber !== undefined && plantNumber !== null) {
            queryParameters = queryParameters.set('plantNumber', <any>plantNumber);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageInventoryBalance>(`${this.basePath}/InventoryBalances/QuantitySumByLot`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param subCategoryID 
     * @param itemID 
     * @param plantNumber 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesQuantitySumByVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, plantNumber, page, size } : IinventoryBalancesQuantitySumByVariantParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariantLocationBalance>;
    public inventoryBalancesQuantitySumByVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, plantNumber, page, size } : IinventoryBalancesQuantitySumByVariantParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariantLocationBalance>>;
    public inventoryBalancesQuantitySumByVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, plantNumber, page, size } : IinventoryBalancesQuantitySumByVariantParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariantLocationBalance>>;
    @datastore
    public inventoryBalancesQuantitySumByVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, plantNumber, page, size } : IinventoryBalancesQuantitySumByVariantParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesQuantitySumByVariant.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (plantNumber !== undefined && plantNumber !== null) {
            queryParameters = queryParameters.set('plantNumber', <any>plantNumber);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariantLocationBalance>(`${this.basePath}/InventoryBalances/QuantitySumByVariant`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param mmReceipt 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesReceiptByProductCode({ deviceId, message, ignoreLoadingBar, formData, mmReceipt } : IinventoryBalancesReceiptByProductCodeParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryBalance>>;
    public inventoryBalancesReceiptByProductCode({ deviceId, message, ignoreLoadingBar, formData, mmReceipt } : IinventoryBalancesReceiptByProductCodeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryBalance>>>;
    public inventoryBalancesReceiptByProductCode({ deviceId, message, ignoreLoadingBar, formData, mmReceipt } : IinventoryBalancesReceiptByProductCodeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryBalance>>>;
    @datastore
    public inventoryBalancesReceiptByProductCode({ deviceId, message, ignoreLoadingBar, formData, mmReceipt } : IinventoryBalancesReceiptByProductCodeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (mmReceipt === null || mmReceipt === undefined) {
            throw new Error('Required parameter mmReceipt was null or undefined when calling inventoryBalancesReceiptByProductCode.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<InventoryBalance>>(`${this.basePath}/InventoryBalances/ReceiptByProductCode`,
            mmReceipt,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param param_projectID 
     * @param param_categoryID 
     * @param param_subCategoryID 
     * @param param_itemID 
     * @param param_itemLotID 
     * @param param_serialNumber 
     * @param param_plantNumber 
     * @param param_selectItemLotIDs 
     * @param param_model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesSplitAllInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesSplitAllInventoryBalanceParams, observe?: 'body', reportProgress?: boolean): Observable<SplitAllInventoryBalanceResult>;
    public inventoryBalancesSplitAllInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesSplitAllInventoryBalanceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<SplitAllInventoryBalanceResult>>;
    public inventoryBalancesSplitAllInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesSplitAllInventoryBalanceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<SplitAllInventoryBalanceResult>>;
    @datastore
    public inventoryBalancesSplitAllInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_categoryID, param_subCategoryID, param_itemID, param_itemLotID, param_serialNumber, param_plantNumber, param_selectItemLotIDs, param_model } : IinventoryBalancesSplitAllInventoryBalanceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_categoryID !== undefined && param_categoryID !== null) {
            queryParameters = queryParameters.set('param.categoryID', <any>param_categoryID);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemID !== undefined && param_itemID !== null) {
            queryParameters = queryParameters.set('param.itemID', <any>param_itemID);
        }
        if (param_itemLotID !== undefined && param_itemLotID !== null) {
            queryParameters = queryParameters.set('param.itemLotID', <any>param_itemLotID);
        }
        if (param_serialNumber !== undefined && param_serialNumber !== null) {
            queryParameters = queryParameters.set('param.serialNumber', <any>param_serialNumber);
        }
        if (param_plantNumber !== undefined && param_plantNumber !== null) {
            queryParameters = queryParameters.set('param.plantNumber', <any>param_plantNumber);
        }
        if (param_selectItemLotIDs) {
            param_selectItemLotIDs.forEach((element) => {
                queryParameters = queryParameters.append('param.selectItemLotIDs', <any>element);
            })
        }
        if (param_model !== undefined && param_model !== null) {
            queryParameters = queryParameters.set('param.model', <any>param_model);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.put<SplitAllInventoryBalanceResult>(`${this.basePath}/InventoryBalances/SplitAllInventoryBalance`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param itemID 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesStatMaterialLocationStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialLocationStatusParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariantBalance>;
    public inventoryBalancesStatMaterialLocationStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialLocationStatusParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariantBalance>>;
    public inventoryBalancesStatMaterialLocationStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialLocationStatusParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariantBalance>>;
    @datastore
    public inventoryBalancesStatMaterialLocationStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialLocationStatusParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesStatMaterialLocationStatus.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling inventoryBalancesStatMaterialLocationStatus.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariantBalance>(`${this.basePath}/InventoryBalances/StatMaterialLocationStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param itemID 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesStatMaterialStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialStatusParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariantBalance>;
    public inventoryBalancesStatMaterialStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialStatusParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariantBalance>>;
    public inventoryBalancesStatMaterialStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialStatusParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariantBalance>>;
    @datastore
    public inventoryBalancesStatMaterialStatus({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatMaterialStatusParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesStatMaterialStatus.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling inventoryBalancesStatMaterialStatus.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariantBalance>(`${this.basePath}/InventoryBalances/StatMaterialStatus`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesStatReceivedItemsByMonth({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID } : IinventoryBalancesStatReceivedItemsByMonthParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemBalance>>;
    public inventoryBalancesStatReceivedItemsByMonth({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID } : IinventoryBalancesStatReceivedItemsByMonthParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemBalance>>>;
    public inventoryBalancesStatReceivedItemsByMonth({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID } : IinventoryBalancesStatReceivedItemsByMonthParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemBalance>>>;
    @datastore
    public inventoryBalancesStatReceivedItemsByMonth({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID } : IinventoryBalancesStatReceivedItemsByMonthParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesStatReceivedItemsByMonth.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling inventoryBalancesStatReceivedItemsByMonth.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemBalance>>(`${this.basePath}/InventoryBalances/StatReceivedItemsByMonth`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param month 
     * @param numberOfMonths 
     * @param count 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesStatReceivedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, month, numberOfMonths, count, views } : IinventoryBalancesStatReceivedTrendParams, observe?: 'body', reportProgress?: boolean): Observable<Array<MonthlyItemBalance>>;
    public inventoryBalancesStatReceivedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, month, numberOfMonths, count, views } : IinventoryBalancesStatReceivedTrendParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<MonthlyItemBalance>>>;
    public inventoryBalancesStatReceivedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, month, numberOfMonths, count, views } : IinventoryBalancesStatReceivedTrendParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<MonthlyItemBalance>>>;
    @datastore
    public inventoryBalancesStatReceivedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, month, numberOfMonths, count, views } : IinventoryBalancesStatReceivedTrendParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesStatReceivedTrend.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling inventoryBalancesStatReceivedTrend.');
        }
        if (month === null || month === undefined) {
            throw new Error('Required parameter month was null or undefined when calling inventoryBalancesStatReceivedTrend.');
        }
        if (numberOfMonths === null || numberOfMonths === undefined) {
            throw new Error('Required parameter numberOfMonths was null or undefined when calling inventoryBalancesStatReceivedTrend.');
        }
        if (count === null || count === undefined) {
            throw new Error('Required parameter count was null or undefined when calling inventoryBalancesStatReceivedTrend.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (month !== undefined && month !== null) {
            queryParameters = queryParameters.set('month', <any>month);
        }
        if (numberOfMonths !== undefined && numberOfMonths !== null) {
            queryParameters = queryParameters.set('numberOfMonths', <any>numberOfMonths);
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<MonthlyItemBalance>>(`${this.basePath}/InventoryBalances/StatReceivedTrend`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IinventoryBalancesStatTopIssuedItemsParams, observe?: 'body', reportProgress?: boolean): Observable<PageInventoryBalance>;
    public inventoryBalancesStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IinventoryBalancesStatTopIssuedItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageInventoryBalance>>;
    public inventoryBalancesStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IinventoryBalancesStatTopIssuedItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageInventoryBalance>>;
    @datastore
    public inventoryBalancesStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IinventoryBalancesStatTopIssuedItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesStatTopIssuedItems.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageInventoryBalance>(`${this.basePath}/InventoryBalances/StatTopIssuedItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param itemID 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesStatTotalReceived({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatTotalReceivedParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariantBalance>;
    public inventoryBalancesStatTotalReceived({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatTotalReceivedParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariantBalance>>;
    public inventoryBalancesStatTotalReceived({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatTotalReceivedParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariantBalance>>;
    @datastore
    public inventoryBalancesStatTotalReceived({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, itemID, page, size } : IinventoryBalancesStatTotalReceivedParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling inventoryBalancesStatTotalReceived.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling inventoryBalancesStatTotalReceived.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariantBalance>(`${this.basePath}/InventoryBalances/StatTotalReceived`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param inventoryBalanceID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, model } : IinventoryBalancesUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryBalancesUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, model } : IinventoryBalancesUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryBalancesUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, model } : IinventoryBalancesUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryBalancesUpdate({ deviceId, message, ignoreLoadingBar, formData, inventoryBalanceID, model } : IinventoryBalancesUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (inventoryBalanceID === null || inventoryBalanceID === undefined) {
            throw new Error('Required parameter inventoryBalanceID was null or undefined when calling inventoryBalancesUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling inventoryBalancesUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/InventoryBalances/${encodeURIComponent(String(inventoryBalanceID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public inventoryBalancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public inventoryBalancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public inventoryBalancesUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling inventoryBalancesUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/InventoryBalances/UpdateTaggingByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesUpdateTagging({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateTaggingParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryBalance>>;
    public inventoryBalancesUpdateTagging({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateTaggingParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryBalance>>>;
    public inventoryBalancesUpdateTagging({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateTaggingParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryBalance>>>;
    @datastore
    public inventoryBalancesUpdateTagging({ deviceId, message, ignoreLoadingBar, formData, models } : IinventoryBalancesUpdateTaggingParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling inventoryBalancesUpdateTagging.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<InventoryBalance>>(`${this.basePath}/InventoryBalances/UpdateTagging`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param fileType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesUploadNeedStockReceiveVariantsAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IinventoryBalancesUploadNeedStockReceiveVariantsAttachmentParams, observe?: 'body', reportProgress?: boolean): Observable<UploadToStockReceiptResult>;
    public inventoryBalancesUploadNeedStockReceiveVariantsAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IinventoryBalancesUploadNeedStockReceiveVariantsAttachmentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadToStockReceiptResult>>;
    public inventoryBalancesUploadNeedStockReceiveVariantsAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IinventoryBalancesUploadNeedStockReceiveVariantsAttachmentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadToStockReceiptResult>>;
    @datastore
    public inventoryBalancesUploadNeedStockReceiveVariantsAttachment({ deviceId, message, ignoreLoadingBar, formData, fileType } : IinventoryBalancesUploadNeedStockReceiveVariantsAttachmentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileType === null || fileType === undefined) {
            throw new Error('Required parameter fileType was null or undefined when calling inventoryBalancesUploadNeedStockReceiveVariantsAttachment.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileType !== undefined && fileType !== null) {
            queryParameters = queryParameters.set('fileType', <any>fileType);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<UploadToStockReceiptResult>(`${this.basePath}/InventoryBalances/UploadNeedStockReceiveVariantsAttachment`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectId 
     * @param fileType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inventoryBalancesUploadPlantNosToGetBalances({ deviceId, message, ignoreLoadingBar, formData, projectId, fileType } : IinventoryBalancesUploadPlantNosToGetBalancesParams, observe?: 'body', reportProgress?: boolean): Observable<UploadPlantNosToGetBalancesResult>;
    public inventoryBalancesUploadPlantNosToGetBalances({ deviceId, message, ignoreLoadingBar, formData, projectId, fileType } : IinventoryBalancesUploadPlantNosToGetBalancesParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UploadPlantNosToGetBalancesResult>>;
    public inventoryBalancesUploadPlantNosToGetBalances({ deviceId, message, ignoreLoadingBar, formData, projectId, fileType } : IinventoryBalancesUploadPlantNosToGetBalancesParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UploadPlantNosToGetBalancesResult>>;
    @datastore
    public inventoryBalancesUploadPlantNosToGetBalances({ deviceId, message, ignoreLoadingBar, formData, projectId, fileType } : IinventoryBalancesUploadPlantNosToGetBalancesParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling inventoryBalancesUploadPlantNosToGetBalances.');
        }
        if (fileType === null || fileType === undefined) {
            throw new Error('Required parameter fileType was null or undefined when calling inventoryBalancesUploadPlantNosToGetBalances.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectId !== undefined && projectId !== null) {
            queryParameters = queryParameters.set('projectId', <any>projectId);
        }
        if (fileType !== undefined && fileType !== null) {
            queryParameters = queryParameters.set('fileType', <any>fileType);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<UploadPlantNosToGetBalancesResult>(`${this.basePath}/InventoryBalances/UploadPlantNosToGetBalances`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
