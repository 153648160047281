import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from './logging.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector) { }
    handleError(error: any): void {
        const loggingService = this.injector.get(LoggingService);
        // if(error instanceof Error) error.message = `GlobalErrorHandler::${error.message}`;

        const chunkFailedMessage = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test(error.message)) {
          window.location.reload();
        }

        loggingService.Error(error);
    }

}
