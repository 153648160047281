/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface UpdateApprovalDTO { 
    additionalParameterMap?: { [key: string]: string; };
    applicationCode?: UpdateApprovalDTO.ApplicationCodeEnum;
    formCode?: UpdateApprovalDTO.FormCodeEnum;
    approverAD?: string;
    comments?: string;
    decision?: UpdateApprovalDTO.DecisionEnum;
    processInstanceId?: number;
    taskId?: number;
}
export namespace UpdateApprovalDTO {
    export type ApplicationCodeEnum = 'EFORM2';
    export const ApplicationCodeEnum = {
        EFORM2: 'EFORM2' as ApplicationCodeEnum
    };
    export type FormCodeEnum = 'MM';
    export const FormCodeEnum = {
        MM: 'MM' as FormCodeEnum
    };
    export type DecisionEnum = 'APPROVED' | 'PENDING' | 'REJECTED' | 'BYPASSED' | 'CANCELLED';
    export const DecisionEnum = {
        APPROVED: 'APPROVED' as DecisionEnum,
        PENDING: 'PENDING' as DecisionEnum,
        REJECTED: 'REJECTED' as DecisionEnum,
        BYPASSED: 'BYPASSED' as DecisionEnum,
        CANCELLED: 'CANCELLED' as DecisionEnum
    };
}
