<ng-container *ngIf="!add">
    <pgcard [Controls]="true" [Close]="false" [Refresh]="false" [Maximize]="false" [Toggle]="false" [AdditionalClasses]="'h-100 approval-card'">
        <ng-template #CardTitle>
            <ng-container *ngIf="editable">
                <input class="form-control" [disabled]="ismodify" type="text" [(ngModel)]="approver.Type" max="20"/>
                <button class="btn btn-success" (click)="canModify()">{{'MODIFY'|translate}}</button>
            </ng-container>

            <ng-container *ngIf="!editable">
                <span *ngIf="approver">{{approver.Type}}</span>
            </ng-container>

        </ng-template>

        <ng-template #CardExtraControls>
            <span class="cursor-pointer" *ngIf="removable" (click)="removeApprover()"><i class="fas fa-times"></i></span>
        </ng-template>

        <div *ngIf="approver">
            <div class="row">
                <div class="col text-center">
                    <ng-container *ngIf="approver.User; else defaultImage">
                        <img default="assets/images/avatar.png" height="113px" [src]="'https://intranet.gammonconstruction.com/PeopleDirectory_Picture/'+approver.User.PhotoUrl" />
                    </ng-container>
                    <ng-template #defaultImage>
                        <img default="assets/images/avatar.png" height="113px" />
                    </ng-template>
                </div>
            </div>

            <div class="row">
                <div class="col-4 my-auto">
                    <h6 class="semi-bold">{{'NAME'|translate}}</h6>
                </div>
                <div class="col-8 my-auto">
                    <ng-container *ngIf="approver.User; else defaultName">
                        {{ approver.User.Name || approver.ApproverAD }}
                    </ng-container>
                    <ng-template #defaultName>
                        {{approver.ApproverAD}}
                    </ng-template>
                </div>
            </div>

            <div class="row" *ngIf="showTitle">
                <div class="col-4 my-auto">
                    <h6 class="semi-bold">{{'STAFF_TITLE'|translate}}</h6>
                </div>
                <div class="col-8 my-auto" *ngIf="approver.User">
                    {{approver.User.Title}}
                </div>
            </div>

            <div class="row">
                <div class="col-4 my-auto">
                    <h6 class="semi-bold">{{'STAFF_ID'|translate}}</h6 >
                </div>
                <div class="col-8 my-auto" *ngIf="approver.User">
                    {{approver.User.StaffID}}
                </div>
            </div>

            <div class="row" *ngIf="showSeq">
                <div class="col-4 my-auto">
                    <h6 class="semi-bold">{{'SEQUENCE'|translate}}</h6 >
                </div>
                <div class="col-8 my-auto" *ngIf="approver.User">
                    {{approver.ApprovalSequence}}
                </div>
            </div>

            <div class="row">
                <div class="col-4 my-auto">
                    <h6 class="semi-bold">{{'TYPE'|translate}}</h6 >
                </div>
                <div class="col-8 my-auto" *ngIf="approver.User">
                    <ng-container *ngIf="editType; else editTypeSelect">
                        <ng-select [items]="['AND', 'OR']" [(ngModel)]="approver.Approval_Sequence_Type" appendTo="body" [clearable]="false" (ngModelChange)="onChangeType()">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item }}</span>
                            </ng-template>
                        </ng-select>
                    </ng-container>
                    <ng-template #editTypeSelect>
                        {{approver.Approval_Sequence_Type}}
                    </ng-template>
                </div>
            </div>
        </div>
    </pgcard>
</ng-container>

<ng-container *ngIf="add">
    <pgcard [MinimalHeader]="true" [Refresh]="false" [Controls]="false" [AdditionalClasses]="'h-100 cursor-pointer add-approval'">
        <ng-template #CardTitle>
            {{'ADD_APPROVER'|translate}}
        </ng-template>
        <div class="m-auto text-center d-flex flex-column justify-content-center">
            <h3 class="fa-custom-color"><i class="fas fa-plus-circle fa-3x"></i></h3>
        </div>
    </pgcard>
</ng-container>
