/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ApprovalHeader } from './approvalHeader';
import { Budget } from './budget';
import { InventoryBalance } from './inventoryBalance';
import { InventoryPlan } from './inventoryPlan';
import { ItemCategory } from './itemCategory';
import { Location } from './location';
import { ProjectApprover } from './projectApprover';
import { SiteEntrance } from './siteEntrance';
import { StockLevel } from './stockLevel';
import { Team } from './team';
import { TransportType } from './transportType';


export interface Project { 
    ProjectNumber?: string;
    ProjectDescription?: string;
    DivisionCode?: string;
    DivisionDescription?: string;
    ApprovalProject?: string;
    ApprovalLocation?: string;
    ApprovalWriteOff?: string;
    ApprovalIssue?: string;
    ApprovalAcceptRequest?: string;
    SiteEntrance?: string;
    SiteEntranceTimeSlotLimit?: string;
    SiteEntranceLicensePlate?: string;
    SiteEnterTransportTypeRequired?: string;
    SiteEnterInventoryPlanRequired?: string;
    DeliveryDateColorIndicator?: string;
    DeliveryDateAhead1?: number;
    DeliveryDateAhead1Color?: string;
    DeliveryDateAhead2?: number;
    DeliveryDateAhead2Color?: string;
    DeliveryDateAhead3?: number;
    DeliveryDateAhead3Color?: string;
    RequestTransportTypeRequired?: string;
    Locations?: Array<Location>;
    ProjectApprovers?: Array<ProjectApprover>;
    StockLevels?: Array<StockLevel>;
    InventoryBalances?: Array<InventoryBalance>;
    ItemCateories?: Array<ItemCategory>;
    FromApprovalHeader?: Array<ApprovalHeader>;
    ToApprovalHeader?: Array<ApprovalHeader>;
    Budgets?: Array<Budget>;
    InventoryPlans?: Array<InventoryPlan>;
    Teams?: Array<Team>;
    SiteEntrances?: Array<SiteEntrance>;
    TransportTypes?: Array<TransportType>;
    ID?: number;
    CreatedDate?: Date;
    Status?: string;
    DataStatus?: Project.DataStatusEnum;
    DataDate?: Date;
}
export namespace Project {
    export type DataStatusEnum = 'BACKEND' | 'CACHE' | 'CREATE_OK' | 'CREATE_FAIL' | 'CREATE_PENDING' | 'CREATE_QUEUE' | 'DELETE_OK' | 'DELETE_FAIL' | 'DELETE_PENDING' | 'DELETE_QUEUE' | 'UPDATE_OK' | 'UPDATE_FAIL' | 'UPDATE_PENDING' | 'UPDATE_QUEUE' | 'VALIDATION_OK' | 'VALIDATION_FAIL' | 'WAITING_FOR_APPROVAL';
    export const DataStatusEnum = {
        BACKEND: 'BACKEND' as DataStatusEnum,
        CACHE: 'CACHE' as DataStatusEnum,
        CREATEOK: 'CREATE_OK' as DataStatusEnum,
        CREATEFAIL: 'CREATE_FAIL' as DataStatusEnum,
        CREATEPENDING: 'CREATE_PENDING' as DataStatusEnum,
        CREATEQUEUE: 'CREATE_QUEUE' as DataStatusEnum,
        DELETEOK: 'DELETE_OK' as DataStatusEnum,
        DELETEFAIL: 'DELETE_FAIL' as DataStatusEnum,
        DELETEPENDING: 'DELETE_PENDING' as DataStatusEnum,
        DELETEQUEUE: 'DELETE_QUEUE' as DataStatusEnum,
        UPDATEOK: 'UPDATE_OK' as DataStatusEnum,
        UPDATEFAIL: 'UPDATE_FAIL' as DataStatusEnum,
        UPDATEPENDING: 'UPDATE_PENDING' as DataStatusEnum,
        UPDATEQUEUE: 'UPDATE_QUEUE' as DataStatusEnum,
        VALIDATIONOK: 'VALIDATION_OK' as DataStatusEnum,
        VALIDATIONFAIL: 'VALIDATION_FAIL' as DataStatusEnum,
        WAITINGFORAPPROVAL: 'WAITING_FOR_APPROVAL' as DataStatusEnum
    };
}
