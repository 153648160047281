/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { IdValueDTO } from '../model/idValueDTO';
import { PageProject } from '../model/pageProject';
import { Project } from '../model/project';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ProjectsServiceInterface, IprojectsAllMmJobNumberListParams,IprojectsAllowedMmJobNumberListParams,IprojectsCreateParams,IprojectsDeleteParams,IprojectsDeleteByListParams,IprojectsGetByJobsParams,IprojectsGetDivisionParams,IprojectsGetListParams,IprojectsGetProjectByIDParams,IprojectsGetProjectByProjectNumberParams,IprojectsGetSearchListParams,IprojectsUpdateParams,IprojectsUpdateByListParams, }                            from './projects.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ProjectsService implements ProjectsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsAllMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllMmJobNumberListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public projectsAllMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllMmJobNumberListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public projectsAllMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllMmJobNumberListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    @datastore
    public projectsAllMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllMmJobNumberListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<string>>(`${this.basePath}/Projects/AllMmJobNumberList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsAllowedMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllowedMmJobNumberListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public projectsAllowedMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllowedMmJobNumberListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public projectsAllowedMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllowedMmJobNumberListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    @datastore
    public projectsAllowedMmJobNumberList({ deviceId, message, ignoreLoadingBar, formData,  } : IprojectsAllowedMmJobNumberListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<string>>(`${this.basePath}/Projects/AllowedMmJobNumberList`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<Project>;
    public projectsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Project>>;
    public projectsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Project>>;
    @datastore
    public projectsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IprojectsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling projectsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Project>(`${this.basePath}/Projects`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsDelete({ deviceId, message, ignoreLoadingBar, formData, projectID } : IprojectsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public projectsDelete({ deviceId, message, ignoreLoadingBar, formData, projectID } : IprojectsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public projectsDelete({ deviceId, message, ignoreLoadingBar, formData, projectID } : IprojectsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public projectsDelete({ deviceId, message, ignoreLoadingBar, formData, projectID } : IprojectsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Projects/${encodeURIComponent(String(projectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, projectIDs } : IprojectsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public projectsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, projectIDs } : IprojectsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public projectsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, projectIDs } : IprojectsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public projectsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, projectIDs } : IprojectsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectIDs === null || projectIDs === undefined) {
            throw new Error('Required parameter projectIDs was null or undefined when calling projectsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectIDs) {
            projectIDs.forEach((element) => {
                queryParameters = queryParameters.append('projectIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Projects/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param jobs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGetByJobs({ deviceId, message, ignoreLoadingBar, formData, jobs } : IprojectsGetByJobsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Project>>;
    public projectsGetByJobs({ deviceId, message, ignoreLoadingBar, formData, jobs } : IprojectsGetByJobsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Project>>>;
    public projectsGetByJobs({ deviceId, message, ignoreLoadingBar, formData, jobs } : IprojectsGetByJobsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Project>>>;
    @datastore
    public projectsGetByJobs({ deviceId, message, ignoreLoadingBar, formData, jobs } : IprojectsGetByJobsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (jobs === null || jobs === undefined) {
            throw new Error('Required parameter jobs was null or undefined when calling projectsGetByJobs.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (jobs) {
            jobs.forEach((element) => {
                queryParameters = queryParameters.append('jobs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Project>>(`${this.basePath}/Projects/GetByJobs`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGetDivision({ deviceId, message, ignoreLoadingBar, formData, code } : IprojectsGetDivisionParams, observe?: 'body', reportProgress?: boolean): Observable<Array<IdValueDTO>>;
    public projectsGetDivision({ deviceId, message, ignoreLoadingBar, formData, code } : IprojectsGetDivisionParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<IdValueDTO>>>;
    public projectsGetDivision({ deviceId, message, ignoreLoadingBar, formData, code } : IprojectsGetDivisionParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<IdValueDTO>>>;
    @datastore
    public projectsGetDivision({ deviceId, message, ignoreLoadingBar, formData, code } : IprojectsGetDivisionParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (code !== undefined && code !== null) {
            queryParameters = queryParameters.set('code', <any>code);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<IdValueDTO>>(`${this.basePath}/Projects/GetDivision`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGetList({ deviceId, message, ignoreLoadingBar, formData, projectID, page, views } : IprojectsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Project>>;
    public projectsGetList({ deviceId, message, ignoreLoadingBar, formData, projectID, page, views } : IprojectsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Project>>>;
    public projectsGetList({ deviceId, message, ignoreLoadingBar, formData, projectID, page, views } : IprojectsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Project>>>;
    @datastore
    public projectsGetList({ deviceId, message, ignoreLoadingBar, formData, projectID, page, views } : IprojectsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Project>>(`${this.basePath}/Projects`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param byPassJobSecurity 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGetProjectByID({ deviceId, message, ignoreLoadingBar, formData, projectID, byPassJobSecurity, views } : IprojectsGetProjectByIDParams, observe?: 'body', reportProgress?: boolean): Observable<Project>;
    public projectsGetProjectByID({ deviceId, message, ignoreLoadingBar, formData, projectID, byPassJobSecurity, views } : IprojectsGetProjectByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Project>>;
    public projectsGetProjectByID({ deviceId, message, ignoreLoadingBar, formData, projectID, byPassJobSecurity, views } : IprojectsGetProjectByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Project>>;
    @datastore
    public projectsGetProjectByID({ deviceId, message, ignoreLoadingBar, formData, projectID, byPassJobSecurity, views } : IprojectsGetProjectByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsGetProjectByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (byPassJobSecurity !== undefined && byPassJobSecurity !== null) {
            queryParameters = queryParameters.set('byPassJobSecurity', <any>byPassJobSecurity);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Project>(`${this.basePath}/Projects/GetProjectByID`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNumber 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGetProjectByProjectNumber({ deviceId, message, ignoreLoadingBar, formData, projectNumber, views } : IprojectsGetProjectByProjectNumberParams, observe?: 'body', reportProgress?: boolean): Observable<Project>;
    public projectsGetProjectByProjectNumber({ deviceId, message, ignoreLoadingBar, formData, projectNumber, views } : IprojectsGetProjectByProjectNumberParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Project>>;
    public projectsGetProjectByProjectNumber({ deviceId, message, ignoreLoadingBar, formData, projectNumber, views } : IprojectsGetProjectByProjectNumberParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Project>>;
    @datastore
    public projectsGetProjectByProjectNumber({ deviceId, message, ignoreLoadingBar, formData, projectNumber, views } : IprojectsGetProjectByProjectNumberParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNumber === null || projectNumber === undefined) {
            throw new Error('Required parameter projectNumber was null or undefined when calling projectsGetProjectByProjectNumber.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectNumber !== undefined && projectNumber !== null) {
            queryParameters = queryParameters.set('projectNumber', <any>projectNumber);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Project>(`${this.basePath}/Projects/GetProjectByProjectNumber`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param checkJobAll 
     * @param projectID 
     * @param divisionCode 
     * @param projectNumber 
     * @param projectName 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, checkJobAll, projectID, divisionCode, projectNumber, projectName, page, take, views } : IprojectsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageProject>;
    public projectsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, checkJobAll, projectID, divisionCode, projectNumber, projectName, page, take, views } : IprojectsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageProject>>;
    public projectsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, checkJobAll, projectID, divisionCode, projectNumber, projectName, page, take, views } : IprojectsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageProject>>;
    @datastore
    public projectsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, checkJobAll, projectID, divisionCode, projectNumber, projectName, page, take, views } : IprojectsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (checkJobAll !== undefined && checkJobAll !== null) {
            queryParameters = queryParameters.set('checkJobAll', <any>checkJobAll);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (divisionCode !== undefined && divisionCode !== null) {
            queryParameters = queryParameters.set('divisionCode', <any>divisionCode);
        }
        if (projectNumber !== undefined && projectNumber !== null) {
            queryParameters = queryParameters.set('projectNumber', <any>projectNumber);
        }
        if (projectName !== undefined && projectName !== null) {
            queryParameters = queryParameters.set('projectName', <any>projectName);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageProject>(`${this.basePath}/Projects/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsUpdate({ deviceId, message, ignoreLoadingBar, formData, projectID, model } : IprojectsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public projectsUpdate({ deviceId, message, ignoreLoadingBar, formData, projectID, model } : IprojectsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public projectsUpdate({ deviceId, message, ignoreLoadingBar, formData, projectID, model } : IprojectsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public projectsUpdate({ deviceId, message, ignoreLoadingBar, formData, projectID, model } : IprojectsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling projectsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling projectsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/Projects/${encodeURIComponent(String(projectID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public projectsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public projectsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public projectsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IprojectsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling projectsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/Projects/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
