<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'SEARCH_INVENTORY_BALACNE' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row d-flex flex-row">
        <div class="col my-auto">

            <div class="row mb-4">
                <div class="col-6">
                    <ng-container>
                        <search-category [orientation]="'row'" [project]="currentProject" [(category)]="selectedCategory"
                            (categoryChange)="onCategoryChange($event)">
                        </search-category>
                    </ng-container>
                    <ng-container>
                        <search-subcategory [orientation]="'row'" [project]="currentProject" [category]="selectedCategory" [(subCategory)]="selectedSubCategory"
                            (subCategoryChange)="onSubCategoryChange($event)">
                        </search-subcategory>
                    </ng-container>
                    <ng-container>
                        <search-item [orientation]="'row'" [project]="currentProject" [category]="selectedCategory" [subCategory]="selectedSubCategory"
                            [(item)]="selectedItem" (onItemChange)="onItemChange($event)">
                        </search-item>
                    </ng-container>
                    <div class="row py-1">
                        <div class="col-3 my-auto">
                            <span class="fs-12">{{ 'DESCRIPTION' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control w-100 p-0" type="text" [(ngModel)]="itemDescription" />
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-3 my-auto">
                            <span class="fs-12">{{ 'MODEL' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control w-100 p-0" type="text" [(ngModel)]="itemVariantModel" />
                        </div>
                    </div>
                    <!-- <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'ITEM_CATEGORY' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <ng-select
                                [disabled]="disableItemSearch || disableAllSearchCriteria"
                                class="w-100 p-0"
                                (open)="searchCategory()"
                                [items]="searchCategoryList"
                                [(ngModel)]="selectedCategory"
                                (ngModelChange)="selectionCategoryChange()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{item['CategoryCode']}} - {{item['CATEGORY_DESC'|translate]||''}}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{item['CategoryCode']}} - {{item['CATEGORY_DESC'|translate]||''}}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'ITEM_SUBCATEGORY' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <ng-select
                                class="w-100 p-0"
                                (open)="searchSubCategory()"
                                [disabled]="disableAllSearchCriteria || selectedCategory==null"
                                [items]="searchSubCategoryList"
                                [(ngModel)]="selectedSubCategory"
                                (ngModelChange)="selectionSubCategoryChange()">
                                <ng-template ng-label-tmp let-item="item">
                                    <ng-container *ngIf="item">
                                        <span>{{item['SubCategoryCode']}} - {{ item['SUB_CATEGORY_DESC'|translate]||''}}</span>
                                    </ng-container>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{item['SubCategoryCode']}} - {{ item['SUB_CATEGORY_DESC'|translate]||''}}</span>
                                </ng-template>
                            </ng-select>

                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'ITEM' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <ng-select
                                class="w-100 p-0"
                                (open)="searchItem()"
                                [disabled]="disableAllSearchCriteria || selectedCategory==null || selectedSubCategory==null"
                                [items]="searchItemList"
                                [(ngModel)]="selectedItem">
                                <ng-template ng-label-tmp let-item="item">
                                    <ng-container *ngIf="item">
                                        <span>{{item['ItemCode']}} - {{ item['ITEM_DESC'|translate]||''}}</span>
                                    </ng-container>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{item['ItemCode']}} - {{ item['ITEM_DESC'|translate]||''}}</span>
                                </ng-template>
                            </ng-select>

                        </div>
                    </div> -->

                    <!-- <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'UNIVERSAL_PRODUCT_CODE' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control" type="text" [(ngModel)]="universalProductCode" [disabled]="disableAllSearchCriteria"/>
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'GAMMON_PRODUCT_CODE' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control" type="text" [(ngModel)]="gammonProductCode" [disabled]="disableAllSearchCriteria"/>
                        </div>
                    </div> -->
                </div>

                <div class="col-6">
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'LOCATION' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <ng-select
                                [disabled]="disableLocationSearch || disableAllSearchCriteria"
                                class="w-100 p-0"
                                (open)="searchLocation()"
                                [items]="searchLocationList"
                                [(ngModel)]="selectedLocation"
                                (ngModelChange)="selectionLocationChange()">
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{item['LOCATION_DESC'|translate]||''}}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{item['LOCATION_DESC'|translate]||''}}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'AREA' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <ng-select
                                [disabled]="disableAllSearchCriteria || !selectedLocation"
                                class="w-100 p-0"
                                (open)="searchArea()"
                                [items]="searchAreaList"
                                [(ngModel)]="selectedArea"
                                (ngModelChange)="selectionAreaChange()">
                                <ng-template ng-label-tmp let-item="item">
                                    <ng-container *ngIf="item">
                                        <span>{{ item['AREA_DESC'|translate]||''}}</span>
                                    </ng-container>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{ item['AREA_DESC'|translate]||''}}</span>
                                </ng-template>
                            </ng-select>

                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'BIN' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <ng-select
                                class="w-100 p-0"
                                (open)="searchBin()"
                                [disabled]="disableAllSearchCriteria || selectedLocation==null || selectedArea==null"
                                [items]="searchBinList"
                                [(ngModel)]="selectedBin">
                                <ng-template ng-label-tmp let-item="item">
                                    <ng-container *ngIf="item">
                                        <span>{{ item['BIN_DESC'|translate]||''}}</span>
                                    </ng-container>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{ item['BIN_DESC'|translate]||''}}</span>
                                </ng-template>
                            </ng-select>

                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'PLANT_NUMBER' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control w-100 p-0" type="text" [(ngModel)]="plantNumber" />
                        </div>
                    </div>
                    <div class="row py-1">
                        <div class="col-4 my-auto">
                            <span class="fs-12">{{ 'RECEIPT_REMARKS' | translate }}</span>
                        </div>
                        <div class="col-8 my-auto">
                            <input class="form-control w-100 p-0" type="text" [(ngModel)]="movementRemarks" />
                        </div>
                    </div>
                    <div class="row py-1 pull-right pr-3" *ngIf="!disableAllSearchCriteria">
                        <button class="btn btn-complete w-abs-120" (click)="search()">{{ 'SEARCH' | translate }}</button>
                    </div>

                </div>
            </div>
            <label class="text-danger" *ngIf="actionType == ActionType.CHANGE_LOCATION && !selectedLocation && withApproval">{{'LOCATION_TRANSFER_MISSING_LOCATION_HINT'|translate}}</label>
            <label class="text-danger" *ngIf="actionType == ActionType.RETURN && !_keeper && !_keeperName">{{'RETURN_MISSING_KEEPER_HINT'|translate}}</label>
            <div class="row">
                <div class="col">
                    <div class="table-responsive" style="border-radius: 5px;">
                        <!--  [lazy]="true"
                            (onLazyLoad)="loadInventoryBalanceLazy($event)"-->
                        <p-table
                            [lazy]="isLazyLoad"
                            (onLazyLoad)="loadInventoryBalanceLazy($event)"
                            [selectionMode]="tableSelectionMode"
                            [value]="searchInventoryBalanceList"
                            [responsive]="true"
                            [scrollable]="true"
                            [paginator]="true"
                            [rows]="this.tableTakeRows"
                            [totalRecords]="totalRecords"
                            scrollHeight="250px"
                            [(selection)]="selectionResultList"
                            dataKey="ID" #dt>
                            <ng-template pTemplate="header">
                              <tr>
                                  <th style="width: 60px">
                                      <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                  </th>
                                  <th>{{ 'ITEM_CODE' | translate }}</th>
                                  <th>{{ 'DESCRIPTION' | translate }}</th>
                                  <th>{{ 'BRAND' | translate }}</th>
                                  <th>{{ 'MODEL' | translate}}</th>
                                  <th>{{ 'SIZE' | translate }}</th>

                                <ng-container
                                    *ngIf="[ActionType.ISSUE, ActionType.WRITE_OFF, ActionType.CHANGE_LOCATION, ActionType.ISSUE_REQUEST, ActionType.TRANSFER].indexOf(actionType) > -1">
                                    <th>{{ 'LOCATION' | translate }}</th>
                                </ng-container>
                                
                                <ng-container *ngIf="[ActionType.RETURN].indexOf(actionType) > -1; else keeperElseTemplate">
                                    <th>{{ 'KEEPER' | translate }}</th>
                                    <th>{{ 'NON_GAMMON_KEEPER' | translate }}</th>
                                </ng-container>
                                <ng-template #keeperElseTemplate>
                                    <th>{{ 'RESPONSIBLE_PERSON' | translate }}</th>
                                </ng-template>
                                
                                  <th>{{ 'REMARK' | translate }}</th>
                                  <th>{{ 'QUANTITY' | translate }}</th>
                                  <th>{{ 'UNIT' | translate }}</th>
                                  <th>{{ 'GAMMON_PRODUCT_CODE' | translate }}</th>
                                  <th>{{ 'SERIAL_NO' | translate }}</th>
                                  <th>{{ 'PLANT_NUMBER' | translate }}</th>
                                  <th>{{ 'TRANSACTION_DATE' | translate }}</th>
                                  <th>{{ 'EXPIRY_DATE' | translate }}</th>
                                  <th>{{ 'ITEM_LOT' | translate }}</th>
                              </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-item let-ri="rowIndex">
                                    <tr [pSelectableRow]="item">
                                        <td style="width: 60px" [pSelectableRow]="item">
                                            <p-tableCheckbox [value]="item"></p-tableCheckbox>
                                        </td>
                                        <td class="fs-12">{{ item.ItemLot.ItemVariant.Item.ItemCode }}</td>
                                        <td class="fs-12">{{ item.ItemLot.ItemVariant.Item | desc: 'ITEM_DESC' : 'ItemEnglish'  }}</td>
                                        <td class="fs-12">{{ item.ItemLot.ItemVariant.Brand }}</td>
                                        <td class="fs-12">{{ item.ItemLot.ItemVariant.ModelNo }}</td>
                                        <td class="fs-12">{{ item.ItemLot.ItemVariant.Size }}</td>

                                        <ng-container *ngIf="[ActionType.ISSUE, ActionType.WRITE_OFF, ActionType.CHANGE_LOCATION, ActionType.ISSUE_REQUEST, ActionType.TRANSFER].indexOf(actionType) > -1">
                                            <td class="fs-12">{{item.Bin | desc: null : null : 'BIN_DESC'}}</td>
                                        </ng-container>

                                        <ng-container *ngIf="[ActionType.RETURN].indexOf(actionType) > -1; else keeperElseTemplate">
                                            <td class="fs-12">{{ item.Keeper | desc: 'KEEPER_DESC' : 'Name' }}</td>
                                            <td class="fs-12">{{ item.Keeper ? null : item.KeeperName }}</td>
                                        </ng-container>
                                        <ng-template #keeperElseTemplate>
                                            <td class="fs-12">{{ item.Keeper | desc: 'KEEPER_DESC' : 'Name' }}</td>
                                        </ng-template>
                                      
                                        <td class="fs-12">{{ item.ItemLot.ItemVariant.Remark }}</td>
                                        <td class="fs-12">{{ item.Quantity }}</td>
                                        <td class="fs-12">{{ item.ItemLot.ItemVariant.Item.UOM }}</td>
                                        <td class="fs-12">{{ item.GammonProductCode }}</td>
                                        <td class="fs-12">{{ item.ItemLot.SerialNo }}</td>
                                        <td class="fs-12">{{ item.ItemLot.PlantNo }}</td>
                                        <td class="fs-12">{{ item.ItemLot.TransactionDate | date }}</td>
                                        <td class="fs-12">{{ (item.ItemLot||{}).ExpiryDate |date:'yyyy-MM-dd' }}</td>
                                        <td class="fs-12">{{ (item.ItemLot||{}).ID }}</td>
                                    </tr>
                            </ng-template>
                        </p-table>
                    </div>
                    <div class="d-flex flex-row justify-content-end">
                        <button class="btn btn-info mx-1" (click)="closeModal()">{{ 'CANCEL' | translate }}</button>
                        <button class="btn btn-primary mx-1" (click)="addInventoryBalance(); closeModal()">{{ 'SELECT' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


