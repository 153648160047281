import { AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';

@Component({
  selector: 'modalTemplate',
  templateUrl: './modal-template.component.html',
  styleUrls: ['./modal-template.component.scss']
})
export class ModalTemplateComponent implements OnInit, AfterViewInit {
  title: string;
  messages: string[] | string; // can be string/int/boolean

  confirmBtnName: string;
  cancelBtnName: string;

  modalType: string;
  modalStatus: string;

  messageParam: any;

  @ViewChild('componentTemplate', { read: ViewContainerRef, static: true }) container: ViewContainerRef;
  template: any;
  templateData;

  constructor(public translate: TranslateService, public bsModalRef: BsModalRef, private bsModalService: BsModalService, private componentFactory: ComponentFactoryResolver) {}

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.template) {
      const comp = this.componentFactory.resolveComponentFactory(this.template);
      const componentRef = this.container.createComponent(comp);
      if (this.templateData) {
        (componentRef.instance as any).data = this.templateData;
      }
    }
  }

  confirm() {
    this.bsModalService.setDismissReason('confirm');
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalService.setDismissReason('cancel');
    this.bsModalRef.hide();
  }

  close() {
    this.bsModalService.setDismissReason('close');
    this.bsModalRef.hide();
  }

  isArray(content): boolean {
    return content instanceof Array;
  }

  isObject(content): boolean {
    return typeof content === 'object';
  }
}
