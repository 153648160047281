/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { JDEMapping } from '../model/jDEMapping';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { JdeMappingsServiceInterface, IjdeMappingsCreateParams,IjdeMappingsDeleteParams,IjdeMappingsDeleteByListParams,IjdeMappingsGetListParams,IjdeMappingsUpdateParams,IjdeMappingsUpdateByListParams, }                            from './jdeMappings.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class JdeMappingsService implements JdeMappingsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jdeMappingsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IjdeMappingsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<JDEMapping>;
    public jdeMappingsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IjdeMappingsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<JDEMapping>>;
    public jdeMappingsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IjdeMappingsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<JDEMapping>>;
    @datastore
    public jdeMappingsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IjdeMappingsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling jdeMappingsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<JDEMapping>(`${this.basePath}/JdeMappings`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param jdeMappingID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jdeMappingsDelete({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID } : IjdeMappingsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public jdeMappingsDelete({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID } : IjdeMappingsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public jdeMappingsDelete({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID } : IjdeMappingsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public jdeMappingsDelete({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID } : IjdeMappingsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (jdeMappingID === null || jdeMappingID === undefined) {
            throw new Error('Required parameter jdeMappingID was null or undefined when calling jdeMappingsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/JdeMappings/${encodeURIComponent(String(jdeMappingID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param jdeMappingIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jdeMappingsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingIDs } : IjdeMappingsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public jdeMappingsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingIDs } : IjdeMappingsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public jdeMappingsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingIDs } : IjdeMappingsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public jdeMappingsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingIDs } : IjdeMappingsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (jdeMappingIDs === null || jdeMappingIDs === undefined) {
            throw new Error('Required parameter jdeMappingIDs was null or undefined when calling jdeMappingsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (jdeMappingIDs) {
            jdeMappingIDs.forEach((element) => {
                queryParameters = queryParameters.append('jdeMappingIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/JdeMappings/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param jdeMappingID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jdeMappingsGetList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, page, views } : IjdeMappingsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<JDEMapping>>;
    public jdeMappingsGetList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, page, views } : IjdeMappingsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<JDEMapping>>>;
    public jdeMappingsGetList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, page, views } : IjdeMappingsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<JDEMapping>>>;
    @datastore
    public jdeMappingsGetList({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, page, views } : IjdeMappingsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (jdeMappingID !== undefined && jdeMappingID !== null) {
            queryParameters = queryParameters.set('jdeMappingID', <any>jdeMappingID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<JDEMapping>>(`${this.basePath}/JdeMappings`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param jdeMappingID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jdeMappingsUpdate({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, model } : IjdeMappingsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public jdeMappingsUpdate({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, model } : IjdeMappingsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public jdeMappingsUpdate({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, model } : IjdeMappingsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public jdeMappingsUpdate({ deviceId, message, ignoreLoadingBar, formData, jdeMappingID, model } : IjdeMappingsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (jdeMappingID === null || jdeMappingID === undefined) {
            throw new Error('Required parameter jdeMappingID was null or undefined when calling jdeMappingsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling jdeMappingsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/JdeMappings/${encodeURIComponent(String(jdeMappingID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public jdeMappingsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IjdeMappingsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public jdeMappingsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IjdeMappingsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public jdeMappingsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IjdeMappingsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public jdeMappingsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IjdeMappingsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling jdeMappingsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/JdeMappings/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
