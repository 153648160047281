/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DeviceInfo } from '../model/deviceInfo';
import { ErrorMessage } from '../model/errorMessage';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { DeviceInfosServiceInterface, IdeviceInfosLoggingParams,IdeviceInfosSendNotificationByUuidParams,IdeviceInfosUpdateByUuidParams, }                            from './deviceInfos.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class DeviceInfosService implements DeviceInfosServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param errorMessage 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceInfosLogging({ deviceId, message, ignoreLoadingBar, formData, errorMessage } : IdeviceInfosLoggingParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceInfosLogging({ deviceId, message, ignoreLoadingBar, formData, errorMessage } : IdeviceInfosLoggingParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceInfosLogging({ deviceId, message, ignoreLoadingBar, formData, errorMessage } : IdeviceInfosLoggingParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    @datastore
    public deviceInfosLogging({ deviceId, message, ignoreLoadingBar, formData, errorMessage } : IdeviceInfosLoggingParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (errorMessage === null || errorMessage === undefined) {
            throw new Error('Required parameter errorMessage was null or undefined when calling deviceInfosLogging.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<any>(`${this.basePath}/DeviceInfos/Logging`,
            errorMessage,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param uuid 
     * @param title 
     * @param body 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceInfosSendNotificationByUuid({ deviceId, message, ignoreLoadingBar, formData, uuid, title, body, data } : IdeviceInfosSendNotificationByUuidParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deviceInfosSendNotificationByUuid({ deviceId, message, ignoreLoadingBar, formData, uuid, title, body, data } : IdeviceInfosSendNotificationByUuidParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deviceInfosSendNotificationByUuid({ deviceId, message, ignoreLoadingBar, formData, uuid, title, body, data } : IdeviceInfosSendNotificationByUuidParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    @datastore
    public deviceInfosSendNotificationByUuid({ deviceId, message, ignoreLoadingBar, formData, uuid, title, body, data } : IdeviceInfosSendNotificationByUuidParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling deviceInfosSendNotificationByUuid.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (uuid !== undefined && uuid !== null) {
            queryParameters = queryParameters.set('uuid', <any>uuid);
        }
        if (title !== undefined && title !== null) {
            queryParameters = queryParameters.set('title', <any>title);
        }
        if (body !== undefined && body !== null) {
            queryParameters = queryParameters.set('body', <any>body);
        }
        if (data !== undefined && data !== null) {
            queryParameters = queryParameters.set('data', <any>data);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<any>(`${this.basePath}/DeviceInfos/SendNotificationByUuid`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deviceInfosUpdateByUuid({ deviceId, message, ignoreLoadingBar, formData, model } : IdeviceInfosUpdateByUuidParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public deviceInfosUpdateByUuid({ deviceId, message, ignoreLoadingBar, formData, model } : IdeviceInfosUpdateByUuidParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public deviceInfosUpdateByUuid({ deviceId, message, ignoreLoadingBar, formData, model } : IdeviceInfosUpdateByUuidParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public deviceInfosUpdateByUuid({ deviceId, message, ignoreLoadingBar, formData, model } : IdeviceInfosUpdateByUuidParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling deviceInfosUpdateByUuid.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/DeviceInfos`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
