<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'REFERENCE_NUMBER' | translate }}: {{referenceNumber}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row d-flex flex-row">
        <div class="col my-auto">
            <div [innerHTML]="decisionMapHtml | sanitize: 'html'"></div>
        </div>
    </div>
</div>