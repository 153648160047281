/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';


import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ExcelsServiceInterface, IexcelsExportItemExcelParams,IexcelsExportProjectExcelParams,IexcelsImportItemExcelParams,IexcelsImportProjectExcelParams,IexcelsImportProjectExcelBlindAddParams, }                            from './excels.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ExcelsService implements ExcelsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param projectID 
     * @param by 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelsExportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectID, by } : IexcelsExportItemExcelParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public excelsExportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectID, by } : IexcelsExportItemExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public excelsExportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectID, by } : IexcelsExportItemExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    @datastore
    public excelsExportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectID, by } : IexcelsExportItemExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling excelsExportItemExcel.');
        }
        if (by === null || by === undefined) {
            throw new Error('Required parameter by was null or undefined when calling excelsExportItemExcel.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<any>(`${this.basePath}/Excels/ExportItemExcel/${encodeURIComponent(String(projectID))}/${encodeURIComponent(String(by))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelsExportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectID } : IexcelsExportProjectExcelParams, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public excelsExportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectID } : IexcelsExportProjectExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public excelsExportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectID } : IexcelsExportProjectExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    @datastore
    public excelsExportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectID } : IexcelsExportProjectExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling excelsExportProjectExcel.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<any>(`${this.basePath}/Excels/ExportProjectExcel/${encodeURIComponent(String(projectID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNumber 
     * @param categoryType 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelsImportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber, categoryType } : IexcelsImportItemExcelParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public excelsImportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber, categoryType } : IexcelsImportItemExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public excelsImportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber, categoryType } : IexcelsImportItemExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public excelsImportItemExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber, categoryType } : IexcelsImportItemExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNumber === null || projectNumber === undefined) {
            throw new Error('Required parameter projectNumber was null or undefined when calling excelsImportItemExcel.');
        }
        if (categoryType === null || categoryType === undefined) {
            throw new Error('Required parameter categoryType was null or undefined when calling excelsImportItemExcel.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Excels/ImportItemExcel/${encodeURIComponent(String(projectNumber))}/${encodeURIComponent(String(categoryType))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelsImportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public excelsImportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public excelsImportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public excelsImportProjectExcel({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNumber === null || projectNumber === undefined) {
            throw new Error('Required parameter projectNumber was null or undefined when calling excelsImportProjectExcel.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<boolean>(`${this.basePath}/Excels/ImportProjectExcel/${encodeURIComponent(String(projectNumber))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public excelsImportProjectExcelBlindAdd({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelBlindAddParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public excelsImportProjectExcelBlindAdd({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelBlindAddParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public excelsImportProjectExcelBlindAdd({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelBlindAddParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public excelsImportProjectExcelBlindAdd({ deviceId, message, ignoreLoadingBar, formData, projectNumber } : IexcelsImportProjectExcelBlindAddParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNumber === null || projectNumber === undefined) {
            throw new Error('Required parameter projectNumber was null or undefined when calling excelsImportProjectExcelBlindAdd.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Excels/ImportProjectExcelBlindAdd/${encodeURIComponent(String(projectNumber))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
