<div class="row py-2">
    <div class="col-1 my-auto">
        <h5 class="w-abs-120">{{ 'BIN_CODE' | translate }}</h5>
    </div>
    <div class="col-3 my-auto">
        <div class="input-group">
            <div class="input-group-prepend">
                <div class="input-group-text">
                    <span><i class="fa fa-qrcode"></i></span>
                </div>
            </div>
            <input type="text" class="form-control w-abs-200" [(ngModel)]="quickSearchBinInput" (keyup)="term$.next($event.target.value)" placeholder="{{'SCAN_LOCATION'|translate}}"/>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-1 my-auto">
        {{'LOCATION'|translate}}
    </div>
    <div class="col">
        <ng-select 
            (open)="searchLocationList()"
            [items]="locationList"
            [(ngModel)]="selectedLocation"
            (ngModelChange)="changeLocation()"
            appendTo="body">
            <ng-template ng-label-tmp let-item="item">
                <ng-container *ngIf="item">
                    <span>{{ item['LOCATION_DESC'|translate] }}</span>
                </ng-container>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <span>{{ item['LOCATION_DESC'|translate] }}</span>
            </ng-template>
        </ng-select>
    </div>
</div>

<div class="row">
    <div class="col-1 my-auto">
        {{'AREA'|translate}}
    </div>
    <div class="col">
        <ng-select 
            (open)="searchAreaList()"
            [items]="areaList"
            [(ngModel)]="selectedArea"
            (ngModelChange)="changeArea()"
            [disabled]="!selectedLocation"
            appendTo="body">
            <ng-template ng-label-tmp let-item="item">
                <ng-container *ngIf="item">
                    <span>{{ item['AREA_DESC'|translate] }}</span>
                </ng-container>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <span>{{ item['AREA_DESC'|translate] }}</span>
            </ng-template>
        </ng-select>
    </div>
</div>

<div class="row">
    <div class="col-1 my-auto">
        {{'BIN'|translate}}
    </div>
    <div class="col">
        <ng-select 
            (open)="searchBinList()"
            [items]="binList"
            [(ngModel)]="selectedBin"
            (ngModelChange)="changeBin()"
            [disabled]="!selectedArea || !selectedLocation"
            appendTo="body">
            <ng-template ng-label-tmp let-item="item">
                <ng-container *ngIf="item">
                    <span>{{ item['BIN_DESC'|translate] }}</span>
                </ng-container>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <span>{{ item['BIN_DESC'|translate] }}</span>
            </ng-template>
        </ng-select>
    </div>
</div>