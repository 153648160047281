<div class="overlay" [class.hide]=!isVisible [@fadeAnimation]="isVisible">
	<!-- BEGIN Overlay Content !-->

	<div class="overlay-content has-results m-t-20">
		<!-- BEGIN Overlay Header !-->

		<div class="container-fluid">
			<!-- BEGIN Overlay Logo !-->
			<img alt="logo" class="overlay-brand" pgRetina src1x="assets/img/logo.png" src2x="assets/img/logo_2x.png" height="22" src=
			"assets/img/logo.png" width="78"> <!-- END Overlay Logo !-->
			 <!-- BEGIN Overlay Close !-->
			 <a class="close-icon-light btn-link btn-rounded  overlay-close text-black" href="javascript:void(0)" (click)="close($event)"><i class="pg-icon">close</i></a> <!-- END Overlay Close !-->
		</div>
		<!-- END Overlay Header !-->

		<div class="container-fluid">
			<!-- BEGIN Overlay Controls !-->
			<input autocomplete="off" #searchField (keyup)="searchKeyPress($event)" class="no-border overlay-search bg-transparent" [(ngModel)]="value" placeholder="Search..." spellcheck=
      "false">
      <br>
      <div class="d-flex align-items-center">
        <div class="form-check right m-b-0 d-flex">
          <input id="checkboxn" type="checkbox" value="1" checked="checked">
          <label for="checkboxn" class="d-flex"><i class="pg-icon m-r-5">search</i> Search within page</label>
        </div>
        <p class="fs-13  m-l-10 m-b-0">Press enter to search</p>
      </div>
			<!-- END Overlay Controls !-->
		</div>
		<!-- BEGIN Overlay Search Results, This part is for demo purpose, you can add anything you like !-->

		<div class="container-fluid">
			<span><strong>suggestions :</strong></span> {{value}}<br>

			<div class="search-results m-t-40">
				<p class="bold">
					Pages Search Results
				</p>

				<div class="row">
					<div class="col-md-6">
						<!-- BEGIN Search Result Item !-->

						<div class="d-flex m-t-15">
							<!-- BEGIN Search Result Item Thumbnail !-->

							<div class="thumbnail-wrapper d48 circular bg-success text-white inline m-t-10">
								<div>
									<img alt="" pgRetina src1x="assets/img/profiles/avatar.jpg" src2x="assets/img/profiles/avatar2x.jpg" height="40" src=
									"assets/img/profiles/avatar.jpg" width="40">
								</div>
							</div>
							<!-- END Search Result Item Thumbnail !-->

							<div class="p-l-10">
								<h5 class="m-b-5">
									<span class="semi-bold result-name">{{value}}</span> on pages
								</h5>

								<p class="hint-text">
									via john smith
								</p>
							</div>
						</div>
						<!-- END Search Result Item !-->
						<!-- BEGIN Search Result Item !-->

						<div class="d-flex m-t-15">
							<!-- BEGIN Search Result Item Thumbnail !-->

							<div class="thumbnail-wrapper d48 circular bg-success text-white inline m-t-10">
								<div>
									T
								</div>
							</div>
							<!-- END Search Result Item Thumbnail !-->

							<div class="p-l-10">
								<h5 class="m-b-5">
									<span class="semi-bold result-name">{{value}}</span> related topics
								</h5>

								<p class="hint-text">
									via pages
								</p>
							</div>
						</div>
						<!-- END Search Result Item !-->
						<!-- BEGIN Search Result Item !-->

						<div class="d-flex m-t-15">
							<!-- BEGIN Search Result Item Thumbnail !-->

							<div class="thumbnail-wrapper d48 circular bg-success text-white inline m-t-10">
								<div>
									M
								</div>
							</div>
							<!-- END Search Result Item Thumbnail !-->

							<div class="p-l-10">
								<h5 class="m-b-5">
									<span class="semi-bold result-name">{{value}}</span> music
								</h5>

								<p class="hint-text">
									via pagesmix
								</p>
							</div>
						</div>
						<!-- END Search Result Item !-->
					</div>

					<div class="col-md-6">
						<!-- BEGIN Search Result Item !-->

						<div class="d-flex m-t-15">
							<!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular bg-info text-white d-flex align-items-center m-t-10">
                  <i class="pg-icon large-text ">facebook</i>
              </div>
							<!-- END Search Result Item Thumbnail !-->

							<div class="p-l-10">
								<h5 class="m-b-5">
									<span class="semi-bold result-name">{{value}}</span> on facebook
                </h5>

								<p class="hint-text">
									via facebook
								</p>
							</div>
						</div>
						<!-- END Search Result Item !-->
						<!-- BEGIN Search Result Item !-->

						<div class="d-flex m-t-15">
							<!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular bg-complete text-white d-flex align-items-center m-t-10">
                  <i class="pg-icon large-text ">twitter</i>
              </div>
							<!-- END Search Result Item Thumbnail !-->

							<div class="p-l-10">
								<h5 class="m-b-5">
									Tweats on <span class="semi-bold result-name">{{value}}</span>
								</h5>

								<p class="hint-text">
									via twitter
								</p>
							</div>
						</div>
						<!-- END Search Result Item !-->
						<!-- BEGIN Search Result Item !-->

						<div class="d-flex m-t-15">
							<!-- BEGIN Search Result Item Thumbnail !-->
              <div class="thumbnail-wrapper d48 circular text-white bg-danger d-flex align-items-center m-t-10">
                  <i class="pg-icon large-text ">google_plus</i>
              </div>
							<!-- END Search Result Item Thumbnail !-->

							<div class="p-l-10">
								<h5 class="m-b-5">
									Circles on <span class="semi-bold result-name">{{value}}</span>
								</h5>

								<p class="hint-text">
									via google plus
								</p>
							</div>
						</div>
						<!-- END Search Result Item !-->
					</div>
				</div>
			</div>
		</div>
		<!-- END Overlay Search Results !-->
	</div>
	<!-- END Overlay Content !-->
</div>
<!-- END OVERLAY -->
