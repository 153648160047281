<div id="quickview" class="quickview-wrapper" [class.open]=isOpen>
<!-- Nav tabs -->
<!--
<tabset>
		<tab heading="Chat" id="quickview-chat" active="true" customClass="full-height "> 
		</tab>
</tabset>
-->
<div class="d-flex justify-content-center" style="background: #2b303b;">
    <div class="m-2">
        <span class="fs-16" style="color: white">
            {{ 'CHANGE_PROJECT' | translate }}
        </span>
    </div>
    <a class="btn-link quickview-toggle" (click)="toggle()" style="top: 5px;">
        <i class="pg pg-close text-white" style="opacity: 0.7;"></i>
    </a>
</div>

<div class="view-port clearfix" id="chat">
    <div class="view bg-white">
        
        <div class="navbar navbar-default">
            <div class="navbar-inner">
                <!-- Search box -->
                <input type="text" class="d-flex justify-content-center" style="width: 100%; height: 100%; background-color:transparent; border: 0px solid; text-align: center" />

                <a href="javascript:void(0)" class="inline action p-r-10 pull-right link text-master">
                    <i class="pg pg-search"></i>
                </a>

            </div>
        </div>
        
        <pg-list-view-container class="scrollable full-height" [perfectScrollbar]="config">
            <pg-list-item>
                <ng-template #ItemHeading>
                        Projects
                </ng-template>

                <!-- If hard code the list, it generates a fake-header class that blocks part of the ItemHeading above -->
                <!-- Workaround: call API to grab the list -->
                <li class="chat-user-list clearfix"  *ngFor="let job of jobList">
                    <a>
                        <p class="p-l-10 ">
                            <span class="text-master">{{job.jobNo}}</span>
                            <span class="block text-master hint-text fs-12">{{job.jobName}}</span>
                        </p>
                    </a>
                </li>
            </pg-list-item>
        </pg-list-view-container>
        
    </div>
		
</div>