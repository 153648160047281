<ng-container *ngIf="false && actionType==ActionType.RECEIPT">
    <div class="row my-2">
        <div class="col-6">
            <div class="row">
                <div class="col-4 my-auto">
                    {{ 'LOCATION' | translate }}
                </div>
                <div class="col-md-8 my-auto">
                    <span *ngIf="location">{{location['LOCATION_DESC'|translate]||''}}</span>
                </div>
            </div>
        </div>
    </div>

    <div class="row my-2">
        <div class="col-6">
            <div class="row">
                <div class="col-4 my-auto">
                    {{ 'AREA' | translate }}
                </div>
                <div class="col-8 my-auto">
                    <span *ngIf="area">{{(area['AREA_DESC'|translate])||''}}</span>
                </div>
            </div>
        </div>

        <div class="col-6">
            <div class="row">
                <div class="col-4">
                    {{ 'BIN' | translate }}
                </div>
                <div class="col-8">
                    <span *ngIf="bin">{{bin['BIN_DESC'|translate]}}</span>
                </div>
            </div>
        </div>

    </div>
</ng-container>

<ng-container *ngIf="(actionType==ActionType.RETURN) && keeper">
    <div class="row">
        <div class="col" style="max-width: 90px">
            <img default="assets/images/avatar.png" [src]="'https://intranet.gammonconstruction.com/PeopleDirectory_Picture/'+keeper.PhotoUrl" style="max-width: 70px">
        </div>
        <div class="col">
            {{ keeper.Name }} <br/>
            <small>{{ keeper.DivisionDepartment }}</small><br/>
            <small>{{ keeper.Title }}</small><br/>
            <small>{{ keeper.StaffID }}</small><br/>
        </div>
    </div>
</ng-container>


<div class="row">
    <div class="col">
        <p-table
            [value]="_itemList"
            [responsive]="true"
            [scrollable]="true"
            scrollHeight="50vh"
            [paginator]="true"
            [rows]="10">
            <ng-template pTemplate="header">
                <ng-container>
                    <tr>
                      <ng-container>
                        <th class="w-130">{{ 'TRANSACTION_DATE' | translate }}</th>
                      </ng-container>

                      <th class="w-90">{{ 'QUANTITY' | translate }}</th>
                      <ng-container *ngIf="[ActionType.WRITE_OFF].indexOf(actionType) > -1">
                        <th>{{ 'PLANT_NUMBER' | translate }}</th>
                      </ng-container>
                      <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
                        <th>{{ 'PLANT_NUMBER' | translate }}</th>
                        <th class="w-110">{{ 'EXPIRY_DATE' | translate }}</th>
                      </ng-container>
                      <th class="w-80">{{ 'UNIT' | translate }}</th>

                      <ng-container *ngIf="[ActionType.CHANGE_LOCATION, ActionType.ISSUE, ActionType.WRITE_OFF].indexOf(actionType) > -1">
                        <th class="w-160">{{ 'FROM_BIN' | translate }}</th>
                      </ng-container>

                      <ng-container *ngIf="[ActionType.RECEIPT, ActionType.RETURN, ActionType.CHANGE_LOCATION].indexOf(actionType) > -1">
                        <th class="w-160">{{ 'TO_BIN' | translate }}</th>
                      </ng-container>

                      <th class="w-160" *ngIf="[ActionType.RECEIPT, ActionType.CHANGE_LOCATION, ActionType.WRITE_OFF].indexOf(actionType) > -1">
                        <span *ngIf="!teamIsResponsible">{{ 'RESPONSIBLE_PERSON' | translate }}</span>
                        <span *ngIf="teamIsResponsible">{{ 'RESPONSIBLE_TEAM' | translate }}</span>
                      </th>
                      <th class="w-160" *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
                        <span>{{ 'KEEPER' | translate }}</span>
                      </th>
                      <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
                        <th class="w-160">{{ 'NON_GAMMON_KEEPER' | translate }}</th>
                        <th>{{ 'ISSUE_PROJECT' | translate }}</th>
                      </ng-container>
                      <th *ngIf="[ActionType.ISSUE, ActionType.RETURN].indexOf(actionType) > -1">
                        <span>{{ 'RESPONSIBLE_TEAM' | translate }}</span>
                      </th>
                      <th class="w-150">{{ 'DESCRIPTION' | translate }}</th>
                      <th>{{ 'BRAND' | translate }}</th>
                      <th>{{ 'MODEL' | translate }}</th>
                      <th>{{ 'SIZE' | translate }}</th>
                      <th class="w-130">{{ 'ITEM_REMARKS' | translate }}</th>
                      <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
                        <th class="w-160">{{ 'ISSUE_REMARKS' | translate }}</th>
                      </ng-container>
                      <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
                        <th class="w-160">{{ 'RECEIPT_REMARKS' | translate }}</th>
                      </ng-container>
                      <th *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">{{ 'PO_NUMBER' | translate }}</th>
                    </tr>
                </ng-container>
            </ng-template>
            <ng-template pTemplate="body" let-item let-ri="rowIndex">
                <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
                    <tr>
                      <td class="w-130">{{ item.TransactionDate | date: 'yyyy-MM-dd'  }}</td>
                      <td class="w-90">{{ item.Quantity}}</td>
                      <td>{{ item.PlantNo }}</td>
                      <td class="w-110">{{ item.ExpiryDate | date: 'yyyy-MM-dd'   }}</td>
                      <td class="w-80">{{ item.Item.UOM }}</td>
                      <td class="w-160">{{ item.TargetBin | desc: null : null : 'BIN_DESC' }}</td>
                      <td class="w-160">
                        <span *ngIf="!teamIsResponsible">{{ item.Keeper | desc: 'ADUSER_DESC': 'Name' }}</span>
                        <span *ngIf="teamIsResponsible">{{ (item.Team || {}).Name }}</span>
                      </td>
                      <td class="w-150">{{ item.Item | desc: 'ITEM_DESC' : 'ItemEnglish' }}</td>
                      <td>{{ item.Brand }}</td>
                      <td>{{ item.ModelNo }}</td>
                      <td>{{ item.Size }}</td>
                      <td class="w-130">{{ item.Remark }}</td>
                      <td class="w-160">{{ item.MovementRemarks }}</td>
                      <td>{{ item.PoNo }}</td>
                    </tr>
                </ng-container>

                <ng-container *ngIf="[ActionType.ISSUE, ActionType.WRITE_OFF, ActionType.RETURN, ActionType.CHANGE_LOCATION].indexOf(actionType) > -1">
                    <tr>
                      <ng-container>
                      <td class="w-130">{{ item.TransactionDate | date: 'yyyy-MM-dd'  }}</td>
                      </ng-container>

                      <td class="w-90">{{ item.Quantity }}</td>
                      <ng-container *ngIf="[ActionType.WRITE_OFF].indexOf(actionType) > -1">
                        <td>{{ item.ItemLot.PlantNo }}</td>
                      </ng-container>
                      <td class="w-80">{{ item.ItemLot.ItemVariant.Item.UOM }}</td>

                      <ng-container *ngIf="[ActionType.CHANGE_LOCATION, ActionType.ISSUE, ActionType.WRITE_OFF].indexOf(actionType) > -1">
                        <td class="w-160">{{ item.Bin | desc: null : null : 'BIN_DESC' }}</td>
                      </ng-container>

                      <ng-container *ngIf="[ActionType.CHANGE_LOCATION, ActionType.RETURN].indexOf(actionType) > -1">
                        <td class="w-160">{{ item.TargetBin | desc: null : null : 'BIN_DESC' }}</td>
                      </ng-container>

                      
                      <td class="w-160" *ngIf="[ActionType.RECEIPT, ActionType.CHANGE_LOCATION, ActionType.WRITE_OFF].indexOf(actionType) > -1">
                        <span *ngIf="!teamIsResponsible">{{ item.Keeper | desc: 'ADUSER_DESC': 'Name' }}</span>
                        <span *ngIf="teamIsResponsible">{{ (item.Team || {}).Name }}</span>
                      </td>
                      
                      <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
                        <td class="w-160"><span>{{ item.Keeper | desc: 'ADUSER_DESC': 'Name' }}</span></td>
                        <td class="w-160">{{ item.KeeperName }}</td>
                        <td>{{ (item.IssueToProject ? 'YES' : 'NO') | translate}}</td>
                      </ng-container>
                      <th *ngIf="[ActionType.ISSUE, ActionType.RETURN].indexOf(actionType) > -1">
                        <span>{{ (item.Team || {}).Name || '--'}}</span>
                      </th>
                      <td class="w-150">{{ item.ItemLot.ItemVariant.Item | desc: 'ITEM_DESC' : 'ItemEnglish' }}</td>
                      <td>{{ item.ItemLot.ItemVariant.Brand }}</td>
                      <td>{{ item.ItemLot.ItemVariant.ModelNo }}</td>
                      <td>{{ item.ItemLot.ItemVariant.Size }}</td>
                      <td class="w-130">{{ item.ItemLot.Remarks || item.ItemLot.ItemVariant.Remark }}</td>
                      <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
                        <td class="w-160">{{ item.MovementRemarks }}</td>
                      </ng-container>
                    </tr>
                </ng-container>
            </ng-template>
        </p-table>
    </div>
</div>
