/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { InventoryBalanceByProject } from '../model/inventoryBalanceByProject';
import { InventoryBalanceByProjectKeeper } from '../model/inventoryBalanceByProjectKeeper';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { QueryServiceInterface, IqueryGroupInventoryBalanceByProjectParams,IqueryGroupInventoryBalanceByProjectKeeperParams, }                            from './query.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class QueryService implements QueryServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param projectNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queryGroupInventoryBalanceByProject({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryBalanceByProject>>;
    public queryGroupInventoryBalanceByProject({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryBalanceByProject>>>;
    public queryGroupInventoryBalanceByProject({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryBalanceByProject>>>;
    @datastore
    public queryGroupInventoryBalanceByProject({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNo === null || projectNo === undefined) {
            throw new Error('Required parameter projectNo was null or undefined when calling queryGroupInventoryBalanceByProject.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<Array<InventoryBalanceByProject>>(`${this.basePath}/Query/GroupInventoryBalanceByProject/${encodeURIComponent(String(projectNo))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public queryGroupInventoryBalanceByProjectKeeper({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectKeeperParams, observe?: 'body', reportProgress?: boolean): Observable<Array<InventoryBalanceByProjectKeeper>>;
    public queryGroupInventoryBalanceByProjectKeeper({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectKeeperParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InventoryBalanceByProjectKeeper>>>;
    public queryGroupInventoryBalanceByProjectKeeper({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectKeeperParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InventoryBalanceByProjectKeeper>>>;
    @datastore
    public queryGroupInventoryBalanceByProjectKeeper({ deviceId, message, ignoreLoadingBar, formData, projectNo } : IqueryGroupInventoryBalanceByProjectKeeperParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNo === null || projectNo === undefined) {
            throw new Error('Required parameter projectNo was null or undefined when calling queryGroupInventoryBalanceByProjectKeeper.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<Array<InventoryBalanceByProjectKeeper>>(`${this.basePath}/Query/GroupInventoryBalanceByProjectKeeper/${encodeURIComponent(String(projectNo))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
