export type ActionType = 'RECEIPT' | 'ISSUE' | 'RETURN' | 'WRITE_OFF' | 'CHANGE_LOCATION' | 'RECEIPT_REQUEST' | 'ISSUE_REQUEST' | 'TRANSFER' | 'ISSUE_PARTIAL' | 'ISSUE_DELIVERY';
export const ActionTypeEnum = {
  RECEIPT: 'RECEIPT' as ActionType,
  ISSUE: 'ISSUE' as ActionType,
  RETURN: 'RETURN' as ActionType,
  WRITE_OFF: 'WRITE_OFF' as ActionType,
  CHANGE_LOCATION: 'CHANGE_LOCATION' as ActionType,
  RECEIPT_REQUEST: 'RECEIPT_REQUEST' as ActionType,
  ISSUE_REQUEST: 'ISSUE_REQUEST' as ActionType,
  TRANSFER: 'TRANSFER' as ActionType,
  ISSUE_PROJECT: 'ISSUE_PROJECT' as ActionType,
  ISSUE_PARTIAL: 'ISSUE_PARTIAL' as ActionType
};

export type ApprovalType = typeof ActionTypeEnum.WRITE_OFF | typeof ActionTypeEnum.CHANGE_LOCATION | typeof ActionTypeEnum.TRANSFER | typeof ActionTypeEnum.ISSUE_REQUEST  
| typeof ActionTypeEnum.RECEIPT_REQUEST;
export const ApprovalTypeEnum = {
  WRITE_OFF: ActionTypeEnum.WRITE_OFF as ApprovalType,
  CHANGE_LOCATION: ActionTypeEnum.CHANGE_LOCATION as ApprovalType,
  TRANSFER: ActionTypeEnum.TRANSFER as ApprovalType,
  ISSUE_REQUEST: ActionTypeEnum.ISSUE_REQUEST as ApprovalType,
  RECEIPT_REQUEST: ActionTypeEnum.RECEIPT_REQUEST as ApprovalType
};

export type RequestType = typeof ActionTypeEnum.RECEIPT_REQUEST | typeof ActionTypeEnum.ISSUE_REQUEST | typeof ActionTypeEnum.TRANSFER;
export const RequestTypeEnum = {
  RECEIPT_REQUEST: ActionTypeEnum.RECEIPT_REQUEST as RequestType,
  ISSUE_REQUEST: ActionTypeEnum.ISSUE_REQUEST as RequestType,
  TRANSFER: ActionTypeEnum.TRANSFER as RequestType
};
