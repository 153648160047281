import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DescPipe } from './pipes/desc.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';

const DIRECTIVES = [];

const PIPES = [DescPipe];

const COMPONENTS = [];

const DECLARATIONS = [...DIRECTIVES, ...PIPES, ...COMPONENTS];

const IMPORTS = [CommonModule, FormsModule, FontAwesomeModule, NgxQrcodeStylingModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...IMPORTS],
  exports: [...DECLARATIONS, ...IMPORTS, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MaterialCommonModule {}
