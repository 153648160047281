import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faDolly } from '@fortawesome/free-solid-svg-icons';
import { SupplierInformation, SupplierInformationService, UsersService } from '@gammon/inventory-api';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';

@Component({
  selector: 'search-supplier',
  templateUrl: './search-supplier.component.html',
  styleUrls: ['./search-supplier.component.scss']
})
export class SearchSupplierComponent implements OnInit, OnDestroy {
  @Input() placeholder = 'SEARCH_SUPPLIER';

  @Output() supplierChange = new EventEmitter<SupplierInformation>();
  @Input() supplier: SupplierInformation;

  @Output() focus = new EventEmitter<boolean>();

  supplierList: SupplierInformation[] = [];
  supplierTypeahead = new EventEmitter<string>();
  supplierTerm = null;

  faDolly = faDolly;

  subscriptionList: Subscription[] = [];

  constructor(private supplierInformationService: SupplierInformationService) {}

  ngOnInit() {
    this.subscriptionList.push(
      this.supplierTypeahead
        .pipe(
          debounceTime(300),
          switchMap((term) => this.filterSupplier(term))
        )
        .subscribe((supplierList) => {
          if (supplierList) {
            this.supplierList = supplierList;
          }
        })
    );
  }

  filterSupplier(input?): Observable<any> {
    this.supplierTerm = input;

    if (input && input.length >= 3) {
      return this.supplierInformationService.supplierInformationSearchSupplier({ query: input });
    } else {
      return EMPTY;
    }
  }

  clearSupplierList() {
    this.supplierList = [];
  }

  onSupplierChange() {
    console.log('selected supplier: ', this.supplier);
    if (!this.supplier && this.supplierTerm) {
      this.supplier = { AddressBookName: this.supplierTerm };
    }
    this.supplierChange.emit(this.supplier);
  }

  onFocus() {
    this.focus.emit(true);
  }

  onBlur() {
    this.focus.emit(false);
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  addTagFn(AddressBookName) {
    const manualInputSupplier: SupplierInformation = {
      AddressBookName
    };
    return manualInputSupplier;
  }
}
