
<div class="col input-group" [style.padding.px]="0">
  <div class="input-group-prepend">
      <span class="input-group-text"><i class="fa fa-user"></i></span>
  </div>
  <ng-select
    style="width: calc(100% - 35px); min-width:150px;"
    placeholder="{{placeholder | translate}}"
    (focus)="filterADUser()"
    (clear)="clearADUserList()"
    [items]="adUserList"
    [typeahead]="adUserTypeahead"
    [(ngModel)]="adUser"
    (ngModelChange)="onADUserChange()"
    [clearable]="true"
    [appendTo]="'body'">

  <ng-template ng-label-tmp let-item="item">
      {{ item | desc: 'ADUSER_DESC' : 'Name' }}
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
      <div class="row">
          <div class="col" style="max-width: 90px">
              <img default="assets/images/avatar.png" [src]="'https://intranet.gammonconstruction.com/PeopleDirectory_Picture/'+item.PhotoUrl" style="max-width: 70px">
          </div>
          <div class="col">
              {{ item | desc: 'ADUSER_DESC' : 'Name' }} <br/>
              <small>{{ item.DivisionDepartment }}</small><br/>
              <small>{{ item.Title }}</small><br/>
              <small>{{ item.StaffID }}</small><br/>
          </div>
      </div>
  </ng-template>
  </ng-select>
</div>
