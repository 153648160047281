import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { Configuration } from '@gammon/inventory-api';
import { LoggingConfig } from '@gammon/logging';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

export const DefaultConfig = {
  env: 'default',
  codeLength: 4,
  lockPeriod: 10 * 60 * 1000,
  enableRouteTracking: false,
  redirectToHttps: false,
  loggingConfig: {
    loggingApi: '/DeviceInfos/Logging',
    loggingConsole: { Error: true, Warn: true, Debug: true, Info: true, Log: true },
    loggingBackend: { Error: false },
    loggingLimitPerMin: 180
  } as LoggingConfig,
  codePushDeploymentKey: {
    android: 'ezMxPiSEuujYbjmVMY0p8SDKXNoUhuY04F-eK',
    ios: '2y6ShD-uoMisPU6GvFHlBVQEoVeL3tSsx96hV',
    isActive: true
  },
  API_BASE_PATH: 'http://localhost:7060/api'
};

export const LocConfig = Object.assign({}, DefaultConfig, {
  env: 'LOC'
});
export const DevConfig = Object.assign({}, DefaultConfig, {
  env: 'DEV',
  loggingConfig: Object.assign({}, DefaultConfig.loggingConfig, {
    loggingConsole: { Error: true, Warn: false, Debug: true, Info: true, Log: true },
    loggingBackend: { Error: false },
    loggingLimitPerMin: 600
  }),
  API_BASE_PATH: 'https://mobile-app2.gammonconstruction.com/ERP/DEV/Material/api'
});
export const FeatureConfig = Object.assign({}, DefaultConfig, {
  env: 'FEATURE',
  loggingConfig: Object.assign({}, DefaultConfig.loggingConfig, {
    loggingConsole: { Error: true, Warn: false, Debug: true, Info: true, Log: true },
    loggingBackend: { Error: false, Debug: false },
    loggingLimitPerMin: 600
  }),
  codePushDeploymentKey: Object.assign({}, DefaultConfig.codePushDeploymentKey, {
    android: '5lR0gqP6olQfpFmdMBaAa6kMTH4HxsVgGZUE_',
    ios: 'px8h5BWuAgqVNYtrYsfIy15V8Ncc9MtbPZ0AB'
  }),
  API_BASE_PATH: 'https://mobile-app2.gammonconstruction.com/ERP/FEATURE/Material/api'
});
export const UatConfig = Object.assign({}, DefaultConfig, {
  env: 'UAT',
  loggingConfig: Object.assign({}, DefaultConfig.loggingConfig, {
    loggingConsole: { Error: true },
    loggingBackend: { Error: false },
    loggingLimitPerMin: 60
  }),
  codePushDeploymentKey: Object.assign({}, DefaultConfig.codePushDeploymentKey, {
    android: 'u7oCyU35L7DAxaO9VHsGyszrFitvEJL_17TDZ',
    ios: 'RvORJvDfgPyhniDevAp-ZNXa1YGcpFZGcn1ZZ'
  }),
  API_BASE_PATH: 'https://mobile-app2.gammonconstruction.com/ERP/UAT/Material/api'
});
export const ProdConfig = Object.assign({}, DefaultConfig, {
  env: 'PROD',
  loggingConfig: Object.assign({}, DefaultConfig.loggingConfig, {
    loggingConsole: { Error: true },
    loggingBackend: { Error: false },
    loggingLimitPerMin: 30
  }),
  codePushDeploymentKey: Object.assign({}, DefaultConfig.codePushDeploymentKey, {
    android: 'IM7X3v4w6J4YJnu2n1Sz3-wfWq4Oafbe7dcd-e1fc-4ac1-88a7-a67c416ee13d',
    ios: 'BWQhcckpZNbMh_ggSdeloRU5He4kafbe7dcd-e1fc-4ac1-88a7-a67c416ee13d'
  }),
  API_BASE_PATH: 'https://material.gammonconstruction.com/api'
});
export const SamsonConfig = Object.assign({}, DefaultConfig, {
  env: 'SAMSON',
  loggingConfig: Object.assign({}, DefaultConfig.loggingConfig, {
    loggingConsole: { Error: true, Warn: false, Debug: true, Info: true, Log: true },
    loggingBackend: { Error: false, Debug: false },
    loggingLimitPerMin: 600
  }),
  codePushDeploymentKey: Object.assign({}, DefaultConfig.codePushDeploymentKey, {
    android: 'mWbPWvejglFzkZw7ObseiaTF8GvX7XghLao9c',
    ios: 'EP2W6r6B5-6fPraDzHIuFoJz62wdv5pKaVscZ'
  }),
  API_BASE_PATH: 'http://hkg0001wks5084n:7060/api'
});

export const CenengConfig = Object.assign({}, DefaultConfig, {
  env: 'CENENG',
  codePushDeploymentKey: Object.assign({}, DefaultConfig.codePushDeploymentKey, {
    android: '9wsxO2Wqi_zpjC682t4rRliF-WhGG7v5Sb6ms',
    ios: 't_2o3CmgS-zcJPNtiUQC0IYBm3O3Pup9A-k1L'
  }),
  API_BASE_PATH: 'https://mobile-app2.gammonconstruction.com/ERP/CENENG/Material/api'
});

export const HarryConfig = Object.assign({}, DefaultConfig, {
  env: 'HARRY',
  codePushDeploymentKey: Object.assign({}, DefaultConfig.codePushDeploymentKey, {
    android: 'H-Xgx7IKD9vUsyOAkZ54engTG9j0N3RdtjG6H',
    ios: '4B0IY9JtVD6B7rT1DuGtba8JhvvLth-aN5RmR'
  }),
  API_BASE_PATH: 'https://mobile-app2.gammonconstruction.com/ERP/HARRY/Material/api'
});

export const ConfigVault = {
  LOC: {
    description: 'Local',
    WEB: Object.assign({}, LocConfig),
    MOBILE: Object.assign({}, LocConfig, {
      codePushDeploymentKey: Object.assign({}, LocConfig.codePushDeploymentKey, {
        isActive: false
      })
    })
  },
  DEV: {
    description: 'Development',
    WEB: Object.assign({}, DevConfig),
    MOBILE: Object.assign({}, DevConfig)
  },
  FEATURE: {
    description: 'Feature',
    WEB: Object.assign({}, FeatureConfig),
    MOBILE: Object.assign({}, FeatureConfig)
  },
  UAT: {
    description: 'User acceptance testing',
    WEB: Object.assign({}, UatConfig),
    MOBILE: Object.assign({}, UatConfig)
  },
  PROD: {
    description: 'Production',
    WEB: Object.assign({}, ProdConfig),
    MOBILE: Object.assign({}, ProdConfig)
  },
  SAMSON: {
    description: 'SAMSON',
    WEB: Object.assign({}, SamsonConfig),
    MOBILE: Object.assign({}, SamsonConfig)
  },
  CENENG: {
    description: 'CENENG',
    WEB: Object.assign({}, CenengConfig),
    MOBILE: Object.assign({}, CenengConfig)
  },
  HARRY: {
    description: 'HARRY',
    WEB: Object.assign({}, HarryConfig),
    MOBILE: Object.assign({}, HarryConfig)
  }
};

export const isAndroid = navigator.userAgent.indexOf('Android') > -1;
export const isIos = navigator.userAgent.indexOf('iP') > -1 || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2);
export const isMobile = isAndroid || isIos;
export const isMobileBrowser = location.port == '8000' || location.port == '8100' || localStorage.getItem('mobile');
export const currentPlatform = isMobile || isMobileBrowser ? 'MOBILE' : 'WEB';

export const defaultEnv = 'PROD';
export const isLoc = location.href.indexOf('localhost') > -1;
export const isDev = location.port == '7060' || location.href.toLowerCase().indexOf('/dev/') > -1;
export const isFeature = location.href.toLowerCase().indexOf('/feature/') > -1;
export const isUat = location.port == '8060' || location.href.toLowerCase().indexOf('/uat/') > -1;
export const isPre = location.href.toLowerCase().indexOf('/pre/') > -1;
export const isSamson = location.href.toLowerCase().indexOf('/samson/') > -1;
export const isCeneng = location.href.toLowerCase().indexOf('/ceneng/') > -1;
export const isHarry = location.href.toLowerCase().indexOf('/harry/') > -1;
export const envPref = localStorage.getItem('env');

export type ApiEnvOptions = 'LOC' | 'DEV' | 'UAT' | 'PRE' | 'PROD' | 'FEATURE' | 'SAMSON' | 'CENENG' | 'HARRY';
export function currentEnv(): ApiEnvOptions {
  if (envPref === 'LOC' && !isLoc) {
    return 'PROD';
  }

  if (isMobileBrowser || (isLoc && !isMobile)) {
    return 'LOC';
  }

  if (isDev) {
    return 'DEV';
  }

  if (isFeature) {
    return 'FEATURE';
  }

  if (isUat) {
    return 'UAT';
  }

  if (isSamson) {
    return 'SAMSON';
  }

  if (isCeneng) {
    return 'CENENG';
  }

  if (isHarry) {
    return 'HARRY';
  }

  if (isPre) {
    return 'PRE';
  }
  return defaultEnv;
}

export const envConfig: any = ConfigVault[currentEnv()] ? ConfigVault[currentEnv()] : ConfigVault[defaultEnv];
export const envPath = ['PRE', 'PROD'].indexOf(currentEnv()) > -1 ? '' : currentEnv;
export const isMobileApp2 = ['PROD'].indexOf(currentEnv()) < 0 && (localStorage.getItem('mobileApp2') || location.host.toLowerCase().indexOf('mobile-app2') > -1);
export const gamerp11Port80 = window.location.hostname == 'gamerp11' && window.location.port == '';
export const config: any = envConfig[currentPlatform];

export function getApiBase() {
  return config.API_BASE_PATH;
}

export function getApiConfig() {
  return new Configuration();
}

export function getOnlineCheckUrl(): string {
  const onlineChekUrl = getApiBase().replace('/api', '/favicon.ico');
  return onlineChekUrl;
}

export function getLogConfig(): LoggingConfig {
  return {
    loggingApi: getApiBase() + config.loggingConfig.loggingApi,
    loggingBypassKeyword: config.loggingConfig.loggingBypassKeyword,
    loggingConsole: config.loggingConfig.loggingConsole,
    loggingBackend: config.loggingConfig.loggingBackend,
    loggingLimitPerMin: config.loggingConfig.loggingLimitPerMin
  };
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@Injectable()
export class AppHammerConfig extends HammerGestureConfig {
  overrides = {
    pinch: { enable: false },
    rotate: { enable: false }
  } as any;
}

export const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: '051eb053-51a2-4bd1-b469-0ac2012e6792',
      authority: 'https://login.microsoftonline.com/common', // 988687c9-cef9-453f-9b1d-0e3ffe89fe50',
      redirectUri: document.baseURI.toLocaleLowerCase().replace('/$', ''),
      postLogoutRedirectUri: document.baseURI.toLocaleLowerCase().replace('/$', ''),
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/', ['user.read']);
  protectedResourceMap.set('http://localhost:7060/api', ['api://051eb053-51a2-4bd1-b469-0ac2012e6792/erp.mm.api']);
  protectedResourceMap.set('https://mobile-app2.gammonconstruction.com/ERP/', ['api://051eb053-51a2-4bd1-b469-0ac2012e6792/erp.mm.api']);
  protectedResourceMap.set('https://material.gammonconstruction.com/api', ['api://051eb053-51a2-4bd1-b469-0ac2012e6792/erp.mm.api']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    // loginFailedRoute: '/login-failed',
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}
