/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Attachment } from '../model/attachment';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { AttachmentsServiceInterface, IattachmentsCheckAttachmentExistParams,IattachmentsDeleteAttachmentParams,IattachmentsGenerateUUIDParams,IattachmentsGetAttachmentParams,IattachmentsGetAttachmentListMetaDataParams,IattachmentsMoveTempAttachmentParams,IattachmentsUploadAttachmentParams, }                            from './attachments.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class AttachmentsService implements AttachmentsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param actionType 
     * @param folderID 
     * @param fileName 
     * @param createFolder 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachmentsCheckAttachmentExist({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName, createFolder } : IattachmentsCheckAttachmentExistParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public attachmentsCheckAttachmentExist({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName, createFolder } : IattachmentsCheckAttachmentExistParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public attachmentsCheckAttachmentExist({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName, createFolder } : IattachmentsCheckAttachmentExistParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public attachmentsCheckAttachmentExist({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName, createFolder } : IattachmentsCheckAttachmentExistParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling attachmentsCheckAttachmentExist.');
        }
        if (folderID === null || folderID === undefined) {
            throw new Error('Required parameter folderID was null or undefined when calling attachmentsCheckAttachmentExist.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling attachmentsCheckAttachmentExist.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (folderID !== undefined && folderID !== null) {
            queryParameters = queryParameters.set('folderID', <any>folderID);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }
        if (createFolder !== undefined && createFolder !== null) {
            queryParameters = queryParameters.set('createFolder', <any>createFolder);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/Attachments/checkAttachmentExist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param actionType 
     * @param folderID 
     * @param fileName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachmentsDeleteAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsDeleteAttachmentParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public attachmentsDeleteAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsDeleteAttachmentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public attachmentsDeleteAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsDeleteAttachmentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public attachmentsDeleteAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsDeleteAttachmentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling attachmentsDeleteAttachment.');
        }
        if (folderID === null || folderID === undefined) {
            throw new Error('Required parameter folderID was null or undefined when calling attachmentsDeleteAttachment.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling attachmentsDeleteAttachment.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (folderID !== undefined && folderID !== null) {
            queryParameters = queryParameters.set('folderID', <any>folderID);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Attachments/deleteAttachment`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachmentsGenerateUUID({ deviceId, message, ignoreLoadingBar, formData,  } : IattachmentsGenerateUUIDParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public attachmentsGenerateUUID({ deviceId, message, ignoreLoadingBar, formData,  } : IattachmentsGenerateUUIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public attachmentsGenerateUUID({ deviceId, message, ignoreLoadingBar, formData,  } : IattachmentsGenerateUUIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public attachmentsGenerateUUID({ deviceId, message, ignoreLoadingBar, formData,  } : IattachmentsGenerateUUIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<string>(`${this.basePath}/Attachments/generateUUID`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param actionType 
     * @param folderID 
     * @param fileName 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachmentsGetAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsGetAttachmentParams, observe?: 'body', reportProgress?: boolean): Observable<Attachment>;
    public attachmentsGetAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsGetAttachmentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Attachment>>;
    public attachmentsGetAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsGetAttachmentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Attachment>>;
    @datastore
    public attachmentsGetAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID, fileName } : IattachmentsGetAttachmentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling attachmentsGetAttachment.');
        }
        if (folderID === null || folderID === undefined) {
            throw new Error('Required parameter folderID was null or undefined when calling attachmentsGetAttachment.');
        }
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling attachmentsGetAttachment.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (folderID !== undefined && folderID !== null) {
            queryParameters = queryParameters.set('folderID', <any>folderID);
        }
        if (fileName !== undefined && fileName !== null) {
            queryParameters = queryParameters.set('fileName', <any>fileName);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Attachment>(`${this.basePath}/Attachments/getAttachment`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param actionType 
     * @param folderID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachmentsGetAttachmentListMetaData({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsGetAttachmentListMetaDataParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Attachment>>;
    public attachmentsGetAttachmentListMetaData({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsGetAttachmentListMetaDataParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Attachment>>>;
    public attachmentsGetAttachmentListMetaData({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsGetAttachmentListMetaDataParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Attachment>>>;
    @datastore
    public attachmentsGetAttachmentListMetaData({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsGetAttachmentListMetaDataParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling attachmentsGetAttachmentListMetaData.');
        }
        if (folderID === null || folderID === undefined) {
            throw new Error('Required parameter folderID was null or undefined when calling attachmentsGetAttachmentListMetaData.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (folderID !== undefined && folderID !== null) {
            queryParameters = queryParameters.set('folderID', <any>folderID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Attachment>>(`${this.basePath}/Attachments/getAttachmentListMetadata`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param actionType 
     * @param uuid 
     * @param folderID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachmentsMoveTempAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, uuid, folderID } : IattachmentsMoveTempAttachmentParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public attachmentsMoveTempAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, uuid, folderID } : IattachmentsMoveTempAttachmentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public attachmentsMoveTempAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, uuid, folderID } : IattachmentsMoveTempAttachmentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public attachmentsMoveTempAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, uuid, folderID } : IattachmentsMoveTempAttachmentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling attachmentsMoveTempAttachment.');
        }
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling attachmentsMoveTempAttachment.');
        }
        if (folderID === null || folderID === undefined) {
            throw new Error('Required parameter folderID was null or undefined when calling attachmentsMoveTempAttachment.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (uuid !== undefined && uuid !== null) {
            queryParameters = queryParameters.set('uuid', <any>uuid);
        }
        if (folderID !== undefined && folderID !== null) {
            queryParameters = queryParameters.set('folderID', <any>folderID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Attachments/moveTempAttachment`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param actionType 
     * @param folderID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public attachmentsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsUploadAttachmentParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public attachmentsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsUploadAttachmentParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public attachmentsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsUploadAttachmentParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public attachmentsUploadAttachment({ deviceId, message, ignoreLoadingBar, formData, actionType, folderID } : IattachmentsUploadAttachmentParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling attachmentsUploadAttachment.');
        }
        if (folderID === null || folderID === undefined) {
            throw new Error('Required parameter folderID was null or undefined when calling attachmentsUploadAttachment.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (folderID !== undefined && folderID !== null) {
            queryParameters = queryParameters.set('folderID', <any>folderID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];


        return this.httpClient.post<string>(`${this.basePath}/Attachments/uploadAttachment`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
