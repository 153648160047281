/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Area } from '../model/area';
import { AreaMetrics } from '../model/areaMetrics';
import { AreaStatus } from '../model/areaStatus';
import { PageAreaMetrics } from '../model/pageAreaMetrics';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { AreaMetricsServiceInterface, IareaMetricsCreateParams,IareaMetricsDeleteParams,IareaMetricsDeleteByListParams,IareaMetricsGetAreaMetricsByAreaIDParams,IareaMetricsGetAvailableAreaListParams,IareaMetricsSearchAreaMetricsParams,IareaMetricsStatConsumedTrendParams,IareaMetricsUpdateParams,IareaMetricsUpdateByListParams, }                            from './areaMetrics.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class AreaMetricsService implements AreaMetricsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareaMetricsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<AreaMetrics>;
    public areaMetricsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareaMetricsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AreaMetrics>>;
    public areaMetricsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareaMetricsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AreaMetrics>>;
    @datastore
    public areaMetricsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IareaMetricsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling areaMetricsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<AreaMetrics>(`${this.basePath}/AreaMetrics`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaMetricsID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsDelete({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID } : IareaMetricsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areaMetricsDelete({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID } : IareaMetricsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areaMetricsDelete({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID } : IareaMetricsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areaMetricsDelete({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID } : IareaMetricsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaMetricsID === null || areaMetricsID === undefined) {
            throw new Error('Required parameter areaMetricsID was null or undefined when calling areaMetricsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/AreaMetrics/${encodeURIComponent(String(areaMetricsID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaMetricsIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaMetricsIDs } : IareaMetricsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areaMetricsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaMetricsIDs } : IareaMetricsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areaMetricsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaMetricsIDs } : IareaMetricsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areaMetricsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, areaMetricsIDs } : IareaMetricsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaMetricsIDs === null || areaMetricsIDs === undefined) {
            throw new Error('Required parameter areaMetricsIDs was null or undefined when calling areaMetricsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (areaMetricsIDs) {
            areaMetricsIDs.forEach((element) => {
                queryParameters = queryParameters.append('areaMetricsIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/AreaMetrics/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsGetAreaMetricsByAreaID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareaMetricsGetAreaMetricsByAreaIDParams, observe?: 'body', reportProgress?: boolean): Observable<AreaMetrics>;
    public areaMetricsGetAreaMetricsByAreaID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareaMetricsGetAreaMetricsByAreaIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AreaMetrics>>;
    public areaMetricsGetAreaMetricsByAreaID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareaMetricsGetAreaMetricsByAreaIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AreaMetrics>>;
    @datastore
    public areaMetricsGetAreaMetricsByAreaID({ deviceId, message, ignoreLoadingBar, formData, areaID, views } : IareaMetricsGetAreaMetricsByAreaIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling areaMetricsGetAreaMetricsByAreaID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<AreaMetrics>(`${this.basePath}/AreaMetrics/GetAreaMetricsByAreaID/${encodeURIComponent(String(areaID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsGetAvailableAreaList({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IareaMetricsGetAvailableAreaListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Area>>;
    public areaMetricsGetAvailableAreaList({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IareaMetricsGetAvailableAreaListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Area>>>;
    public areaMetricsGetAvailableAreaList({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IareaMetricsGetAvailableAreaListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Area>>>;
    @datastore
    public areaMetricsGetAvailableAreaList({ deviceId, message, ignoreLoadingBar, formData, locationID, views } : IareaMetricsGetAvailableAreaListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling areaMetricsGetAvailableAreaList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Area>>(`${this.basePath}/AreaMetrics/GetAvailableAreaList/${encodeURIComponent(String(locationID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param locationID 
     * @param areaDescription 
     * @param areaChineseDescription 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsSearchAreaMetrics({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, views } : IareaMetricsSearchAreaMetricsParams, observe?: 'body', reportProgress?: boolean): Observable<PageAreaMetrics>;
    public areaMetricsSearchAreaMetrics({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, views } : IareaMetricsSearchAreaMetricsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageAreaMetrics>>;
    public areaMetricsSearchAreaMetrics({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, views } : IareaMetricsSearchAreaMetricsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageAreaMetrics>>;
    @datastore
    public areaMetricsSearchAreaMetrics({ deviceId, message, ignoreLoadingBar, formData, locationID, areaDescription, areaChineseDescription, views } : IareaMetricsSearchAreaMetricsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (locationID === null || locationID === undefined) {
            throw new Error('Required parameter locationID was null or undefined when calling areaMetricsSearchAreaMetrics.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (areaDescription !== undefined && areaDescription !== null) {
            queryParameters = queryParameters.set('areaDescription', <any>areaDescription);
        }
        if (areaChineseDescription !== undefined && areaChineseDescription !== null) {
            queryParameters = queryParameters.set('areaChineseDescription', <any>areaChineseDescription);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageAreaMetrics>(`${this.basePath}/AreaMetrics/SearchAreaMetrics/${encodeURIComponent(String(locationID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param previousNumberOfDays 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsStatConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IareaMetricsStatConsumedTrendParams, observe?: 'body', reportProgress?: boolean): Observable<Array<AreaStatus>>;
    public areaMetricsStatConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IareaMetricsStatConsumedTrendParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AreaStatus>>>;
    public areaMetricsStatConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IareaMetricsStatConsumedTrendParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AreaStatus>>>;
    @datastore
    public areaMetricsStatConsumedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, previousNumberOfDays } : IareaMetricsStatConsumedTrendParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling areaMetricsStatConsumedTrend.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (previousNumberOfDays !== undefined && previousNumberOfDays !== null) {
            queryParameters = queryParameters.set('previousNumberOfDays', <any>previousNumberOfDays);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<AreaStatus>>(`${this.basePath}/AreaMetrics/StatConsumedTrend/${encodeURIComponent(String(projectID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaMetricsID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsUpdate({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID, model } : IareaMetricsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areaMetricsUpdate({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID, model } : IareaMetricsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areaMetricsUpdate({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID, model } : IareaMetricsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areaMetricsUpdate({ deviceId, message, ignoreLoadingBar, formData, areaMetricsID, model } : IareaMetricsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaMetricsID === null || areaMetricsID === undefined) {
            throw new Error('Required parameter areaMetricsID was null or undefined when calling areaMetricsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling areaMetricsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/AreaMetrics/${encodeURIComponent(String(areaMetricsID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public areaMetricsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IareaMetricsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public areaMetricsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IareaMetricsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public areaMetricsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IareaMetricsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public areaMetricsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, model } : IareaMetricsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling areaMetricsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/AreaMetrics/UpdateByList`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
