import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { Role } from '../model/Role';
import { CommonGuardSecurity } from './services';

@Injectable({ providedIn: 'root' })
export class CommonSecurityService implements CommonGuardSecurity {
  public commonSecurityRoles: Role[];
  public functions: string[];
  commonSecurityRolesSubject: BehaviorSubject<Role[]> = new BehaviorSubject<Role[]>([]);
  commonSecurityRolesObservable: Observable<Role[]> = this.commonSecurityRolesSubject.asObservable();

  public SignIn: () => Promise<any>;
  public SignOut: () => Promise<any>;

  private isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject(false);
  get IsAuthenticated() {
    return this.isAuthenticated;
  }

  Roles(roles: string[], condition: 'some' | 'every' = 'some'): boolean {
    return roles[condition]((role) => this.functions?.indexOf(role) > -1);
  }

  hasRole(roles: string[], condition: 'some' | 'every' = 'some'): boolean {
    let result = false;
    if (this.commonSecurityRoles && roles) {
      result = roles[condition]((role) => this.commonSecurityRoles.map((r) => r.RoleName).indexOf(role) > -1);
    }
    return result;
  }

  RolesAsync(roles: string[], condition: 'some' | 'every' = 'some'): Observable<boolean> {
    const isAllowed = this.Roles(roles, condition);
    return new Observable<boolean>((subscriber) => {
      if (this.functions != null) {
        this.completeObservable(subscriber, isAllowed);
      } else {
        setTimeout(() => {
          this.RolesAsync(roles, condition).subscribe((isAllowed) => {
            this.completeObservable(subscriber, isAllowed);
          });
        }, 300);
      }
    });
  }

  completeObservable(subscriber: Subscriber<boolean>, isAllowed: boolean): void {
    subscriber.next(isAllowed);
    subscriber.complete();
  }

  isAllowedIssuer(iss: string): boolean {
    return ['988687c9-cef9-453f-9b1d-0e3ffe89fe50', 'a912b6c9-bf81-41d2-b164-efcad3419db5'].findIndex((e) => iss.includes(e)) > -1;
  }
}
