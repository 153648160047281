<div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'ADD_APPROVER' | translate }}({{ approvalType | translate }})</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="row d-flex flex-row">
        <div class="col">
            <input
                class="form-control"
                (keyup)="userSearchTerm$.next($event.target.value)"
                type="text"
                [(ngModel)]="searchUserInput"
                placeholder="{{ 'ENTER_NAME_OR_STAFF_ID' | translate }}"
            />
            <perfect-scrollbar style="height: 75vh; width: 100%">
                <div class="row">
                    <div
                        class="col cursor-pointer"
                        *ngFor="let searchUser of userSearchList"
                        style="min-width: 350px; max-width: 350px"
                    >
                        <user-card [user]="searchUser" (click)="selectUser(searchUser)"> </user-card>
                    </div>
                </div>
            </perfect-scrollbar>
        </div>
        <div class="col my-auto" style="min-width: 350px; max-width: 350px">
            <ng-container *ngIf="searchUser">
                <approver-card [approver]="searchUser" [editable]="true" [editType]="true"> </approver-card>

                <ng-container *ngIf="approvalType == ApprovalType.CHANGE_LOCATION">
                    <ng-select
                        (open)="searchLocations()"
                        [items]="searchLocationList"
                        [(ngModel)]="selectedLocation"
                        [appendTo]="'body'"
                    >
                        <ng-template ng-label-tmp let-item="item">
                            {{ item | desc : 'LOCATION_DESC' : 'LocationEnglish' }}
                        </ng-template>

                        <ng-template ng-option-tmp let-item="item">
                            {{ item | desc : 'LOCATION_DESC' : 'LocationEnglish' }}
                        </ng-template>
                    </ng-select>
                </ng-container>

                <button class="btn btn-primary btn-block" [disabled]="disableAdd()" (click)="addApprover()">
                    {{ 'ADD' | translate }}
                </button>
            </ng-container>

            <ng-container *ngIf="!searchUser">
                <div class="text-center">
                    <span class="mx-auto">{{ 'PLEASE_SELECT_STAFF' | translate }}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>
