<div class="input-group">
  <ng-select
    style="width: 250px"
    (open)="searchSiteEntranceTransports()"
    [items]="siteEntranceTransportList"
    [(ngModel)]="siteEntranceTransport"
    [disabled]="!project || !siteEntrance || disabled"
    (ngModelChange)="siteEntranceTransportChange.emit(siteEntranceTransport)"
    appendTo="body"
  >
    <ng-template ng-label-tmp let-item="item">
      <span>{{ item.TransportType | desc : 'TRANSPORT_TYPE_DESC' : 'TransportTypeEnglish' }}</span>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
      <span>{{ item.TransportType | desc : 'TRANSPORT_TYPE_DESC' : 'TransportTypeEnglish' }}</span>
    </ng-template>
  </ng-select>
</div>
