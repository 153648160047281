import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { appInjector } from './app/app-injector';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
.then((appRef: NgModuleRef<any>) => {
  appInjector(appRef.injector);
}).catch((err) => {
  console.log(err)
});