import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { faBan, faCheckCircle, faHandshake, faHourglass, faEdit } from '@fortawesome/free-solid-svg-icons';
import { RequestType, RequestTypeEnum } from '@material/common';

@Component({
  selector: 'request-status-tiles',
  templateUrl: './request-status-tiles.component.html',
  styleUrls: ['./request-status-tiles.component.scss']
})
export class RequestStatusTilesComponent implements OnInit, OnDestroy {
  @Input() actionType: RequestType = RequestTypeEnum.RECEIPT_REQUEST;
  @Input() data: number[] = [0, 0, 0, 0, 0];
  RequestType = RequestTypeEnum;

  faCheckCircle = faCheckCircle;
  faHourglass = faHourglass;
  faBan = faBan;
  faHandshake = faHandshake;
  faEdit = faEdit;
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}
}
