import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'common-change-env',
  templateUrl: './change-env.component.html',
  styleUrls: ['./change-env.component.scss'],
})
export class ChangeEnvComponent implements OnInit {
  @Input()
  label: string;
  @Input()
  allEnv: { [K: string]: { description } };
  @Input()
  currentEnv: string;
  @Input()
  countLimit = 5;
  @Input()
  preCountLimit = 0;
  @Input()
  reset = 5000;
  @Input()
  showExternal = true;
  preCountArray: string[] = [];
  count = 0;
  preCount = 0;
  sto: any;
  mobileApp2: string;
  constructor(
  ) {
    this.mobileApp2 = localStorage.getItem('mobileApp2');
  }

  ngOnInit() {
  }

  reloadEnv() {
    if (['PRE', 'PROD'].indexOf(this.currentEnv) > -1) {
      localStorage.removeItem('env');
    } else {
      const lang = localStorage.getItem('lang');
      localStorage.clear();
      if (['DEV', 'UAT'].indexOf(this.currentEnv) > -1) {
        if (this.mobileApp2 != null) { localStorage.setItem('mobileApp2', 'true'); }
      }
      if (lang != null) { localStorage.setItem('lang', lang); }
      localStorage.setItem('env', this.currentEnv);
    }
    location.reload();
  }

  setExternal() {
    if (this.mobileApp2) {
      localStorage.setItem('mobileApp2', this.mobileApp2);
    } else {
      localStorage.removeItem('mobileApp2');
    }
  }

  increasePreCount() {
    this.clearSto();
    if (this.preCount <= this.preCountLimit) {
      this.preCountArray.push('.');
      this.preCount++;
    }
    this.sto = setTimeout(() => this.resetCount(), this.reset);
  }

  increaseCount() {
    this.clearSto();
    if (this.preCount >= this.preCountLimit) {
      this.count++;
      if (this.preCount < this.preCountLimit) {
      this.sto = setTimeout(() => this.resetCount(), this.reset);
      }
    }
  }

  showEnvMenu() {
    return this.count >= this.countLimit;
  }

  resetCount() {
    this.preCountArray = [];
    this.preCount = 0;
    this.count = 0;
  }

  clearSto() {
    if (this.sto > 0) {
      clearTimeout(this.sto);
    }
  }


}
