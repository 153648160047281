import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import * as StackTrace from 'stacktrace-js';

export const LOG_CONFIG = new InjectionToken<LoggingConfig>('Logging Config');
export const PREFIX = '[@gammon/logging]';
export type LoggingMethod = { [key in LOGTYPE.Enum]?: boolean; };

export type LoggingBackend = { [key in keyof LoggingMethod]?: boolean; };

export class LoggingConfig {
  loggingApi: string;
  loggingHttpHeader?: { headers?: HttpHeaders | { [header: string]: string | string[] } };
  loggingStart?: number;
  loggingEnd?: number;
  loggingBypassKeyword?: string[];
  loggingConsole: LoggingMethod;
  loggingBackend?: LoggingBackend;
  loggingLimitPerMin?: number;
}

export namespace LOGTYPE {
  export type Enum = 'Log' | 'Info' | 'Warn' | 'Debug' | 'Error';
  export const Enum = {
    Log: 'Log' as Enum,
    Info: 'Info' as Enum,
    Warn: 'Warn' as Enum,
    Debug: 'Debug' as Enum,
    Error: 'Error' as Enum
  };
}

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  $console: { [key in keyof LoggingMethod]?: (message?: any, ...optionalParams: any[]) => void } = {};
  prefix = '';
  lastLog = 0;
  logCount = 0;
  logCountStart: number = new Date().getTime();
  countExcessLog: boolean;

  constructor(
    @Inject(LOG_CONFIG) private config: LoggingConfig,
    private http: HttpClient,
    private injector: Injector
  ) {
    if (!this.iSIE11()) {
      this.config = injector.get(LOG_CONFIG);
      this.$console[LOGTYPE.Enum.Log] = window.console.log;
      this.$console[LOGTYPE.Enum.Info] = window.console.info;
      this.$console[LOGTYPE.Enum.Warn] = window.console.warn;
      this.$console[LOGTYPE.Enum.Debug] = window.console.debug;
      this.$console[LOGTYPE.Enum.Error] = window.console.error;
      window.onerror = this.onError.bind(this);
      window.console.log = this.Log.bind(this);
      window.console.info = this.Info.bind(this);
      window.console.warn = this.Warn.bind(this);
      window.console.debug = this.Debug.bind(this);
      window.console.error = this.Error.bind(this);
    }
  }

  iSIE11?() {
    return !!navigator.userAgent.match(/Trident\/7\./);
  }

  ShouldLog(): boolean {
    if (this.iSIE11()) { return false; }
    const now = new Date().getTime();
    if (this.logCountStart + (60 * 1000) > now) {
      return this.logCount <= this.GetLogCountLimit();
    } else {
      this.countExcessLog = false;
      this.logCountStart = now;
      this.logCount = 0;
      return true;
    }
  }

  GetLogCountLimit() {
    return (this.config.loggingLimitPerMin || 15);
  }

  Process(logType: LOGTYPE.Enum, message?: any, ...optionalParams: any[]) {
    if (!this.iSIE11()){
      if (this.ShouldLog()) {
        const msg = `${PREFIX}${this.prefix}::[${logType}]::\n`;

        if ((this.config.loggingBypassKeyword || []).every((s) => message.indexOf(s) < 0)) {

          if (this.config.loggingBackend[logType] || this.config.loggingConsole[logType]) {
            if (this.config.loggingConsole[logType]) {
              this.logCount++;
              this.lastLog = new Date().getTime();
              const isStyleLog = (message || '').toString().startsWith('%c');
              const isLogInfo = [LOGTYPE.Enum.Info, LOGTYPE.Enum.Log].indexOf(logType) > -1;
              const addMessageToTitle = !(message instanceof Object) && !isStyleLog && isLogInfo;

              let groupTitle = `[${this.GetLocaleTimeString(this.lastLog)}]\t${this.logCount}/${this.config.loggingLimitPerMin} since ${this.GetLocaleTimeString(this.logCountStart)}:`;
              if (addMessageToTitle) { groupTitle += message; }

              console.groupCollapsed(groupTitle);
              if (optionalParams.length > 0) { this.$console[logType](optionalParams); }
              if (optionalParams[0]?.length > 1) {this.$console[LOGTYPE.Enum.Debug](optionalParams[0][1]); }
              this.$console[logType](Error().stack);
              // console.trace(msg);
              console.groupEnd();

              if (!isStyleLog && !addMessageToTitle) {
                if (Array.isArray(message)) { console.table(message); } else if (Array.isArray(message['Items'])) { console.table(message['Items']); } else { this.$console[logType](message); }
              }
              if (isStyleLog) {
                if (optionalParams[0]?.length > 1) {
                  this.$console[logType](message, optionalParams[0][0].toString());
                } else {
                  this.$console[logType](message, optionalParams[0]?.toString());
                }
              }

            }

            if (this.config.loggingBackend[logType]) { this.Backend(msg + JSON.stringify(message)); }

            this.$console.Log('\n');

          }
        }
      } else {
        if (!this.countExcessLog) {
          this.$console[LOGTYPE.Enum.Warn](`Log count excess:${this.GetLogCountLimit()}, next count reset at ${this.GetLocaleTimeString(this.logCountStart + (60 * 1000))}`);
          this.countExcessLog = true;
        }
      }
    }
  }

  GetLocaleTimeString(time: number): string {
    return new Date(time).toLocaleTimeString();
  }

  async Backend(message: string) {
    const msg = message.substr(this.config.loggingStart || 0, this.config.loggingEnd || 512);
    const headers = Object.assign(
      {
        params: null,
        'Content-Type': 'application/json'
      }, this.config.loggingHttpHeader
    );
    try {
      const data = await this.http.post(this.config.loggingApi, { message: msg }, headers).toPromise();
      if (data) { this.$console[LOGTYPE.Enum.Info](`[${LOGTYPE.Enum.Debug}] ${data}`); }
    } catch (error) {
      this.$console[LOGTYPE.Enum.Error](`::log to backend failed::\n${error}`);
    }
  }

  onError(msg, file, line, col, error) {
    const message = `window.onError:${msg} file:${file} line:${line} col:${col}\n${this.StackTrackToString(error)}`;
    this.Process(LOGTYPE.Enum.Error, message);
  }

  async Log(message?: any, ...optionalParams: any[]): Promise<void> {
    this.Process(LOGTYPE.Enum.Log, message, optionalParams);
  }

  async Info(message?: any, ...optionalParams: any[]): Promise<void> {
    this.Process(LOGTYPE.Enum.Info, message, optionalParams);
  }

  async Warn(message?: any, ...optionalParams: any[]): Promise<void> {
    this.Process(LOGTYPE.Enum.Warn, message, optionalParams);
  }

  async Debug(message?: any, ...optionalParams: any[]): Promise<void> {
    this.Process(LOGTYPE.Enum.Debug, message, optionalParams);
  }

  async Error(message?: any, ...optionalParams: any[]): Promise<void> {
    let msg = '';
    if (message instanceof Error) {
      msg += await this.StackTrackToString(message);
    } else {
      msg = message;
    }
    this.Process(LOGTYPE.Enum.Error, msg, optionalParams);
  }

  async StackTrackToString(error: Error): Promise<string> {
    return error.message + '\n' + await StackTrace.fromError(error)
      .then((stackframes) => {
        return stackframes
          .splice(0, 20)
          .map((sf) => {
            return sf.toString();
          }).join('\n');
      });
  }

}
