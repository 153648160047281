import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'decision-map',
    templateUrl: './decision-map.component.html',
    styleUrls: ['./decision-map.component.scss']
})
export class DecisionMapComponent implements OnInit, OnDestroy {

    @Input() decisionMapHtml: string;
    @Input() referenceNumber: string;
    @Output() close = new EventEmitter<any>();

    subscriptionList: Subscription[] = [];
    constructor() { }

    ngOnInit() {}

    closeModal() {
        this.close.emit();
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }



}
