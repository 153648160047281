import { Component, enableProdMode, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Project } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';
import { GeneralService } from '../../../services/general.service';
enableProdMode();
@Component({
    selector: 'projects-tab',
    templateUrl: './projects-tab.component.html',
    styleUrls: ['./projects-tab.component.scss']
})
export class ProjectsTabComponent implements OnInit, OnDestroy {

    subscriptionList: Subscription[] = [];

    @Output() projectChange = new EventEmitter<Project>();
    projectList: Project[] = [];
    projectSelection: Project;

    tableRowCount = 20;
    totalRecords = 0;

    constructor(
        private generalService: GeneralService) { }

    ngOnInit() {

        this.generalService.SearchUserProjectsByJobs().then(projects => {
            this.projectList = projects;
            this.projectSelection = this.projectList.filter(data => data.ProjectNumber == this.generalService.userPreference.DefaultProject)[0];
        });

        this.subscriptionList.push(this.generalService.OnReloadQuickView.subscribe((projects) => {
            if (projects) {
                this.totalRecords = projects.length;
                this.projectList = projects;
                console.log('Project List: ', this.projectList);
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }

}
