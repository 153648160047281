<div class="row mb-2">
    <!-- Searchings -->
</div>

<div class="row">
    <div class="col">
        <p-table 
            [value]="approvalHeaderList" 
            [paginator]="true" 
            [rows]="15"
            [scrollable]="true"
            scrollHeight="40vh"
            [totalRecords]="totalRecords"
            [lazy]="true"
            (onLazyLoad)="loadApprovalHeaderLazy($event)" >
            <ng-template pTemplate="header">
                <tr>
                    <th>{{'REFERENCE_NUMBER'|translate}}</th>
                    <th>{{'APPROVAL_STATUS'|translate}} <span (click)="searchApprovalHeader()"><i class="fas fa-sync cursor-pointer mx-1"></i></span></th>
                    <th>{{'DATE'|translate}}</th>
                    <th>{{'REQUESTOR'|translate}}</th>
                    <th>{{'REMARKS'|translate}}</th>
                    <th></th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item let-ri="rowIndex">
                <tr>
                    <td>{{item.ReferenceNumber}}</td>
                    <td>
                        <div class="d-flex flex-col">
                            <!-- <pg-progress type="bar" color="primary" value="35" thick="true"></pg-progress> -->
                            <!-- Pg-Progress Not working??? -->
                            <h6 class="semi-bold cursor-pointer" (click)="showDecisionMapModal(item)" [ngClass]="[
                                item.ApprovalStatus=='APPROVED'?'text-success':'',
                                item.ApprovalStatus=='REJECTED'?'text-danger':'',
                                item.ApprovalStatus=='DRAFT'?'text-primary':'',
                                item.ApprovalStatus=='SUBMITTING'?'text-warning':'',
                                item.ApprovalStatus=='SUBMITTED'?'text-complete':'',
                                item.ApprovalStatus=='CANCELLED'?'text-info':'']">
                                {{item.ApprovalStatus|translate}}
                            </h6>
                        </div>
                    </td>
                    <td>{{item.TransferDate|date: 'dd/MM/yyyy'}}</td>
                    <td>{{item.Requestor}}</td>
                    <td>{{item.Remarks}}</td>
                    <td>
                        <span class="cursor-pointer fs-24 mx-2" (click)="showSummaryModal(item)"><i class="far fa-file-alt"></i></span>
                        <span class="cursor-pointer fs-24 mx-5" (click)="abortApproval(item)" *ngIf="item.ApprovalStatus=='SUBMITTED' && item.ReferenceNumber && item.ReferenceNumber!=0"><i class="fas fa-trash"></i></span>
                    </td>
                </tr>
            </ng-template>

        </p-table>
    </div>
</div>

<ng-template #decisionMapModal>
    <decision-map 
        [decisionMapHtml]="decisionMapHtml"
        [referenceNumber]="selectedApprovalHeader.ReferenceNumber"
        (close)="closeModal()">
    </decision-map>
</ng-template>

<!-- <div bsModal #decisionMapModal="bs-modal" class="modal fade fill-in" tabindex="-1" role="dialog" aria-hidden="true" [config]="{ignoreBackdropClick: true}">

    <div class="bg-white" style="margin: 20px; padding: 20px">
        <div class="row">
            <div class="col">
                <h5 class="text-left p-b-5"><span class="semi-bold pull-left">{{ 'REFERENCE_NUMBER' | translate }}: {{(selectedApprovalHeader||{}).ReferenceNumber}}</span></h5>
                <button type="button" class="close" (click)="decisionMapModal.hide()" aria-hidden="true"><i class="pg-icon">close</i></button>
            </div>
        </div>

        <div class="row d-flex flex-row">
            <div [innerHTML]="decisionMapHtml | sanitize: 'html'"></div>
        </div>
        
    </div>
</div> -->