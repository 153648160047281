/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PageProjectTransferEnquiryDTO } from '../model/pageProjectTransferEnquiryDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ProjectTransferServiceInterface, IprojectTransferGetEnquiryListPageParams, }                            from './projectTransfer.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ProjectTransferService implements ProjectTransferServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param projectID 
     * @param locationID 
     * @param searchMode 
     * @param source 
     * @param supplierNo 
     * @param requestor 
     * @param fromDeliveryDate 
     * @param toDeliveryDate 
     * @param datePeriod 
     * @param teamID 
     * @param showAll 
     * @param page 
     * @param size 
     * @param status 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public projectTransferGetEnquiryListPage({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, searchMode, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, showAll, page, size, status } : IprojectTransferGetEnquiryListPageParams, observe?: 'body', reportProgress?: boolean): Observable<PageProjectTransferEnquiryDTO>;
    public projectTransferGetEnquiryListPage({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, searchMode, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, showAll, page, size, status } : IprojectTransferGetEnquiryListPageParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageProjectTransferEnquiryDTO>>;
    public projectTransferGetEnquiryListPage({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, searchMode, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, showAll, page, size, status } : IprojectTransferGetEnquiryListPageParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageProjectTransferEnquiryDTO>>;
    @datastore
    public projectTransferGetEnquiryListPage({ deviceId, message, ignoreLoadingBar, formData, projectID, locationID, searchMode, source, supplierNo, requestor, fromDeliveryDate, toDeliveryDate, datePeriod, teamID, showAll, page, size, status } : IprojectTransferGetEnquiryListPageParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (searchMode !== undefined && searchMode !== null) {
            queryParameters = queryParameters.set('searchMode', <any>searchMode);
        }
        if (source !== undefined && source !== null) {
            queryParameters = queryParameters.set('source', <any>source);
        }
        if (supplierNo !== undefined && supplierNo !== null) {
            queryParameters = queryParameters.set('supplierNo', <any>supplierNo);
        }
        if (requestor !== undefined && requestor !== null) {
            queryParameters = queryParameters.set('requestor', <any>requestor);
        }
        if (fromDeliveryDate !== undefined && fromDeliveryDate !== null) {
            queryParameters = queryParameters.set('fromDeliveryDate', <any>fromDeliveryDate.toISOString());
        }
        if (toDeliveryDate !== undefined && toDeliveryDate !== null) {
            queryParameters = queryParameters.set('toDeliveryDate', <any>toDeliveryDate.toISOString());
        }
        if (datePeriod !== undefined && datePeriod !== null) {
            queryParameters = queryParameters.set('datePeriod', <any>datePeriod);
        }
        if (teamID !== undefined && teamID !== null) {
            queryParameters = queryParameters.set('teamID', <any>teamID);
        }
        if (showAll !== undefined && showAll !== null) {
            queryParameters = queryParameters.set('showAll', <any>showAll);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (status) {
            status.forEach((element) => {
                queryParameters = queryParameters.append('status', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageProjectTransferEnquiryDTO>(`${this.basePath}/ProjectTransfer/GetEnquiryListPage`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
