<br><br><br><br><br><br>
<div class="error-page">
  <h2 class="headline text-yellow">403</h2>
  <div class="error-content">
    <h3><i class="fa fa-warning text-yellow"></i> Oops! You are not authorized</h3>
    <p>
      Please fill in the <b><a href="https://eform.gammonconstruction.com/Portal/#/app/IMS/uaa/type">User Account Administration Form</a></b> to create account.
    </p>
  </div>
  <div style="text-align:center"><button (click)="SignOut()">Sign Out</button></div>
</div>
<br><br><br><br><br><br>
