<div class="d-flex flex-column justify-content-start gap-1">
  <div class="flex-1 d-flex align-items-center gap-1">
    <!-- From Project -->
    <ng-container
      *ngIf="
        [ActionType.TRANSFER, ActionType.RECEIPT_REQUEST, ActionType.ISSUE_REQUEST].indexOf(actionType) > -1
      "
    >
      <ng-container *ngTemplateOutlet="fromProjectSelectionTemplate"></ng-container>
    </ng-container>

    <!-- To Project  -->
    <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="toProjectSelectionTemplate"></ng-container>
    </ng-container>

    <!-- Search Team  -->
    <ng-container *ngIf="[ActionType.RECEIPT_REQUEST, ActionType.ISSUE_REQUEST].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="searchTeamSelectionTemplate"></ng-container>
    </ng-container>

    <!-- Receipt Request Target Location -->
    <ng-container *ngIf="[ActionType.RECEIPT_REQUEST].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="locationSelectionTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="areaSelectionTemplate"></ng-container>

      <ng-container *ngIf="supplier || supplierName">
        <ng-container *ngTemplateOutlet="liftingQuantitySelectionTemplate"></ng-container>

        <ng-container *ngTemplateOutlet="installationDateSelectionTemplate"></ng-container>
      </ng-container>
    </ng-container>

    <!-- Issue for Construction Location -->
    <ng-container *ngIf="[ActionType.ISSUE_REQUEST].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="sourceLocationSelectionTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="sourceAreaSelectionTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="targetLocationSelectionTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="targetAreaSelectionTemplate"></ng-container>
    </ng-container>
  </div>

  <div class="flex-1 d-flex align-items-center gap-1">
    <!-- QR Item Input -->
    <ng-container *ngTemplateOutlet="searchItemTemplate"></ng-container>

    <!-- Search User/Team [Return] -->
    <ng-container *ngIf="[ActionType.RETURN].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="teamOrKeeperSelectionTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="nonGammonKeeperInputTemplate"></ng-container>
    </ng-container>

    <!-- Toggle for Responsible Person/Team-->
    <ng-container
      *ngIf="
        [ActionType.RECEIPT, ActionType.RETURN, ActionType.CHANGE_LOCATION, ActionType.WRITE_OFF].indexOf(
          actionType
        ) > -1
      "
    >
      <div
        class="d-flex flex-row"
        *ngIf="
          [ActionType.RECEIPT, ActionType.CHANGE_LOCATION, ActionType.WRITE_OFF].indexOf(actionType) > -1
        "
      >
        <mm-toggle
          class="mr-1"
          [toggleOnBackgroundColor]="'#fcaf41'"
          [(ngModel)]="teamIsResponsible"
          (ngModelChange)="onToggleResponsibleTeam($event)"
        ></mm-toggle>
        <span>{{ (teamIsResponsible ? 'RESPONSIBLE_TEAM' : 'RESPONSIBLE_PERSON') | translate }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="[ActionType.WRITE_OFF].indexOf(actionType) > -1">
      <div class="d-flex flex-row" *ShowByRoles="['WRITE_OFF_ADM_UPDATE']">
        <input
          #fileUpload
          type="file"
          (change)="handleImportPlantNos($event)"
          style="display: none"
          multiple="false"
        />
        <button
          (click)="batchWriteOffClick(fileUpload)"
          data-placement="left"
          tooltip="{{ 'BATCH_WRITE_OFF_TIP' | translate }}"
          placement="auto"
          class="btn btn-complete"
        >
          {{ 'BATCH_WRITE_OFF_BTN' | translate }}
        </button>
      </div>
    </ng-container>

    <!-- Target Date -->
    <ng-container
      *ngIf="
        [ActionType.TRANSFER, ActionType.RECEIPT_REQUEST, ActionType.ISSUE_REQUEST].indexOf(actionType) > -1
      "
    >
      <ng-container *ngTemplateOutlet="targetDateSelectionTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="[ActionType.ISSUE_REQUEST].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="keeperSelectionTemplate"></ng-container>
    </ng-container>

    <!-- Supplier Number / Name -->
    <ng-container *ngIf="[ActionType.RECEIPT_REQUEST].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="supplierSelectionTemplate"></ng-container>
    </ng-container>

    <!-- Date Bin Keeper -->
    <ng-container
      *ngIf="
        [
          ActionType.RECEIPT,
          ActionType.CHANGE_LOCATION,
          ActionType.ISSUE,
          ActionType.RETURN,
          ActionType.WRITE_OFF
        ].indexOf(actionType) > -1
      "
    >
      <date-bin-keeper
        [itemList]="itemList"
        (itemListChange)="itemListChange.emit(itemList)"
        [teamIsResponsible]="teamIsResponsible"
        [actionType]="actionType"
        [currentProject]="currentProject"
        [shortDesc]="true"
      ></date-bin-keeper>
    </ng-container>

    <!--export import item information-->
    <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
      <div class="">
        <button class="btn btn-complete" (click)="handleExportItemVariant()">
          {{ 'EXPORT' | translate }}
        </button>
      </div>
      <div class="">
        <input
          #fileUpload
          type="file"
          (change)="handleImportItemVariant($event)"
          style="display: none"
          multiple="false"
        />
        <button
          (click)="fileUpload.click()"
          data-placement="left"
          tooltip="{{ 'STOCK_RECEIPT_IMPORT_TIP' | translate }}"
          class="btn btn-complete"
        >
          {{ 'IMPORT' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
      <div class="">
        <button class="btn btn-complete" (click)="handleExportInventoryBalanceForIssue()">
          {{ 'EXPORT' | translate }}
        </button>
      </div>
      <div class="">
        <input
          #fileUpload
          type="file"
          (change)="handleImportInventoryBalanceForIssue($event)"
          style="display: none"
          multiple="false"
        />
        <button
          (click)="fileUpload.click()"
          data-placement="left"
          tooltip="{{ 'STOCK_ISSUE_IMPORT_TIP' | translate }}"
          class="btn btn-complete"
        >
          {{ 'IMPORT' | translate }}
        </button>
      </div>
    </ng-container>

    <!-- Receipt Request / Issue Request Remarks -->
    <ng-container *ngIf="[ActionType.RECEIPT_REQUEST, ActionType.ISSUE_REQUEST].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="remarksInputTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="[ActionType.RECEIPT_REQUEST].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="siteEntranceSelectionTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="siteEntranceTransportSelectionTemplate"></ng-container>
      <ng-container *ngTemplateOutlet="licensePlateNumberInputTemplate"></ng-container>
    </ng-container>

    <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
      <ng-container *ngTemplateOutlet="transferRemarksInputTemplate"></ng-container>
    </ng-container>
  </div>

  <div class="flex-2 d-flex flex-column">
    <p-table
      [value]="itemList"
      selectionMode="multiple"
      [paginator]="true"
      [rows]="tableRowCount"
      [(selection)]="itemListSelection"
      [scrollable]="true"
      scrollHeight="50vh"
      [totalRecords]="totalRecords"
    >
      <ng-template pTemplate="header">
        <tr>
          <th class="w-40"></th>

          <ng-container
            *ngIf="
              [
                ActionType.RECEIPT,
                ActionType.CHANGE_LOCATION,
                ActionType.ISSUE,
                ActionType.RETURN,
                ActionType.WRITE_OFF
              ].indexOf(actionType) > -1
            "
          >
            <th class="w-150">{{ 'TRANSACTION_DATE' | translate }}</th>
          </ng-container>
          <th>{{ 'QUANTITY' | translate }}</th>
          <ng-container *ngIf="[ActionType.WRITE_OFF].indexOf(actionType) > -1">
            <th>{{ 'PLANT_NUMBER' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
            <th class="w-130">{{ 'PLANT_NUMBER' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
            <th class="w-130">{{ 'EXPIRY_DATE' | translate }}</th>
          </ng-container>
          <th class="w-70">{{ 'UNIT' | translate }}</th>

          <ng-container
            *ngIf="
              [
                ActionType.TRANSFER,
                ActionType.ISSUE,
                ActionType.WRITE_OFF,
                ActionType.CHANGE_LOCATION
              ].indexOf(actionType) > -1
            "
          >
            <th>{{ 'FROM_BIN' | translate }}</th>
          </ng-container>

          <ng-container
            *ngIf="
              [ActionType.RECEIPT, ActionType.CHANGE_LOCATION, ActionType.RETURN].indexOf(actionType) > -1
            "
          >
            <th class="w-120">{{ 'TO_BIN' | translate }}</th>
          </ng-container>

          <ng-container
            *ngIf="
              [
                ActionType.RECEIPT,
                ActionType.CHANGE_LOCATION,
                ActionType.ISSUE,
                ActionType.WRITE_OFF
              ].indexOf(actionType) > -1
            "
          >
            <th class="w-210">
              <div class="d-flex flex-row">
                <span *ngIf="!teamIsResponsible">{{
                  [ActionType.ISSUE].indexOf(actionType) > -1
                    ? ('KEEPER' | translate)
                    : ('RESPONSIBLE_PERSON' | translate)
                }}</span>
                <span *ngIf="teamIsResponsible">{{ 'RESPONSIBLE_TEAM' | translate }}</span>
              </div>
            </th>
          </ng-container>

          <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
            <th class="w-180">{{ 'NON_GAMMON_KEEPER' | translate }}</th>
            <th class="w-130">{{ 'ISSUE_PROJECT' | translate }}</th>
          </ng-container>

          <ng-container *ngIf="[ActionType.ISSUE, ActionType.RETURN].indexOf(actionType) > -1">
            <th class="w-210">
              <div class="d-flex flex-row">
                <span>{{ 'RESPONSIBLE_TEAM' | translate }}</span>
              </div>
            </th>
          </ng-container>

          <th>{{ 'DESCRIPTION' | translate }}</th>
          <th>{{ 'BRAND' | translate }}</th>
          <th>{{ 'MODEL' | translate }}</th>
          <th>{{ 'SIZE' | translate }}</th>
          <th class="w-130">{{ 'ITEM_REMARKS' | translate }}</th>
          <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
            <th class="w-180">{{ 'ISSUE_REMARKS' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
            <th class="w-180">{{ 'RECEIPT_REMARKS' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
            <th>{{ 'LOT_NO' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
            <th>{{ 'SERIAL_NO' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
            <th>{{ 'PLANT_NUMBER' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
            <th class="w-130">{{ 'EXPIRY_DATE' | translate }}</th>
          </ng-container>
          <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
            <th class="text-center">{{ 'FILE' | translate }}</th>
            <!-- <th class="text-center">{{ 'EDIT' | translate }}</th> -->
          </ng-container>
          <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
            <th>{{ 'PO_NUMBER' | translate }}</th>
          </ng-container>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-item let-ri="rowIndex">
        <tr [pSelectableRow]="item">
          <td class="w-40">
            <span (click)="removeItem(ri)">
              <fa-icon [icon]="faTrash"></fa-icon>
            </span>
          </td>

          <ng-container
            *ngIf="
              [
                ActionType.RECEIPT,
                ActionType.CHANGE_LOCATION,
                ActionType.ISSUE,
                ActionType.RETURN,
                ActionType.WRITE_OFF
              ].indexOf(actionType) > -1
            "
          >
            <td class="w-150">
              <div class="d-flex flex-column">
                <div class="input-group date">
                  <input
                    class="form-control cursor-pointer"
                    bsDatepicker
                    [(ngModel)]="item.TransactionDate"
                    [bsConfig]="{
                      showWeekNumbers: false,
                      dateInputFormat: 'YYYY-MM-DD',
                      adaptivePosition: true
                    }"
                    placement="bottom"
                    [maxDate]="maxTransactionDate"
                    style="background: white; color: black"
                  />
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container *ngIf="[ActionType.RECEIPT, ActionType.RECEIPT_REQUEST].indexOf(actionType) > -1">
            <td>
              <span class="fs-12 tips-text" *ngIf="quantityRequired && actionType == ActionType.RECEIPT">
                {{ 'THIS_FIELD_IS_REQUIRED' | translate }}
              </span>
              <input
                class="form-control"
                type="number"
                [(ngModel)]="item.Quantity"
                min="0"
                [max]="item.MaxQuantity"
              />
            </td>
          </ng-container>
          <ng-container
            *ngIf="
              [
                ActionType.RETURN,
                ActionType.ISSUE_REQUEST,
                ActionType.TRANSFER,
                ActionType.ISSUE,
                ActionType.WRITE_OFF,
                ActionType.CHANGE_LOCATION
              ].indexOf(actionType) > -1
            "
          >
            <td>
              <span
                class="fs-12 tips-text"
                *ngIf="item.Quantity > item.MaxQuantity || item.Quantity < 0 || !quantityIsOk"
                >{{ 'QUANTITY_EXCEEDS_THE_LIMIT' | translate }}</span
              >
              <span class="fs-12 tips-text" *ngIf="quantityRequired">
                {{ 'THIS_FIELD_IS_REQUIRED' | translate }}
              </span>
              <div class="td-quantity">
                <input
                  class="form-control"
                  type="number"
                  [(ngModel)]="item.Quantity"
                  min="0"
                  [max]="item.MaxQuantity"
                />
                <span class="py-0 px-2"> / {{ item['MaxQuantity'] }}</span>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="[ActionType.WRITE_OFF].indexOf(actionType) > -1">
            <td>{{ item.ItemLot.PlantNo }}</td>
          </ng-container>
          <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
            <td class="w-130">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="item.PlantNo"
                placeholder="{{ 'PLANT_NUMBER' | translate }}"
              />
            </td>

            <td class="w-130">
              <pg-datepicker [(ngModel)]="item.ExpiryDate"></pg-datepicker>
            </td>
          </ng-container>
          <ng-container
            *ngIf="
              [ActionType.RECEIPT, ActionType.RECEIPT_REQUEST, ActionType.ISSUE_REQUEST].indexOf(actionType) >
              -1
            "
          >
            <td class="w-70">{{ item.Item.UOM }}</td>
          </ng-container>

          <ng-container
            *ngIf="
              [
                ActionType.TRANSFER,
                ActionType.ISSUE,
                ActionType.WRITE_OFF,
                ActionType.CHANGE_LOCATION,
                ActionType.RETURN
              ].indexOf(actionType) > -1
            "
          >
            <td class="w-70">{{ item.ItemLot.ItemVariant.Item.UOM }}</td>
          </ng-container>

          <ng-container
            *ngIf="
              [
                ActionType.TRANSFER,
                ActionType.ISSUE,
                ActionType.WRITE_OFF,
                ActionType.CHANGE_LOCATION
              ].indexOf(actionType) > -1
            "
          >
            <td>
              {{ item.Bin | desc : null : null : 'BIN_DESC' }}
            </td>
          </ng-container>

          <ng-container
            *ngIf="
              [ActionType.RECEIPT, ActionType.CHANGE_LOCATION, ActionType.RETURN].indexOf(actionType) > -1
            "
          >
            <td class="w-120">
              <span class="fs-12 tips-text" *ngIf="toBinRequired">
                {{ 'THIS_FIELD_IS_REQUIRED' | translate }}
              </span>
              <search-bin-line
                [(bin)]="item.TargetBin"
                [item]="item"
                [actionType]="actionType"
                [currentProject]="currentProject"
              ></search-bin-line>
            </td>
          </ng-container>

          <ng-container
            *ngIf="
              [ActionType.RECEIPT, ActionType.CHANGE_LOCATION, ActionType.WRITE_OFF].indexOf(actionType) > -1
            "
          >
            <td class="w-210">
              <span class="fs-12 tips-text" *ngIf="keeperRequired">
                {{ 'THIS_FIELD_IS_REQUIRED' | translate }}
              </span>
              <search-user
                [(keeper)]="item.Keeper"
                (keeperChange)="checkSameBin(item); onTableKeeperChange(item)"
                *ngIf="!teamIsResponsible"
                [disabled]="actionType == ActionType.ISSUE && item.IssueToProject"
              ></search-user>
              <search-team
                [(team)]="item.Team"
                [currentProject]="currentProject"
                *ngIf="teamIsResponsible && actionType != ActionType.ISSUE"
              ></search-team>
            </td>
          </ng-container>

          <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
            <td class="w-210">
              <span class="fs-12 tips-text" *ngIf="keeperRequired">
                {{ 'ONE_OF_TWO_IS_REQURED' | translate }}
              </span>
              <search-user
                [(keeper)]="item.Keeper"
                (keeperChange)="checkSameBin(item); onTableKeeperChange(item)"
                *ngIf="!teamIsResponsible"
                [actionType]="ActionType.ISSUE"
                [disabled]="actionType == ActionType.ISSUE && item.IssueToProject"
              ></search-user>
              <search-team
                [(team)]="item.Team"
                [currentProject]="currentProject"
                *ngIf="teamIsResponsible && actionType != ActionType.ISSUE"
              ></search-team>
            </td>
            <td class="w-180">
              <span class="fs-12 tips-text" *ngIf="keeperRequired">
                {{ 'ONE_OF_TWO_IS_REQURED' | translate }}
              </span>
              <input
                class="form-control"
                type="text"
                maxlength="50"
                [(ngModel)]="item.KeeperName"
                placeholder="{{ 'NON_GAMMON_KEEPER' | translate }}"
              />
            </td>
            <td class="w-130">
              <mm-toggle
                style="padding-right: 5px"
                [toggleOnBackgroundColor]="'#fcaf41'"
                [(ngModel)]="item.IssueToProject"
                (ngModelChange)="item.Keeper = null; item.OriginalKeeper = null; item.KeeperName = null"
              ></mm-toggle>
            </td>
          </ng-container>

          <ng-container *ngIf="[ActionType.ISSUE, ActionType.RETURN].indexOf(actionType) > -1">
            <td class="w-210">
              <search-team [(team)]="item.Team" [currentProject]="currentProject"></search-team>
            </td>
          </ng-container>

          <ng-container
            *ngIf="
              [ActionType.RECEIPT, ActionType.RECEIPT_REQUEST, ActionType.ISSUE_REQUEST].indexOf(actionType) >
              -1
            "
          >
            <td>{{ item.Item | desc : 'ITEM_DESC' : 'ItemEnglish' }}</td>
            <td>{{ item.Brand }}</td>
            <td>{{ item.ModelNo }}</td>
            <td>{{ item.Size }}</td>
            <td class="w-130">{{ item.Remark }}</td>
          </ng-container>

          <ng-container
            *ngIf="
              [
                ActionType.TRANSFER,
                ActionType.ISSUE,
                ActionType.WRITE_OFF,
                ActionType.CHANGE_LOCATION,
                ActionType.RETURN
              ].indexOf(actionType) > -1
            "
          >
            <td>{{ item.ItemLot.ItemVariant.Item | desc : 'ITEM_DESC' : 'ItemEnglish' }}</td>
            <td>{{ item.ItemLot.ItemVariant.Brand }}</td>
            <td>{{ item.ItemLot.ItemVariant.ModelNo }}</td>
            <td>{{ item.ItemLot.ItemVariant.Size }}</td>
            <td class="w-130">{{ item.ItemLot.Remarks || item.ItemLot.ItemVariant.Remark }}</td>
          </ng-container>
          <ng-container *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
            <td class="w-180">
              <input
                class="form-control"
                type="text"
                maxlength="150"
                [(ngModel)]="item.MovementRemarks"
                placeholder="{{ 'ISSUE_REMARKS' | translate }}"
              />
            </td>
          </ng-container>
          <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
            <td class="w-180">
              <input
                class="form-control"
                type="text"
                maxlength="150"
                [(ngModel)]="item.MovementRemarks"
                placeholder="{{ 'RECEIPT_REMARKS' | translate }}"
              />
            </td>
          </ng-container>
          <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
            <td>{{ item.ItemLot.ID }}</td>
            <td>{{ item.ItemLot.SerialNo }}</td>
            <td>{{ item.ItemLot.PlantNo }}</td>
          </ng-container>
          <ng-container *ngIf="[ActionType.TRANSFER].indexOf(actionType) > -1">
            <td class="w-130">
              <pg-datepicker
                [(ngModel)]="((item || {}).ItemLot || {}).ExpiryDate"
                (onValueChange)="onHandleExpireDateChange($event, item.ItemLot.ID)"
              ></pg-datepicker>
            </td>
            <td class="text-center">
              <button class="btn btn-primary" (click)="showImportModal(item.ItemLot.ID)">
                <fa-icon [icon]="faFile"></fa-icon>
              </button>
            </td>
          </ng-container>
          <ng-container *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1">
            <td class="text-center">
              <input class="form-control" type="text" [(ngModel)]="(item || {}).PoNo" />
            </td>
          </ng-container>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<ng-template #searchItemVariantModal>
  <search-item-variant
    [currentProject]="currentProject"
    [tableTakeRows]="tableTakeRows"
    (add)="addItemVariants($event)"
    (close)="closeModal()"
  >
  </search-item-variant>
</ng-template>

<ng-template #searchInventoryBalanceModal>
  <search-inventory-balance
    [actionType]="actionType"
    [currentProject]="currentProject"
    [keeper]="keeper"
    [keeperName]="keeperNameSearchParam"
    [tableTakeRows]="tableTakeRows"
    [team]="team"
    [location]="sourceLocation"
    [item]="searchItem"
    [withApproval]="withApproval"
    [disableLocationSearch]="disableModalLocationSearch()"
    (add)="addInventoryBalances($event)"
    (close)="closeModal()"
    (locationChange)="sourceLocation = $event"
  >
  </search-inventory-balance>
</ng-template>

<ng-template #setLocationModal>
  <search-bin
    [actionType]="actionType"
    [currentProject]="currentProject"
    [itemList]="itemListSelection"
    (itemListChange)="setSelectionList($event)"
    (close)="closeModal()"
  >
  </search-bin>
</ng-template>

<ng-template #importWordPdfModal>
  <import-word-pdf
    [title]="'IMPORT_WORD_PDF'"
    [uploadLink]="getUploadLink()"
    [authHeader]="authHeader"
    [FileList]="FileList"
    [IsNeedShowNoteOfFile]="true"
    [IsNeedCallApiToRemoveFile]="true"
    (HandleRemove)="onHandleRemove($event)"
    [IsNeedCallApiToDownloadFile]="true"
    (handleDownloadFile)="onHandleDownloadFile($event)"
    (handleDownloadAllFile)="handleDownloadAll($event)"
    (close)="closeModal()"
  >
  </import-word-pdf>
</ng-template>

<ng-template #exportItemVariantFilterModal>
  <div class="modal-header">
    <h4 class="modal-title pull-left">{{ 'SEARCH_ITEM' | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row d-flex flex-row">
      <div class="col my-auto">
        <div class="row mb-4">
          <div class="col-6">
            <search-category
              [componentType]="'item-variant'"
              [orientation]="'row'"
              [project]="currentProject"
              [(category)]="selectedCategory"
              (categoryChange)="onCategoryChange($event)"
            >
            </search-category>
            <search-subcategory
              [componentType]="'item-variant'"
              [orientation]="'row'"
              [project]="currentProject"
              [category]="selectedCategory"
              [(subCategory)]="selectedSubCategory"
              (subCategoryChange)="onSubCategoryChange($event)"
            >
            </search-subcategory>
          </div>
          <div class="col-6">
            <search-item
              [componentType]="'item-variant'"
              [orientation]="'row'"
              [project]="currentProject"
              [category]="selectedCategory"
              [subCategory]="selectedSubCategory"
              [(item)]="selectedItem"
              (onItemChange)="onItemChange($event)"
            ></search-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="row py-1 pull-right pr-3">
      <button class="btn btn-complete w-abs-120" (click)="handleExportAllItemVariant()">
        {{ 'CONFIRM' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #updateFailTemplate>
  <div class="modal-header p-3" class="bg-danger">
    <h4 class="modal-title text-white bold pull-left">{{ 'OPERATION_FAIL' | translate }}</h4>
    <button type="button" class="close text-white pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body p-3">
    <ng-container>
      <div *ngFor="let message of itemLotsUpdateFailMessages; let i = index">
        <ng-container *ngIf="i === 0; else elseTemplate">
          <span>{{ message }}</span>
        </ng-container>
        <ng-template #elseTemplate>
          <span>&nbsp;&nbsp;{{ message }}</span>
        </ng-template>
      </div>
    </ng-container>
  </div>

  <div class="modal-footer p-3"></div>
</ng-template>

<ng-template #fromProjectSelectionTemplate>
  <div class="d-flex flex-column min-width-250">
    <span class="fs-12">{{ 'FROM_PROJECT' | translate }}</span>
    <search-job [(project)]="sourceProject" [isDisable]="true"></search-job>
  </div>
</ng-template>

<ng-template #toProjectSelectionTemplate>
  <div class="d-flex flex-column min-width-250">
    <div class="d-flex gap-1">
      <span class="fs-12">{{ 'TO_PROJECT' | translate }}</span>
      <span class="fs-12 tips-text" *ngIf="toProjectRequired">{{
        'THIS_FIELD_IS_REQUIRED' | translate
      }}</span>
    </div>
    <search-job
      [(project)]="targetProject"
      [exclude]="sourceProject"
      (projectChange)="onTargetProjectChange($event)"
    ></search-job>
  </div>
</ng-template>

<ng-template #searchTeamSelectionTemplate>
  <div class="d-flex flex-column">
    <span>&nbsp;</span>
    <search-team [(team)]="team" (teamChange)="onTeamChange($event)" [currentProject]="currentProject">
    </search-team>
  </div>
</ng-template>

<ng-template #locationSelectionTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex gap-1">
      <span class="fs-12">{{ 'LOCATION' | translate }}</span>
      <span class="fs-12 tips-text">{{ 'THIS_FIELD_IS_REQUIRED' | translate }}</span>
    </div>
    <search-location
      [project]="currentProject"
      [(location)]="requestLocation"
      (locationChange)="onRequestLocationChange($event)"
    >
    </search-location>
  </div>
</ng-template>

<ng-template #areaSelectionTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex gap-1">
      <span class="fs-12">{{ 'AREA' | translate }}</span>
      <span class="fs-12 tips-text">{{ 'THIS_FIELD_IS_REQUIRED' | translate }}</span>
    </div>

    <search-area
      [location]="requestLocation"
      [(area)]="requestArea"
      (areaChange)="onRequestAreaChange($event)"
    >
    </search-area>
  </div>
</ng-template>

<ng-template #searchItemButtonTemplate>
  <div class="d-flex flex-row">
    <button class="btn btn-complete mx-1" (click)="showSearchStockModal()">
      {{ 'SEARCH_ITEM' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #liftingQuantitySelectionTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'LIFTING_QUANTITY' | translate }}</span>
    <ng-select
      style="width: 100%"
      appendTo="body"
      [items]="requestLiftingQuantityList"
      [(ngModel)]="requestLiftingQuantity"
      (ngModelChange)="requestLiftingQuantityChange.emit(requestLiftingQuantity)"
    >
      <ng-template ng-label-tmp let-item="item">
        <ng-container>
          <span>{{ item | translate }}</span>
        </ng-container>
      </ng-template>
    </ng-select>
  </div>
</ng-template>

<ng-template #installationDateSelectionTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'INSTALLATION_DATE' | translate }}</span>

    <div class="input-group date">
      <div class="input-group-prepend">
        <span class="input-group-text d-none d-md-block">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </span>
      </div>
      <input
        class="form-control w-abs-160"
        [(ngModel)]="requestInstallationDate"
        (ngModelChange)="onInstallationDateChange()"
        placeholder="{{ 'SELECT_DATE' | translate }}"
        [minDate]="minDate"
        [bsConfig]="{
          showWeekNumbers: false,
          dateInputFormat: 'YYYY-MM-DD',
          adaptivePosition: true
        }"
        bsDatepicker
        placement="bottom"
      />
      <div class="input-group-append">
        <span class="input-group-text" (click)="requestInstallationDate = null; onInstallationDateChange()">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #teamOrKeeperSelectionTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row gap-1">
      <span class="fs-12">{{ (teamIsResponsible ? 'RESPONSIBLE_TEAM' : 'KEEPER') | translate }}</span>
      <span class="fs-12 tips-text" *ngIf="teamIsResponsible && !team">{{
        'THIS_FIELD_IS_REQUIRED' | translate
      }}</span>
      <span class="fs-12 tips-text" *ngIf="!keeper && !keeperNameSearchParam && !teamIsResponsible">{{
        'ONE_OF_TWO_IS_REQURED' | translate
      }}</span>
    </div>

    <search-user
      *ngIf="!teamIsResponsible"
      [(keeper)]="keeper"
      [actionType]="ActionType.RETURN"
      (keeperChange)="onKeeperChange()"
    ></search-user>
    <search-team
      *ngIf="teamIsResponsible"
      [(team)]="team"
      (teamChange)="onTeamChange($event)"
      [currentProject]="currentProject"
    ></search-team>
  </div>
</ng-template>

<ng-template #nonGammonKeeperInputTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row gap-1">
      <span class="fs-12">{{ 'NON_GAMMON_KEEPER' | translate }}</span>
      <span class="fs-12 tips-text" *ngIf="teamIsResponsible && !team">{{
        'THIS_FIELD_IS_REQUIRED' | translate
      }}</span>
      <span class="fs-12 tips-text" *ngIf="!keeper && !keeperNameSearchParam && !teamIsResponsible">{{
        'ONE_OF_TWO_IS_REQURED' | translate
      }}</span>
    </div>
    <input
      class="form-control"
      type="text"
      (blur)="onKeeperNameChange()"
      [(ngModel)]="keeperNameSearchParam"
      placeholder="{{ 'NON_GAMMON_KEEPER' | translate }}"
    />
  </div>
</ng-template>

<ng-template #keeperSelectionTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12 tips-text">{{ 'THIS_FIELD_IS_REQUIRED' | translate }}</span>
    <search-user [(keeper)]="keeper" [actionType]="actionType" (keeperChange)="onKeeperChange()">
    </search-user>
  </div>
</ng-template>

<ng-template #searchItemTemplate>
  <div class="d-flex flex-column">
    <span>&nbsp;</span>
    <div class="d-flex">
      <ng-container *ngTemplateOutlet="scanItemInputTemplate"></ng-container>

      <ng-container *ngTemplateOutlet="searchItemButtonTemplate"></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #scanItemInputTemplate>
  <div class="input-group">
    <div class="input-group-prepend">
      <div class="input-group-text">
        <span>
          <fa-icon [icon]="faQrcode"></fa-icon>
        </span>
      </div>
    </div>
    <input
      class="form-control"
      type="text"
      (keyup)="term$.next($event.target.value)"
      [(ngModel)]="quickSearchItemInput"
      placeholder="{{ 'SCAN_ITEM' | translate }}"
      style="max-width: 300px"
    />
  </div>
</ng-template>

<ng-template #targetDateSelectionTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex gap-1">
      <span class="fs-12">{{ 'TARGET_DATE' | translate }}</span>
      <span class="fs-12 tips-text" *ngIf="targetDateRequired">{{
        'THIS_FIELD_IS_REQUIRED' | translate
      }}</span>
    </div>
    <div class="input-group date">
      <div class="input-group-prepend">
        <span class="input-group-text d-none d-md-block">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </span>
      </div>
      <input
        class="form-control w-abs-160"
        [(ngModel)]="targetDate"
        (ngModelChange)="onTargetDateChange()"
        placeholder="{{ 'SELECT_TARGET_DATE' | translate }}"
        [minDate]="minDate"
        [bsConfig]="{
          showWeekNumbers: false,
          dateInputFormat: 'YYYY-MM-DD',
          adaptivePosition: true
        }"
        bsDatepicker
        placement="bottom"
      />
      <div class="input-group-append">
        <span class="input-group-text" (click)="targetDate = null; onTargetDateChange()"
          ><fa-icon [icon]="faTimes"></fa-icon
        ></span>
      </div>

      <ng-select
        class="ml-1 min-width-70"
        appendTo="body"
        [items]="['time', 'AM', 'PM']"
        [clearable]="false"
        [(ngModel)]="period"
        (ngModelChange)="onPeriodChange($event)"
      >
        <ng-template ng-label-tmp let-item="item">
          <ng-container>
            <span>{{ item | uppercase | translate }}</span>
          </ng-container>
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          <ng-container>
            <span>{{ item | uppercase | translate }}</span>
          </ng-container>
        </ng-template>
      </ng-select>

      <ng-container *ngIf="period === 'time'">
        <ng-select
          class="ml-1 min-width-70 animation-fade-in-bottom"
          appendTo="body"
          [items]="hourArray"
          [clearable]="false"
          [ngClass]="{ 'btn-danger': timeSlotUnavailable }"
          [(ngModel)]="hour"
          (ngModelChange)="onTargetTimeChange('hour', $event)"
        >
          <ng-template ng-label-tmp let-item="item">
            <ng-container>
              <span>{{ item | translate }}</span>
            </ng-container>
          </ng-template>
        </ng-select>
        <div class="time-separator">:</div>
        <ng-select
          class="mr-1 min-width-70 animation-fade-in-bottom"
          appendTo="body"
          [items]="['00', '30']"
          [clearable]="false"
          [ngClass]="{ 'btn-danger': timeSlotUnavailable }"
          [(ngModel)]="minute"
          (ngModelChange)="onTargetTimeChange('minute', $event)"
        >
          <ng-template ng-label-tmp let-item="item">
            <ng-container>
              <span>{{ item | translate }}</span>
            </ng-container>
          </ng-template>
        </ng-select>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #supplierSelectionTemplate>
  <div class="d-flex flex-column min-width-250 max-width-250">
    <span class="fs-12">{{ 'SUPPLIER' | translate }}</span>
    <search-supplier [(supplier)]="supplier" (supplierChange)="onSupplierChange($event)"></search-supplier>
  </div>
</ng-template>

<ng-template #remarksInputTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'REMARKS' | translate }}</span>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <fa-icon [icon]="faStickyNote"></fa-icon>
        </span>
      </div>
      <input
        class="form-control"
        type="text"
        [(ngModel)]="remarks"
        (ngModelChange)="onRemarksChange()"
        maxlength="100"
      />
    </div>
  </div>
</ng-template>

<ng-template #transferRemarksInputTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'REMARKS' | translate }}</span>
    <div class="input-group">
      <div class="input-group-prepend">
        <span class="input-group-text"><fa-icon [icon]="faStickyNote"></fa-icon></span>
      </div>
      <input
        class="form-control"
        type="text"
        [(ngModel)]="planRemarks"
        (ngModelChange)="onPlanRemarksChange()"
        maxlength="100"
      />
    </div>
  </div>
</ng-template>

<ng-template #siteEntranceSelectionTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row gap-1">
      <span class="fs-12">{{ 'SITE_ENTRANCE' | translate }}</span>
      <span class="fs-12 tips-text" *ngIf="siteEntranceRequired">{{
        'THIS_FIELD_IS_REQUIRED' | translate
      }}</span>
    </div>
    <search-site-entrance
      [project]="currentProject"
      [siteEntrance]="siteEntrance"
      (siteEntranceChange)="onSiteEntranceChange($event)"
    >
    </search-site-entrance>
  </div>
</ng-template>

<ng-template #siteEntranceTransportSelectionTemplate>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row gap-1">
      <span class="fs-12">{{ 'TRANSPORT_TYPE' | translate }}</span>
      <span class="fs-12 tips-text" *ngIf="transportTypeRequired">{{
        'THIS_FIELD_IS_REQUIRED' | translate
      }}</span>
    </div>
    <search-site-entrance-transport
      [project]="currentProject"
      [siteEntrance]="siteEntrance"
      [siteEntranceTransport]=""
      (siteEntranceTransportChange)="onSiteEntranceTransportChange($event)"
    >
    </search-site-entrance-transport>
  </div>
</ng-template>

<ng-template #licensePlateNumberInputTemplate>
  <div class="d-flex flex-column max-width-250">
    <div class="d-flex flex-row gap-1">
      <span class="fs-12">{{ 'LICENSE_PLATE_NUMBER' | translate }}</span>
      <span class="fs-12 tips-text" *ngIf="licensePlateRequired">{{
        'THIS_FIELD_IS_REQUIRED' | translate
      }}</span>
    </div>
    <div class="form-group tag-group mb-0">
      <div class="tags-control">
        <pg-tag [Closable]="true" *ngFor="let lpn of licensePlateNumberList">{{ lpn }}</pg-tag>
        <input
          type="text"
          class="form-control p-1"
          (keydown.enter)="addLicensePlateNumber()"
          (keydown.backspace)="removeLicensePlateNumber()"
          (blur)="checkLicensePlateNumberInput()"
          (focus)="onLicensePlateNumberListChange()"
          [(ngModel)]="licensePlateNumberInput"
          placeholder="{{ 'PRESS_ENTER_TO_INSERT' | translate }}"
        />
      </div>
    </div>
  </div>
</ng-template>

<ng-template #sourceLocationSelectionTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'SOURCE_LOCATION' | translate }}</span>
    <search-location
      [(location)]="requestSourceLocation"
      (locationChange)="onRequestSourceLocationChange($event)"
      [project]="currentProject"
    ></search-location>
  </div>
</ng-template>

<ng-template #sourceAreaSelectionTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'SOURCE_AREA' | translate }}</span>
    <search-area
      [(area)]="requestSourceArea"
      [location]="requestSourceLocation"
      (areaChange)="onRequestSourceAreaChange($event)"
    ></search-area>
  </div>
</ng-template>

<ng-template #targetLocationSelectionTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'TARGET_LOCATION' | translate }}</span>
    <search-location
      [(location)]="requestTargetLocation"
      (locationChange)="onRequestTargetLocationChange($event)"
      [project]="currentProject"
    ></search-location>
  </div>
</ng-template>

<ng-template #targetAreaSelectionTemplate>
  <div class="d-flex flex-column">
    <span class="fs-12">{{ 'TARGET_AREA' | translate }}</span>
    <search-area
      [(area)]="requestTargetArea"
      [location]="requestTargetLocation"
      (areaChange)="onRequestTargetAreaChange($event)"
    ></search-area>
  </div>
</ng-template>
