<div
  class="list-view boreded no-top-border scrollable full-height relative"
  (cdkObserveContent)="cacheElements()"
>
  <!-- <div [class.list-view-hidden]="fakeHeaderHidden">
    <ng-template [ngTemplateOutlet]="topHeader"></ng-template>
  </div> -->
  <div
    class="scroll-wrapper list-view-wrapper"
    (scroll)="computeHeader()"
    #itemListWrapper
    [perfectScrollbar]="config"
    [disabled]="isPerfectScrollbarDisabled"
  >
    <div
      class="list-view-group-container"
      [class.list-view-animated]="elems[elem_i]?.animated"
      *ngFor="let item of _items; let elem_i = index"
      [class.list-view-animated]="item.animated"
    >
      <div class="list-view-group-header text-uppercase">
        <ng-template [ngTemplateOutlet]="item._itemHeading"></ng-template>
      </div>
      <ul>
        <!-- BEGIN List Item  !-->
        <ng-template [ngTemplateOutlet]="item._content"></ng-template>
        <!-- END List Item  !-->
      </ul>
    </div>
  </div>
</div>
