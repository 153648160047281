
<button class="btn btn-complete ml-1" style="white-space: normal;" (click)="!preventChange && showSearchBin()" [title]="bin | desc: null : null : 'BIN_DESC'">
  {{bin ? (shortDesc ? (bin | desc :'BIN_DESC' : 'BinEnglish') : (bin | desc: null : null : 'BIN_DESC')) : 'SEARCH_BIN'|translate}}
</button>

<ng-template #setLocationByLineModal>
  <search-bin
      [actionType]="actionType"
      [currentProject]="currentProject"
      (binChange)="onBinChange($event)"
      [excludeBin]="excludeBin"
      [item]="item"
      (close)="closeModal()">
  </search-bin>
</ng-template>
