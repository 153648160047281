import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { CommonAccessDeniedComponent } from '@gammon/common';

export const routes: Routes = [
  { path: '', redirectTo: 'inventory', pathMatch: 'full'},
  { path: '403', component: CommonAccessDeniedComponent },
  { path: 'inventory', loadChildren: () => import('./inventory/inventory.module')
                                          .then(m => m.InventoryModule), canActivate: [MsalGuard], data: {roles: []}},
  { path: '**', redirectTo: 'inventory'}
];
