/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ItemBalance } from '../model/itemBalance';
import { ItemVariantBalance } from '../model/itemVariantBalance';
import { Movement } from '../model/movement';
import { PageMovement } from '../model/pageMovement';
import { TeamConsumptionStatus } from '../model/teamConsumptionStatus';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { MovementsServiceInterface, ImovementsCreateParams,ImovementsDeleteParams,ImovementsDownloadMovementsExcelParams,ImovementsGetIssuedHistoryParams,ImovementsGetIssuedQuantityParams,ImovementsGetListParams,ImovementsGetListByActionTypeParams,ImovementsGetMovementTypeParams,ImovementsGetSearchListParams,ImovementsGetTeamConsumptionStatusParams,ImovementsReverseParams,ImovementsStatIssuedTrendParams,ImovementsStatSummaryParams,ImovementsStatTopIssuedItemsParams,ImovementsStatTopReceivedItemsParams,ImovementsStatTotalReceivedItemVariantParams,ImovementsUpdateParams, }                            from './movements.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class MovementsService implements MovementsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ImovementsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<Movement>;
    public movementsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ImovementsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Movement>>;
    public movementsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ImovementsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Movement>>;
    @datastore
    public movementsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : ImovementsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling movementsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Movement>(`${this.basePath}/Movements`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param movementID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsDelete({ deviceId, message, ignoreLoadingBar, formData, movementID } : ImovementsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public movementsDelete({ deviceId, message, ignoreLoadingBar, formData, movementID } : ImovementsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public movementsDelete({ deviceId, message, ignoreLoadingBar, formData, movementID } : ImovementsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public movementsDelete({ deviceId, message, ignoreLoadingBar, formData, movementID } : ImovementsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementID === null || movementID === undefined) {
            throw new Error('Required parameter movementID was null or undefined when calling movementsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Movements/${encodeURIComponent(String(movementID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param param_projectID 
     * @param param_division 
     * @param param_locationID 
     * @param param_areaID 
     * @param param_binID 
     * @param param_employeeID 
     * @param param_keeperName 
     * @param param_actionType 
     * @param param_fromDate 
     * @param param_toDate 
     * @param param_categoryID 
     * @param param_subCategoryID 
     * @param param_itemID 
     * @param param_itemVariantID 
     * @param param_itemLotID 
     * @param param_referencePlanID 
     * @param param_minQuantity 
     * @param param_maxQuantity 
     * @param param_transactionDate 
     * @param param_batchNo 
     * @param param_brand 
     * @param param_model 
     * @param param_serialNumber 
     * @param param_plantNumber 
     * @param param_teamID 
     * @param param_startCreateDate 
     * @param param_endCreateDate 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsDownloadMovementsExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_employeeID, param_keeperName, param_actionType, param_fromDate, param_toDate, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_referencePlanID, param_minQuantity, param_maxQuantity, param_transactionDate, param_batchNo, param_brand, param_model, param_serialNumber, param_plantNumber, param_teamID, param_startCreateDate, param_endCreateDate } : ImovementsDownloadMovementsExcelParams, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public movementsDownloadMovementsExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_employeeID, param_keeperName, param_actionType, param_fromDate, param_toDate, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_referencePlanID, param_minQuantity, param_maxQuantity, param_transactionDate, param_batchNo, param_brand, param_model, param_serialNumber, param_plantNumber, param_teamID, param_startCreateDate, param_endCreateDate } : ImovementsDownloadMovementsExcelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public movementsDownloadMovementsExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_employeeID, param_keeperName, param_actionType, param_fromDate, param_toDate, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_referencePlanID, param_minQuantity, param_maxQuantity, param_transactionDate, param_batchNo, param_brand, param_model, param_serialNumber, param_plantNumber, param_teamID, param_startCreateDate, param_endCreateDate } : ImovementsDownloadMovementsExcelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    @datastore
    public movementsDownloadMovementsExcel({ deviceId, message, ignoreLoadingBar, formData, param_projectID, param_division, param_locationID, param_areaID, param_binID, param_employeeID, param_keeperName, param_actionType, param_fromDate, param_toDate, param_categoryID, param_subCategoryID, param_itemID, param_itemVariantID, param_itemLotID, param_referencePlanID, param_minQuantity, param_maxQuantity, param_transactionDate, param_batchNo, param_brand, param_model, param_serialNumber, param_plantNumber, param_teamID, param_startCreateDate, param_endCreateDate } : ImovementsDownloadMovementsExcelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (param_projectID !== undefined && param_projectID !== null) {
            queryParameters = queryParameters.set('param.projectID', <any>param_projectID);
        }
        if (param_division !== undefined && param_division !== null) {
            queryParameters = queryParameters.set('param.division', <any>param_division);
        }
        if (param_locationID !== undefined && param_locationID !== null) {
            queryParameters = queryParameters.set('param.locationID', <any>param_locationID);
        }
        if (param_areaID !== undefined && param_areaID !== null) {
            queryParameters = queryParameters.set('param.areaID', <any>param_areaID);
        }
        if (param_binID !== undefined && param_binID !== null) {
            queryParameters = queryParameters.set('param.binID', <any>param_binID);
        }
        if (param_employeeID !== undefined && param_employeeID !== null) {
            queryParameters = queryParameters.set('param.employeeID', <any>param_employeeID);
        }
        if (param_keeperName !== undefined && param_keeperName !== null) {
            queryParameters = queryParameters.set('param.keeperName', <any>param_keeperName);
        }
        if (param_actionType !== undefined && param_actionType !== null) {
            queryParameters = queryParameters.set('param.actionType', <any>param_actionType);
        }
        if (param_fromDate !== undefined && param_fromDate !== null) {
            queryParameters = queryParameters.set('param.fromDate', <any>param_fromDate.toISOString());
        }
        if (param_toDate !== undefined && param_toDate !== null) {
            queryParameters = queryParameters.set('param.toDate', <any>param_toDate.toISOString());
        }
        if (param_categoryID !== undefined && param_categoryID !== null) {
            queryParameters = queryParameters.set('param.categoryID', <any>param_categoryID);
        }
        if (param_subCategoryID !== undefined && param_subCategoryID !== null) {
            queryParameters = queryParameters.set('param.subCategoryID', <any>param_subCategoryID);
        }
        if (param_itemID !== undefined && param_itemID !== null) {
            queryParameters = queryParameters.set('param.itemID', <any>param_itemID);
        }
        if (param_itemVariantID !== undefined && param_itemVariantID !== null) {
            queryParameters = queryParameters.set('param.itemVariantID', <any>param_itemVariantID);
        }
        if (param_itemLotID !== undefined && param_itemLotID !== null) {
            queryParameters = queryParameters.set('param.itemLotID', <any>param_itemLotID);
        }
        if (param_referencePlanID !== undefined && param_referencePlanID !== null) {
            queryParameters = queryParameters.set('param.referencePlanID', <any>param_referencePlanID);
        }
        if (param_minQuantity !== undefined && param_minQuantity !== null) {
            queryParameters = queryParameters.set('param.minQuantity', <any>param_minQuantity);
        }
        if (param_maxQuantity !== undefined && param_maxQuantity !== null) {
            queryParameters = queryParameters.set('param.maxQuantity', <any>param_maxQuantity);
        }
        if (param_transactionDate !== undefined && param_transactionDate !== null) {
            queryParameters = queryParameters.set('param.transactionDate', <any>param_transactionDate.toISOString());
        }
        if (param_batchNo !== undefined && param_batchNo !== null) {
            queryParameters = queryParameters.set('param.batchNo', <any>param_batchNo);
        }
        if (param_brand !== undefined && param_brand !== null) {
            queryParameters = queryParameters.set('param.brand', <any>param_brand);
        }
        if (param_model !== undefined && param_model !== null) {
            queryParameters = queryParameters.set('param.model', <any>param_model);
        }
        if (param_serialNumber !== undefined && param_serialNumber !== null) {
            queryParameters = queryParameters.set('param.serialNumber', <any>param_serialNumber);
        }
        if (param_plantNumber !== undefined && param_plantNumber !== null) {
            queryParameters = queryParameters.set('param.plantNumber', <any>param_plantNumber);
        }
        if (param_teamID !== undefined && param_teamID !== null) {
            queryParameters = queryParameters.set('param.teamID', <any>param_teamID);
        }
        if (param_startCreateDate !== undefined && param_startCreateDate !== null) {
            queryParameters = queryParameters.set('param.startCreateDate', <any>param_startCreateDate.toISOString());
        }
        if (param_endCreateDate !== undefined && param_endCreateDate !== null) {
            queryParameters = queryParameters.set('param.endCreateDate', <any>param_endCreateDate.toISOString());
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get(`${this.basePath}/Movements/DownloadMovementsExcel`,
            {
                params: queryParameters,
                responseType: "blob",
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param issuePlanID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsGetIssuedHistory({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, views } : ImovementsGetIssuedHistoryParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Movement>>;
    public movementsGetIssuedHistory({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, views } : ImovementsGetIssuedHistoryParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Movement>>>;
    public movementsGetIssuedHistory({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, views } : ImovementsGetIssuedHistoryParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Movement>>>;
    @datastore
    public movementsGetIssuedHistory({ deviceId, message, ignoreLoadingBar, formData, issuePlanID, views } : ImovementsGetIssuedHistoryParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (issuePlanID === null || issuePlanID === undefined) {
            throw new Error('Required parameter issuePlanID was null or undefined when calling movementsGetIssuedHistory.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issuePlanID !== undefined && issuePlanID !== null) {
            queryParameters = queryParameters.set('issuePlanID', <any>issuePlanID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Movement>>(`${this.basePath}/Movements/GetIssuedHistory`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param issuePlanID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsGetIssuedQuantity({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : ImovementsGetIssuedQuantityParams, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public movementsGetIssuedQuantity({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : ImovementsGetIssuedQuantityParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public movementsGetIssuedQuantity({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : ImovementsGetIssuedQuantityParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    @datastore
    public movementsGetIssuedQuantity({ deviceId, message, ignoreLoadingBar, formData, issuePlanID } : ImovementsGetIssuedQuantityParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (issuePlanID === null || issuePlanID === undefined) {
            throw new Error('Required parameter issuePlanID was null or undefined when calling movementsGetIssuedQuantity.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (issuePlanID !== undefined && issuePlanID !== null) {
            queryParameters = queryParameters.set('issuePlanID', <any>issuePlanID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<number>(`${this.basePath}/Movements/GetIssuedQuantity`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param movementID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsGetList({ deviceId, message, ignoreLoadingBar, formData, movementID, page, views } : ImovementsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Movement>>;
    public movementsGetList({ deviceId, message, ignoreLoadingBar, formData, movementID, page, views } : ImovementsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Movement>>>;
    public movementsGetList({ deviceId, message, ignoreLoadingBar, formData, movementID, page, views } : ImovementsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Movement>>>;
    @datastore
    public movementsGetList({ deviceId, message, ignoreLoadingBar, formData, movementID, page, views } : ImovementsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (movementID !== undefined && movementID !== null) {
            queryParameters = queryParameters.set('movementID', <any>movementID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Movement>>(`${this.basePath}/Movements`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param actionType 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsGetListByActionType({ deviceId, message, ignoreLoadingBar, formData, actionType, page, size, views } : ImovementsGetListByActionTypeParams, observe?: 'body', reportProgress?: boolean): Observable<PageMovement>;
    public movementsGetListByActionType({ deviceId, message, ignoreLoadingBar, formData, actionType, page, size, views } : ImovementsGetListByActionTypeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMovement>>;
    public movementsGetListByActionType({ deviceId, message, ignoreLoadingBar, formData, actionType, page, size, views } : ImovementsGetListByActionTypeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMovement>>;
    @datastore
    public movementsGetListByActionType({ deviceId, message, ignoreLoadingBar, formData, actionType, page, size, views } : ImovementsGetListByActionTypeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actionType === null || actionType === undefined) {
            throw new Error('Required parameter actionType was null or undefined when calling movementsGetListByActionType.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageMovement>(`${this.basePath}/Movements/GetListByActionType`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsGetMovementType({ deviceId, message, ignoreLoadingBar, formData,  } : ImovementsGetMovementTypeParams, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public movementsGetMovementType({ deviceId, message, ignoreLoadingBar, formData,  } : ImovementsGetMovementTypeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public movementsGetMovementType({ deviceId, message, ignoreLoadingBar, formData,  } : ImovementsGetMovementTypeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    @datastore
    public movementsGetMovementType({ deviceId, message, ignoreLoadingBar, formData,  } : ImovementsGetMovementTypeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<string>>(`${this.basePath}/Movements/GetMovementType`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param movementID 
     * @param projectID 
     * @param division 
     * @param locationID 
     * @param areaID 
     * @param binID 
     * @param EmployeeID 
     * @param keeperName 
     * @param actionType 
     * @param fromDate 
     * @param toDate 
     * @param categoryID 
     * @param subCategoryID 
     * @param itemID 
     * @param itemVariantID 
     * @param itemLotID 
     * @param referencePlanID 
     * @param minQuantity 
     * @param maxQuantity 
     * @param transactionDate 
     * @param batchNo 
     * @param brand 
     * @param model 
     * @param serialNumber 
     * @param plantNumber 
     * @param teamID 
     * @param startCreateDate 
     * @param endCreateDate 
     * @param page 
     * @param size 
     * @param views 
     * @param allStatus 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, movementID, projectID, division, locationID, areaID, binID, EmployeeID, keeperName, actionType, fromDate, toDate, categoryID, subCategoryID, itemID, itemVariantID, itemLotID, referencePlanID, minQuantity, maxQuantity, transactionDate, batchNo, brand, model, serialNumber, plantNumber, teamID, startCreateDate, endCreateDate, page, size, views, allStatus } : ImovementsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageMovement>;
    public movementsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, movementID, projectID, division, locationID, areaID, binID, EmployeeID, keeperName, actionType, fromDate, toDate, categoryID, subCategoryID, itemID, itemVariantID, itemLotID, referencePlanID, minQuantity, maxQuantity, transactionDate, batchNo, brand, model, serialNumber, plantNumber, teamID, startCreateDate, endCreateDate, page, size, views, allStatus } : ImovementsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMovement>>;
    public movementsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, movementID, projectID, division, locationID, areaID, binID, EmployeeID, keeperName, actionType, fromDate, toDate, categoryID, subCategoryID, itemID, itemVariantID, itemLotID, referencePlanID, minQuantity, maxQuantity, transactionDate, batchNo, brand, model, serialNumber, plantNumber, teamID, startCreateDate, endCreateDate, page, size, views, allStatus } : ImovementsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMovement>>;
    @datastore
    public movementsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, movementID, projectID, division, locationID, areaID, binID, EmployeeID, keeperName, actionType, fromDate, toDate, categoryID, subCategoryID, itemID, itemVariantID, itemLotID, referencePlanID, minQuantity, maxQuantity, transactionDate, batchNo, brand, model, serialNumber, plantNumber, teamID, startCreateDate, endCreateDate, page, size, views, allStatus } : ImovementsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (movementID !== undefined && movementID !== null) {
            queryParameters = queryParameters.set('movementID', <any>movementID);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (division !== undefined && division !== null) {
            queryParameters = queryParameters.set('division', <any>division);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (binID !== undefined && binID !== null) {
            queryParameters = queryParameters.set('binID', <any>binID);
        }
        if (EmployeeID !== undefined && EmployeeID !== null) {
            queryParameters = queryParameters.set('EmployeeID', <any>EmployeeID);
        }
        if (keeperName !== undefined && keeperName !== null) {
            queryParameters = queryParameters.set('keeperName', <any>keeperName);
        }
        if (actionType !== undefined && actionType !== null) {
            queryParameters = queryParameters.set('actionType', <any>actionType);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (itemLotID !== undefined && itemLotID !== null) {
            queryParameters = queryParameters.set('itemLotID', <any>itemLotID);
        }
        if (referencePlanID !== undefined && referencePlanID !== null) {
            queryParameters = queryParameters.set('referencePlanID', <any>referencePlanID);
        }
        if (minQuantity !== undefined && minQuantity !== null) {
            queryParameters = queryParameters.set('minQuantity', <any>minQuantity);
        }
        if (maxQuantity !== undefined && maxQuantity !== null) {
            queryParameters = queryParameters.set('maxQuantity', <any>maxQuantity);
        }
        if (transactionDate !== undefined && transactionDate !== null) {
            queryParameters = queryParameters.set('transactionDate', <any>transactionDate.toISOString());
        }
        if (batchNo !== undefined && batchNo !== null) {
            queryParameters = queryParameters.set('batchNo', <any>batchNo);
        }
        if (brand !== undefined && brand !== null) {
            queryParameters = queryParameters.set('brand', <any>brand);
        }
        if (model !== undefined && model !== null) {
            queryParameters = queryParameters.set('model', <any>model);
        }
        if (serialNumber !== undefined && serialNumber !== null) {
            queryParameters = queryParameters.set('serialNumber', <any>serialNumber);
        }
        if (plantNumber !== undefined && plantNumber !== null) {
            queryParameters = queryParameters.set('plantNumber', <any>plantNumber);
        }
        if (teamID !== undefined && teamID !== null) {
            queryParameters = queryParameters.set('teamID', <any>teamID);
        }
        if (startCreateDate !== undefined && startCreateDate !== null) {
            queryParameters = queryParameters.set('startCreateDate', <any>startCreateDate.toISOString());
        }
        if (endCreateDate !== undefined && endCreateDate !== null) {
            queryParameters = queryParameters.set('endCreateDate', <any>endCreateDate.toISOString());
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        if (allStatus !== undefined && allStatus !== null) {
            queryParameters = queryParameters.set('allStatus', <any>allStatus);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageMovement>(`${this.basePath}/Movements/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param categoryID 
     * @param subCategoryID 
     * @param itemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsGetTeamConsumptionStatus({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemID } : ImovementsGetTeamConsumptionStatusParams, observe?: 'body', reportProgress?: boolean): Observable<Array<TeamConsumptionStatus>>;
    public movementsGetTeamConsumptionStatus({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemID } : ImovementsGetTeamConsumptionStatusParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TeamConsumptionStatus>>>;
    public movementsGetTeamConsumptionStatus({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemID } : ImovementsGetTeamConsumptionStatusParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TeamConsumptionStatus>>>;
    @datastore
    public movementsGetTeamConsumptionStatus({ deviceId, message, ignoreLoadingBar, formData, categoryID, subCategoryID, itemID } : ImovementsGetTeamConsumptionStatusParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<Array<TeamConsumptionStatus>>(`${this.basePath}/Movements/GetTeamConsumptionStatus`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param batchNo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsReverse({ deviceId, message, ignoreLoadingBar, formData, batchNo } : ImovementsReverseParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public movementsReverse({ deviceId, message, ignoreLoadingBar, formData, batchNo } : ImovementsReverseParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public movementsReverse({ deviceId, message, ignoreLoadingBar, formData, batchNo } : ImovementsReverseParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public movementsReverse({ deviceId, message, ignoreLoadingBar, formData, batchNo } : ImovementsReverseParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (batchNo === null || batchNo === undefined) {
            throw new Error('Required parameter batchNo was null or undefined when calling movementsReverse.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (batchNo !== undefined && batchNo !== null) {
            queryParameters = queryParameters.set('batchNo', <any>batchNo);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Movements/Reverse`,
            formData,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param year 
     * @param itemID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsStatIssuedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, year, itemID, views } : ImovementsStatIssuedTrendParams, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public movementsStatIssuedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, year, itemID, views } : ImovementsStatIssuedTrendParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public movementsStatIssuedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, year, itemID, views } : ImovementsStatIssuedTrendParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    @datastore
    public movementsStatIssuedTrend({ deviceId, message, ignoreLoadingBar, formData, projectID, year, itemID, views } : ImovementsStatIssuedTrendParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling movementsStatIssuedTrend.');
        }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling movementsStatIssuedTrend.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<number>>(`${this.basePath}/Movements/StatIssuedTrend`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsStatSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : ImovementsStatSummaryParams, observe?: 'body', reportProgress?: boolean): Observable<Array<number>>;
    public movementsStatSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : ImovementsStatSummaryParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<number>>>;
    public movementsStatSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : ImovementsStatSummaryParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<number>>>;
    @datastore
    public movementsStatSummary({ deviceId, message, ignoreLoadingBar, formData, projectID } : ImovementsStatSummaryParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling movementsStatSummary.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<number>>(`${this.basePath}/Movements/StatSummary`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param year 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, views } : ImovementsStatTopIssuedItemsParams, observe?: 'body', reportProgress?: boolean): Observable<PageMovement>;
    public movementsStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, views } : ImovementsStatTopIssuedItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageMovement>>;
    public movementsStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, views } : ImovementsStatTopIssuedItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageMovement>>;
    @datastore
    public movementsStatTopIssuedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, year, views } : ImovementsStatTopIssuedItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling movementsStatTopIssuedItems.');
        }
        if (year === null || year === undefined) {
            throw new Error('Required parameter year was null or undefined when calling movementsStatTopIssuedItems.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (year !== undefined && year !== null) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageMovement>(`${this.basePath}/Movements/StatTopIssuedItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param fromDate 
     * @param toDate 
     * @param count 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsStatTopReceivedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, fromDate, toDate, count, views } : ImovementsStatTopReceivedItemsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemBalance>>;
    public movementsStatTopReceivedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, fromDate, toDate, count, views } : ImovementsStatTopReceivedItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemBalance>>>;
    public movementsStatTopReceivedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, fromDate, toDate, count, views } : ImovementsStatTopReceivedItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemBalance>>>;
    @datastore
    public movementsStatTopReceivedItems({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, fromDate, toDate, count, views } : ImovementsStatTopReceivedItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling movementsStatTopReceivedItems.');
        }
        if (categoryID === null || categoryID === undefined) {
            throw new Error('Required parameter categoryID was null or undefined when calling movementsStatTopReceivedItems.');
        }
        if (fromDate === null || fromDate === undefined) {
            throw new Error('Required parameter fromDate was null or undefined when calling movementsStatTopReceivedItems.');
        }
        if (toDate === null || toDate === undefined) {
            throw new Error('Required parameter toDate was null or undefined when calling movementsStatTopReceivedItems.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (fromDate !== undefined && fromDate !== null) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate.toISOString());
        }
        if (toDate !== undefined && toDate !== null) {
            queryParameters = queryParameters.set('toDate', <any>toDate.toISOString());
        }
        if (count !== undefined && count !== null) {
            queryParameters = queryParameters.set('count', <any>count);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemBalance>>(`${this.basePath}/Movements/StatTopReceivedItems`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param itemVariantID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsStatTotalReceivedItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, itemVariantID } : ImovementsStatTotalReceivedItemVariantParams, observe?: 'body', reportProgress?: boolean): Observable<ItemVariantBalance>;
    public movementsStatTotalReceivedItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, itemVariantID } : ImovementsStatTotalReceivedItemVariantParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemVariantBalance>>;
    public movementsStatTotalReceivedItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, itemVariantID } : ImovementsStatTotalReceivedItemVariantParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemVariantBalance>>;
    @datastore
    public movementsStatTotalReceivedItemVariant({ deviceId, message, ignoreLoadingBar, formData, projectID, itemVariantID } : ImovementsStatTotalReceivedItemVariantParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling movementsStatTotalReceivedItemVariant.');
        }
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling movementsStatTotalReceivedItemVariant.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ItemVariantBalance>(`${this.basePath}/Movements/StatTotalReceivedItemVariant`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param movementID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public movementsUpdate({ deviceId, message, ignoreLoadingBar, formData, movementID, model } : ImovementsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public movementsUpdate({ deviceId, message, ignoreLoadingBar, formData, movementID, model } : ImovementsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public movementsUpdate({ deviceId, message, ignoreLoadingBar, formData, movementID, model } : ImovementsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public movementsUpdate({ deviceId, message, ignoreLoadingBar, formData, movementID, model } : ImovementsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (movementID === null || movementID === undefined) {
            throw new Error('Required parameter movementID was null or undefined when calling movementsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling movementsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/Movements/${encodeURIComponent(String(movementID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
