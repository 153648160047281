import { Inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { CommonGuardSecurity } from '../services/services';

@Injectable({
  providedIn: 'root',
})
export class CommonCanActiveGuard implements CanActivate {
  constructor(
    @Inject('CommonGuardSecurity') private service: CommonGuardSecurity,
    private router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const requiredRoles = next.data['roles'];
    const condition = next.data['condition'] === 'every' ? 'every' : 'some';
    return this.allowActiveByFunction(requiredRoles, condition);
  }

  allowActiveByFunction(
    requiredFunctions: string[],
    condition: 'every' | 'some' = 'some'
  ): Observable<boolean> {
    const allowActivate = requiredFunctions.length === 0
      ? true
      : requiredFunctions[condition]((required) =>
          this.service.functions?.includes(required)
        );
    return new Observable<boolean>((subscriber) => {
      if (allowActivate || this.service.functions) {
        this.completeObservable(subscriber, allowActivate);
        if (!allowActivate) {
          console.warn(
            `::CommonCanActiveGuard:: require role ${requiredFunctions} to access ${this.router.url}`
          );
          this.router.navigate(['/403']);
        }
      } else {
        setTimeout(() => {
          this.allowActiveByFunction(requiredFunctions, condition)
            .subscribe(isAllowed => {
              this.completeObservable(subscriber, isAllowed);
            });
        }, 300);
      }
    });
  }

  completeObservable(subscriber: Subscriber<boolean>, isAllowed: boolean): void {
    subscriber.next(isAllowed);
    subscriber.complete();
  }

  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map((v) => v.url.map((segment) => segment.toString()).join('/'))
      .join('/');
  }
}
