<!-- <div class="d-flex flex-row justify-content-end">
    <button class="btn btn-danger" *ngIf="hasMoved" [disabled]="!hasMoved" (click)="saveRoute()">{{'SAVE'|translate}}</button>
</div> -->

<div class="row mb-2" *ngIf="approvalType === ApprovalType.CHANGE_LOCATION">
    <div class="col" style="max-width: 600px">
        <ng-select
            (open)="searchLocations()"
            [items]="searchLocationList"
            placeholder="{{ 'SELECT_LOCATION' | translate }}"
            [(ngModel)]="selectedLocation"
            (ngModelChange)="searchApprovalRoute()"
            [appendTo]="'body'"
        >
            <ng-template ng-label-tmp let-item="item">
                {{ item | desc : 'LOCATION_DESC' : 'LocationEnglish' }}
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
                {{ item | desc : 'LOCATION_DESC' : 'LocationEnglish' }}
            </ng-template>
        </ng-select>
    </div>
</div>

<div
    class="route-container"
    cdkDropListGroup
    *ngIf="approvalType === ApprovalType.CHANGE_LOCATION ? selectedLocation : true"
>
    <div cdkDropList>
        <ng-container *ngFor="let approverList of approvalRouteList; let i = index">
            <div class="route-list" cdkDrag (cdkDragDropped)="entered($event)">
                <ng-container *ngFor="let approver of approverList; let n = index">
                    <div class="route-arrow" *ngIf="n > 0 && approver.Approval_Sequence_Type === 'AND'">
                        <i class="fa fa-link fa-2x" aria-hidden="true"></i>
                    </div>
                    <div class="route-arrow" *ngIf="n > 0 && approver.Approval_Sequence_Type === 'OR'">
                        <i class="fa fa-random fa-2x" aria-hidden="true"></i>
                    </div>
                    <div class="route-item">
                        <approver-card
                            [approver]="approver"
                            [removable]="true"
                            (remove)="removeApprover($event)"
                            [showSeq]="false"
                            [editType]="true"
                            (changeType)="changeType()"
                        >
                        </approver-card>
                    </div>
                </ng-container>
                <div class="item-button">
                    <div class="add" (click)="showAddApproverModal(i + 1)">
                        <i class="fa fa-plus-circle fa-3x" aria-hidden="true"></i>
                    </div>
                    <div class="remove" (click)="removeSequence(i)">
                        <i class="fa fa-minus-circle fa-3x" aria-hidden="true"></i>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center" *ngIf="i < approvalRouteList.length - 1">
                <i class="fa fa-arrow-down fa-2x" aria-hidden="true"></i>
            </div>
        </ng-container>
    </div>

    <div class="add-sequence">
        <button
            class="btn btn-primary w-abs-120"
            *ngIf="showAdd && (approvalType === ApprovalType.CHANGE_LOCATION ? selectedLocation : true)"
            (click)="addSequence()"
        >
            {{ 'ADD' | translate }} {{ 'SEQUENCE' | translate }}
        </button>
    </div>
</div>

<ng-template #addApproverModal>
    <approval-add
        [project]="project"
        [approvalRoute]="approvalRoute"
        [sequence]="selectSequence"
        [approvalType]="approvalType"
        [searchLocationList]="searchLocationList"
        [selectedLocation]="selectedLocation"
        (add)="addApprover($event)"
        (close)="closeModal()"
    >
    </approval-add>
</ng-template>
