import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ADUser, UsersService } from '@gammon/inventory-api';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';


@Component({
    selector: 'search-aduser',
    templateUrl: './search-aduser.component.html',
    styleUrls: ['./search-aduser.component.scss']
})
export class SearchADUserComponent implements OnInit, OnDestroy {

  @Input() placeholder = 'SEARCH_STAFF';

  @Output() adUserChange = new EventEmitter<ADUser>();
  adUser: ADUser;
  adUserList: ADUser[] = [];
  adUserTypeahead = new EventEmitter<string>();

  subscriptionList: Subscription[] = [];

  constructor(
      private usersService: UsersService,
  ) { }

  ngOnInit() {
    this.subscriptionList.push(
      this.adUserTypeahead.pipe(debounceTime(300), switchMap(term => this.filterADUser(term))).subscribe(adUserList => {
        if (adUserList) {
            this.adUserList = adUserList;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptionList.forEach(subscription => {
        if (subscription) { subscription.unsubscribe(); }
    });
  }

  filterADUser(input?): Observable<any> {
    if (input && input.length >= 3) {
        return this.usersService.usersGetListByQuery({query: input});
    } else {
        return EMPTY;
    }
  }

  clearADUserList() {
      this.adUserList = [];
  }

  onADUserChange() {
    this.adUserChange.emit(this.adUser);
  }
}
