<div class="modal-header">
    <h4 class="modal-title pull-left">{{ _title | translate }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <pg-upload
        Type="drag"
        [Multiple]="false"
        [Action]="uploadLink"
        (Change)="handleChange($event)"
        [Headers]="authHeader"
        [FileType]="'xls,xlsx,csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv'"
        extraClass="dropzone cursor-pointer"
        progressType="circle">
        <div class="d-flex flex-column align-items-center">
            <h4 class="semi-bold no-margin">{{ 'DROP_FILES_TO_UPLOAD' | translate }}</h4>
            <p>{{ 'OR_CLICK_HERE' | translate }}</p>
        </div>
    </pg-upload>

</div>