import { Component, OnInit, Input } from '@angular/core';
import { ADUser } from '@gammon/inventory-api';

@Component({
    selector: 'user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss']
})
export class UserCardComponent implements OnInit {

    @Input() user: ADUser;
    
    constructor() { }

    ngOnInit() {
    }

}
