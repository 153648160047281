import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IdValueDTO, Location, LocationsService, Project } from '@gammon/inventory-api';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'search-location',
    templateUrl: './search-location.component.html',
    styleUrls: ['./search-location.component.scss']
})
export class SearchLocationComponent implements OnInit, OnDestroy {


  @Input() location: Location;
  @Output() locationChange = new EventEmitter<Location>();
  @Input() project: Project;
  @Input() division: IdValueDTO;
  @Input() placeholder = '';
  @Input() jobDiv = false;
  locationList: Location[] = [];

  subscriptionList: Subscription[] = [];

  constructor(
    private locationsService: LocationsService,
    public translate: TranslateService
  ) { }

  ngOnInit() {
    this.subscriptionList.push(
    );
  }

  ngOnDestroy() {
      this.subscriptionList.forEach(subscription => {
          if (subscription) { subscription.unsubscribe(); }
      });
    }

  searchLocation() {
    const projectID = this.jobDiv ? null : ((this.project || {}).ID) || null;
    const division = this.jobDiv ? ((this.division || {}).Id || null) : null;
    this.subscriptionList.push(this.locationsService.locationsGetSearchList({projectID, division, views: ['LOCATION','AREA', 'BIN']}).subscribe(pageData => {
        if (pageData.Items) {
            this.locationList = pageData.Items;
        }
    }));
  }

}
