<div class="d-flex flex-row">
  <div class="date-bin-keeper-box p-1">
    <div
      class="col-auto my-auto"
      style="padding-right: 0px"
      *ngIf="[ActionType.RECEIPT].indexOf(actionType) > -1"
    >
      <span class="mx-2">{{ 'PO_NUMBER' | translate }}</span>
      <input
        class="form-control"
        style="display: inline; width: auto"
        placeholder="{{ 'PO_NUMBER' | translate }}"
        type="text"
        [(ngModel)]="poNumber"
      />
    </div>

    <div class="col-auto my-auto">
      <span class="mx-2">{{ 'TRANSACTION_DATE' | translate }}</span>
      <input
        class="form-control cursor-pointer"
        bsDatepicker
        [(ngModel)]="AllTransactionDate"
        [bsConfig]="{ showWeekNumbers: false, dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true }"
        placement="bottom"
        [maxDate]="maxTransactionDate"
        style="background: white; color: black; display: inline; width: auto"
      />
    </div>
    <search-bin-line
      *ngIf="[ActionType.RECEIPT, ActionType.RETURN, ActionType.CHANGE_LOCATION].indexOf(actionType) > -1"
      [(bin)]="AllBin"
      [shortDesc]="shortDesc"
      [actionType]="actionType"
      [currentProject]="currentProject"
    ></search-bin-line>
    <search-user
      class="my-auto"
      [(keeper)]="AllKeeper"
      [actionType]="actionType"
      *ngIf="
        !teamIsResponsible &&
        [ActionType.RECEIPT, ActionType.CHANGE_LOCATION, ActionType.ISSUE, ActionType.WRITE_OFF].indexOf(
          actionType
        ) > -1
      "
    ></search-user>

    <search-team
      class="my-auto"
      *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1 || teamIsResponsible"
      [(team)]="team"
      [currentProject]="currentProject"
    ></search-team>

    <!-- <input class="form-control" style="display: inline-block; width: 75px;" type="number" [(ngModel)]="AllAmount" min="0" [title]="'QUANTITY' | translate" /> -->
    <div
      class="col-auto my-auto"
      style="padding-right: 0px"
      *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1"
    >
      <input
        class="form-control"
        style="display: inline; width: auto"
        type="text"
        maxlength="50"
        placeholder="{{ 'NON_GAMMON_KEEPER' | translate }}"
        [(ngModel)]="keeperName"
      />
    </div>

    <div class="mx-2 my-auto" *ngIf="[ActionType.ISSUE].indexOf(actionType) > -1">
      <span>{{ 'ISSUE_PROJECT' | translate }}</span>
      <mm-toggle
        style="padding-right: 5px; margin-left: 10px"
        [toggleOnBackgroundColor]="'#fcaf41'"
        [(ngModel)]="AllIssueToProject"
      ></mm-toggle>
    </div>
  </div>

  <div class="d-flex flex-row align-items-center">
    <button class="btn btn-primary ml-1" (click)="onApplyAll()">
      {{ 'APPLY_TO_ALL_ITEMS' | translate }}
    </button>
  </div>
</div>
