import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ItemCategoriesService, ItemCategory, Project } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';
import { ShareStateService } from '../../services/share-state.service';

@Component({
    selector: 'search-category',
    templateUrl: './search-category.component.html',
    styleUrls: ['./search-category.component.scss']
})
export class SearchCategoryComponent implements OnInit, OnDestroy {

    @Input() project: Project;
    @Input() category: ItemCategory;
    @Output() categoryChange = new EventEmitter<ItemCategory>();
    @Input() orientation: 'row' | 'column';
    @Input() componentType: 'item-variant' | 'inventory-balance' = 'inventory-balance';

    searchCategoryList: ItemCategory[] = [];
    subscriptionList: Subscription[] = [];
    constructor(
        private itemCategoriesService: ItemCategoriesService,
        private shareStateService: ShareStateService
    ) { }

    ngOnInit() { }

    searchCategories() {
        switch (this.componentType) {
            case 'item-variant': // search from item variant (e.g. receipt)
                this.subscriptionList.push(
                    this.itemCategoriesService.itemCategoriesGetSearchList({
                      projectNumber: this.project.ProjectNumber.toString(),
                      views: ['ITEM_CATEGORY', 'ITEM_SUB_CATEGORY', 'ITEM', 'PROJECT']
                    }).subscribe(pageData => {
                      if (pageData.Items) {
                        this.searchCategoryList = pageData.Items;
                      }
                    })
                );
                break;
            default: // search from inventory balance (e.g. issue, change-location, etc...)
                this.subscriptionList.push(
                    this.itemCategoriesService.itemCategoriesGetListWithIbQtyGreaterThanZero({
                        projectNo: this.project.ProjectNumber,
                        views: ['ITEM_CATEGORY', 'ITEM_SUB_CATEGORY', 'ITEM']
                    }).subscribe(itemCategories => {
                        if (itemCategories) {
                            this.searchCategoryList = itemCategories;
                        }
                    })
                );
                break;
        }
    }

    onCategoryChange(event) {
        this.categoryChange.emit(event);
        this.shareStateService.emitSubCategoryList([]);
        this.shareStateService.emitSelectedSubCategory(null);
        this.shareStateService.emitItemList([]);
        this.shareStateService.emitSelectedItem(null);
    }

    ngOnDestroy() {
        this.subscriptionList.forEach(subscription => {
            if (subscription) { subscription.unsubscribe(); }
        });
    }

}
