<div class="input-group">
    <div class="input-group-prepend">
        <span class="input-group-text d-none d-md-block">
            <fa-icon [icon]="faHardHat"></fa-icon>
        </span>
    </div>
    <ng-select
        [disabled]="isDisable"
        style="width: calc(100% - 35px); min-width: 150px; max-width: 250px"
        (focus)="searchJob()"
        (open)="jobList = []"
        [items]="jobList"
        [typeahead]="jobTypeahead"
        [(ngModel)]="project"
        [clearable]="true"
        [appendTo]="'body'"
    >
        <ng-template ng-label-tmp let-item="item">
            {{ item.ProjectNumber }} - {{ item.ProjectDescription }}
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
            {{ item.ProjectNumber }} - {{ item.ProjectDescription }}
        </ng-template>
    </ng-select>
</div>
