<div class="input-group">
    <ng-select
        style="width: 250px"
        (open)="searchSiteEntrances()"
        [items]="siteEntranceList"
        [(ngModel)]="siteEntrance"
        [disabled]="!project"
        (ngModelChange)="siteEntranceChange.emit(siteEntrance)"
        appendTo="body"
    >
        <ng-template ng-label-tmp let-item="item">
            <span>{{ item | desc: 'SITE_ENTRANCE_DESC':'NameEnglish' }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
            <span>{{ item | desc: 'SITE_ENTRANCE_DESC':'NameEnglish' }}</span>
        </ng-template>
    </ng-select>
</div>
