import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ValuesService } from '@gammon/inventory-api'

@Injectable()
export class Interceptor implements HttpInterceptor {

  time: number = 0;

  constructor(private valuesService: ValuesService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const now = new Date().getTime();
    if (now - this.time > 5000) {
      this.time = now;
      this.valuesService.valuesGetVersion({}).subscribe(sysVersion => {
        const localVersion = localStorage.getItem('version');
        if (sysVersion != localVersion) {
          localStorage.setItem('version', sysVersion);
          window.location.reload();
        }
      });
    }
    return next.handle(req);
  }

}