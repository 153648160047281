/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ItemVariant } from '../model/itemVariant';
import { PageItemVariant } from '../model/pageItemVariant';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ItemVariantsServiceInterface, IitemVariantsCheckIsExistCodeParams,IitemVariantsCheckIsExistItemVariantsParams,IitemVariantsCreateParams,IitemVariantsDeleteParams,IitemVariantsDeleteByListParams,IitemVariantsGetItemVariantByIDParams,IitemVariantsGetListParams,IitemVariantsGetListByEmployeeIDParams,IitemVariantsGetListByItemExcludeExistsInBudgetParams,IitemVariantsGetListByItemExcludeExistsInStockLevelParams,IitemVariantsGetListByProjectsParams,IitemVariantsGetListWithItemLotsParams,IitemVariantsGetListWithStockLevelsParams,IitemVariantsGetSearchListParams,IitemVariantsUpdateParams,IitemVariantsUpdateByListParams,IitemVariantsUploadItemsParams, }                            from './itemVariants.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class ItemVariantsService implements ItemVariantsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param itemID 
     * @param code 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, itemID, code } : IitemVariantsCheckIsExistCodeParams, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public itemVariantsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, itemID, code } : IitemVariantsCheckIsExistCodeParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public itemVariantsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, itemID, code } : IitemVariantsCheckIsExistCodeParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    @datastore
    public itemVariantsCheckIsExistCode({ deviceId, message, ignoreLoadingBar, formData, itemID, code } : IitemVariantsCheckIsExistCodeParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemVariantsCheckIsExistCode.');
        }
        if (code === null || code === undefined) {
            throw new Error('Required parameter code was null or undefined when calling itemVariantsCheckIsExistCode.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<boolean>(`${this.basePath}/ItemVariants/CheckIsExistCode/${encodeURIComponent(String(itemID))}/${encodeURIComponent(String(code))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsCheckIsExistItemVariants({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsCheckIsExistItemVariantsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemVariant>>;
    public itemVariantsCheckIsExistItemVariants({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsCheckIsExistItemVariantsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemVariant>>>;
    public itemVariantsCheckIsExistItemVariants({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsCheckIsExistItemVariantsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemVariant>>>;
    @datastore
    public itemVariantsCheckIsExistItemVariants({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsCheckIsExistItemVariantsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemVariantsCheckIsExistItemVariants.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<ItemVariant>>(`${this.basePath}/ItemVariants/CheckIsExistItemVariants`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemVariantsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<ItemVariant>;
    public itemVariantsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemVariantsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemVariant>>;
    public itemVariantsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemVariantsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemVariant>>;
    @datastore
    public itemVariantsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IitemVariantsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemVariantsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<ItemVariant>(`${this.basePath}/ItemVariants`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemVariantID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsDelete({ deviceId, message, ignoreLoadingBar, formData, itemVariantID } : IitemVariantsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemVariantsDelete({ deviceId, message, ignoreLoadingBar, formData, itemVariantID } : IitemVariantsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemVariantsDelete({ deviceId, message, ignoreLoadingBar, formData, itemVariantID } : IitemVariantsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemVariantsDelete({ deviceId, message, ignoreLoadingBar, formData, itemVariantID } : IitemVariantsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemVariantsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ItemVariants/${encodeURIComponent(String(itemVariantID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemVariantIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemVariantIDs } : IitemVariantsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemVariantsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemVariantIDs } : IitemVariantsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemVariantsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemVariantIDs } : IitemVariantsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemVariantsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, itemVariantIDs } : IitemVariantsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemVariantIDs === null || itemVariantIDs === undefined) {
            throw new Error('Required parameter itemVariantIDs was null or undefined when calling itemVariantsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemVariantIDs) {
            itemVariantIDs.forEach((element) => {
                queryParameters = queryParameters.append('itemVariantIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/ItemVariants/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemVariantID 
     * @param restrictActiveParents 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetItemVariantByID({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, restrictActiveParents, views } : IitemVariantsGetItemVariantByIDParams, observe?: 'body', reportProgress?: boolean): Observable<ItemVariant>;
    public itemVariantsGetItemVariantByID({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, restrictActiveParents, views } : IitemVariantsGetItemVariantByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ItemVariant>>;
    public itemVariantsGetItemVariantByID({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, restrictActiveParents, views } : IitemVariantsGetItemVariantByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ItemVariant>>;
    @datastore
    public itemVariantsGetItemVariantByID({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, restrictActiveParents, views } : IitemVariantsGetItemVariantByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemVariantsGetItemVariantByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (restrictActiveParents !== undefined && restrictActiveParents !== null) {
            queryParameters = queryParameters.set('restrictActiveParents', <any>restrictActiveParents);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<ItemVariant>(`${this.basePath}/ItemVariants/${encodeURIComponent(String(itemVariantID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemVariantID 
     * @param offset 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetList({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, offset, take, views } : IitemVariantsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemVariant>>;
    public itemVariantsGetList({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, offset, take, views } : IitemVariantsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemVariant>>>;
    public itemVariantsGetList({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, offset, take, views } : IitemVariantsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemVariant>>>;
    @datastore
    public itemVariantsGetList({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, offset, take, views } : IitemVariantsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemVariant>>(`${this.basePath}/ItemVariants`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param EmployeeID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetListByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID, views } : IitemVariantsGetListByEmployeeIDParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemVariant>>;
    public itemVariantsGetListByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID, views } : IitemVariantsGetListByEmployeeIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemVariant>>>;
    public itemVariantsGetListByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID, views } : IitemVariantsGetListByEmployeeIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemVariant>>>;
    @datastore
    public itemVariantsGetListByEmployeeID({ deviceId, message, ignoreLoadingBar, formData, EmployeeID, views } : IitemVariantsGetListByEmployeeIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (EmployeeID === null || EmployeeID === undefined) {
            throw new Error('Required parameter EmployeeID was null or undefined when calling itemVariantsGetListByEmployeeID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemVariant>>(`${this.basePath}/ItemVariants/Keepers/${encodeURIComponent(String(EmployeeID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param projectID 
     * @param budgetType 
     * @param locationID 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetListByItemExcludeExistsInBudget({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, budgetType, locationID, page, size, views } : IitemVariantsGetListByItemExcludeExistsInBudgetParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariant>;
    public itemVariantsGetListByItemExcludeExistsInBudget({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, budgetType, locationID, page, size, views } : IitemVariantsGetListByItemExcludeExistsInBudgetParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariant>>;
    public itemVariantsGetListByItemExcludeExistsInBudget({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, budgetType, locationID, page, size, views } : IitemVariantsGetListByItemExcludeExistsInBudgetParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariant>>;
    @datastore
    public itemVariantsGetListByItemExcludeExistsInBudget({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, budgetType, locationID, page, size, views } : IitemVariantsGetListByItemExcludeExistsInBudgetParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemVariantsGetListByItemExcludeExistsInBudget.');
        }
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemVariantsGetListByItemExcludeExistsInBudget.');
        }
        if (budgetType === null || budgetType === undefined) {
            throw new Error('Required parameter budgetType was null or undefined when calling itemVariantsGetListByItemExcludeExistsInBudget.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (budgetType !== undefined && budgetType !== null) {
            queryParameters = queryParameters.set('budgetType', <any>budgetType);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariant>(`${this.basePath}/ItemVariants/ExcludeExistsInBudget`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param projectID 
     * @param alertType 
     * @param locationID 
     * @param stockLevelType 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetListByItemExcludeExistsInStockLevel({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, alertType, locationID, stockLevelType, page, size, views } : IitemVariantsGetListByItemExcludeExistsInStockLevelParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariant>;
    public itemVariantsGetListByItemExcludeExistsInStockLevel({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, alertType, locationID, stockLevelType, page, size, views } : IitemVariantsGetListByItemExcludeExistsInStockLevelParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariant>>;
    public itemVariantsGetListByItemExcludeExistsInStockLevel({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, alertType, locationID, stockLevelType, page, size, views } : IitemVariantsGetListByItemExcludeExistsInStockLevelParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariant>>;
    @datastore
    public itemVariantsGetListByItemExcludeExistsInStockLevel({ deviceId, message, ignoreLoadingBar, formData, itemID, projectID, alertType, locationID, stockLevelType, page, size, views } : IitemVariantsGetListByItemExcludeExistsInStockLevelParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemVariantsGetListByItemExcludeExistsInStockLevel.');
        }
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemVariantsGetListByItemExcludeExistsInStockLevel.');
        }
        if (alertType === null || alertType === undefined) {
            throw new Error('Required parameter alertType was null or undefined when calling itemVariantsGetListByItemExcludeExistsInStockLevel.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (alertType !== undefined && alertType !== null) {
            queryParameters = queryParameters.set('alertType', <any>alertType);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (stockLevelType !== undefined && stockLevelType !== null) {
            queryParameters = queryParameters.set('stockLevelType', <any>stockLevelType);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariant>(`${this.basePath}/ItemVariants/ExcludeExistsInStockLevel`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetListByProjects({ deviceId, message, ignoreLoadingBar, formData, projectID, page, take, views } : IitemVariantsGetListByProjectsParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariant>;
    public itemVariantsGetListByProjects({ deviceId, message, ignoreLoadingBar, formData, projectID, page, take, views } : IitemVariantsGetListByProjectsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariant>>;
    public itemVariantsGetListByProjects({ deviceId, message, ignoreLoadingBar, formData, projectID, page, take, views } : IitemVariantsGetListByProjectsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariant>>;
    @datastore
    public itemVariantsGetListByProjects({ deviceId, message, ignoreLoadingBar, formData, projectID, page, take, views } : IitemVariantsGetListByProjectsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemVariantsGetListByProjects.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariant>(`${this.basePath}/ItemVariants/Projects/${encodeURIComponent(String(projectID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemVariantID 
     * @param itemLotID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, itemLotID, page, views } : IitemVariantsGetListWithItemLotsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemVariant>>;
    public itemVariantsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, itemLotID, page, views } : IitemVariantsGetListWithItemLotsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemVariant>>>;
    public itemVariantsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, itemLotID, page, views } : IitemVariantsGetListWithItemLotsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemVariant>>>;
    @datastore
    public itemVariantsGetListWithItemLots({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, itemLotID, page, views } : IitemVariantsGetListWithItemLotsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemVariantsGetListWithItemLots.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (itemLotID !== undefined && itemLotID !== null) {
            queryParameters = queryParameters.set('itemLotID', <any>itemLotID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemVariant>>(`${this.basePath}/ItemVariants/${encodeURIComponent(String(itemVariantID))}/ItemLots`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemVariantID 
     * @param stockLevelID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, stockLevelID, page, views } : IitemVariantsGetListWithStockLevelsParams, observe?: 'body', reportProgress?: boolean): Observable<Array<ItemVariant>>;
    public itemVariantsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, stockLevelID, page, views } : IitemVariantsGetListWithStockLevelsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ItemVariant>>>;
    public itemVariantsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, stockLevelID, page, views } : IitemVariantsGetListWithStockLevelsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ItemVariant>>>;
    @datastore
    public itemVariantsGetListWithStockLevels({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, stockLevelID, page, views } : IitemVariantsGetListWithStockLevelsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemVariantsGetListWithStockLevels.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stockLevelID !== undefined && stockLevelID !== null) {
            queryParameters = queryParameters.set('stockLevelID', <any>stockLevelID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<ItemVariant>>(`${this.basePath}/ItemVariants/${encodeURIComponent(String(itemVariantID))}/StockLevels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param subCategoryID 
     * @param itemID 
     * @param itemVariantID 
     * @param gammonProductCode 
     * @param brand 
     * @param model 
     * @param itemDescription 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, gammonProductCode, brand, model, itemDescription, page, take, views } : IitemVariantsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageItemVariant>;
    public itemVariantsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, gammonProductCode, brand, model, itemDescription, page, take, views } : IitemVariantsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageItemVariant>>;
    public itemVariantsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, gammonProductCode, brand, model, itemDescription, page, take, views } : IitemVariantsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageItemVariant>>;
    @datastore
    public itemVariantsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, gammonProductCode, brand, model, itemDescription, page, take, views } : IitemVariantsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling itemVariantsGetSearchList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (gammonProductCode !== undefined && gammonProductCode !== null) {
            queryParameters = queryParameters.set('gammonProductCode', <any>gammonProductCode);
        }
        if (brand !== undefined && brand !== null) {
            queryParameters = queryParameters.set('brand', <any>brand);
        }
        if (model !== undefined && model !== null) {
            queryParameters = queryParameters.set('model', <any>model);
        }
        if (itemDescription !== undefined && itemDescription !== null) {
            queryParameters = queryParameters.set('itemDescription', <any>itemDescription);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageItemVariant>(`${this.basePath}/ItemVariants/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemVariantID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, model } : IitemVariantsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemVariantsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, model } : IitemVariantsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemVariantsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, model } : IitemVariantsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemVariantsUpdate({ deviceId, message, ignoreLoadingBar, formData, itemVariantID, model } : IitemVariantsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemVariantID === null || itemVariantID === undefined) {
            throw new Error('Required parameter itemVariantID was null or undefined when calling itemVariantsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling itemVariantsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/ItemVariants/${encodeURIComponent(String(itemVariantID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemVariantsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemVariantsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemVariantsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IitemVariantsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling itemVariantsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/ItemVariants/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param itemID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public itemVariantsUploadItems({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemVariantsUploadItemsParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public itemVariantsUploadItems({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemVariantsUploadItemsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public itemVariantsUploadItems({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemVariantsUploadItemsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public itemVariantsUploadItems({ deviceId, message, ignoreLoadingBar, formData, itemID } : IitemVariantsUploadItemsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (itemID === null || itemID === undefined) {
            throw new Error('Required parameter itemID was null or undefined when calling itemVariantsUploadItems.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/ItemVariants/UploadItem/${encodeURIComponent(String(itemID))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
