import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ProjectApprover, UsersService } from '@gammon/inventory-api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'approver-card',
  templateUrl: './approver-card.component.html',
  styleUrls: ['./approver-card.component.scss']
})
export class ApproverCardComponent implements OnInit, OnDestroy {
  subscriptionList: Subscription[] = [];

  @Input() add = false;

  private _approver: ProjectApprover;
  @Input() set approver(value: ProjectApprover) {
    value.Approval_Sequence_Type ? (value.Approval_Sequence_Type = value.Approval_Sequence_Type) : (value.Approval_Sequence_Type = 'AND');
    this._approver = value;
  }
  get approver(): ProjectApprover {
    return this._approver;
  }

  @Input() editable = false;
  @Input() removable = false;
  @Output() remove = new EventEmitter<ProjectApprover>();
  @Input() showTitle = true;
  @Input() showSeq = true;
  @Input() editType = false;
  @Output() changeType = new EventEmitter<any>();

  ismodify = true;
  constructor(private usersService: UsersService) {}

  ngOnInit() {}

  removeApprover() {
    this.remove.emit(this._approver);
  }

  canModify() {
    this.ismodify = false;
  }

  getUserByAD(userAD: string) {
    return this.usersService.usersGetUserByAD({ userAD, ignoreLoadingBar: true });
  }

  onChangeType() {
    this.changeType.emit();
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
