import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'dropdown-by-array',
    templateUrl: './dropdown-by-array.component.html',
    styleUrls: ['./dropdown-by-array.component.scss']
})
export class DropdownByArrayComponent {
  @Input() required = false;
  @Input() stringArray: string[];
  @Input() selected: string;
  @Output() selectedChange = new EventEmitter<string>();
}
