import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  AreasService,
  InventoryBalance,
  InventoryPlan,
  IssuePlan,
  LocationsService,
  Project,
  ReceiptPlan,
  InventoryBalancesService,
  InventoryPlansService,
  MovementsService,
  ReturnDecisionMapDTO,
  ApprovalHeader,
  ApprovalHeadersService,
  ApprovalService,
  ActiveTaskDTO
} from '@gammon/inventory-api';
import { CommonIssuePlan, RequestType, RequestTypeEnum } from '@material/common';
import { CommonInventoryPlan } from '@material/common/models/CommonInventoryPlan';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap';
import { ModalService, ModalStatus } from '../../../services/modal.service';
import { GeneralService } from '../../../services/general.service';
import { EMPTY } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ApprovalTypeEnum, ActionTypeEnum, ActionType } from '@material/common';
import { Subscription } from 'rxjs';
import { UploadFile } from '../../../../@pages/components/upload/interface';
import { config } from 'projects/AppConfig';
import { UpdateExpireDateParam } from '@gammon/inventory-api/model/updateExpireDateParam';
import { TranslateService } from '@ngx-translate/core';
import { ProjectsService } from '@gammon/inventory-api';
import { forkJoin, Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
/**
 *  ++++++++++++++++++++++++++++++++++++++
 *  | This component loads request items |
 *  | and do different approval actions  |
 *  ++++++++++++++++++++++++++++++++++++++
 */

@Component({
  selector: 'request-items-tab',
  templateUrl: './request-items-tab.component.html',
  styleUrls: ['./request-items-tab.component.scss']
})
export class RequestItemsTabComponent implements OnInit, OnDestroy {
  @Input() actionType: RequestType;
  @Input() currentProject: Project;
  @Input() set request(value: CommonInventoryPlan) {
    this._request = value;
    console.log('Detected request change: ', this._request);
    this.loadItemList(this._request);
  }
  @Input() decisionMap: ReturnDecisionMapDTO;
  //@ViewChild('setLocationModal', { static: false }) setLocationModal;

  @ViewChild('searchBinModal') searchBinModal: TemplateRef<any>;
  @ViewChild('searchInventoryBalanceSourceModal') searchInventoryBalanceSourceModal: TemplateRef<any>;
  @ViewChild('searchTargetBinModal') searchTargetBinModal: TemplateRef<any>;
  @ViewChild('issueRequestConfirmHistoryModal') issueRequestConfirmHistoryModal: TemplateRef<any>;

  _request: CommonInventoryPlan;

  @Output() itemListChange = new EventEmitter<ReceiptPlan[] | IssuePlan[]>();
  itemList: ReceiptPlan[] | IssuePlan[];

  @Output() itemListSelectionChange = new EventEmitter<ReceiptPlan[] | IssuePlan>();
  // Should be type ReceiptPlan[]|IssuePlan Receipt Plan allows multiple select, Issue Plan only allow single select
  itemListSelection: any;

  //Table Pagination
  tableRowOffset: number;
  tableRowCount = 10;
  tablePage: number;

  bsModalRef: BsModalRef;
  RequestType = RequestTypeEnum;

  @Output() targetTimeChange = new EventEmitter<Date>();
  targetTime: Date;
  minDate: Date = new Date();
  @Output() periodChange = new EventEmitter<string>();
  period = 'time';

  @Output() saveRemarks = new EventEmitter<string>();
  @Output() saveReviewRemarks = new EventEmitter<string>();
  @Output() saveCompleteRemarks = new EventEmitter<string>();

  @Output() remarksChange = new EventEmitter<string>();
  @Output() remarksReviewChange = new EventEmitter<string>();
  @Output() remarksCompleteChange = new EventEmitter<string>();

  selectedIssuePlan: CommonIssuePlan;
  currentLanguage: string;
  inventoryPlan: InventoryPlan;
  ActionType = ActionTypeEnum;
  activeTask: ActiveTaskDTO;

  fromProject: Project;
  toProject: Project;
  constructor(
    private bsModalService: BsModalService,
    private locationsService: LocationsService,
    private areasService: AreasService,
    private modalService: ModalService,
    private approvalService: ApprovalService,
    private approvalHeadersService: ApprovalHeadersService,
    private inventoryBalanceService: InventoryBalancesService,
    private inventoryPlansService: InventoryPlansService,
    private movementsService: MovementsService,
    public generalService: GeneralService,
    private translate: TranslateService,
    private projectsService: ProjectsService
  ) {}

  ngOnInit() {
    this.currentLanguage = this.translate.currentLang;
  }
  getInventoryPlanDetails() {
    if (this._request && this._request.ID) {
      this.inventoryPlan = this._request;
      this.toProject = this.inventoryPlan.Project;
      if (this.inventoryPlan.Source.indexOf('TRANSFER') > -1) {
        const sourceProjectNumber = this.inventoryPlan.Source.split(' ')[1];
        this.projectsService.projectsGetProjectByProjectNumber({ projectNumber: sourceProjectNumber }).subscribe((fromProject) => {
          this.fromProject = fromProject;
        });
      }
    }
  }

  //tagging file
  @ViewChild('importWordPdfModal', { static: true }) importWordPdfModal: TemplateRef<any>;
  FileList: UploadFile[] = [];
  @Input() authHeader: { Authorization };
  currentItemLotId: number;
  API_BASE_PATH: string;
  subscriptionList: Subscription[] = [];
  /**
   * +++++++++++++++++++++++++++++++
   * |     Import word and pdf     |
   * +++++++++++++++++++++++++++++++
   */
  showImportModal(itemLotId) {
    this.currentItemLotId = itemLotId;
    this.inventoryBalanceService
      .inventoryBalancesGetTaggingFiles({
        itemLotId: itemLotId
      })
      .subscribe((p) => {
        const data = p.Data;
        this.FileList = [];
        data.forEach((d) => {
          this.FileList.push({
            name: d.Name,
            size: 10,
            type: '',
            uid: '',
            lastModifiedDate: d.LastModifiedDate,
            status: 'complete',
            response: { itemLotId: itemLotId }
          });
        });
      });
    // tslint:disable-next-line: no-shadowed-variable
    const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xl' };
    this.bsModalRef = this.bsModalService.show(this.importWordPdfModal, config);
  }

  getUploadLink() {
    return this.currentItemLotId ? config.API_BASE_PATH + '/InventoryBalances/ImportItemTaggingFile/' + this.currentItemLotId : null;
  }

  onHandleRemove(file) {
    if (!file || !file.response || !file.response.itemLotId) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    this.modalService.showConfirmModal(ModalStatus.danger, 'DELETE', ['ARE_YOU_SURE_YOU_WANT_TO_DELETE', file.name]).subscribe((event) => {
      if (event == 'confirm') {
        //request api to delete file by itemLotId and file name.
        this.inventoryBalanceService
          .inventoryBalancesDeleteTaggingFiles({
            itemLotId: file.response.itemLotId,
            fileName: file.name
          })
          .subscribe((p) => {
            if (p) {
              const matchKey = file.uid ? 'uid' : 'name';
              const removed = this.FileList.filter((item) => item[matchKey] !== file[matchKey]);
              this.FileList = removed;
            }
          });
      }
    });
  }

  handleDownloadAll(files: UploadFile[]): void {
    let that = this;
    files.forEach((file) => {
      that.onHandleDownloadFile(file);
    });
  }

  onHandleDownloadFile(file) {
    if (!file || !file.name || !file.response || !file.response.itemLotId) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    let fileInfos = file.name.split('.');
    //back API rule: fileName forbid to include '.' when to upload file.
    if (fileInfos.length < 2) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    let fileExtension = fileInfos[fileInfos.length - 1];
    fileExtension = fileExtension.trim().toLowerCase();
    let contentType = '';
    if (fileExtension === 'doc') {
      contentType = 'application/msword';
    } else if (fileExtension === 'docx') {
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (fileExtension === 'pdf') {
      contentType = 'application/pdf';
    } else if (fileExtension === 'jpg' || fileExtension === 'jpeg') {
      contentType = 'image/jpeg';
    } else if (fileExtension === 'png') {
      contentType = 'image/png';
    } else if (fileExtension === 'bmp') {
      contentType = 'image/bmp';
    } else if (fileExtension === 'csv') {
      contentType = 'text/csv';
    } else if (fileExtension === 'xls') {
      contentType = 'application/vnd.ms-excel';
    } else if (fileExtension === 'xlsx') {
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    }
    if (!contentType) {
      this.modalService.showMessageModal(ModalStatus.danger, 'OPERATION_FAIL', 'FAIL');
      return;
    }
    this.inventoryBalanceService.inventoryBalancesDownloadItemTaggingFile({ itemLotId: file.response.itemLotId, fileName: file.name }).subscribe((data) => {
      let uA = window.navigator.userAgent;
      let isIE = /msie\s|trident\/|edge\//i.test(uA) && !!('uniqueID' in document || 'documentMode' in document || 'ActiveXObject' in window || 'MSInputMethodContext' in window);
      const blob = new Blob([data], { type: contentType });
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = file.name;
      link.addEventListener('click', function () {
        URL.revokeObjectURL(downloadURL);
        document.getElementById('download').remove();
      });

      link.click();
    });
  }

  loadItemList(request: InventoryPlan) {
    if (!request) {
      this.itemList = [];
      return;
    }
    this.getInventoryPlanDetails();
    if (this.actionType == RequestTypeEnum.RECEIPT_REQUEST) {
      this.itemList = request.ReceiptPlans;
    } else if (this.actionType == RequestTypeEnum.ISSUE_REQUEST) {
      this.itemList = request.IssuePlans;
      //console.log('Item List: ', this.itemList);

      this.itemList.forEach((issuePlan: CommonIssuePlan) => {
        issuePlan.IssuedQuantityObservable = this.movementsService.movementsGetIssuedQuantity({ issuePlanID: issuePlan.ID });
      });
    }
    this.itemListChange.emit(this.itemList);
  }

  onRowSelect(event) {
    this.itemListSelectionChange.emit(this.itemListSelection);
  }

  onRowUnselect(event) {
    this.itemListSelectionChange.emit(this.itemListSelection);
  }

  /**
   * ++++++++++++++++++++++
   * |       Modals       |
   * ++++++++++++++++++++++
   */

  closeModal() {
    //this.itemListSelection = null;
    if (this.bsModalRef) {
      this.bsModalRef.hide();
    }
  }

  showSetLocationModal(item?) {
    if (item) {
      this.itemListSelection = item;
    }
    let config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
    switch (this.actionType) {
      case RequestTypeEnum.RECEIPT_REQUEST:
        config = { ignoreBackdropClick: true, class: 'modal-xl' };
        this.bsModalRef = this.bsModalService.show(this.searchBinModal, config);
        break;
      case RequestTypeEnum.ISSUE_REQUEST:
        console.log(this._request);
        config = { ignoreBackdropClick: true, class: 'modal-xxl' };
        this.bsModalRef = this.bsModalService.show(this.searchInventoryBalanceSourceModal, config);
        break;
    }

    this.itemListChange.emit(this.itemList);
  }

  showSetTargetBinModal(item?) {
    if (item) {
      this.itemListSelection = item;
    }

    let config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
    config = { ignoreBackdropClick: true, class: 'modal-xxl' };

    if (this._request.TargetLocationID || this._request.TargetAreaID) {
      this.locationsService
        .locationsGetLocationByID({ locationID: this._request.TargetLocationID })
        .pipe(
          concatMap((location) => {
            this._request.TargetLocation = location;
            if (this._request.TargetAreaID) {
              return this.areasService.areasGetAreaByID({ areaID: this._request.TargetAreaID });
            }

            this.bsModalRef = this.bsModalService.show(this.searchTargetBinModal, config);
            return EMPTY;
          })
        )
        .subscribe((area) => {
          if (area) {
            this._request.TargetArea = area;
          }

          this.bsModalRef = this.bsModalService.show(this.searchTargetBinModal, config);
        });
    } else {
      this.bsModalRef = this.bsModalService.show(this.searchTargetBinModal, config);
    }

    this.itemListChange.emit(this.itemList);
  }

  /**
   * Sets the L/A/B of itemListSelection: IssuePlan
   * @param event Inventory Balance emit from Search-Inventory-Balance
   */
  setSourceLocation(event: InventoryBalance) {
    this.itemListSelection['SourceBin'] = event.Bin;
    this.itemListSelection['SourceArea'] = event.Bin.Area;
    this.itemListSelection['SourceLocation'] = event.Bin.Area.Location;
    this.itemListSelection['ItemLot'] = event.ItemLot;
    this.itemListSelection.InventoryBalanceID = event.ID; // change location from this speficied InventoryBalance ID
    this.itemListSelection.ItemLotID = event.ItemLotID;

    console.log('InventoryBalanceID :', this.itemListSelection.InventoryBalanceID);

    this.itemListChange.emit(this.itemList);
  }

  reset(clearRequest: boolean = true) {
    switch (this.actionType) {
      case RequestTypeEnum.RECEIPT_REQUEST:
        this._request = null;
        this.itemList = null;
        this.itemListSelection = []; //multiple selection

        this.itemListChange.emit(this.itemList);
        this.itemListSelectionChange.emit(this.itemListSelection);
        break;
      case RequestTypeEnum.ISSUE_REQUEST:
        if (clearRequest) {
          this._request = null;
        }
        this.itemList = null;
        this.itemListChange.emit(this.itemList);
        break;
    }
  }

  clearIssueRequestSourceInventoryBalance() {
    this.itemList.forEach((item) => {
      if (item['SourceBin']) {
        item['SourceBin'] = null;
      }
      if (item['SourceArea']) {
        item['SourceArea'] = null;
      }
      if (item['SourceLocation']) {
        item['SourceLocation'] = null;
      }
      if (item['InventoryBalanceID']) {
        item['InventoryBalanceID'] = null;
      }
      if (item['ItemLotID']) {
        item['ItemLotID'] = null;
      }
    });
  }

  clearIssueRequestTargetBin() {
    this.itemList.forEach((item) => {
      if (item['TargetBin']) {
        item['TargetBin'] = null;
      }
      if (item['TargetArea']) {
        item['TargetArea'] = null;
      }
      if (item['TargetLocation']) {
        item['TargetLocation'] = null;
      }
    });
  }

  onTargetTimeChange() {
    this.targetTimeChange.emit(this.targetTime);
  }

  onPeriodChange(value) {
    this._request.DatePeriod = value;
    this.periodChange.emit(this._request.DatePeriod);
  }

  onSaveRemarks(request: InventoryPlan) {
    console.log('emitting ' + request.Remarks);
    this.saveRemarks.emit(request.Remarks);
  }

  onSaveReviewRemarks(request: InventoryPlan) {
    this.saveReviewRemarks.emit(request.RemarksReview);
  }

  onSaveCompleteRemarks(request: InventoryPlan) {
    this.saveCompleteRemarks.emit(request.RemarksComplete);
  }

  onRemarksChange(remarks: string) {
    this.remarksChange.emit(remarks);
  }

  onRemarksReviewChange(remarks: string) {
    this.remarksReviewChange.emit(remarks);
  }

  onRemarksCompleteChange(remarks: string) {
    this.remarksCompleteChange.emit(remarks);
  }

  isReceiptRequest(): boolean {
    return this._request.ReceiptPlans && this._request.ReceiptPlans.length > 0;
  }

  isIssueRequest(): boolean {
    return this._request.IssuePlans && this._request.IssuePlans.length > 0;
  }

  showissueRequestConfirmHistoryModal(issuePlan: IssuePlan) {
    this.selectedIssuePlan = issuePlan;

    // tslint:disable-next-line: no-shadowed-variable
    const config: ModalOptions = { ignoreBackdropClick: true, class: 'modal-xxl' };
    this.bsModalRef = this.bsModalService.show(this.issueRequestConfirmHistoryModal, config);
  }

  onRefreshItems() {
    console.log('onRefreshItems');
    if (!this._request) {
      return;
    }
    this.inventoryPlansService
      .inventoryPlansGetInventoryPlanByID({
        inventoryPlanID: this._request.ID,
        views: ['ITEM_LOT', 'ITEM_VARIANT', 'ITEM', 'ITEM_SUB_CATEGORY', 'ITEM_CATEGORY', 'RECEIPT_PLAN', 'ISSUE_PLAN', 'LOCATION', 'AREA', 'BIN', 'KEEPER', 'SITE_ENTRANCE', 'TEAM']
      })
      .subscribe((request) => {
        console.log('refreshed request: ', request);

        if (this.isReceiptRequest()) {
          this._request.ReceiptPlans = request.ReceiptPlans;
        } else if (this.isIssueRequest()) {
          this._request.IssuePlans = request.IssuePlans;

          this._request.IssuePlans.forEach((currentIssuePlan) => {
            const targetedIssuePlan = request.IssuePlans.find((n) => n.ID === currentIssuePlan.ID);
            currentIssuePlan.QuantityDelivered = targetedIssuePlan.QuantityDelivered;
            console.log('qty delivered: ', currentIssuePlan.QuantityDelivered);
          });

          this.loadItemList(this._request);
        }
      });
  }

  checkExistReceivedCanceledItems() {
    if (!this.isReceiptRequest()) {
      return false;
    }
    const existReceivedItems = this.itemList.some((s) => s.ReceiptPlanStatus == 'RECEIVED' || s.ReceiptPlanStatus == 'CANCELLED');
    return existReceivedItems;
  }

  licensePlateNumbersToArray(): string[] {
    return this._request?.LicensePlateNumber?.split(',') ?? [];
  }

  getActionTypeString(): string {
    switch (this.actionType) {
      case ActionTypeEnum.RECEIPT_REQUEST:
        return 'Delivery from Supplier Request';
      case ActionTypeEnum.ISSUE_REQUEST:
        return 'Issue for Construction Request';
      case ActionTypeEnum.TRANSFER:
        return 'Project Transfer';
      case ActionTypeEnum.CHANGE_LOCATION:
        return 'Change Location';
      default:
        return '';
    }
  }

  ngOnDestroy() {}
}
