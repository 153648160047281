import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './app.routes';
import { config } from 'projects/AppConfig';

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: config.enableRouteTracking, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
