<p-tabView *ngIf="currentProject">
    <p-tabPanel
        styleClass=""
        header="{{ 'WRITE_OFF' | translate }}"
        *ngIf="currentProject.ApprovalWriteOff === ToggleValue.Y"
    >
        <approval-route
            #writeOffRoute
            [project]="currentProject"
            [approvalType]="ApprovalType.WRITE_OFF"
            [showAdd]="true"
        >
        </approval-route>
    </p-tabPanel>
    <p-tabPanel
        header="{{ 'STOCK_CHANGE_LOCATION' | translate }}"
        *ngIf="currentProject.ApprovalLocation === ToggleValue.Y"
    >
        <approval-route
            #changeLocationRoute
            [project]="currentProject"
            [approvalType]="ApprovalType.CHANGE_LOCATION"
            [showAdd]="true"
        >
        </approval-route>
    </p-tabPanel>
    <p-tabPanel
        header="{{ 'PROJECT_TRANSFER' | translate }}"
        *ngIf="currentProject.ApprovalProject === ToggleValue.Y"
    >
        <approval-route
            #transferRoute
            [project]="currentProject"
            [approvalType]="ApprovalType.TRANSFER"
            [showAdd]="true"
        >
        </approval-route>
    </p-tabPanel>
    <p-tabPanel
        header="{{ 'ISSUE_FOR_CONSTRUCTION_REQUEST' | translate }}"
        *ngIf="currentProject.ApprovalIssue === ToggleValue.Y"
    >
        <approval-route
            #issueRequestRoute
            [project]="currentProject"
            [approvalType]="ApprovalType.ISSUE_REQUEST"
            [showAdd]="true"
        >
        </approval-route>
    </p-tabPanel>
    <p-tabPanel
        header="{{ 'DELIVERY_FROM_SUPPLIER_REQUEST' | translate }}"
        *ngIf="currentProject.ApprovalAcceptRequest === ToggleValue.Y"
    >
        <approval-route
            #receiptRequestRoute
            [project]="currentProject"
            [approvalType]="ApprovalType.RECEIPT_REQUEST"
            [showAdd]="true"
        >
        </approval-route>
    </p-tabPanel>
</p-tabView>
