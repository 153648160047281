import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'select-date',
    templateUrl: './select-date.component.html',
    styleUrls: ['./select-date.component.scss']
})
export class SelectDateComponent {
  @Input() minDate;
  @Input() maxDate;
  @Input() required = false;
  @Input() selectedDate: Date | Date[];
  @Output() selectedDateChange = new EventEmitter<Date | Date[]>();
  @Input() dateRange = false;
  bsConfig = { showWeekNumbers: false, dateInputFormat: 'YYYY-MM-DD', adaptivePosition: true};
}
