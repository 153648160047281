/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ADUser } from '../model/aDUser';
import { Keeper } from '../model/keeper';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { KeeperServiceInterface, IkeeperCreateKeeperParams,IkeeperGetKeeperParams, }                            from './keeper.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class KeeperService implements KeeperServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public keeperCreateKeeper({ deviceId, message, ignoreLoadingBar, formData, model } : IkeeperCreateKeeperParams, observe?: 'body', reportProgress?: boolean): Observable<Keeper>;
    public keeperCreateKeeper({ deviceId, message, ignoreLoadingBar, formData, model } : IkeeperCreateKeeperParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Keeper>>;
    public keeperCreateKeeper({ deviceId, message, ignoreLoadingBar, formData, model } : IkeeperCreateKeeperParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Keeper>>;
    @datastore
    public keeperCreateKeeper({ deviceId, message, ignoreLoadingBar, formData, model } : IkeeperCreateKeeperParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling keeperCreateKeeper.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Keeper>(`${this.basePath}/Keeper/CreateKeeper`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param EmployeeID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public keeperGetKeeper({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IkeeperGetKeeperParams, observe?: 'body', reportProgress?: boolean): Observable<Keeper>;
    public keeperGetKeeper({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IkeeperGetKeeperParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Keeper>>;
    public keeperGetKeeper({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IkeeperGetKeeperParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Keeper>>;
    @datastore
    public keeperGetKeeper({ deviceId, message, ignoreLoadingBar, formData, EmployeeID } : IkeeperGetKeeperParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (EmployeeID === null || EmployeeID === undefined) {
            throw new Error('Required parameter EmployeeID was null or undefined when calling keeperGetKeeper.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Keeper>(`${this.basePath}/Keeper/${encodeURIComponent(String(EmployeeID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
