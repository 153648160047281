import { ADUser, Location, Area, InventoryPlan, Project, SupplierInformation, ApprovalHeader } from '@gammon/inventory-api';
import { Observable } from 'rxjs';

export interface CommonInventoryPlan extends InventoryPlan {
  ProjectObservable: Observable<Project>;
  TidObservable: Observable<string>;
  SupplierObservable: Observable<SupplierInformation>;
  RequestorObservable: Observable<ADUser>;
  TargetLocationObservable: Observable<Location>;
  TargetAreaObservable: Observable<Area>;
  TargetLocation: Location;
  TargetArea: Area;
  ApprovalHeader?: ApprovalHeader;
  Selected?: boolean;
}

export enum InventoryPlanStatus {
  ACCEPTED = 'ACCEPTED',
  WAITING = 'WAITING',
  WAITING_FOR_APPROVAL = 'WAITING_FOR_APPROVAL',
  REJECTED = 'REJECTED',
  RECEIVED = 'RECEIVED',
  ISSUED = 'ISSUED',
  PARTIALISSUED = 'PARTIALISSUED',
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT'
}
