import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { MessageService } from '../../@pages/components/message/message.service';
import { ModalTemplateComponent } from '../components/modal-template/modal-template.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService implements OnInit {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService, private toastr: ToastrService, private translate: TranslateService, private notificationService: MessageService) {}

  ngOnInit() {}

  /**
   * Shows a simple message modal with a close button
   * @param modalStatus - Can either be [primary, success, danger, warning, complete, info] that sets the modal header color
   * @param title - Modal title
   * @param messages - Modal body message
   * @param closeBtnName - (optional) Custom Close button string
   * @param customClass - (optional) Custom class
   * @param customClass - (optional) Custom class for the modal
   */
  showMessageModal(modalStatus: ModalStatus, title: string, messages: string[] | string, closeBtnName?: string, customClass?: ModalSize | string, messageParam?: any): Observable<any> {
    console.log('showMessageModal: ', messages);
    const initialState: ModalData = {
      modalType: ModalType.default,
      modalStatus,
      title,
      messages,
      closeBtnName: closeBtnName ?? 'CLOSE',
      class: customClass ? customClass : ModalSize.default,
      messageParam
    };
    this.bsModalRef?.hide();
    this.bsModalRef = this.modalService.show(ModalTemplateComponent, { initialState, ignoreBackdropClick: true, class: initialState.class });

    return this.modalService.onHidden.pipe(take(1));
  }

  /**
   * Shows a simple confirm modal with confirm and close button
   * @param modalStatus - Can either be [primary, success, danger, warning, complete, info] that sets the modal header color
   * @param title - Modal title
   * @param messages - Modal body message
   * @param confirmBtnName - (optional) Custom Confirm button string
   * @param cancelBtnName - (optional) Custom Cancel button string
   * @param customClass - (optional) Custom class for the modal
   */
  showConfirmModal(
    modalStatus: ModalStatus,
    title: string,
    messages: string[] | string,
    confirmBtnName?: string,
    cancelBtnName?: string,
    customClass?: ModalSize | string,
    messageParam?: any
  ): Observable<any> {
    const initialState: ModalData = {
      modalType: ModalType.yesNo,
      modalStatus,
      title,
      messages,
      confirmBtnName: confirmBtnName ?? 'CONFIRM',
      cancelBtnName: cancelBtnName ?? 'CANCEL',
      class: customClass ? customClass : ModalSize.default,
      messageParam
    };
    this.bsModalRef = this.modalService.show(ModalTemplateComponent, { initialState, ignoreBackdropClick: true, class: initialState.class });

    return this.modalService.onHidden.pipe(take(1));
  }

  /**
   * Shows a custom template modal with no footer button
   * @param modalStatus - Can either be [primary, success, danger, warning, complete, info] that sets the modal header color
   * @param template - Modal template component
   * @param templateData - (optional) Modal template component's data (to be input to the template)
   * @param title - (optional) Modal title
   * @param customClass - (optional) Custom class for the modal
   */
  showTemplateEmptyModal(modalStatus: ModalStatus, template: any, templateData?: any, title?: string, customClass?: ModalSize | string): Observable<any> {
    const initialState: ModalData = {
      modalType: ModalType.empty,
      modalStatus,
      title,
      template,
      templateData,
      class: customClass ? customClass : ModalSize.default
    };
    this.bsModalRef = this.modalService.show(ModalTemplateComponent, { initialState, ignoreBackdropClick: true, class: initialState.class });

    return this.modalService.onHidden.pipe(take(1));
  }

  /**
   * Shows a custom template modal with a close button
   * @param modalStatus - Can either be [primary, success, danger, warning, complete, info] that sets the modal header color
   * @param template - Modal template component
   * @param templateData - (optional) Modal template component's data (to be input to the template)
   * @param title - (optional) Modal title
   * @param closeBtnName - (optional) Custom Close button string
   * @param customClass - (optional) Custom class for the modal
   */
  showTemplateMessageModal(modalStatus: ModalStatus, template: any, templateData?: any, title?: string, closeBtnName?: string, customClass?: ModalSize | string, messageParam?: any): Observable<any> {
    const initialState: ModalData = {
      modalType: ModalType.default,
      modalStatus,
      title,
      template,
      templateData,
      closeBtnName: closeBtnName ?? 'CLOSE',
      class: customClass ? customClass : ModalSize.default,
      messageParam
    };
    this.bsModalRef = this.modalService.show(ModalTemplateComponent, { initialState, ignoreBackdropClick: true, class: initialState.class });

    return this.modalService.onHidden.pipe(take(1));
  }

  /**
   * Shows a custom template modal with confirm and close button
   * @param modalStatus - Can either be [primary, success, danger, warning, complete, info] that sets the modal header color
   * @param template - Modal template component
   * @param templateData - (optional) Modal template component's data (to be input to the template)
   * @param title - (optional) Modal title
   * @param confirmBtnName - (optional) Custom Confirm button string
   * @param cancelBtnName - (optional) Custom Cancel button string
   * @param customClass - (optional) Custom class for the modal
   */
  showTemplateConfirmModal(
    modalStatus: ModalStatus,
    template: any,
    templateData?: any,
    title?: string,
    confirmBtnName?: string,
    cancelBtnName?: string,
    customClass?: ModalSize | string,
    messageParam?: any
  ): Observable<any> {
    const initialState: ModalData = {
      modalType: ModalType.yesNo,
      modalStatus,
      title,
      template,
      templateData,
      confirmBtnName: confirmBtnName ?? 'CONFIRM',
      cancelBtnName: cancelBtnName ?? 'CANCEL',
      class: customClass ? customClass : ModalSize.default,
      messageParam
    };
    this.bsModalRef = this.modalService.show(ModalTemplateComponent, { initialState, ignoreBackdropClick: true, class: initialState.class });

    return this.modalService.onHidden.pipe(take(1));
  }

  /**
   * Shows a simple toastr
   * @param status - True for success, false for danger
   * @param message - Message to be displayed
   */
  showSimpleToastr(status: boolean, message: string) {
    if (status) {
      this.toastr.success(this.translate.instant(message), null, {
        positionClass: 'toast-top-full-width'
      });
    } else {
      this.toastr.error(this.translate.instant(message), null, {
        positionClass: 'toast-top-full-width'
      });
    }
  }

  /**
   * Shows a simple notification
   * @param modalStatus
   * @param message
   * @param duration
   * @param position
   * @param type
   */
  showNotification(modalStatus: ModalStatus, message: string | string[], duration?: number, position?: NotificationPosition, type?: NotificationType) {
    this.notificationService.create(modalStatus, message, {
      Position: position ? position : 'top',
      Style: type ? type : 'bar',
      Duration: duration ? duration : 0
    });
  }
}

/**
 * =======================
 *    Type Definitions
 * =======================
 */
export enum ModalStatus {
  success = 'success',
  danger = 'danger',
  primary = 'primary',
  complete = 'complete',
  info = 'info',
  warning = 'warning',
  empty = ''
}

export enum ModalType {
  default = 'close', // just a close button
  yesNo = 'yesNo',
  empty = ''
}

export interface ModalData {
  modalStatus?: ModalStatus;
  modalType?: ModalType;
  title: string;
  messages?: string[] | string;
  template?: any;
  templateData?: any;
  confirmBtnName?: string;
  cancelBtnName?: string;
  closeBtnName?: string;
  class?: ModalSize | string;
  messageParam?: any;
}

export enum ModalSize {
  default = '',
  large = 'modal-lg',
  xLarge = 'modal-xl',
  xxLarge = 'modal-xxl'
}

export enum NotificationPosition {
  top = 'top',
  topRight = 'top-right',
  topLeft = 'top-left',
  bottom = 'bottom',
  bottomRight = 'bottom-right',
  bottomLeft = 'bottom-left'
}

export enum NotificationType {
  bar = 'bar',
  flip = 'flip',
  circle = 'circle',
  simple = 'simple'
}
