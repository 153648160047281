/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LevelDecision } from './levelDecision';


export interface ReturnDecisionMapDTO { 
    refNo?: number;
    decision?: ReturnDecisionMapDTO.DecisionEnum;
    processName?: string;
    childDecision?: Array<LevelDecision>;
}
export namespace ReturnDecisionMapDTO {
    export type DecisionEnum = 'APPROVED' | 'PENDING' | 'REJECTED' | 'BYPASSED' | 'CANCELLED';
    export const DecisionEnum = {
        APPROVED: 'APPROVED' as DecisionEnum,
        PENDING: 'PENDING' as DecisionEnum,
        REJECTED: 'REJECTED' as DecisionEnum,
        BYPASSED: 'BYPASSED' as DecisionEnum,
        CANCELLED: 'CANCELLED' as DecisionEnum
    };
}
