/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PageStockLevel } from '../model/pageStockLevel';
import { StockLevel } from '../model/stockLevel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { StockLevelsServiceInterface, IstockLevelsCreateParams,IstockLevelsCreateByListParams,IstockLevelsDeleteParams,IstockLevelsDeleteByListParams,IstockLevelsGetListParams,IstockLevelsGetListByProjectIDParams,IstockLevelsGetLowLevelCountByProjectIDParams,IstockLevelsGetSearchListParams,IstockLevelsUpdateParams,IstockLevelsUpdateByListParams, }                            from './stockLevels.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class StockLevelsService implements StockLevelsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IstockLevelsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<StockLevel>;
    public stockLevelsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IstockLevelsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<StockLevel>>;
    public stockLevelsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IstockLevelsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<StockLevel>>;
    @datastore
    public stockLevelsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IstockLevelsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling stockLevelsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<StockLevel>(`${this.basePath}/StockLevels`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsCreateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsCreateByListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<StockLevel>>;
    public stockLevelsCreateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsCreateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StockLevel>>>;
    public stockLevelsCreateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsCreateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StockLevel>>>;
    @datastore
    public stockLevelsCreateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsCreateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling stockLevelsCreateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Array<StockLevel>>(`${this.basePath}/StockLevels/CreateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stockLevelID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsDelete({ deviceId, message, ignoreLoadingBar, formData, stockLevelID } : IstockLevelsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public stockLevelsDelete({ deviceId, message, ignoreLoadingBar, formData, stockLevelID } : IstockLevelsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public stockLevelsDelete({ deviceId, message, ignoreLoadingBar, formData, stockLevelID } : IstockLevelsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public stockLevelsDelete({ deviceId, message, ignoreLoadingBar, formData, stockLevelID } : IstockLevelsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stockLevelID === null || stockLevelID === undefined) {
            throw new Error('Required parameter stockLevelID was null or undefined when calling stockLevelsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/StockLevels/${encodeURIComponent(String(stockLevelID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stockLevelIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, stockLevelIDs } : IstockLevelsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public stockLevelsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, stockLevelIDs } : IstockLevelsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public stockLevelsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, stockLevelIDs } : IstockLevelsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public stockLevelsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, stockLevelIDs } : IstockLevelsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stockLevelIDs === null || stockLevelIDs === undefined) {
            throw new Error('Required parameter stockLevelIDs was null or undefined when calling stockLevelsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stockLevelIDs) {
            stockLevelIDs.forEach((element) => {
                queryParameters = queryParameters.append('stockLevelIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/StockLevels/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stockLevelID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsGetList({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, views } : IstockLevelsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<StockLevel>>;
    public stockLevelsGetList({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, views } : IstockLevelsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<StockLevel>>>;
    public stockLevelsGetList({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, views } : IstockLevelsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<StockLevel>>>;
    @datastore
    public stockLevelsGetList({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, views } : IstockLevelsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (stockLevelID !== undefined && stockLevelID !== null) {
            queryParameters = queryParameters.set('stockLevelID', <any>stockLevelID);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<StockLevel>>(`${this.basePath}/StockLevels`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param showLowLevel 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, showLowLevel, page, size, views } : IstockLevelsGetListByProjectIDParams, observe?: 'body', reportProgress?: boolean): Observable<PageStockLevel>;
    public stockLevelsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, showLowLevel, page, size, views } : IstockLevelsGetListByProjectIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStockLevel>>;
    public stockLevelsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, showLowLevel, page, size, views } : IstockLevelsGetListByProjectIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStockLevel>>;
    @datastore
    public stockLevelsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, showLowLevel, page, size, views } : IstockLevelsGetListByProjectIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling stockLevelsGetListByProjectID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showLowLevel !== undefined && showLowLevel !== null) {
            queryParameters = queryParameters.set('showLowLevel', <any>showLowLevel);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageStockLevel>(`${this.basePath}/StockLevels/Project/${encodeURIComponent(String(projectID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsGetLowLevelCountByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID } : IstockLevelsGetLowLevelCountByProjectIDParams, observe?: 'body', reportProgress?: boolean): Observable<number>;
    public stockLevelsGetLowLevelCountByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID } : IstockLevelsGetLowLevelCountByProjectIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public stockLevelsGetLowLevelCountByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID } : IstockLevelsGetLowLevelCountByProjectIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    @datastore
    public stockLevelsGetLowLevelCountByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID } : IstockLevelsGetLowLevelCountByProjectIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling stockLevelsGetLowLevelCountByProjectID.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<number>(`${this.basePath}/StockLevels/Project/${encodeURIComponent(String(projectID))}/LowLevelCount`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param categoryID 
     * @param subCategoryID 
     * @param itemID 
     * @param itemVariantID 
     * @param locationID 
     * @param showOnlyLowLevel 
     * @param stockLevelType 
     * @param alertType 
     * @param page 
     * @param size 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, locationID, showOnlyLowLevel, stockLevelType, alertType, page, size, views } : IstockLevelsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageStockLevel>;
    public stockLevelsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, locationID, showOnlyLowLevel, stockLevelType, alertType, page, size, views } : IstockLevelsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageStockLevel>>;
    public stockLevelsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, locationID, showOnlyLowLevel, stockLevelType, alertType, page, size, views } : IstockLevelsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageStockLevel>>;
    @datastore
    public stockLevelsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, projectID, categoryID, subCategoryID, itemID, itemVariantID, locationID, showOnlyLowLevel, stockLevelType, alertType, page, size, views } : IstockLevelsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling stockLevelsGetSearchList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (projectID !== undefined && projectID !== null) {
            queryParameters = queryParameters.set('projectID', <any>projectID);
        }
        if (categoryID !== undefined && categoryID !== null) {
            queryParameters = queryParameters.set('categoryID', <any>categoryID);
        }
        if (subCategoryID !== undefined && subCategoryID !== null) {
            queryParameters = queryParameters.set('subCategoryID', <any>subCategoryID);
        }
        if (itemID !== undefined && itemID !== null) {
            queryParameters = queryParameters.set('itemID', <any>itemID);
        }
        if (itemVariantID !== undefined && itemVariantID !== null) {
            queryParameters = queryParameters.set('itemVariantID', <any>itemVariantID);
        }
        if (locationID !== undefined && locationID !== null) {
            queryParameters = queryParameters.set('locationID', <any>locationID);
        }
        if (showOnlyLowLevel !== undefined && showOnlyLowLevel !== null) {
            queryParameters = queryParameters.set('showOnlyLowLevel', <any>showOnlyLowLevel);
        }
        if (stockLevelType !== undefined && stockLevelType !== null) {
            queryParameters = queryParameters.set('stockLevelType', <any>stockLevelType);
        }
        if (alertType !== undefined && alertType !== null) {
            queryParameters = queryParameters.set('alertType', <any>alertType);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageStockLevel>(`${this.basePath}/StockLevels/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param stockLevelID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsUpdate({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, model } : IstockLevelsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public stockLevelsUpdate({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, model } : IstockLevelsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public stockLevelsUpdate({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, model } : IstockLevelsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public stockLevelsUpdate({ deviceId, message, ignoreLoadingBar, formData, stockLevelID, model } : IstockLevelsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (stockLevelID === null || stockLevelID === undefined) {
            throw new Error('Required parameter stockLevelID was null or undefined when calling stockLevelsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling stockLevelsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/StockLevels/${encodeURIComponent(String(stockLevelID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public stockLevelsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public stockLevelsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public stockLevelsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public stockLevelsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IstockLevelsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling stockLevelsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/StockLevels/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
