import { DataStoreInjector } from './data-store.service';

// import { appInjector } from "~/app/app-injector";

export function datastore(
  target: object,
  propertyName: string,
  propertyDescriptor: PropertyDescriptor): PropertyDescriptor {
  const method = propertyDescriptor.value;

  propertyDescriptor.value = function(...args: any[]) {
    // const injector = appInjector();
    const ds = DataStoreInjector.getService('DataStoreService');
    if (ds.isEnable) {
      const requestType = ds.dsRequestType(propertyName);
      const key = ds.dsSubjectName(target, propertyName, ...args);
      const model = (args[0] || {})['model'];

      ds.dsOfflineResponse(requestType, key, model)
      .then(() => {
        ds.isOnline().then((online) => {
          if (online) {
            const result = method.apply(this, args);
            result.subscribe((data) => {
              // if (data) {
                ds.dsOnlineResponse(requestType, key, data);
              // }
            }, (error) => {
              console.log(error);
              if (error.statusText != 'Unknown Error') { ds.dsOnlineResponse(requestType, key, model, false); }
            });
          } else {
            console.log(`cannot connect ${ds.onlineCheckUrl} for API online checking`);
          }
        });
      });
      return ds.getV(key).asObservable();
    } else {
      const result = method.apply(this, args);
      return result;
    }
  };
  return propertyDescriptor;
}
