import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { DataStoreInjector } from '@gammon/data-store';
import { currentEnv } from 'projects/AppConfig';
import { filter } from 'rxjs/operators';
import { GeneralService } from './shared/services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  isAuthenciated = false;
  currentEnv: string = currentEnv();
  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private generalService: GeneralService,
    private router: Router,
    private datastoreInjector: DataStoreInjector
  ) {
    this.generalService.commonSecurityService.SignOut = async () => this.authService.logoutRedirect();

    const accountInfos: AccountInfo[] = this.authService.instance.getAllAccounts() || [];
    this.isAuthenciated = accountInfos.length > 0;
    if (this.isAuthenciated) {
      const account = accountInfos[0];
      this.checkAllowedIssuer(account);
    }
    this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)).subscribe((result: EventMessage) => {
      this.isAuthenciated = true;
      this.checkAllowedIssuer(result.payload as AccountInfo);
    });
  }

  checkAllowedIssuer(account: AccountInfo) {
    const iss = account.idTokenClaims['iss'];
    const isAllowedIssuer = this.generalService.commonSecurityService.isAllowedIssuer(iss);

    if (isAllowedIssuer) {
      this.generalService.getUserSecurity().subscribe(() => {
        this.generalService.setupMenu();
      });
    } else {
      this.router.navigate(['403']);
    }
  }
}
