import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CommonSecurityService } from '@gammon/common';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { GeneralService } from '../services';

@Injectable({
    providedIn: 'root'
})
export class RedirectGuard implements CanActivate {

    constructor(
        private generalService: GeneralService,
        private commonSecurityService: CommonSecurityService,
        private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (
          !this.generalService.ShowWhen(['PROJECT_SELECTED']) &&
          ![
            'approval/summary/'
          ].some(pattern => state.url.includes(pattern))
        ) {
            this.generalService.OnProjectChanged.pipe(take(1)).subscribe((project) => {
                this.router.navigate(['']);
            });
            if (this.commonSecurityService.Roles(['PROJECT_UPDATE'])) {
                if (state.url != '/inventory/main/projects' ) {
                    this.router.navigate(['/inventory/main/projects']);
                }
            } else {
              if (state.url != '/inventory/main/request' ) {
                this.router.navigate(['/inventory/main/request']);
              }
            }
            return true;
        } else {
            return true;
        }
    }

}
