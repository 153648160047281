import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faHardHat } from '@fortawesome/free-solid-svg-icons';
import { Project, ProjectsService } from '@gammon/inventory-api';
import { Observable, Subscription, EMPTY } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { ModalService, ModalStatus } from '../../services/modal.service';

@Component({
  selector: 'search-job',
  templateUrl: './search-job.component.html',
  styleUrls: ['./search-job.component.scss']
})
export class SearchJobComponent implements OnInit, OnDestroy {
  @Input() project: Project;
  @Input() exclude?: Project;
  @Input() isDisable;
  @Output() projectChange = new EventEmitter<Project>();
  jobList: Project[] = [];
  jobTypeahead = new EventEmitter<any>();

  subscriptionList: Subscription[] = [];

  faHardHat = faHardHat;

  constructor(private projectsService: ProjectsService, private modalService: ModalService) {}

  ngOnInit() {
    this.subscriptionList.push(
      this.jobTypeahead
        .pipe(
          debounceTime(300),
          switchMap((term) => this.searchJob(term))
        )
        .subscribe((projects) => {
          if (projects.Items) {
            this.jobList = projects.Items;

            //auto select
            if (projects.Items.length == 1) {
              this.project = projects.Items[0];
            }
            if (this.project && this.exclude && this.project.ID == this.exclude.ID) {
              this.project = null;
              this.modalService.showMessageModal(ModalStatus.warning, 'WARNING', 'TARGET_PROJECT_CANNOT_BE_THE_SAME_AS_CURRENT_PROJECT');
            }
          } else {
            this.project = null;
          }

          this.projectChange.emit(this.project);
        })
    );
  }

  ngOnDestroy() {
    this.subscriptionList.forEach((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  searchJob(input?: string): Observable<any> {
    if (input && input.length >= 5) {
      return this.projectsService.projectsGetSearchList({ projectNumber: input });
    } else {
      return EMPTY;
    }
  }
}
