<p-table 
    [value]="projectList" 
    selectionMode="single"
    [paginator]="true" 
    [rows]="tableRowCount"
    [(selection)]="projectSelection"
    (onRowSelect)="projectChange.emit(projectSelection)"
    (onRowUnselect)="projectChange.emit(null)"
    [scrollable]="true"
    scrollHeight="44vh"
    [totalRecords]="totalRecords">
    <ng-template pTemplate="header">
        <tr>
            <th>{{'PROJECT_NUMBER'|translate}}</th>
            <th>{{'DESCRIPTION'|translate}}</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item let-ri="rowIndex">
        <tr [pSelectableRow]="item">
            <td>{{item.ProjectNumber}}</td>
            <td>{{item.ProjectDescription}}</td>
        </tr>   
    </ng-template>
</p-table>