import { Component, Input, OnInit } from '@angular/core';
import { CommonDecision, ReturnDecisionMapDTO } from '@gammon/inventory-api';
import { GeneralService } from '../../services/general.service';

@Component({
  selector: 'decision-map-table',
  templateUrl: './decision-map-table.component.html',
  styleUrls: ['./decision-map-table.component.scss']
})
export class DecisionMapTableComponent implements OnInit {

    commonDecisionList: CommonDecision[] = [];

    private _decisionMap: ReturnDecisionMapDTO;
    @Input() set decisionMap(value: ReturnDecisionMapDTO) {
        this._decisionMap = value;
        this.commonDecisionList = [];
        this.getDecisionList();
    }
    get decisionMap(): ReturnDecisionMapDTO {
      return this._decisionMap;
    }

    constructor(
      public generalService: GeneralService
    ) { }

    ngOnInit() {
    }

    getDecisionList() {
      if (this.decisionMap) {
        this.decisionMap.childDecision.forEach(level => {
          level.childDecision.forEach(node => {
            node.childDecision.forEach(commonDecision => {
              commonDecision.forEach(projectApproval=>{
                let decisionE = projectApproval.decision;
                if ([
                  ReturnDecisionMapDTO.DecisionEnum.APPROVED.toString(),
                  ReturnDecisionMapDTO.DecisionEnum.REJECTED.toString()
                ].indexOf(decisionE) > -1) {
                  this.commonDecisionList.push(projectApproval);
                }
              });
            });
          });
        });
      }
    }
}
