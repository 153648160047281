/**
 * inventory.api
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { Bin } from '../model/bin';
import { PageBin } from '../model/pageBin';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { BinsServiceInterface, IbinsCreateParams,IbinsDeleteParams,IbinsDeleteByListParams,IbinsGetBinByIDParams,IbinsGetListParams,IbinsGetListByProjectIDParams,IbinsGetListByProjectNoParams,IbinsGetListWithInventoryBalanceParams,IbinsGetSearchListParams,IbinsUpdateParams,IbinsUpdateByListParams,IbinsUploadLocationsParams, }                            from './bins.serviceInterface';

import { datastore } from '@gammon/data-store';



@Injectable({
  providedIn: 'root'
})
export class BinsService implements BinsServiceInterface {

    protected basePath = null;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }



    /**
     * 
     * 
     * @param model 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IbinsCreateParams, observe?: 'body', reportProgress?: boolean): Observable<Bin>;
    public binsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IbinsCreateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Bin>>;
    public binsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IbinsCreateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Bin>>;
    @datastore
    public binsCreate({ deviceId, message, ignoreLoadingBar, formData, model, views } : IbinsCreateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling binsCreate.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<Bin>(`${this.basePath}/Bins`,
            model,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param binID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsDelete({ deviceId, message, ignoreLoadingBar, formData, binID } : IbinsDeleteParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public binsDelete({ deviceId, message, ignoreLoadingBar, formData, binID } : IbinsDeleteParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public binsDelete({ deviceId, message, ignoreLoadingBar, formData, binID } : IbinsDeleteParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public binsDelete({ deviceId, message, ignoreLoadingBar, formData, binID } : IbinsDeleteParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (binID === null || binID === undefined) {
            throw new Error('Required parameter binID was null or undefined when calling binsDelete.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Bins/${encodeURIComponent(String(binID))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param binIDs 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, binIDs } : IbinsDeleteByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public binsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, binIDs } : IbinsDeleteByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public binsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, binIDs } : IbinsDeleteByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public binsDeleteByList({ deviceId, message, ignoreLoadingBar, formData, binIDs } : IbinsDeleteByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (binIDs === null || binIDs === undefined) {
            throw new Error('Required parameter binIDs was null or undefined when calling binsDeleteByList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (binIDs) {
            binIDs.forEach((element) => {
                queryParameters = queryParameters.append('binIDs', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.delete<string>(`${this.basePath}/Bins/DeleteByList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param binID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsGetBinByID({ deviceId, message, ignoreLoadingBar, formData, binID, views } : IbinsGetBinByIDParams, observe?: 'body', reportProgress?: boolean): Observable<Bin>;
    public binsGetBinByID({ deviceId, message, ignoreLoadingBar, formData, binID, views } : IbinsGetBinByIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Bin>>;
    public binsGetBinByID({ deviceId, message, ignoreLoadingBar, formData, binID, views } : IbinsGetBinByIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Bin>>;
    @datastore
    public binsGetBinByID({ deviceId, message, ignoreLoadingBar, formData, binID, views } : IbinsGetBinByIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (binID === null || binID === undefined) {
            throw new Error('Required parameter binID was null or undefined when calling binsGetBinByID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Bin>(`${this.basePath}/Bins/${encodeURIComponent(String(binID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param binID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsGetList({ deviceId, message, ignoreLoadingBar, formData, binID, page, views } : IbinsGetListParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Bin>>;
    public binsGetList({ deviceId, message, ignoreLoadingBar, formData, binID, page, views } : IbinsGetListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Bin>>>;
    public binsGetList({ deviceId, message, ignoreLoadingBar, formData, binID, page, views } : IbinsGetListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Bin>>>;
    @datastore
    public binsGetList({ deviceId, message, ignoreLoadingBar, formData, binID, page, views } : IbinsGetListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (binID !== undefined && binID !== null) {
            queryParameters = queryParameters.set('binID', <any>binID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Bin>>(`${this.basePath}/Bins`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectID 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IbinsGetListByProjectIDParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Bin>>;
    public binsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IbinsGetListByProjectIDParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Bin>>>;
    public binsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IbinsGetListByProjectIDParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Bin>>>;
    @datastore
    public binsGetListByProjectID({ deviceId, message, ignoreLoadingBar, formData, projectID, views } : IbinsGetListByProjectIDParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectID === null || projectID === undefined) {
            throw new Error('Required parameter projectID was null or undefined when calling binsGetListByProjectID.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Bin>>(`${this.basePath}/Bins/Project/${encodeURIComponent(String(projectID))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param projectNo 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsGetListByProjectNo({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IbinsGetListByProjectNoParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Bin>>;
    public binsGetListByProjectNo({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IbinsGetListByProjectNoParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Bin>>>;
    public binsGetListByProjectNo({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IbinsGetListByProjectNoParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Bin>>>;
    @datastore
    public binsGetListByProjectNo({ deviceId, message, ignoreLoadingBar, formData, projectNo, views } : IbinsGetListByProjectNoParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectNo === null || projectNo === undefined) {
            throw new Error('Required parameter projectNo was null or undefined when calling binsGetListByProjectNo.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Bin>>(`${this.basePath}/Bins/ProjectNo/${encodeURIComponent(String(projectNo))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param binID 
     * @param InventoryBalanceID 
     * @param page 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsGetListWithInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, binID, InventoryBalanceID, page, views } : IbinsGetListWithInventoryBalanceParams, observe?: 'body', reportProgress?: boolean): Observable<Array<Bin>>;
    public binsGetListWithInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, binID, InventoryBalanceID, page, views } : IbinsGetListWithInventoryBalanceParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Bin>>>;
    public binsGetListWithInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, binID, InventoryBalanceID, page, views } : IbinsGetListWithInventoryBalanceParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Bin>>>;
    @datastore
    public binsGetListWithInventoryBalance({ deviceId, message, ignoreLoadingBar, formData, binID, InventoryBalanceID, page, views } : IbinsGetListWithInventoryBalanceParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (binID === null || binID === undefined) {
            throw new Error('Required parameter binID was null or undefined when calling binsGetListWithInventoryBalance.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (InventoryBalanceID !== undefined && InventoryBalanceID !== null) {
            queryParameters = queryParameters.set('InventoryBalanceID', <any>InventoryBalanceID);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<Array<Bin>>(`${this.basePath}/Bins/${encodeURIComponent(String(binID))}/InventoryBalances`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param binDescription 
     * @param binChineseDescription 
     * @param page 
     * @param take 
     * @param views 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, areaID, binDescription, binChineseDescription, page, take, views } : IbinsGetSearchListParams, observe?: 'body', reportProgress?: boolean): Observable<PageBin>;
    public binsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, areaID, binDescription, binChineseDescription, page, take, views } : IbinsGetSearchListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageBin>>;
    public binsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, areaID, binDescription, binChineseDescription, page, take, views } : IbinsGetSearchListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageBin>>;
    @datastore
    public binsGetSearchList({ deviceId, message, ignoreLoadingBar, formData, areaID, binDescription, binChineseDescription, page, take, views } : IbinsGetSearchListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling binsGetSearchList.');
        }
        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (areaID !== undefined && areaID !== null) {
            queryParameters = queryParameters.set('areaID', <any>areaID);
        }
        if (binDescription !== undefined && binDescription !== null) {
            queryParameters = queryParameters.set('binDescription', <any>binDescription);
        }
        if (binChineseDescription !== undefined && binChineseDescription !== null) {
            queryParameters = queryParameters.set('binChineseDescription', <any>binChineseDescription);
        }
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (take !== undefined && take !== null) {
            queryParameters = queryParameters.set('take', <any>take);
        }
        if (views) {
            views.forEach((element) => {
                queryParameters = queryParameters.append('views', <any>element);
            })
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.get<PageBin>(`${this.basePath}/Bins/GetSearchList`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param binID 
     * @param model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsUpdate({ deviceId, message, ignoreLoadingBar, formData, binID, model } : IbinsUpdateParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public binsUpdate({ deviceId, message, ignoreLoadingBar, formData, binID, model } : IbinsUpdateParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public binsUpdate({ deviceId, message, ignoreLoadingBar, formData, binID, model } : IbinsUpdateParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public binsUpdate({ deviceId, message, ignoreLoadingBar, formData, binID, model } : IbinsUpdateParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (binID === null || binID === undefined) {
            throw new Error('Required parameter binID was null or undefined when calling binsUpdate.');
        }
        if (model === null || model === undefined) {
            throw new Error('Required parameter model was null or undefined when calling binsUpdate.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.put<string>(`${this.basePath}/Bins/${encodeURIComponent(String(binID))}`,
            model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param models 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IbinsUpdateByListParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public binsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IbinsUpdateByListParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public binsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IbinsUpdateByListParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public binsUpdateByList({ deviceId, message, ignoreLoadingBar, formData, models } : IbinsUpdateByListParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (models === null || models === undefined) {
            throw new Error('Required parameter models was null or undefined when calling binsUpdateByList.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }


        return this.httpClient.post<string>(`${this.basePath}/Bins/UpdateByList`,
            models,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param areaID 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public binsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, areaID } : IbinsUploadLocationsParams, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public binsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, areaID } : IbinsUploadLocationsParams, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public binsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, areaID } : IbinsUploadLocationsParams, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    @datastore
    public binsUploadLocations({ deviceId, message, ignoreLoadingBar, formData, areaID } : IbinsUploadLocationsParams, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (areaID === null || areaID === undefined) {
            throw new Error('Required parameter areaID was null or undefined when calling binsUploadLocations.');
        }
        let headers = this.defaultHeaders;
        if (deviceId != null) headers = headers.set('deviceId', deviceId);
        if (message != null) headers = headers.set('message', encodeURIComponent(message));
        if (ignoreLoadingBar) headers = headers.set('ignoreLoadingBar', '');
        
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/octet-stream',
            'application/xml',
            'text/xml',
            'multipart/form-data'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];


        return this.httpClient.post<string>(`${this.basePath}/Bins/UploadBin/${encodeURIComponent(String(areaID))}`,
            formData,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
